import React, { useState } from 'react';
import { Button, Modal, Spinner, buttonTypes } from '@sb-itops/react';
import smokeballIcon from 'web/assets/smokeball-icon.svg';
import wordLogo from 'web/assets/word-logo.svg';
import connectArrows from 'web/assets/connect-arrows.svg';
import externalLinkIcon from 'web/assets/external-link.svg';
import Styles from './Modal.module.scss';

export interface ICreateCorrespondenceDocumentModalProps {
  authorizationState: 'loading' | 'unauthorized' | 'authorized';
  onCreateCorrespondenceDocumentInWeb: (documentName: string | undefined) => void;
  onCreateCorrespondenceDocumentInDesktop: (documentName: string) => void;
  isCreatingCorrespondenceDocument: boolean;
  correspondenceDocumentUrl: string | undefined;
  interactive: boolean;
  showCreateInDesktop: boolean;
  onClose: () => void;
}

export const CreateCorrespondenceDocumentModal = ({
  authorizationState,
  onCreateCorrespondenceDocumentInWeb,
  onCreateCorrespondenceDocumentInDesktop,
  isCreatingCorrespondenceDocument,
  correspondenceDocumentUrl,
  interactive,
  showCreateInDesktop,
  onClose,
}: ICreateCorrespondenceDocumentModalProps) => {
  React.useEffect(() => {
    if (!interactive) {
      onCreateCorrespondenceDocumentInWeb(undefined);
    }
    setDocumentNameError('Please provide a valid document name.');
  }, []);
  const [documentName, setDocumentName] = useState('');
  const [documentNameError, setDocumentNameError] = useState('');
  const handleDocumentNameChange = (event: { target: { value: string } }) => {
    const value = event.target.value;
    setDocumentName(value);
    if (validateFileName(value)) {
      setDocumentNameError('');
    } else {
      setDocumentNameError('Invalid file name. Do not include extension as it will be docx.');
    }
  };

  let modalBody: JSX.Element;
  if (authorizationState === 'unauthorized') {
    modalBody = (
      <>
        <div className={Styles.connectIcons}>
          <img src={wordLogo} alt="Word" width={60} />
          <img src={connectArrows} alt="Connect" width={50} />
          <img src={smokeballIcon} alt="Smokeball" width={60} />
        </div>
        <div>
          <p>To open Word documents from Smokeball we require some additional permissions.</p>
          <p>This can be done by following the steps below:</p>
          <ol>
            <li>Open a Word document in your browser.</li>
            <li>
              <a
                href="https://support.smokeball.com/hc/en-au/articles/27531660842647"
                target="_blank"
                rel="noopener noreferrer"
              >
                Install the Smokeball Word Add-in.
              </a>
            </li>
            <li>Log in to Smokeball.</li>
            <li>Grant access to your OneDrive, where we will place your documents.</li>
            <li>
              Once the Add-in has been successfully installed, please close this window and try again from
              Archie&lsquo;s chat continue.
            </li>
          </ol>
        </div>
      </>
    );
  } else if (!interactive || authorizationState === 'loading' || isCreatingCorrespondenceDocument) {
    modalBody = (
      <div className={Styles.center}>
        <Spinner small />
      </div>
    );
  } else if (interactive) {
    modalBody = (
      <div className={Styles.container}>
        <label>Document name (required):</label>
        <input type="text" required maxLength={255} value={documentName} onChange={handleDocumentNameChange} />
        {documentNameError && <p style={{ color: 'red' }}>{documentNameError}</p>}
        {correspondenceDocumentUrl && (
          <a href={correspondenceDocumentUrl} target="_blank" rel="noreferrer">
            <img src={externalLinkIcon} alt="External link" className={Styles.externalLinkIcon} />
            Open Document
          </a>
        )}
      </div>
    );
  } else {
    modalBody = <div />;
  }
  let modalFooter: JSX.Element;
  const createButtonsAreDisabled =
    !interactive ||
    documentNameError !== '' ||
    isCreatingCorrespondenceDocument ||
    correspondenceDocumentUrl !== undefined ||
    authorizationState === 'unauthorized';

  if (interactive) {
    modalFooter = (
      <div className={Styles.createButtonContainer}>
        {showCreateInDesktop && (
          <Button
            className={Styles.createButton}
            type={buttonTypes.secondary}
            size="full-width"
            disabled={createButtonsAreDisabled}
            locked={isCreatingCorrespondenceDocument}
            onClick={() => onCreateCorrespondenceDocumentInDesktop(documentName)}
          >
            Create in Desktop
          </Button>
        )}
        <Button
          className={Styles.createButton}
          type={buttonTypes.secondary}
          size="full-width"
          disabled={createButtonsAreDisabled}
          locked={isCreatingCorrespondenceDocument}
          onClick={() => onCreateCorrespondenceDocumentInWeb(documentName)}
        >
          Create in Web
        </Button>
      </div>
    );
  } else {
    modalFooter = <div />;
  }

  return (
    <Modal
      className={`${Styles.modal} ${Styles.createCorrespondenceDocumentModal}`}
      title="Create Document"
      body={modalBody}
      footer={modalFooter}
      onModalClose={onClose}
    />
  );
};
const validateFileName = (value: string) => {
  if (value === undefined || value.trim() === '') {
    return false;
  }
  const pattern = /^[a-zA-Z0-9_\- ]+$/;
  return pattern.test(value);
};
