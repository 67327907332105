import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import {
  CreditNoteModal,
  CREDIT_NOTE_MODAL_ID,
  PaymentAddModal,
  ADD_PAYMENT_MODAL_ID,
  DepositFundsModal,
  DEPOSIT_FUNDS_MODAL_ID,
  TransferFundsModal,
  TRANSFER_FUNDS_MODAL_ID,
  AddLeadModal,
  ADD_LEAD_MODAL_ID,
  ContactCreateEditModal,
  CONTACT_CREATE_EDIT_MODAL_ID,
  CONVERT_LEAD_MODAL_ID,
  ConvertLeadModal,
  RESTORE_MATTER_MODAL_ID,
  ProtectBalanceAddModal,
  PROTECT_BALANCE_ADD_MODAL_ID,
  VendorPaymentEntrySplitPayorsModal,
  VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_MODAL_ID,
  InvoiceStatementPaymentAddModal,
  INVOICE_STATEMENT__PAYMENT_ADD_MODAL_ID,
  MarkAsSentModal,
  MARK_AS_SENT_MODAL_ID,
  AddMatterWizardModal,
  ADD_MATTER_WIZARD_MODAL_ID,
} from 'web/react-redux';

import {
  AddEventModalContainer,
  ADD_EVENT_MODAL_ID,
  AddTaskModal,
  AddPhoneMessageModal,
  AddPaymentModal,
  ADD_TASK_MODAL_ID,
  ADD_PHONE_MESSAGE_MODAL_ID,
  AddInvoicePaymentModal,
  InvoiceStatementAddPaymentModal,
  CreditCardPaymentModal,
  DepositFundsModal as DepositFundsNewModal,
  MarkAsSentModal as MarkAsSentNewModal,
  DRAFT_INVOICE_DELETE_PROCEED_MODAL_ID,
  DRAFT_INVOICE_PREVIEW_MODAL_ID,
  INVOICE_PREVIEW_MODAL_ID,
  DraftInvoiceDeleteProceedModal,
  DraftInvoicePreviewModal,
  InvoicePreviewModal,
  ExpenseModal,
  FeeModalContainer,
  FinaliseInvoiceConfirmationModal,
  INFOTRACK_MODAL_ID,
  InvoiceCommunicateModal,
  InvoiceEmailModal,
  OPERATING_CHEQUE_DETAILS_MODAL_ID,
  OperatingChequeDetailsModal,
  TRUST_CHEQUE_DETAILS_MODAL_ID,
  TrustChequeDetailsModal,
  PRINT_OPERATING_CHEQUE_MODAL_ID,
  PrintOperatingChequeModal,
  UnpaidAnticipatedDisbursementAcknowledgementModal,
  InvoiceStatementEmailModal,
  CorrespondenceHistoryModal,
  InfoTrackEmbeddedContainer,
} from 'web/components';

import { Modal, Button, buttonTypes } from '@sb-itops/react';
import { restoreDeletedMatter } from '@sb-matter-management/redux/matters';
import * as messageDisplay from '@sb-itops/message-display';
import classNames from 'classnames';
import { SDK_MODAL_ID, SdkIframeContainer } from 'web/react-redux/components/sb-client-sdk';
import * as Styles from './BillingModals.module.scss';

const BillingModals = memo(
  ({
    addPaymentNewModalProps,
    addInvoicePaymentModalProps,
    contactCreateEditModalProps,
    convertLeadModalProps,
    creditCardPaymentModalProps,
    creditModalProps,
    depositModalProps,
    depositFundsModalProps,
    draftInvoiceDeleteModalProps,
    draftInvoicePreviewModalProps,
    invoicePreviewModalProps,
    eventModalProps,
    expenseModalProps,
    feeModalProps,
    invoiceCommunicateModalProps,
    invoiceEmailModalProps,
    invoiceStatementAddPaymentNewModalProps,
    invoiceStatementEmailModalProps,
    isAddEventModalVisible,
    isAddInvoicePaymentModalVisible,
    isAddLeadModalVisible,
    isAddPaymentModalVisible,
    isAddPaymentNewModalVisible,
    isAddPhoneMessageModalVisible,
    addMatterWizardModalProps,
    isAddMatterWizardModalVisible,
    isAddTaskModalVisible,
    isContactCreateEditModalVisible,
    isConvertLeadModalVisible,
    isCreditCardPaymentModalVisible,
    isCreditNoteModalVisible,
    isDepositModalVisible,
    isDepositFundsModalVisible,
    isDraftInvoiceDeleteModalVisible,
    isDraftInvoicePreviewModalVisible,
    isInvoicePreviewModalVisible,
    isExpenseModalVisible,
    isFeeModalVisible,
    isFinaliseInvoiceConfirmationModalProps,
    isFinaliseInvoiceConfirmationModalVisible,
    isInvoiceCommunicateModalVisible,
    isInvoiceEmailModalVisible,
    isInvoiceStatementAddPaymentModalVisible,
    isInvoiceStatementAddPaymentNewModalVisible,
    isInvoiceStatementEmailModalVisible,
    isMarkAsSentModalVisible,
    isMarkAsSentNewModalVisible,
    isOperatingChequeDetailsModalVisible,
    isPrintOperatingChequeModalVisible,
    isProtectedFundsModalVisible,
    isRestoreMatterModalVisible,
    isTransferFundsModalVisible,
    isTrustChequeDetailsModalVisible,
    isUnpaidAnticipatedDisbursementAcknowledgementModalVisible,
    isVendorPaymentEntrySplitPayorsModalVisible,
    invoiceStatementPaymentModalProps,
    markAsSentModalProps,
    markAsSentNewModalProps,
    newPhoneNumberModalProps,
    onClickLink,
    onClickLinks,
    operatingChequeDetailsModalProps,
    paymentModalProps,
    printCheques,
    printOperatingChequeModalProps,
    protectedFundsModalProps,
    restoreMatterModalProps,
    taskModalProps,
    transferFundsModalProps,
    trustChequeDetailsModalProps,
    unpaidAnticipatedDisbursementAcknowledgementModalProps,
    vendorPaymentEntrySplitPayorsProps,
    isInfoTrackModalVisible,
    infoTrackModalProps,
    SDKModalProps,
    isSDKModalVisible,
    correspondenceHistoryModalProps,
    isCorrespondenceHistoryModalVisible,
  }) => (
    <>
      {isProtectedFundsModalVisible && (
        <ProtectBalanceAddModal
          matterId={protectedFundsModalProps.matterId}
          bankAccountId={protectedFundsModalProps.bankAccountId}
          isVisible={isProtectedFundsModalVisible}
          onClose={() => setModalDialogHidden({ modalId: PROTECT_BALANCE_ADD_MODAL_ID })}
        />
      )}
      {isConvertLeadModalVisible && (
        <ConvertLeadModal
          matterId={convertLeadModalProps.matterId}
          onClose={() => setModalDialogHidden({ modalId: CONVERT_LEAD_MODAL_ID })}
        />
      )}
      {isAddPaymentModalVisible && (
        <PaymentAddModal
          onClickLink={onClickLink}
          matterId={paymentModalProps.matterId}
          contactId={paymentModalProps.contactId}
          printCheques={printCheques}
          isVisible={isAddPaymentModalVisible}
          onClose={() => setModalDialogHidden({ modalId: ADD_PAYMENT_MODAL_ID })}
        />
      )}
      {/* LOD compatible version of PaymentAddModal */}
      {isAddPaymentNewModalVisible && (
        <AddPaymentModal
          {...addPaymentNewModalProps}
          showModal={isAddPaymentNewModalVisible}
          onClickLink={onClickLink}
          printCheques={printCheques}
        />
      )}

      {isAddInvoicePaymentModalVisible && (
        <AddInvoicePaymentModal
          {...addInvoicePaymentModalProps}
          showModal={isAddInvoicePaymentModalVisible}
          onClickLink={onClickLink}
          printCheques={printCheques}
        />
      )}

      {isInvoiceStatementAddPaymentModalVisible && (
        <InvoiceStatementPaymentAddModal
          isVisible={isInvoiceStatementAddPaymentModalVisible}
          invoiceStatementId={invoiceStatementPaymentModalProps.invoiceStatementId}
          onClose={() => setModalDialogHidden({ modalId: INVOICE_STATEMENT__PAYMENT_ADD_MODAL_ID })}
        />
      )}
      {/* LOD compatible version of InvoiceStatementPaymentAddModal */}
      {isInvoiceStatementAddPaymentNewModalVisible && (
        <InvoiceStatementAddPaymentModal
          {...invoiceStatementAddPaymentNewModalProps}
          showModal={isInvoiceStatementAddPaymentNewModalVisible}
        />
      )}

      {isDepositModalVisible && (
        <DepositFundsModal
          onClickLink={onClickLink}
          matterId={depositModalProps.matterId}
          contactId={depositModalProps.contactId}
          bankAccountId={depositModalProps.bankAccountId}
          isVisible={isDepositModalVisible}
          onClose={() => setModalDialogHidden({ modalId: DEPOSIT_FUNDS_MODAL_ID })}
        />
      )}
      {/* LOD compatible version of DepositFundsModal */}
      {isDepositFundsModalVisible && (
        <DepositFundsNewModal
          {...depositFundsModalProps}
          showModal={isDepositFundsModalVisible}
          onClickLink={onClickLink}
        />
      )}

      {isTransferFundsModalVisible && (
        <TransferFundsModal
          onClickLink={onClickLink}
          matterId={transferFundsModalProps.matterId}
          contactId={transferFundsModalProps.contactId}
          bankAccountId={transferFundsModalProps.bankAccountId}
          isVisible={isTransferFundsModalVisible}
          onClose={() => setModalDialogHidden({ modalId: TRANSFER_FUNDS_MODAL_ID })}
        />
      )}

      {isVendorPaymentEntrySplitPayorsModalVisible && (
        <VendorPaymentEntrySplitPayorsModal
          contactId={vendorPaymentEntrySplitPayorsProps.contactId}
          matterId={vendorPaymentEntrySplitPayorsProps.matterId}
          bankAccountId={vendorPaymentEntrySplitPayorsProps.bankAccountId}
          printCheques={printCheques}
          onClickLinks={onClickLinks}
          onClose={() => setModalDialogHidden({ modalId: VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_MODAL_ID })}
        />
      )}

      <CreditNoteModal
        {...creditModalProps}
        isVisible={isCreditNoteModalVisible}
        onClose={() => setModalDialogHidden({ modalId: CREDIT_NOTE_MODAL_ID })}
      />
      {isAddEventModalVisible && (
        <AddEventModalContainer
          appointmentData={eventModalProps}
          onClose={() => setModalDialogHidden({ modalId: ADD_EVENT_MODAL_ID })}
        />
      )}
      {isAddLeadModalVisible && <AddLeadModal onClose={() => setModalDialogHidden({ modalId: ADD_LEAD_MODAL_ID })} />}
      {isAddPhoneMessageModalVisible && (
        <AddPhoneMessageModal
          onClose={() => setModalDialogHidden({ modalId: ADD_PHONE_MESSAGE_MODAL_ID })}
          scope={newPhoneNumberModalProps.scope || ADD_PHONE_MESSAGE_MODAL_ID}
          matterId={newPhoneNumberModalProps.matterId}
          allowMatterSwitching={newPhoneNumberModalProps.allowMatterSwitching}
          task={newPhoneNumberModalProps.task}
          completer={newPhoneNumberModalProps.completer}
        />
      )}
      {isAddTaskModalVisible && (
        <AddTaskModal
          onClose={() => setModalDialogHidden({ modalId: ADD_TASK_MODAL_ID })}
          scope={taskModalProps.scope || ADD_TASK_MODAL_ID}
          matterId={taskModalProps.matterId}
          allowMatterSwitching={taskModalProps.allowMatterSwitching}
          task={taskModalProps.task}
          completer={taskModalProps.completer}
        />
      )}
      {isRestoreMatterModalVisible && (
        <Modal
          modal-id={RESTORE_MATTER_MODAL_ID}
          body="This will restore this deleted matter. Do you wish to continue?"
          title="Restore Matter"
          onModalClose={() => setModalDialogHidden({ modalId: RESTORE_MATTER_MODAL_ID })}
          footer={
            <div className={Styles.buttonsRightAlignContainer}>
              <Button
                className={Styles.button}
                onClick={() => {
                  try {
                    restoreDeletedMatter({ matterId: restoreMatterModalProps.matterId });
                    setModalDialogHidden({ modalId: RESTORE_MATTER_MODAL_ID });
                  } catch (err) {
                    messageDisplay.error('Failed to restore matter');
                  }
                }}
              >
                Yes
              </Button>
              <Button
                className={Styles.button}
                type={buttonTypes.secondary}
                onClick={() => {
                  setModalDialogHidden({ modalId: RESTORE_MATTER_MODAL_ID });
                }}
              >
                No
              </Button>
            </div>
          }
        />
      )}
      <ContactCreateEditModal
        isVisible={isContactCreateEditModalVisible}
        onClickLink={onClickLink}
        onClose={() => setModalDialogHidden({ modalId: CONTACT_CREATE_EDIT_MODAL_ID })}
        {...contactCreateEditModalProps}
      />
      <DraftInvoicePreviewModal
        isVisible={isDraftInvoicePreviewModalVisible}
        onClose={() => setModalDialogHidden({ modalId: DRAFT_INVOICE_PREVIEW_MODAL_ID })}
        {...draftInvoicePreviewModalProps}
      />
      {isInvoicePreviewModalVisible && (
        <InvoicePreviewModal
          isVisible={isInvoicePreviewModalVisible}
          onClose={() => setModalDialogHidden({ modalId: INVOICE_PREVIEW_MODAL_ID })}
          {...invoicePreviewModalProps}
        />
      )}
      {isFeeModalVisible && <FeeModalContainer {...feeModalProps} showModal={isFeeModalVisible} />}
      {isDraftInvoiceDeleteModalVisible && (
        <DraftInvoiceDeleteProceedModal
          isVisible={isDraftInvoiceDeleteModalVisible}
          onClose={() => setModalDialogHidden({ modalId: DRAFT_INVOICE_DELETE_PROCEED_MODAL_ID })}
          {...draftInvoiceDeleteModalProps}
        />
      )}
      {isInvoiceEmailModalVisible && (
        <InvoiceEmailModal {...invoiceEmailModalProps} showModal={isInvoiceEmailModalVisible} />
      )}
      {isInvoiceCommunicateModalVisible && (
        <InvoiceCommunicateModal {...invoiceCommunicateModalProps} showModal={isInvoiceCommunicateModalVisible} />
      )}
      {isInvoiceStatementEmailModalVisible && (
        <InvoiceStatementEmailModal
          {...invoiceStatementEmailModalProps}
          showModal={isInvoiceStatementEmailModalVisible}
        />
      )}
      {isExpenseModalVisible && <ExpenseModal {...expenseModalProps} showModal={isExpenseModalVisible} />}
      {isOperatingChequeDetailsModalVisible && (
        <OperatingChequeDetailsModal
          {...operatingChequeDetailsModalProps}
          isVisible={isOperatingChequeDetailsModalVisible}
          onClose={() => setModalDialogHidden({ modalId: OPERATING_CHEQUE_DETAILS_MODAL_ID })}
        />
      )}
      {isTrustChequeDetailsModalVisible && (
        <TrustChequeDetailsModal
          {...trustChequeDetailsModalProps}
          isVisible={isTrustChequeDetailsModalVisible}
          onClose={() => setModalDialogHidden({ modalId: TRUST_CHEQUE_DETAILS_MODAL_ID })}
        />
      )}
      {isPrintOperatingChequeModalVisible && (
        <PrintOperatingChequeModal
          {...printOperatingChequeModalProps}
          isVisible={isPrintOperatingChequeModalVisible}
          onClose={() => setModalDialogHidden({ modalId: PRINT_OPERATING_CHEQUE_MODAL_ID })}
        />
      )}
      {isCreditCardPaymentModalVisible && (
        <CreditCardPaymentModal {...creditCardPaymentModalProps} showModal={isCreditCardPaymentModalVisible} />
      )}
      {isUnpaidAnticipatedDisbursementAcknowledgementModalVisible && (
        <UnpaidAnticipatedDisbursementAcknowledgementModal
          {...unpaidAnticipatedDisbursementAcknowledgementModalProps}
          showModal={isUnpaidAnticipatedDisbursementAcknowledgementModalVisible}
        />
      )}
      {isMarkAsSentModalVisible && (
        <MarkAsSentModal
          {...markAsSentModalProps}
          isVisible={isMarkAsSentModalVisible}
          onClose={() => setModalDialogHidden({ modalId: MARK_AS_SENT_MODAL_ID })}
        />
      )}
      {isMarkAsSentNewModalVisible && (
        <MarkAsSentNewModal
          {...markAsSentNewModalProps}
          showModal={isMarkAsSentNewModalVisible}
          onClickLink={onClickLink}
        />
      )}
      {isFinaliseInvoiceConfirmationModalVisible && (
        <FinaliseInvoiceConfirmationModal
          {...isFinaliseInvoiceConfirmationModalProps}
          isVisible={isFinaliseInvoiceConfirmationModalVisible}
        />
      )}
      {isAddMatterWizardModalVisible && (
        <AddMatterWizardModal
          onClickLink={onClickLink}
          isVisible={isAddMatterWizardModalVisible}
          onClose={() => setModalDialogHidden({ modalId: ADD_MATTER_WIZARD_MODAL_ID })}
          {...addMatterWizardModalProps}
        />
      )}
      {isInfoTrackModalVisible && (
        <Modal
          className={classNames(Styles.largeModal)}
          showModalBodyPadding={false}
          title={infoTrackModalProps.title}
          showFooter={false}
          isModalBodyScrollable={false}
          modalBodyClassName={Styles.modalBody}
          body={<InfoTrackEmbeddedContainer {...infoTrackModalProps} />}
          onModalClose={() => {
            setModalDialogHidden({ modalId: INFOTRACK_MODAL_ID });
          }}
        />
      )}
      {isSDKModalVisible && (
        <Modal
          className={classNames(Styles.largeModal)}
          showModalBodyPadding={false}
          title={SDKModalProps.title}
          showFooter={false}
          isModalBodyScrollable={false}
          modalBodyClassName={Styles.modalBody}
          body={<SdkIframeContainer {...SDKModalProps} />}
          onModalClose={() => {
            setModalDialogHidden({ modalId: SDK_MODAL_ID });
          }}
        />
      )}
      {isCorrespondenceHistoryModalVisible && (
        <CorrespondenceHistoryModal
          {...correspondenceHistoryModalProps}
          showModal={isCorrespondenceHistoryModalVisible}
        />
      )}
    </>
  ),
);

BillingModals.displayName = 'BillingModals';

BillingModals.propTypes = {
  addPaymentNewModalProps: PropTypes.object,
  addInvoicePaymentModalProps: PropTypes.object,
  contactCreateEditModalProps: PropTypes.object,
  convertLeadModalProps: PropTypes.object,
  creditCardPaymentModalProps: PropTypes.object,
  creditModalProps: PropTypes.object,
  depositModalProps: PropTypes.object,
  depositFundsModalProps: PropTypes.object,
  draftInvoiceDeleteModalProps: PropTypes.object,
  draftInvoicePreviewModalProps: PropTypes.object,
  invoicePreviewModalProps: PropTypes.object,
  eventModalProps: PropTypes.object,
  expenseModalProps: PropTypes.object,
  feeModalProps: PropTypes.object,
  invoiceCommunicateModalProps: PropTypes.object,
  SDKModalProps: PropTypes.object,
  infoTrackModalProps: PropTypes.object,
  invoiceEmailModalProps: PropTypes.object,
  invoiceStatementAddPaymentNewModalProps: PropTypes.object,
  invoiceStatementEmailModalProps: PropTypes.object,
  isAddEventModalVisible: PropTypes.bool,
  isAddInvoicePaymentModalVisible: PropTypes.bool,
  isAddLeadModalVisible: PropTypes.bool,
  addMatterWizardModalProps: PropTypes.object,
  isAddMatterWizardModalVisible: PropTypes.bool,
  isAddPaymentModalVisible: PropTypes.bool,
  isAddPaymentNewModalVisible: PropTypes.bool,
  isAddPhoneMessageModalVisible: PropTypes.bool,
  isAddTaskModalVisible: PropTypes.bool,
  isContactCreateEditModalVisible: PropTypes.bool,
  isConvertLeadModalVisible: PropTypes.bool,
  isCreditCardPaymentModalVisible: PropTypes.bool,
  isCreditNoteModalVisible: PropTypes.bool,
  isDepositModalVisible: PropTypes.bool,
  isDepositFundsModalVisible: PropTypes.bool,
  isDraftInvoiceDeleteModalVisible: PropTypes.bool,
  isDraftInvoicePreviewModalVisible: PropTypes.bool,
  isInvoicePreviewModalVisible: PropTypes.bool,
  isExpenseModalVisible: PropTypes.bool,
  isFeeModalVisible: PropTypes.bool,
  isSDKModalVisible: PropTypes.bool,
  isInfoTrackModalVisible: PropTypes.bool,
  isFinaliseInvoiceConfirmationModalProps: PropTypes.object,
  isFinaliseInvoiceConfirmationModalVisible: PropTypes.bool,
  isInvoiceCommunicateModalVisible: PropTypes.bool,
  isInvoiceEmailModalVisible: PropTypes.bool,
  isInvoiceStatementEmailModalVisible: PropTypes.bool,
  isInvoiceStatementAddPaymentModalVisible: PropTypes.bool,
  isInvoiceStatementAddPaymentNewModalVisible: PropTypes.bool,
  isMarkAsSentModalVisible: PropTypes.bool,
  isMarkAsSentNewModalVisible: PropTypes.bool,
  isOperatingChequeDetailsModalVisible: PropTypes.bool,
  isPrintOperatingChequeModalVisible: PropTypes.bool,
  isProtectedFundsModalVisible: PropTypes.bool,
  isRestoreMatterModalVisible: PropTypes.bool,
  isTransferFundsModalVisible: PropTypes.bool,
  isTrustChequeDetailsModalVisible: PropTypes.bool,
  isUnpaidAnticipatedDisbursementAcknowledgementModalVisible: PropTypes.bool,
  isVendorPaymentEntrySplitPayorsModalVisible: PropTypes.bool,
  invoiceStatementPaymentModalProps: PropTypes.object,
  markAsSentModalProps: PropTypes.object,
  markAsSentNewModalProps: PropTypes.object,
  newPhoneNumberModalProps: PropTypes.object,
  onClickLink: PropTypes.func.isRequired,
  onClickLinks: PropTypes.func.isRequired,
  operatingChequeDetailsModalProps: PropTypes.object,
  paymentModalProps: PropTypes.object,
  printCheques: PropTypes.func,
  printOperatingChequeModalProps: PropTypes.object,
  protectedFundsModalProps: PropTypes.object,
  restoreMatterModalProps: PropTypes.object,
  taskModalProps: PropTypes.object,
  transferFundsModalProps: PropTypes.object,
  trustChequeDetailsModalProps: PropTypes.object,
  unpaidAnticipatedDisbursementAcknowledgementModalProps: PropTypes.object,
  vendorPaymentEntrySplitPayorsProps: PropTypes.object,
  isCorrespondenceHistoryModalVisible: PropTypes.bool,
  correspondenceHistoryModalProps: PropTypes.object,
};

BillingModals.defaultProps = {
  addPaymentNewModalProps: {},
  addInvoicePaymentModalProps: {},
  addMatterWizardModalProps: {},
  contactCreateEditModalProps: {},
  convertLeadModalProps: {},
  creditCardPaymentModalProps: {},
  creditModalProps: {},
  depositModalProps: {},
  depositFundsModalProps: {},
  draftInvoiceDeleteModalProps: {},
  draftInvoicePreviewModalProps: {},
  invoicePreviewModalProps: {},
  eventModalProps: {},
  expenseModalProps: {},
  feeModalProps: {},
  SDKModalProps: {},
  infoTrackModalProps: {},
  invoiceCommunicateModalProps: {},
  invoiceEmailModalProps: {},
  invoiceStatementAddPaymentNewModalProps: {},
  invoiceStatementEmailModalProps: {},
  isAddEventModalVisible: false,
  isAddInvoicePaymentModalVisible: false,
  isAddLeadModalVisible: false,
  isAddMatterWizardModalVisible: false,
  isAddPaymentModalVisible: false,
  isAddPaymentNewModalVisible: false,
  isAddPhoneMessageModalVisible: false,
  isAddTaskModalVisible: false,
  isContactCreateEditModalVisible: false,
  isConvertLeadModalVisible: false,
  isCreditCardPaymentModalVisible: false,
  isCreditNoteModalVisible: false,
  isDepositModalVisible: false,
  isSDKModalVisible: false,
  isInfoTrackModalVisible: false,
  isDepositFundsModalVisible: false,
  isDraftInvoiceDeleteModalVisible: false,
  isDraftInvoicePreviewModalVisible: false,
  isInvoicePreviewModalVisible: false,
  isExpenseModalVisible: false,
  isFeeModalVisible: false,
  isFinaliseInvoiceConfirmationModalProps: {},
  isFinaliseInvoiceConfirmationModalVisible: undefined,
  isInvoiceCommunicateModalVisible: false,
  isInvoiceEmailModalVisible: false,
  isInvoiceStatementEmailModalVisible: false,
  isInvoiceStatementAddPaymentModalVisible: false,
  isInvoiceStatementAddPaymentNewModalVisible: false,
  isMarkAsSentModalVisible: false,
  isMarkAsSentNewModalVisible: false,
  isOperatingChequeDetailsModalVisible: false,
  isPrintOperatingChequeModalVisible: false,
  isProtectedFundsModalVisible: false,
  isRestoreMatterModalVisible: false,
  isTransferFundsModalVisible: false,
  isTrustChequeDetailsModalVisible: false,
  isUnpaidAnticipatedDisbursementAcknowledgementModalVisible: false,
  isVendorPaymentEntrySplitPayorsModalVisible: false,
  invoiceStatementPaymentModalProps: {},
  markAsSentModalProps: {},
  markAsSentNewModalProps: {},
  newPhoneNumberModalProps: {},
  operatingChequeDetailsModalProps: {},
  paymentModalProps: {},
  printCheques: () => {},
  printOperatingChequeModalProps: {},
  protectedFundsModalProps: {},
  restoreMatterModalProps: {},
  taskModalProps: {},
  transferFundsModalProps: {},
  trustChequeDetailsModalProps: {},
  unpaidAnticipatedDisbursementAcknowledgementModalProps: {},
  vendorPaymentEntrySplitPayorsProps: {},
  isCorrespondenceHistoryModalVisible: false,
  correspondenceHistoryModalProps: {},
};

export default BillingModals;
