import { ApolloClient, from } from '@apollo/client';
import { getApolloCache } from './cache';
import { preferredLink, authLink, retryLink, errorLink } from './links';
import { clearCacheSubscriptions } from './subscription-manager';
import { stopPolling } from './polling-manager';

let apolloClient: ApolloClient<unknown> | null = null;
let sbInitCache: object = {};

/**
 * Get Apollo Client
 * Retrieve or create a singleton of the Apollo Client.
 *
 * @returns the Apollo Client
 */
export const getApolloClient = () => {
  try {
    if (!apolloClient) {
      apolloClient = new ApolloClient({
        cache: getApolloCache(),
        link: from([retryLink, errorLink, authLink(), preferredLink()]),
        // connectToDevTools: true,
      });
    }

    return apolloClient;
  } catch (error) {
    // For all requests ApolloLink needs to send the authentication headers.
    // If you are seeing this error the issue is likely that this function
    // was invoked in a top-level scope, before the authentication bootstrap
    // had a chance to complete.
    // This function acts as a singleton and is safe to call repeatedly.
    throw new Error(
      `Attempting to initialise ApolloClient before authentication bootstrap is complete. The issue is likely getApolloClient() getting invoked in the top-level scope rather than via a function that runs post-bootstrap. ${error}`,
    );
  }
};

/**
 * Destroy Apollo Client
 * Clear the cache store and reset the client to null. This is necessary in
 * order to switch between different users as the authLink needs to be reset.
 *
 * @returns true if the client exists and is successfully cleared
 */
export const destroyApolloClient = async () => {
  if (apolloClient) {
    clearCacheSubscriptions();
    stopPolling();

    await apolloClient.clearStore();
    apolloClient = null;
    sbInitCache = {};
  }

  return true;
};

export const getInitCache = ({ queryName }) => sbInitCache[queryName];

export const setInitCache = ({ queryName, data }) => {
  sbInitCache[queryName] = data;
};

export { errors, addError } from './links';
