import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, LoadingBarInfinite, Modal, Spinner, forms2PropTypes, useTranslation } from '@sb-itops/react';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { PaymentProviderChargeForm } from '@sb-billing/payment-provider-charge-form';

import Styles from './CreditCardPaymentModal.module.scss';
import { CreditCardPaymentForm } from '../credit-card-payment-form';

const { Forms2Field } = forms2PropTypes;

export const CreditCardPaymentModal = ({
  /** Modal */
  invoiceNumber,
  invoiceNumberSuffix,
  isProcessButtonDisabled,
  isLoading,
  showCreditCardPaymentModal,
  showTotalChargeAmount,
  totalChargeAmount,
  // Callbacks
  onCreditCardPaymentModalClose,
  onTriggerSubmission,
  /** CreditCardPaymentForm props */
  balanceDueAfterPayment,
  clientIsCoveringFee,
  contactOptions,
  feeDetails,
  isPaymentFormInitialised,
  minAmountAllowed,
  paymentAmount,
  paymentDate,
  payorId,
  reason,
  showReasonField,
  // Callbacks
  onPaymentFormFieldChange,
  /** PaymentProviderChargeForm props */
  paymentFormConfiguration,
  triggerPaymentProviderChargeFormSubmit,
  // Callbacks
  onPaymentProviderChargeFormPreCharge,
  onPaymentProviderChargeFormPreSubmitChange,
  onPaymentProviderChargeFormReadyToSubmit,
  onPaymentProviderChargeFormSubmit,
  /** CreditCardPaymentForm & PaymentProviderChargeForm shared props */
  isSubmitting,
  providerType,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`Credit Card Payment for Invoice #${invoiceNumber}${invoiceNumberSuffix ? `-${invoiceNumberSuffix}` : ''}`}
      className={Styles.creditCardPaymentModal}
      modalHeaderClassName={classNames(Styles.modalHeaderContainer, isLoading && Styles.hide)}
      modalBodyClassName={Styles.modalBodyContainer}
      body={
        isLoading ? (
          <div className={Styles.loadingBarContainer}>
            <LoadingBarInfinite loading={isLoading} />
          </div>
        ) : (
          <div className={Styles.modalBody}>
            <CreditCardPaymentForm
              balanceDueAfterPayment={balanceDueAfterPayment}
              clientIsCoveringFee={clientIsCoveringFee}
              contactOptions={contactOptions}
              feeDetails={feeDetails}
              formInitialised={isPaymentFormInitialised}
              isSubmitting={isSubmitting}
              minAmountAllowed={minAmountAllowed}
              paymentAmount={paymentAmount}
              paymentDate={paymentDate}
              payorId={payorId}
              providerType={providerType}
              reason={reason}
              showReasonField={showReasonField}
              // Callbacks
              onFormFieldChange={onPaymentFormFieldChange}
            />
            <PaymentProviderChargeForm
              isSubmitting={isSubmitting}
              paymentFormConfiguration={paymentFormConfiguration}
              providerType={providerType}
              triggerSubmit={triggerPaymentProviderChargeFormSubmit}
              // Callbacks
              onPreCharge={onPaymentProviderChargeFormPreCharge}
              onPreSubmitChange={onPaymentProviderChargeFormPreSubmitChange}
              onReadyForSubmit={onPaymentProviderChargeFormReadyToSubmit}
              onSubmit={onPaymentProviderChargeFormSubmit}
            />
          </div>
        )
      }
      footer={
        <div className={Styles.modalFooter}>
          {showTotalChargeAmount && (
            <div className={Styles.totalChargeAmount}>
              Total charge <span className={Styles.amount}>{t('cents', { val: totalChargeAmount })}</span>
            </div>
          )}
          <Button onClick={onTriggerSubmission} disabled={isProcessButtonDisabled}>
            Process {isSubmitting && <Spinner small />}
          </Button>
        </div>
      }
      isVisible={showCreditCardPaymentModal}
      onModalClose={onCreditCardPaymentModalClose}
    />
  );
};

CreditCardPaymentModal.displayName = 'CreditCardPaymentModal';

CreditCardPaymentModal.propTypes = {
  /** Modal */
  invoiceNumber: PropTypes.number,
  invoiceNumberSuffix: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isProcessButtonDisabled: PropTypes.bool.isRequired,
  showCreditCardPaymentModal: PropTypes.bool.isRequired,
  showTotalChargeAmount: PropTypes.bool,
  totalChargeAmount: PropTypes.number,
  // Callbacks
  onCreditCardPaymentModalClose: PropTypes.func.isRequired,
  onTriggerSubmission: PropTypes.func.isRequired,
  /** CreditCardPaymentForm props */
  balanceDueAfterPayment: PropTypes.number,
  clientIsCoveringFee: PropTypes.bool,
  contactOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      display: PropTypes.string,
    }),
  ).isRequired,
  feeDetails: PropTypes.object,
  isPaymentFormInitialised: PropTypes.bool.isRequired,
  minAmountAllowed: PropTypes.number,
  paymentAmount: Forms2Field,
  paymentDate: Forms2Field,
  payorId: Forms2Field,
  reason: Forms2Field,
  showReasonField: PropTypes.bool.isRequired,
  // Callbacks
  onPaymentFormFieldChange: PropTypes.func.isRequired,
  /** PaymentProviderChargeForm props */
  paymentFormConfiguration: PropTypes.object.isRequired,
  triggerPaymentProviderChargeFormSubmit: PropTypes.bool.isRequired,
  // Callbacks
  onPaymentProviderChargeFormPreCharge: PropTypes.func.isRequired,
  onPaymentProviderChargeFormPreSubmitChange: PropTypes.func.isRequired,
  onPaymentProviderChargeFormReadyToSubmit: PropTypes.func.isRequired,
  onPaymentProviderChargeFormSubmit: PropTypes.func.isRequired,
  /** CreditCardPaymentForm & PaymentProviderChargeForm shared props */
  isSubmitting: PropTypes.bool.isRequired,
  providerType: PropTypes.oneOf(Object.values(providers)).isRequired,
};

CreditCardPaymentModal.defaultProps = {
  balanceDueAfterPayment: undefined,
  clientIsCoveringFee: undefined,
  feeDetails: undefined,
  invoiceNumber: undefined,
  invoiceNumberSuffix: undefined,
  minAmountAllowed: undefined,
  paymentAmount: undefined,
  paymentDate: undefined,
  payorId: undefined,
  reason: undefined,
  showTotalChargeAmount: undefined,
  totalChargeAmount: undefined,
};
