'use strict';

import { featureActive } from '@sb-itops/feature';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { INVOICE_EMAIL_MODAL_ID } from 'web/components';

angular.module('sb.billing.webapp').component('sbInvoiceEmailModalButton', {
  bindings: { modalScope: '<', invoiceIds: '<', preselectedSplitBillingDebtorId: '<?' },
  templateUrl: 'ng-components/invoice-email-modal-button/invoice-email-modal-button.html',
  controller: function (sbInvoiceSendService, sbLoggerService) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbInvoiceEmailModalButton');

    ctrl.onOpenModal = onOpenModal;
    ctrl.onSendEmails = onSendEmails;
    ctrl.onCloseModal = onCloseModal;

    ctrl.viewState = {
      modalVisible: false,
      emailSendingInProgress: false,
    };

    function onOpenModal() {
      if (featureActive('BB-12838')) {
        setModalDialogVisible({
          modalId: INVOICE_EMAIL_MODAL_ID,
          props: {
            scope: 'view-bill-invoice-email-modal',
            invoiceIds: ctrl.invoiceIds,
            preselectedSplitBillingDebtorId: ctrl.preselectedSplitBillingDebtorId,
            onPreview: ({
              invoiceEmailRequest,
            }) => sbInvoiceSendService.createInvoiceEmailPreviewP({
              invoiceEmailRequest,
            }),
            onSendEmails: async ({ invoiceEmailRequests }) => {
              try {
                await sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequests);
              } catch (err) {
                log.error('Failed to send emails', err);
              } finally {
                ctrl.viewState.emailSendingInProgress = false;
              }
            }
          }
        });
      } else {
        ctrl.viewState.modalVisible = true;
      }
    }

    async function onSendEmails(invoiceEmailRequests) {
      try {
        ctrl.viewState.emailSendingInProgress = true;
        onCloseModal();
        await sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequests);
      } catch (err) {
        log.error('Failed to send emails', err);
      } finally {
        ctrl.viewState.emailSendingInProgress = false;
      }
    }

    function onCloseModal() {
      ctrl.viewState.modalVisible = false;
    }
  }
});