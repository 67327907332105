'use strict';

const { splitBillingMethods, splitBillingMethodLabels } = require('./split-billing-method');

/**
 * Get debtors percentage lookup for draft invoice screen
 * @param {object} params
 * @param {Object} params.invoiceVersion
 * @param {Object} params.matterSplitBillingSettings
 * @returns {Object}
 */
function getSplitBillingDebtorsPercentageLookup({ splitBillingSettings }) {
  // split billing settings should be fetch from
  // 1) If the invoice has a split billing setting we use it
  // 2) if not exist, fetch from matter settings
  let debtorsLookup = {};

  // Handle based on split method
  if (splitBillingSettings.splitMethod === splitBillingMethods.USE_RATIO) {
    // Create lookup with percentages
    debtorsLookup = splitBillingSettings.debtors.reduce((acc, debtor) => {
      acc[debtor.debtorId] = `${debtor.debtorRatio / 100}%`;
      return acc;
    }, {});
  } else {
    // Create lookup with "Split Evenly"
    debtorsLookup = splitBillingSettings.debtors.reduce((acc, debtor) => {
      acc[debtor.debtorId] = splitBillingMethodLabels.SPLIT_EVENLY;
      return acc;
    }, {});
  }

  return debtorsLookup;
}

module.exports = {
  getSplitBillingDebtorsPercentageLookup,
};
