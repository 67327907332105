/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Typeahead, FormLabel, useTranslation, IField } from '@sb-itops/react';
import classnames from 'classnames';
import { TSchemeValuesFor } from '@sb-itops/region-schemes';
import { getStates } from '@sb-customer-management/business-logic/contacts/services/get-states';
import { featureActive } from '@sb-itops/feature';
import { getRegion } from '@sb-itops/region';
import {
  countriesListOptions,
  defaultCountryByRegion,
} from '@sb-customer-management/business-logic/contacts/entities/constants';
import { AddressAutocomplete } from '../address-autocomplete';
import Styles from './AddressForm.module.scss';

type AddressFormUsProps = {
  addressScheme: TSchemeValuesFor<'addressScheme'>;
  formDisabled?: boolean;
  formInitialised?: boolean;
  addressLine1?: IField<string>;
  addressLine2?: IField<string>;
  city?: IField<string>;
  state?: IField<string>;
  country?: IField<string>;
  zipCode?: IField<string>;
  onFieldValueUpdated: (update: Record<string, string | number | undefined>) => void;
  onAddressAutocompleteSelected: (addressInfo: Record<string, string>) => void;
  submitFailed?: boolean;
  optional?: boolean;
  hideCountry?: boolean;
  optionalExceptState?: boolean;
  useManualEntrySwitch?: boolean;
  validateForm: () => void;
};

export const AddressFormUs = ({
  formInitialised,
  addressScheme,
  addressLine1,
  addressLine2,
  city,
  state,
  zipCode,
  country,
  hideCountry,
  onFieldValueUpdated,
  formDisabled,
  submitFailed,
  validateForm,
  optional,
  optionalExceptState,
  onAddressAutocompleteSelected,
  useManualEntrySwitch,
}: AddressFormUsProps) => {
  const { t } = useTranslation();
  const [manualEntry, setManualEntry] = useState(false);

  if (!formInitialised) {
    return null;
  }

  const stateOptions = getStates(addressScheme);
  const isErrorClassnames = (field, style) =>
    field?.isInvalid && (field?.isDirty || submitFailed) ? classnames(Styles.hasError, style) : '';

  const formIsOptional = optional || optionalExceptState;

  const allowFreeStateInput = featureActive('NUCWEB-892') && defaultCountryByRegion[getRegion()] !== country?.value;

  let countryOptions = countriesListOptions;

  if (country?.value && !countryOptions.find((c) => c.value === country?.value)) {
    countryOptions = [...countriesListOptions, { label: country?.value, value: country?.value }];
  }

  return (
    <div className={Styles.addressForm}>
      <fieldset disabled={formDisabled}>
        {(!useManualEntrySwitch || (!manualEntry && !addressLine1?.value)) && (
          <div className="row">
            <div className="col-xs-12">
              <AddressAutocomplete
                label={
                  useManualEntrySwitch && !addressLine1?.value && !optional && !optionalExceptState
                    ? 'Address Search *'
                    : 'Address Search'
                }
                onSelect={onAddressAutocompleteSelected}
                country={country?.value}
              />
              {useManualEntrySwitch && (
                <a className={Styles.link} onClick={() => setManualEntry(true)}>
                  Enter Address Manually
                </a>
              )}
            </div>
          </div>
        )}
        {(!useManualEntrySwitch || manualEntry || addressLine1?.value) && (
          <>
            <div className="row">
              <div className="col-xs-12">
                <FormLabel
                  label="Address Line 1"
                  field={addressLine1}
                  submitFailed={submitFailed}
                  optional={formIsOptional}
                  uppercase={false}
                />
                <input
                  name="addressLine1"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(addressLine1, Styles.formControl))}
                  value={addressLine1?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ addressLine1: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <label>Address Line 2</label>
                <input
                  name="addressLine2"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(addressLine2, Styles.formControl))}
                  value={addressLine2?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ addressLine2: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <FormLabel
                  label={t('suburb')}
                  field={city}
                  optional={formIsOptional}
                  submitFailed={submitFailed}
                  uppercase={false}
                />
                <input
                  name="city"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(city, Styles.formControl))}
                  value={city?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ city: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
              <div className="col-xs-3">
                <FormLabel
                  label={t('addressState')}
                  field={state}
                  submitFailed={submitFailed}
                  optional={optional && !optionalExceptState}
                  uppercase={false}
                />
                {allowFreeStateInput && (
                  <input
                    name="state"
                    type="text"
                    className={classnames('form-control', isErrorClassnames(state, Styles.formControl))}
                    value={state?.value || ''}
                    onChange={(e) => {
                      onFieldValueUpdated({ state: e.target.value });
                      validateForm();
                    }}
                    onBlur={validateForm}
                  />
                )}
                {!allowFreeStateInput && (
                  <Typeahead
                    selectedOption={
                      stateOptions.find((option) => option.value === state?.value || option.label === state?.value) ||
                      undefined
                    }
                    menuPlacement="top"
                    options={stateOptions}
                    disabled={formDisabled}
                    placeholder="Select..."
                    className={classnames(Styles.formSelect, isErrorClassnames(state, Styles.formSelect))}
                    onSelect={(option) => {
                      onFieldValueUpdated({ state: option?.value || undefined });
                      validateForm();
                    }}
                  />
                )}
              </div>
              <div className="col-xs-3">
                <FormLabel
                  label={t('postcode')}
                  field={zipCode}
                  submitFailed={submitFailed}
                  optional={formIsOptional}
                  uppercase={false}
                />
                <input
                  name="zipCode"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(zipCode, Styles.formControl))}
                  value={zipCode?.value || ''}
                  onBlur={validateForm}
                  onChange={(e) => {
                    onFieldValueUpdated({ zipCode: e.target.value });
                  }}
                />
              </div>
            </div>
            {!hideCountry && featureActive('NUCWEB-892') && (
              <div className="row">
                <div className="col-xs-6">
                  <FormLabel
                    label="Country"
                    field={country}
                    submitFailed={submitFailed}
                    optional={formIsOptional}
                    uppercase={false}
                  />
                  <Typeahead
                    selectedOption={country?.value || ''}
                    menuPlacement="top"
                    options={countryOptions}
                    disabled={formDisabled}
                    placeholder=""
                    className={classnames(Styles.formSelect, isErrorClassnames(country, Styles.formSelect))}
                    onSelect={(option) => {
                      onFieldValueUpdated({ country: option?.value || undefined });
                      validateForm();
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </fieldset>
    </div>
  );
};
