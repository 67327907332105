import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withOnLoad } from '@sb-itops/react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { InitFirmTaxSettings, InitStaffSettings, ProductivityFirmActivities } from 'web/graphql/queries';
import { useCacheQuery, useSubscribedQuery } from 'web/hooks';
import { getCurrentUsers, getLoggedInUserId } from '@sb-firm-management/redux/firm-management';
import { productTierIdsByName } from '@sb-finance/business-logic/subscription/entities/constants';
import { useSelector } from 'react-redux';
import { getProductTier } from 'web/services/user-session-management';
import { xmlToJson } from '@sb-itops/xml2json';
import moment from 'moment';
import { withApolloClient } from '../../react-redux/hocs/withApolloClient';
import { ActivitiesTab, activityTypeByName } from './ActivitiesTab';

const limit = 500;

const categoryFilterMap = {
  Unknown: 0,
  'Matter Opened': 1,
  'Lead Opened': 11,
  Event: 2,
  'Matter Administration': 8,
  Document: 4,
  Email: 5,
  Task: 3,
  Message: 10,
  Memo: 6,
  'Intake Form': 12,
  Archie: 14,
};

const defaultFilters = {
  Category: {
    Unknown: true,
    'Matter Opened': true,
    'Lead Opened': true,
    Event: true,
    Task: true,
    Document: true,
    Email: true,
    Message: true,
    Memo: true,
    'Intake Form': true,
    'Matter Administration': true,
    Archie: true,
  },
  'Time Entry': {
    'Show Added': true,
    'Show Not Added': true,
  },
  Type: {
    Matter: true,
    Lead: true,
    Unassigned: true,
  },
};
const defaultBoostFilters = {
  Category: {
    Unknown: true,
    'Matter Opened': true,
    'Lead Opened': true,
    Event: true,
    Task: true,
    Message: true,
    Memo: true,
    'Intake Form': true,
    Archie: true,
  },
  'Time Entry': {
    'Show Added': true,
    'Show Not Added': true,
  },
  Type: {
    Matter: true,
    Lead: true,
    Unassigned: true,
  },
};

const hooks = ({ matterId }) => ({
  useGraphQL: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const { t } = useTranslation();

    const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);
    const { taxRate: taxRateBasisPoints } = firmTaxSettingsData?.firmTaxSettings || {};

    const productTier = useSelector(getProductTier);

    const [filters, setFilters] = useState({
      ...([productTierIdsByName.BILL, productTierIdsByName.BOOST].includes(productTier)
        ? defaultBoostFilters
        : defaultFilters),
    });
    const [selectedTimeFrame, setSelectedTimeFrame] = useState('month');
    const [selectedTime, setSelectedTime] = useState(moment());
    const [lastQueryTime, setLastQueryTime] = useState(moment());
    const [sortDirection, setSortDirection] = useState('DESC');
    const [collapsed, setCollapsed] = useState({});
    const [userFilter, setUserFilter] = useState({ [getLoggedInUserId()]: true });

    const startTime = moment(selectedTime).startOf(selectedTimeFrame);
    const endTime = moment(startTime).add(1, selectedTimeFrame);

    const activityQueryResult = useSubscribedQuery(ProductivityFirmActivities, {
      variables: {
        filter: {
          activityDate: {
            from: startTime.toISOString(),
            to: endTime.toISOString(),
          },
          matterId,
          sortDirection,
          userIds: Object.keys(userFilter).filter((id) => id && userFilter[id]),
          categories: Object.keys(filters.Category)
            .filter((c) => filters.Category[c])
            .map((c) => categoryFilterMap[c]),
          showWithTimeEntries: filters['Time Entry']['Show Added'],
          showWithoutTimeEntries: filters['Time Entry']['Show Not Added'],
        },
        matterFilter: {
          includeNonBillableLeadMatters: true,
        },
        offset: 0,
        limit,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        setLastQueryTime(moment());
      },
    });

    if (activityQueryResult.error) {
      throw new Error(activityQueryResult.error);
    }

    const firmUsers = getCurrentUsers();

    const activities = (activityQueryResult?.data?.activityList?.results || []).filter((a) => {
      if (!filters.Type.Matter && a?.matter?.id && !a?.matter?.isLead) {
        return false;
      }
      if (!filters.Type.Lead && a?.matter?.id && a?.matter?.isLead) {
        return false;
      }
      if (!filters.Type.Unassigned && !a?.matter?.id) {
        return false;
      }
      return true;
    });

    let feeDurationTotal = 0;

    const matters = {};
    const activitiesByDay = {};

    const activityListWithHeaders = [];

    activities.forEach((a) => {
      let dateForDisplay = moment(a.dateTime || 0);

      // These activities get collated and so all have a dateTime of 12am. We must check the LastMessageDateTime for the real updated timestamp
      if (
        [
          activityTypeByName.Memo,
          activityTypeByName.Intake,
          activityTypeByName['Matter Admin'],
          activityTypeByName.Messages,
        ].includes(a.activityType)
      ) {
        const xmlDOM = new DOMParser().parseFromString(a.activityData, 'text/xml');
        const activityData = xmlToJson(xmlDOM);
        dateForDisplay = activityData?.CompoundActivity?.LastMessageDateTime
          ? moment(activityData?.CompoundActivity?.LastMessageDateTime)
          : dateForDisplay;
      }
      const day = dateForDisplay.format('YYYYMMDD');
      if (!activitiesByDay[day]) {
        activitiesByDay[day] = {
          activityCount: 0,
          durationInMinutes: 0,
        };
        activityListWithHeaders.push({
          isRowHeader: true,
          day,
          dateTime: dateForDisplay,
          displayName: `${t('date', {
            date: dateForDisplay.toDate(),
            format: 'Do MMMM',
          })}, ${dateForDisplay.format('YYYY')}`,
        });
      }
      activitiesByDay[day].activityCount += 1;
      if (!collapsed[day]) {
        activityListWithHeaders.push({ ...a, dateTime: dateForDisplay });
      }
      if (a.matter?.id) {
        matters[a.matter?.id] = true;
      }
      if (a.durationInMinutes) {
        activitiesByDay[day].durationInMinutes += a.durationInMinutes;
        feeDurationTotal += a.durationInMinutes;
      }
    });
    activityListWithHeaders.sort((a, b) => {
      // Headings must remain at the top of their group regardless
      if (a.dateTime.isSame(b.dateTime, 'day')) {
        if (a.isRowHeader) {
          return -1;
        }
        if (b.isRowHeader) {
          return 1;
        }
      }
      if (a.dateTime.isAfter(b.dateTime)) {
        return sortDirection === 'ASC' ? 1 : -1;
      }
      if (a.dateTime.isBefore(b.dateTime)) {
        return sortDirection === 'ASC' ? -1 : 1;
      }
      return 0;
    });
    const matterCount = Object.keys(matters).length;

    return {
      lastQueryTime,
      refresh: () => {
        setLastQueryTime(moment());
        activityQueryResult.refetch();
      },
      loggedInStaff: staffData?.loggedInStaff,
      taxRateBasisPoints,
      resetFilters: () => {
        setFilters(defaultFilters);
      },
      userFilter,
      setUserFilter,
      filters,
      setFilters,
      activitiesByDay,
      activityListWithHeaders,
      activityCount: activities.length || 0,
      rowCount: activityListWithHeaders.length + 1, // + 1 for the column headers
      collapsed,
      setCollapsed,
      sortDirection,
      setSortDirection,
      matterCount,
      feeDurationTotal,
      firmUsers,
      atLimit: activities.length === limit,
      selectedTimeFrame,
      setSelectedTimeFrame,
      startTime,
      endTime,
      loading: activityQueryResult.loading,
      activities,
      setSelectedTime,
      selectedTime,
    };
  },
});

export const ActivitiesTabContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(withOnLoad(ActivitiesTab))),
);

ActivitiesTabContainer.displayName = 'ActivitiesTabContainer';

ActivitiesTabContainer.propTypes = {
  onClickLink: PropTypes.func,
  matterId: PropTypes.string,
};

ActivitiesTabContainer.defaultProps = {
  onClickLink: () => {},
};
