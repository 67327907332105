import { debounce } from '@sb-itops/nodash';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getMatterDisplay } from '@sb-matter-management/redux/matters';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { MatterSummaries } from 'web/graphql/queries';
import { useSubscribedLazyQuery } from 'web/hooks';
import { SelectMatterModal } from './SelectMatterModal';

interface ISelectMatterModalContainerProps {
  onClose: () => void;
  onSubmit: (matterId: string) => void;
}

const hooks = ({ onClose, onSubmit }: ISelectMatterModalContainerProps) => ({
  useFormState: () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedMatterId, setSelectedMatterId] = useState('');

    const onMatterSelected = (selectedMatter: any) => {
      setSelectedMatterId(selectedMatter.value);
    };

    const onMatterSubmit = (matterId: string) => {
      setIsSubmitting(true);
      onSubmit(matterId!);
      setIsSubmitting(false);
      onClose();
    };

    return {
      isSubmitting,
      onSubmit: onMatterSubmit,
      selectedMatterId,
      onMatterSelected,
      onClose,
    };
  },
  useMatterSummary: () => {
    const [getMatterSummaries, matterSummariesResult] = useSubscribedLazyQuery(MatterSummaries, {
      context: { skipRequestBatching: true },
      variables: {
        includeMatterHourlyRate: false,
        filter: {
          matterStatus: ['pending', 'open'],
        },
        limit: 25,
        sort: {
          fieldNames: ['statusOpen', 'matterStarted'],
          directions: ['DESC', 'DESC'],
        },
      },
    });

    const results = matterSummariesResult.data?.matterSearch?.results;

    const matterSummaries = useMemo(() => {
      const summaries = !results?.length
        ? []
        : results.map((matter) => {
            const typeahead = [
              matter.matterNumber,
              matter.clientDisplay,
              matter.otherSideDisplay,
              matter.matterType?.name,
              matter.attorneyResponsible?.name,
              matter.attorneyResponsible?.initials,
              matter.description,
            ];

            const matterStartedISO = matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '';

            return {
              ...matter,
              display: getMatterDisplay(matter, matter.matterType?.name),
              matterClientNames: matter.clientNames,
              matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
              matterStartedISO,
              typeahead: typeahead.filter((m) => m).join(' '),
            };
          });

      return summaries;
    }, [results]);

    const getMatterSummariesBySearchText = debounce(
      (searchText = '') => {
        getMatterSummaries({
          variables: {
            searchText,
            offset: 0,
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const onFetchMatterSummaries = (searchText = '') => {
      // When the matter typeahead (Select) loses focus, it executes this
      // function with an empty string, returning different results.
      if (searchText.length > 2) {
        getMatterSummariesBySearchText(searchText);
      }

      return searchText;
    };

    const onFetchMoreMatterSummaries = async () => {
      if (!matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage) {
        return undefined;
      }

      const fetchMoreResults = await matterSummariesResult.fetchMore({
        variables: {
          offset: matterSummariesResult.data.matterSearch.results.length || 0,
        },
      });

      return fetchMoreResults;
    };

    return {
      isLoading: matterSummariesResult.loading,
      matterSummaries,
      matterSummariesHasMore: matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage || false,
      onFetchMatterSummaries,
      onFetchMoreMatterSummaries,
    };
  },
});

export const SelectMatterModalContainer = composeHooks(hooks)(SelectMatterModal);
