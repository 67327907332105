angular.module('sb.billing.webapp').component('sbDataMatterBillingConfigById',
  {
    'require': {
      sbComposeCtrl: '^sbCompose'
    },
    'bindings': {matterId: '<', entityDataKey: '@'},
    'controller': function ($scope, sbLoggerService, sbBillingConfigurationService) {
      'use strict';
      const ctrl = this;
      const log = sbLoggerService.getLogger('sbDataMatterBillingConfigById');
      ctrl.entityDataKey = 'matterBillingConfig';
      let matterBillingConfig;
      let initialized;

      ctrl.$onInit = () => {
        log.info(`init matterBillingConfig`, ctrl.matterId);
        update();
        initialized = true;
      };

      ctrl.$onChanges = function () {
        if (initialized) {
          update();
        }
      };

      $scope.$on('smokeball-data-update-sbBillingConfigurationService', update);

      function update() {
        matterBillingConfig = sbBillingConfigurationService.getByMatterId(ctrl.matterId) || {};
        log.info('updating matter billing config : ', matterBillingConfig);
        ctrl.sbComposeCtrl.setComposeData({dataType: ctrl.entityDataKey, data: matterBillingConfig}, ctrl.entityDataKey);
      }

    }

  }
);
