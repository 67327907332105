import {
  DRAFT_INVOICE_PREVIEW_MODAL_ID,
  DraftInvoicePreviewContainer as DraftInvoicePreview,
} from './DraftInvoicePreview.container';
import { DraftInvoicePreviewModal } from './DraftInvoicePreviewModal';
import { INVOICE_PREVIEW_MODAL_ID } from './InvoicePreviewModal';
import { InvoicePreviewModalContainer as InvoicePreviewModal } from './InvoicePreviewModal.container';
import { InvoicePreviewContainer } from './InvoicePreview.container';

/** The following screens are having the invoice(draft/finalised) preview in this web app.
 * The called preview modal hierarchy is as follows depending on the type and status of invoice:
  - Draft Invoice Screen
    > DraftInvoicePreviewModal
      >  DraftInvoicePreviewContainer
        > SplitBillingDraftInvoicePreviewContainer
          > SplitBillingInvoicePreview
            > InvoicePreviewContainer
              > InvoicePreview
        > InvoicePreviewContainer
          > InvoicePreview

  - Ledger List (firm, matter, contact invoice ledger list)
    > DraftInvoicePreviewModal (for draft invoice)
      >  DraftInvoicePreviewContainer
        > SplitBillingDraftInvoicePreviewContainer
          > SplitBillingInvoicePreview
            > InvoicePreviewContainer
              > InvoicePreview
        > InvoicePreviewContainer
          > InvoicePreview

    > InvoicePreviewModalContainer (for finalised invoice)
      > InvoicePreviewModal
        > SplitBillingInvoicePreview
          > InvoicePreview (skip InvoicePreviewContainer compared to draft invoice preview)
        > InvoicePreview* (to be implemented for non-split billing invoice preview)
*/
export {
  DRAFT_INVOICE_PREVIEW_MODAL_ID,
  DraftInvoicePreview,
  INVOICE_PREVIEW_MODAL_ID,
  InvoicePreviewModal,
  DraftInvoicePreviewModal,
  InvoicePreviewContainer,
};
