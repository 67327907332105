import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { featureActive, featureData } from '@sb-itops/feature';
import { getProductTier } from 'web/services/user-session-management';
import { calculateRetainerRequestAmount } from '@sb-billing/business-logic/evergreen-retainer';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { matterBalanceAutoAllocator } from '@sb-billing/allocate-invoice-payment';
import { getSupportHubUrl } from '@sb-itops/environment-config';
import { getLogger } from '@sb-itops/fe-logger';
import { sentViaTypes } from '@sb-billing/business-logic/correspondence-history';
import { clientCoversFeeStrategy } from '@sb-billing/business-logic/payment-provider/services/client-covers-fee-strategy.js';
import { applyAutoPayments } from './contact-balance-allocation-modal/apply-auto-payments';
import { FinalisePanel } from './FinalisePanel';

const INVOICE_TUTORIAL_BANNER_ID = 'invoiceTutorialBanner';
const COMMUNICATE_CONFIRM_MODAL_ID = 'communicateConfirmModal';

const log = getLogger('FinalisePanel.container');

export const FinalisePanelContainer = ({
  userViewedMessages,
  balances,
  totals,
  trustAccountsDisabled,
  matterTrustBalance,
  isBalanceAvailable,
  matterBillingConfiguration,
  hasOpenTrustAccountsForMatter,
  draftInvoice,
  // form
  formData,
  formErrors,
  formReady,
  formValid,
  onUpdateField,

  lessFundsInTrustAmount,
  protectedTrustFundsAmount,
  matterId,
  onClickLink,
  overrideRedirect,
  closeCurrentTab,
  onSaveInvoice,
  onSavePreDraftInvoice,
  onPreviewInvoice,
  onShowSendInvoiceModal,
  isUtbmsEnabledForFirm,
  hasSelectedUnpaidAD,
  trustChequeEnabled,
  trustChequeNumberLoading,
  onUpdateTrustChequeReference,
  activeProviderFormattedSettings,
  // Discount props
  canApplyDiscount,
  onConfirmDiscount,
  onDiscardDiscount,
  onApplyDiscountChecked,
  // Surcharge props
  canApplySurcharge,
  onConfirmSurcharge,
  onDiscardSurcharge,
  onApplySurchargeChecked,
  // Allocation
  onChangeContactBalanceAllocations,
  onChangeMatterBalanceAllocations,
  preferredBankAccountTypes,
  // Split billing
  debtorOptions,
  invoiceDebtorTotalsLookup,
  onChangeContactBalanceAllocationsForSplitBilling,
}) => {
  // View controller states
  const [expandedDiscount, setExpandedDiscount] = useState(false);
  const [expandedSurcharge, setExpandedSurcharge] = useState(false);

  const [saveIsLocked, setSaveIsLocked] = useState(false);
  const [previewIsLocked, setPreviewIsLocked] = useState(false);
  const [showInvoiceConfirmFinaliseModal, setShowInvoiceConfirmFinaliseModal] = useState(false);
  const [showSendViaCommunicateConfirmModal, setShowSendViaCommunicateConfirmModal] = useState(false);
  const [showAllocationsModal, setShowAllocationsModal] = useState(false);
  // We want accordion to be closed for all accounts by default, except for this case:
  // - When we return to draft invoice tab from another tab, form is already initialised and amount field
  //   can have an error, but since this state resets, we would have closed accordion with error.
  // - If the user has entered an amount to ensure it is visible before finalising.
  const [showPayFromAccordian, onSetShowPayFromAccordian] = useState({
    trust: !!formErrors?.quickPayments?.trust?.amount?.isInvalid || !!formData.quickPayments.trust.amount,
    operating: !!formErrors?.quickPayments?.operating?.amount?.isInvalid || !!formData.quickPayments.operating.amount,
    credit: !!formErrors?.quickPayments?.credit?.amount?.isInvalid || !!formData.quickPayments.credit.amount,
  });

  // Invoice tutorial
  const invoiceTutorialViewed = userViewedMessages?.includes(INVOICE_TUTORIAL_BANNER_ID);
  const productTier = getProductTier();
  const invoiceTutorialLink = featureData('BB-8260')?.[productTier];
  const showInvoiceTutorial = !!featureActive('BB-8260') && !invoiceTutorialViewed && !!invoiceTutorialLink;
  const onCloseInvoiceTutorialBanner = () => {
    dispatchCommand({
      type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
      message: { viewedMessage: INVOICE_TUTORIAL_BANNER_ID },
    });
  };

  // Communicate confirmation
  const doNotShowSendConfirmModalAgain = userViewedMessages?.includes(COMMUNICATE_CONFIRM_MODAL_ID);
  const sendViaCommunicateHelpLink = (featureActive('BB-11329') && featureData('BB-11329')?.url) || getSupportHubUrl();
  const onDoNotShowSendViaCommunicateConfirmModalAgain = () => {
    dispatchCommand({
      type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
      message: { viewedMessage: COMMUNICATE_CONFIRM_MODAL_ID },
    });
  };

  // Retainer
  const payFromTrustAmount = formData.quickPayments?.trust?.amount || 0;
  const retainerRequestAmount = featureActive('BB-6908')
    ? calculateRetainerRequestAmount({
        matterTrustBalance: matterTrustBalance - payFromTrustAmount,
        matterBillingConfiguration: matterBillingConfiguration || {},
      })
    : 0;
  const showRetainer =
    featureActive('BB-6908') &&
    hasOpenTrustAccountsForMatter &&
    formData?.config?.invoiceAdditionalOptions?.showRetainer;

  const showClientCoversFeeWarning = !!(
    showRetainer &&
    retainerRequestAmount &&
    clientCoversFeeStrategy({
      isDeposit: false,
      providerSettings: activeProviderFormattedSettings,
      hasRetainerRequest: showRetainer && retainerRequestAmount,
    })
  );

  // Split billing
  const [selectedDebtorId, setSelectedDebtorId] = useState(debtorOptions[0]?.value);
  const onDebtorChange = (newDebtorId) => {
    setSelectedDebtorId(newDebtorId);
  };

  // Handlers
  const onConfirmDiscountHandler = (...args) => {
    onConfirmDiscount(...args);
    setExpandedDiscount(false);
  };
  const onDiscardDiscountHandler = () => {
    onDiscardDiscount();
    setExpandedDiscount(false);
  };
  const onApplyDiscountCheckedHandler = (checked) => {
    setExpandedDiscount(checked);
    onApplyDiscountChecked(checked);
  };
  const onConfirmSurchargeHandler = (...args) => {
    onConfirmSurcharge(...args);
    setExpandedSurcharge(false);
  };
  const onDiscardSurchargeHandler = () => {
    onDiscardSurcharge();
    setExpandedSurcharge(false);
  };
  const onApplySurchargeCheckedHandler = (checked) => {
    setExpandedSurcharge(checked);
    onApplySurchargeChecked(checked);
  };

  const hasDefaultTrustAccount = !!balances?.TRUST?.bankAccountId;
  const hasUnpaidAD = featureActive('BB-9573') && formData.saveType === 'FINAL' && formValid && hasSelectedUnpaidAD;
  const hasZeroBalance = featureActive('BB-7088') && formData.saveType === 'FINAL' && formValid && totals.total === 0;
  const showLedes = isUtbmsEnabledForFirm && matterBillingConfiguration?.isUtbmsEnabled;
  const showTrustCheque = trustChequeEnabled && hasDefaultTrustAccount && !!balances?.TRUST?.balance;
  const isSplitBillingEnabled = formData.isSplitBillingEnabled; // already considered feature switch BB-9790 when initialising form data

  // Allocation
  // auto allocation is disabled for split billing
  const autoAllocate = () => {
    if (formData.isMatterBalanceType) {
      const { trust, operating, credit } = matterBalanceAutoAllocator(
        totals,
        [balances.TRUST, balances.OPERATING, balances.CREDIT],
        preferredBankAccountTypes,
      );
      onChangeMatterBalanceAllocations(trust, operating, credit);
    } else {
      const { trust, operating, credit } = applyAutoPayments(
        totals,
        balances.TRUST.contactBalances,
        balances.OPERATING.contactBalances,
        balances.CREDIT.contactBalances,
        preferredBankAccountTypes,
      );
      onChangeContactBalanceAllocations(trust, operating, credit);
    }
  };

  useEffect(() => {
    // LOOP WARNING
    // Since we are not subscribed to specific updates, we are updating the allocations on every store update
    // The alternative is attempting to detect when the applicable fees change which is significantly more complicated
    // This function prevents an infinite loop by checking if the form values already match, no updates will be committed to the form
    if (formData.autoAllocate) {
      autoAllocate();
    }
  });

  // Auto allocate is not disabled for split billing
  const onAutoAllocate = () => {
    if (!isBalanceAvailable) {
      return;
    }
    const newValue = !formData.autoAllocate;
    onUpdateField('autoAllocate', newValue);
    if (newValue) {
      onSetShowPayFromAccordian({ trust: true, operating: true, credit: true });
      return;
    }

    // else reset
    if (formData.isMatterBalanceType) {
      onChangeMatterBalanceAllocations(0, 0, 0);
    } else {
      onChangeContactBalanceAllocations([], [], []);
    }
  };

  // PDF preview should also be disabled when there are no selected debtors, issue date
  // or due date as the invoice preview wont appear with complete information.
  // PDF preview wont work if there is an error with quickpayments or the selected fees
  // but we dont want to disable preview if anything else is invalid
  const pdfPreviewIsDisabled =
    formErrors.issueDate?.isInvalid ||
    formErrors.dueDate?.isInvalid ||
    formErrors.quickPayments?.credit?.amount?.isInvalid ||
    formErrors.quickPayments?.operating?.amount?.isInvalid ||
    formErrors.quickPayments?.trust?.amount?.isInvalid ||
    formErrors.feeSummaryLineDescription?.isInvalid ||
    formErrors.expenseSummaryLineDescription?.isInvalid ||
    // These are arrays and don't get set isInvalid directly on them so we need to check length instead
    !formData.selectedContacts?.length ||
    (!formData.selectedExpenseIds?.length && !formData.selectedFeeIds?.length);

  // The "show less in trust" section should be shown when:
  // - There is default trust account with balance > 0
  // - There is no default trust account and it is disabled
  const showLessFundsInTrustSection =
    featureActive('BB-6398') && ((hasDefaultTrustAccount && lessFundsInTrustAmount > 0) || !hasDefaultTrustAccount);

  const onOpenInvoiceCommunicateModal = () => {
    onShowSendInvoiceModal({
      sendVia: sentViaTypes.COMMUNICATE,
      onSend: async ({ invoiceCommunicateRequests }) => {
        try {
          setSaveIsLocked(true);
          await onSaveInvoice({
            sendMethod: sentViaTypes.COMMUNICATE,
            communicateMessageDetails: invoiceCommunicateRequests,
            preDraftInvoiceId: formData.invoiceId,
          });
        } catch (err) {
          log.error('Failed to send invoice communicate request: ', err);
        } finally {
          setSaveIsLocked(false);
        }
      },
    });
  };

  const handleViewPreview = async () => {
    setPreviewIsLocked(true);
    try {
      await onPreviewInvoice({ retainerRequestAmount });
      // eslint-disable-next-line no-empty
    } catch (err) {
    } finally {
      setPreviewIsLocked(false);
    }
  };

  const handleSaveTypeFinal = async () => {
    if (formData.saveType === 'FINAL' && formData.sendNowViaEmail) {
      // As we are in preDraftMode, we need to make absolutely certain that the latest version of the invoice is saved.
      // the sbSaveInvoiceCommand service will use the preDraft redux store to populate email templates, not the invoice you supply to it as you might expect
      onSavePreDraftInvoice();
      onShowSendInvoiceModal({
        sendVia: sentViaTypes.EMAIL,
        onSend: async ({ invoiceEmailRequests }) => {
          try {
            setSaveIsLocked(true);
            await onSaveInvoice({
              emailDetails: invoiceEmailRequests,
              preDraftInvoiceId: formData.invoiceId,
            });
          } catch (err) {
            log.error('Failed to send invoice email request: ', err);
          } finally {
            setSaveIsLocked(false);
          }
        },
      });
    } else if (formData.saveType === 'FINAL' && formData.sendNowViaCommunicate) {
      onSavePreDraftInvoice();
      if (!doNotShowSendConfirmModalAgain) {
        setShowSendViaCommunicateConfirmModal(true);
        return;
      }
      onOpenInvoiceCommunicateModal();
    } else {
      setSaveIsLocked(true);
      try {
        await onSaveInvoice(null);
        // eslint-disable-next-line no-empty
      } catch (err) {
      } finally {
        setSaveIsLocked(false);
      }
    }
  };

  const onSaveAndGenerateLedes = async () => {
    if (saveIsLocked) {
      return;
    }
    setSaveIsLocked(true);
    try {
      await onSaveInvoice({ generateLedes: true });
    } catch (err) {
      setSaveIsLocked(false);
    }
    setSaveIsLocked(false);
  };

  const onInvoiceConfirmFinaliseModalConfirm = async () => {
    setShowInvoiceConfirmFinaliseModal(false);
    await handleSaveTypeFinal();
  };

  // Split billing
  const onUpdateDebtorSpecificQuickPayments = ({ type, debtorId, value }) => {
    const fieldName = `quickPayments.${type}.debtorAllocations.${debtorId}`;
    // update the payment for the debtor
    onUpdateField(fieldName, value || 0);
    const amount = Object.keys(formData.quickPayments[type].debtorAllocations).reduce((acc, key) => {
      if (key === debtorId) {
        return acc + (value || 0);
      }
      return acc + formData.quickPayments[[type]].debtorAllocations[key];
    }, 0);
    // update the total amount for this payment type (type can be trust, operating or credit)
    onUpdateField(`quickPayments.${type}.amount`, amount || 0);
  };

  // this function to get the status of the debtor quick payments, and store debtor name to the according debtorId it is used to display the warning message on finalising
  const getDebtorQuickPaymentStatus = () => {
    const debtorNames = (formData.selectedContacts || []).reduce((acc, contact) => {
      acc[contact.id] = contact.displayNameFirstLast;
      return acc;
    }, {});
    const debtorsWithQuickPayment = {};
    const debtorWithoutQuickPayment = {};
    const debtorIds = debtorOptions.map((debtor) => debtor.value);
    debtorIds.forEach((debtorId) => {
      const trustAmount = formData.quickPayments.trust.debtorAllocations[debtorId] || 0;
      const operatingAmount = formData.quickPayments.operating.debtorAllocations[debtorId] || 0;
      const creditAmount = formData.quickPayments.credit.debtorAllocations[debtorId] || 0;
      if (trustAmount > 0 || operatingAmount > 0 || creditAmount > 0) {
        debtorsWithQuickPayment[debtorId] = debtorNames[debtorId];
      } else {
        debtorWithoutQuickPayment[debtorId] = debtorNames[debtorId];
      }
    });

    return {
      debtorsWithQuickPayment: { ...debtorsWithQuickPayment, count: Object.keys(debtorsWithQuickPayment).length },
      debtorWithoutQuickPayment: { ...debtorWithoutQuickPayment, count: Object.keys(debtorWithoutQuickPayment).length },
    };
  };

  return (
    <FinalisePanel
      {...{
        // User billing attributtes messages
        invoiceTutorialLink,
        showInvoiceTutorial,
        onCloseInvoiceTutorialBanner,
        sendViaCommunicateHelpLink,
        doNotShowSendConfirmModalAgain,
        onDoNotShowSendViaCommunicateConfirmModalAgain,
        // Form
        formData,
        formErrors,
        formReady,
        formValid,
        onUpdateField,
        // Discount props
        canApplyDiscount,
        expandedDiscount,
        setExpandedDiscount,
        onConfirmDiscount: onConfirmDiscountHandler,
        onDiscardDiscount: onDiscardDiscountHandler,
        onApplyDiscountChecked: onApplyDiscountCheckedHandler,
        // Surcharge props
        canApplySurcharge,
        expandedSurcharge,
        setExpandedSurcharge,
        onConfirmSurcharge: onConfirmSurchargeHandler,
        onDiscardSurcharge: onDiscardSurchargeHandler,
        onApplySurchargeChecked: onApplySurchargeCheckedHandler,
        // Allocation
        onAutoAllocate,
        preferredBankAccountTypes,
        onChangeContactBalanceAllocations,
        showAllocationsModal,
        setShowAllocationsModal,
        showPayFromAccordian,
        onSetShowPayFromAccordian,
        // Config
        supportsTax: hasFacet(facets.tax),
        supportsOperatingAccount: hasFacet(facets.operatingAccount),
        supportsAutoAllocatePayments: hasFacet(facets.autoAllocatePayments),
        showOnlyPrintLaterCheque: hasFacet(facets.printLaterChequeOnly),
        // Email and communicate
        showInvoiceConfirmFinaliseModal,
        onInvoiceConfirmFinaliseModalConfirm,
        setShowInvoiceConfirmFinaliseModal,
        showSendViaCommunicateConfirmModal,
        setShowSendViaCommunicateConfirmModal,
        onOpenInvoiceCommunicateModal,
        // Save and preview
        saveIsLocked,
        previewIsLocked,
        pdfPreviewIsDisabled,
        handleSaveTypeFinal,
        onSaveAndGenerateLedes,
        handleViewPreview,
        // Rest
        totals,
        balances,
        hasDefaultTrustAccount,
        hasUnpaidAD,
        hasZeroBalance,
        matterId,
        trustAccountsDisabled,
        isBalanceAvailable,
        showRetainer,
        retainerRequestAmount,
        protectedTrustFundsAmount,
        lessFundsInTrustAmount,
        draftInvoice,
        showLedes,
        showTrustCheque,
        trustChequeNumberLoading,
        showLessFundsInTrustSection,
        showClientCoversFeeWarning,
        onUpdateTrustChequeReference,
        onClickLink,
        overrideRedirect,
        closeCurrentTab,
        // Split billing
        isSplitBillingEnabled,
        selectedDebtorId,
        debtorOptions,
        invoiceDebtorTotalsLookup,
        debtorQuickPaymentStatus: isSplitBillingEnabled ? getDebtorQuickPaymentStatus() : undefined,
        onUpdateDebtorSpecificQuickPayments,
        onDebtorChange,
        onChangeContactBalanceAllocationsForSplitBilling,
      }}
    />
  );
};

FinalisePanelContainer.displayName = 'FinalisePanelContainer';

FinalisePanelContainer.propTypes = {
  userViewedMessages: PropTypes.arrayOf(PropTypes.string),
  balances: PropTypes.object,
  trustAccountsDisabled: PropTypes.bool.isRequired,
  matterTrustBalance: PropTypes.number.isRequired,
  isBalanceAvailable: PropTypes.bool.isRequired,
  matterBillingConfiguration: PropTypes.shape({
    isUtbmsEnabled: PropTypes.bool,
    minimumTrustRetainerActive: PropTypes.bool,
    minimumTrustRetainerAmount: PropTypes.number,
    trustRetainerReplenishAmount: PropTypes.number,
  }),
  draftInvoice: PropTypes.object.isRequired,
  isUtbmsEnabledForFirm: PropTypes.bool.isRequired,
  matterId: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  formReady: PropTypes.bool.isRequired,
  formValid: PropTypes.bool.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  protectedTrustFundsAmount: PropTypes.number.isRequired,
  hasOpenTrustAccountsForMatter: PropTypes.bool.isRequired,
  totals: PropTypes.object.isRequired,
  lessFundsInTrustAmount: PropTypes.number.isRequired,
  onClickLink: PropTypes.func.isRequired,
  overrideRedirect: PropTypes.func.isRequired,
  closeCurrentTab: PropTypes.func.isRequired,
  onSaveInvoice: PropTypes.func.isRequired,
  onSavePreDraftInvoice: PropTypes.func.isRequired,
  onPreviewInvoice: PropTypes.func.isRequired,
  onShowSendInvoiceModal: PropTypes.func.isRequired,
  hasSelectedUnpaidAD: PropTypes.bool.isRequired,
  trustChequeEnabled: PropTypes.bool.isRequired,
  trustChequeNumberLoading: PropTypes.bool.isRequired,
  onUpdateTrustChequeReference: PropTypes.func.isRequired,
  activeProviderFormattedSettings: PropTypes.object.isRequired,
  // Discount
  canApplyDiscount: PropTypes.bool.isRequired,
  onConfirmDiscount: PropTypes.func.isRequired,
  onDiscardDiscount: PropTypes.func.isRequired,
  onApplyDiscountChecked: PropTypes.func.isRequired,
  // Surcharge
  canApplySurcharge: PropTypes.bool.isRequired,
  onConfirmSurcharge: PropTypes.func.isRequired,
  onDiscardSurcharge: PropTypes.func.isRequired,
  onApplySurchargeChecked: PropTypes.func.isRequired,
  // Allocation
  preferredBankAccountTypes: PropTypes.arrayOf(PropTypes.string),
  onChangeContactBalanceAllocations: PropTypes.func.isRequired,
  onChangeMatterBalanceAllocations: PropTypes.func.isRequired,
  // Split billing
  debtorOptions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  invoiceDebtorTotalsLookup: PropTypes.object,
  onChangeContactBalanceAllocationsForSplitBilling: PropTypes.func.isRequired,
};

FinalisePanelContainer.defaultProps = {
  userViewedMessages: undefined,
  balances: {},
  matterBillingConfiguration: {},
  preferredBankAccountTypes: undefined,
  debtorOptions: [],
  invoiceDebtorTotalsLookup: {},
};
