import { gql } from '@apollo/client';

const query = gql`
  query MatterContactBalanceTrustAsOfDate($matterId: ID!, $balanceAsOfDate: Int) {
    matterContactBalanceTrust(id: $matterId, balanceAsOfDate: $balanceAsOfDate) {
      availableBalance
      balance
      bankAccountId
      contactId
      matterId
      protectedBalance
    }
  }
`;

const notificationIds = ['AccountsNotifications.AccountBalanceUpdated', 'AccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterContactBalanceTrustAsOfDate = {
  query,
  notificationIds,
};
