import {
  balanceTypes,
  validAccountTypeForBalanceType,
} from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';

const initAllocations = ({ balances, multiPayments, accountType, debtorId }) => {
  const accountTypeAllocations = multiPayments.reduce((acc, mp) => {
    // debtorId exists only when called from initDebtorAllocationsLookup, in this case it only returns allocations for that debtor
    if (mp.source.toUpperCase() === accountType && (!debtorId || mp.debtorId === debtorId)) {
      acc[mp.payorId] = mp;
    }
    return acc;
  }, {});

  // Calculate the allocated amount for each payor of this account type
  const accountTypeAllocatedAmount = multiPayments.reduce((acc, mp) => {
    if (mp.source.toUpperCase() === accountType) {
      acc[mp.payorId] = (acc[mp.payorId] || 0) + mp.amount;
    }
    return acc;
  }, {});

  const accountTypeBalances = balances[accountType].contactBalances.map((cb) => ({
    ...cb,
    available: accountType in validAccountTypeForBalanceType ? cb[balanceTypes.AVAILABLE] : cb[balanceTypes.BALANCE],
    amount: (accountTypeAllocations[cb.contactId] && accountTypeAllocations[cb.contactId].amount) || 0,
    bankAccountName: accountType === bankAccountTypeEnum.TRUST ? balances[accountType].name : undefined, // Specifically for trust we need its name
    debtorId, // the debtorId of the child invoice
    allocatedAmount: accountTypeAllocatedAmount[cb.contactId] || 0,
  }));

  return accountTypeBalances.sort((a, b) => a.available - b.available);
};

const initDebtorAllocationsLookup = ({ balances, multiPayments, accountType, debtorIds }) => {
  const debtorAllocations = debtorIds.reduce((acc, debtorId) => {
    const baseAllocations = initAllocations({ balances, multiPayments, accountType, debtorId });
    acc[debtorId] = baseAllocations;
    return acc;
  }, {});

  return debtorAllocations;
};

export { initAllocations, initDebtorAllocationsLookup };
