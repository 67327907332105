import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import { getInvoiceDebtorTotalsByInvoiceId } from '@sb-billing/redux/invoice-debtor-totals';
import { withReduxStore, Translation } from '@sb-itops/react';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { featureActive } from '@sb-itops/feature';

const mapStateToProps = (state, { invoiceId, debtorId, totalFor }) => {
  let totals = getTotalsForInvoiceId(invoiceId);

  const invoice = getInvoiceById(invoiceId);
  const invoiceVersion = invoice?.currentVersion;

  // To show retainer request amounts for finalised invoices (RHS panel) when required
  if (featureActive('BB-6908')) {
    if (['FINAL', 'PAID'].includes(invoiceVersion?.status)) {
      const showRetainer = invoiceVersion?.additionalOptions?.showRetainer;

      if (showRetainer) {
        const retainerRequestAmount = invoiceVersion?.retainerRequestAmount || 0;
        const totalPaymentDue = totals.unpaid + retainerRequestAmount || 0;

        totals = {
          ...totals,
          retainerRequestAmount,
          totalPaymentDue,
        };
      }
    }
  }

  // For split billing child invoice, Interest, Payments, Credits, and Waived are debtor specific
  if (featureActive('BB-9790') && debtorId) {
    const isSplitBillingInvoice = !!(
      invoiceVersion?.splitBillingSettings?.isEnabled && invoiceVersion?.splitBillingSettings?.debtors?.length > 1
    );

    if (isSplitBillingInvoice) {
      const invoiceDebtorTotals = getInvoiceDebtorTotalsByInvoiceId(invoiceId);
      // When we finalise a draft invoice for the first time, we got isSplitBillingInvoice a little bit quicker than we got the invoiceDebtorTotals. Add || {} here to make it safer for the below destructure.
      const debtorTotals = (invoiceDebtorTotals || []).find((t) => t.debtorId === debtorId) || {};
      const {
        totalExcInterest: debtorTotalExcInterest,
        interest: debtorInterest,
        paid: debtorPaid,
        paidByCredit: debtorPaidByCredit,
        waived: debtorWaived,
        unpaid: debtorUnpaid,
      } = debtorTotals;

      totals = {
        ...totals,
        totalExcInterest: totals.total - totals.interest, // show Totals of parent invoice excludes Interest
        debtorTotalExcInterest,
        debtorInterest,
        debtorPaid,
        debtorPaidByCredit,
        debtorWaived,
        debtorUnpaid,
      };
    }
  }

  const amount = totals && totals[totalFor];

  return {
    amount: Number.isFinite(amount) ? amount : 0,
  };
};

const InvoiceTotalDisplayContainer = withReduxStore(
  connect(mapStateToProps, undefined)(({ amount }) => <Translation>{(t) => t('cents', { val: amount })}</Translation>),
);

InvoiceTotalDisplayContainer.propTypes = {
  invoiceId: PropTypes.string,
  debtorId: PropTypes.string,
  totalFor: PropTypes.string,
};

InvoiceTotalDisplayContainer.defaultProps = {
  invoiceId: undefined,
  debtorId: undefined,
  totalFor: 'billed',
};

export default InvoiceTotalDisplayContainer;
