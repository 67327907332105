import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@sb-itops/react/modal';
import { Button } from '@sb-itops/react/button';
import { LinkableText } from '@sb-itops/react/linkable-text';
import Styles from './ContactBalanceAllocationModal.module.scss';
import { applyAutoPayments } from './apply-auto-payments';
import { ApplyPaymentsForm } from './apply-payments-form';

export const ContactBalanceAllocationModal = memo(
  ({
    trustAllocations,
    onChangeTrustAllocations,
    operatingAllocations,
    onChangeOperatingAllocations,
    creditAllocations,
    onChangeCreditAllocations,
    totalAmount,
    hasError,

    onChangeAllocations,
    onModalClose,
    onContactLinkClick,
    invoiceTotals,
    preferredBankAccountTypes,
    // Split billing
    isSplitBillingEnabled,
    selectedDebtorId,
    debtorOptions,
    invoiceDebtorTotalsLookup,
    onDebtorChange,
    // initialised when split billing is enabled
    trustDebtorAllocationsLookup,
    errorDebtorLookup,
    onChangeTrustDebtorAllocationsLookup,
    operatingDebtorAllocationsLookup,
    onChangeOperatingDebtorAllocationsLookup,
    creditDebtorAllocationsLookup,
    onChangeCreditDebtorAllocationsLookup,
    onChangeSplitBillingAllocations,
  }) => (
    <Modal
      onModalClose={onModalClose}
      body={
        <ApplyPaymentsForm
          hasError={isSplitBillingEnabled ? errorDebtorLookup[selectedDebtorId] : hasError}
          totalAmount={totalAmount}
          trustAllocations={isSplitBillingEnabled ? trustDebtorAllocationsLookup[selectedDebtorId] : trustAllocations}
          operatingAllocations={
            isSplitBillingEnabled ? operatingDebtorAllocationsLookup[selectedDebtorId] : operatingAllocations
          }
          creditAllocations={
            isSplitBillingEnabled ? creditDebtorAllocationsLookup[selectedDebtorId] : creditAllocations
          }
          onContactLinkClick={onContactLinkClick}
          onChangeTrustAllocations={onChangeTrustAllocations}
          onChangeOperatingAllocations={onChangeOperatingAllocations}
          onChangeCreditAllocations={onChangeCreditAllocations}
          // Split billing will store allocations as lookup in state
          onChangeTrustDebtorAllocationsLookup={onChangeTrustDebtorAllocationsLookup}
          onChangeOperatingDebtorAllocationsLookup={onChangeOperatingDebtorAllocationsLookup}
          onChangeCreditDebtorAllocationsLookup={onChangeCreditDebtorAllocationsLookup}
          invoiceTotals={invoiceTotals}
          isSplitBillingEnabled={isSplitBillingEnabled}
          selectedDebtorId={selectedDebtorId}
          debtorOptions={debtorOptions}
          invoiceDebtorTotalsLookup={invoiceDebtorTotalsLookup}
          onDebtorChange={onDebtorChange}
        />
      }
      title="Apply Payments"
      footer={
        <div className={Styles.footerWrapper}>
          {hasError && (
            <div className={Styles.errorMessage}>
              <span>Amount allocated cannot be more than amount due</span>
            </div>
          )}
          <div className={Styles.footerRow}>
            <Button
              className={Styles.saveButton}
              onClick={() => {
                if (isSplitBillingEnabled) {
                  onChangeSplitBillingAllocations(
                    trustDebtorAllocationsLookup,
                    operatingDebtorAllocationsLookup,
                    creditDebtorAllocationsLookup,
                  );
                } else {
                  onChangeAllocations(trustAllocations, operatingAllocations, creditAllocations);
                }
                onModalClose();
              }}
              disabled={hasError}
            >
              Apply Allocations
            </Button>
            <div className={Styles.footerLinkButtons}>
              <LinkableText
                className={Styles.autoAllocateButton}
                text="Apply Auto-Allocation Values"
                onClickLink={() => {
                  const { trust, operating, credit } = applyAutoPayments(
                    invoiceTotals,
                    trustAllocations,
                    operatingAllocations,
                    creditAllocations,
                    preferredBankAccountTypes,
                  );
                  onChangeTrustAllocations(trust);
                  onChangeOperatingAllocations(operating);
                  onChangeCreditAllocations(credit);
                }}
                asLink
                disabled={isSplitBillingEnabled}
                tooltip={
                  isSplitBillingEnabled ? 'Auto-allocate can not be used for matters with split billing enabled' : ''
                }
              />
              <LinkableText
                className={Styles.clearButton}
                text="Clear Values"
                onClickLink={() => {
                  if (isSplitBillingEnabled) {
                    onChangeTrustDebtorAllocationsLookup(clearDebtorAllocationsLookup(trustDebtorAllocationsLookup));
                    onChangeOperatingDebtorAllocationsLookup(
                      clearDebtorAllocationsLookup(operatingDebtorAllocationsLookup),
                    );
                    onChangeCreditDebtorAllocationsLookup(clearDebtorAllocationsLookup(creditDebtorAllocationsLookup));
                  } else {
                    onChangeTrustAllocations(clearAllocations(trustAllocations));
                    onChangeOperatingAllocations(clearAllocations(operatingAllocations));
                    onChangeCreditAllocations(clearAllocations(creditAllocations));
                  }
                }}
                asLink
              />
            </div>
          </div>
        </div>
      }
    />
  ),
);

const clearAllocations = (allocations) =>
  allocations.map((allocation) => ({
    ...allocation,
    allocatedAmount: 0,
    amount: 0,
  }));

// Clear allocations for all debtors
const clearDebtorAllocationsLookup = (debtorAllocationsLookup) =>
  Object.fromEntries(Object.entries(debtorAllocationsLookup).map(([key, value]) => [key, clearAllocations(value)]));

ContactBalanceAllocationModal.displayName = 'ContactBalanceAllocationModal';

ContactBalanceAllocationModal.propTypes = {
  onChangeAllocations: PropTypes.func.isRequired,
  onContactLinkClick: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  invoiceTotals: PropTypes.object,
  preferredBankAccountTypes: PropTypes.array.isRequired,
  totalAmount: PropTypes.number.isRequired,
  hasError: PropTypes.bool.isRequired,

  trustAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  operatingAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  creditAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  onChangeTrustAllocations: PropTypes.func.isRequired,
  onChangeOperatingAllocations: PropTypes.func.isRequired,
  onChangeCreditAllocations: PropTypes.func.isRequired,
  // Split billing
  isSplitBillingEnabled: PropTypes.bool,
  selectedDebtorId: PropTypes.string,
  debtorOptions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  invoiceDebtorTotalsLookup: PropTypes.object,
  onDebtorChange: PropTypes.func,
  trustDebtorAllocationsLookup: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(
      PropTypes.shape({
        contactId: PropTypes.string.isRequired,
        available: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        allocatedAmount: PropTypes.number.isRequired,
      }),
    ),
  }),
  operatingDebtorAllocationsLookup: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(
      PropTypes.shape({
        contactId: PropTypes.string.isRequired,
        available: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        allocatedAmount: PropTypes.number.isRequired,
      }),
    ),
  }),
  creditDebtorAllocationsLookup: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(
      PropTypes.shape({
        contactId: PropTypes.string.isRequired,
        available: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        allocatedAmount: PropTypes.number.isRequired,
      }),
    ),
  }),
  errorDebtorLookup: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }),
  onChangeTrustDebtorAllocationsLookup: PropTypes.func.isRequired,
  onChangeOperatingDebtorAllocationsLookup: PropTypes.func.isRequired,
  onChangeCreditDebtorAllocationsLookup: PropTypes.func.isRequired,
  onChangeSplitBillingAllocations: PropTypes.func.isRequired,
};

ContactBalanceAllocationModal.defaultProps = {
  trustAllocations: [],
  operatingAllocations: [],
  creditAllocations: [],
  invoiceTotals: undefined,
  // Split billing
  isSplitBillingEnabled: false,
  selectedDebtorId: undefined,
  debtorOptions: [],
  invoiceDebtorTotalsLookup: {},
  onDebtorChange: () => {},
  trustDebtorAllocationsLookup: {},
  operatingDebtorAllocationsLookup: {},
  creditDebtorAllocationsLookup: {},
  errorDebtorLookup: {},
};
