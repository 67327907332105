import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

// When making changes in this file consider whether the change is also needed in InvoiceTableData
const query = gql`
  query ContactInvoiceTableData(
    $invoiceListFilter: InvoiceListFilter
    $offset: Int
    $limit: Int
    $sort: ListSort
    $showStatement: Boolean!
    $includeMatterUtbmsSettings: Boolean
    $paymentPlanStatusAsOfDate: Int
    $searchTerms: [String]
  ) {
    invoiceList(
      filter: $invoiceListFilter
      offset: $offset
      limit: $limit
      sort: $sort
      paymentPlanStatusAsOfDate: $paymentPlanStatusAsOfDate
      includeMatterUtbmsSettings: $includeMatterUtbmsSettings
      searchTerms: $searchTerms
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      sortOrder {
        fieldNames
        directions
      }
      results {
        id
        matter {
          id
          matterNumber
          clientDisplay
          otherSideDisplay
          matterType {
            matterTypeId
            name
          }
          billingConfiguration {
            id
            isUtbmsEnabled
          }
        }
        invoiceStatement @include(if: $showStatement) {
          id
          number
          status
        }
        invoiceNumber
        issuedDate
        finalizedTimestamp
        debtors {
          id
          contact {
            id
            displayName
          }
          invoiceNumberSuffix
        }
        pseudoStatus
        dueDate
        paidDate
        showRetainer
        validFrom
        user {
          id
          person {
            id
            initials
            name
          }
        }
        totals {
          id
          totalExcInterest
          interest
          paid
          unpaid
        }
        debtorTotals {
          invoiceId
          debtorId
          totalExcInterest
          interest
          paid
          unpaid
        }
        splitBillingSettings {
          isEnabled
          debtors {
            debtorId
          }
        }
        listItemProperties {
          hasBeenViewedOnline
          hasUnpaidAnticipatedDisbursements
          sentStatus
        }
      }
    }
  }
`;

const notificationIds = [
  'WebQueryInvoicingNotifications.InvoiceUpdated',
  'WebQueryInvoicingNotifications.InvoiceStatementUpdated',
  'MatterManagementWebQuery.MatterUpdated',
  'CustomerManagementWebQuery.EntityUpdated',
  'WebQueryAccountsNotifications.AccountBalanceUpdated',
  'WebQueryBillingSharedNotifications.CorrespondenceHistoryUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const ContactInvoiceTableData = {
  query,
  notificationIds,
};
