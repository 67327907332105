import { useState } from 'react';
import { featureActive } from '@sb-itops/feature';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useCreateCorrespondenceDocument } from 'web/hooks';
import { sendMetric } from 'web/services/metrics';
import {
  CreateCorrespondenceDocumentModal,
  ICreateCorrespondenceDocumentModalProps,
} from './CreateCorrespondenceDocumentModal';

export interface ICreateCorrespondenceDocumentModalContainerProps {
  matterId: string;
  selectedEntityId?: string;
  body?: string;
  bodyContentType?: string;
  interactive: boolean;
  isFileManagerInstalled: boolean;
  onClose: () => void;
}

const hooks = ({
  matterId,
  body,
  bodyContentType,
  interactive,
  isFileManagerInstalled,
  onClose,
}: ICreateCorrespondenceDocumentModalContainerProps) => ({
  useModalData: (): ICreateCorrespondenceDocumentModalProps => {
    const {
      authorizationLoading,
      isUnauthorized,
      isCreatingCorrespondenceDocument,
      createCorrespondenceDocumentInWeb,
      createCorrespondenceDocumentInDesktop,
    } = useCreateCorrespondenceDocument();
    const [correspondenceDocumentUrl, setCorrespondenceDocumentUrl] = useState<string | undefined>();

    const onCreateCorrespondenceDocumentInWeb = async (documentName: string | undefined) => {
      sendMetric('CreateCorrespondenceDocumentSent');
      const createCorrespondenceDocumentUrl = await createCorrespondenceDocumentInWeb(
        matterId,
        body,
        bodyContentType,
        documentName,
        interactive,
      );

      if (createCorrespondenceDocumentUrl) {
        window.open(createCorrespondenceDocumentUrl, '_blank');
        setCorrespondenceDocumentUrl(createCorrespondenceDocumentUrl);
      }
    };

    const onCreateCorrespondenceDocumentInDesktop = async (documentName: string) => {
      await createCorrespondenceDocumentInDesktop(matterId, documentName, interactive);
      onClose();
    };

    let authorizationState: 'loading' | 'unauthorized' | 'authorized';
    if (authorizationLoading) {
      authorizationState = 'loading';
    } else if (isUnauthorized) {
      authorizationState = 'unauthorized';
    } else {
      authorizationState = 'authorized';
    }
    const showCreateInDesktop = featureActive('NV-5529') && isFileManagerInstalled;

    return {
      authorizationState,
      onCreateCorrespondenceDocumentInWeb,
      onCreateCorrespondenceDocumentInDesktop,
      isCreatingCorrespondenceDocument,
      interactive,
      showCreateInDesktop,
      correspondenceDocumentUrl,
      onClose,
    };
  },
});

export const CreateCorrespondenceDocumentModalContainer = composeHooks(hooks)(CreateCorrespondenceDocumentModal);
