import React, { useState } from 'react';
import { connect } from 'react-redux';
import { featureActive } from '@sb-itops/feature';
import { getLogger } from '@sb-itops/fe-logger';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { sortByProperty } from '@sb-itops/nodash';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { getStripeOnboardingReturnUrl } from '@sb-itops/environment-config';
import { getProviderSettings } from '@sb-billing/redux/payment-provider-settings/selectors';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { getAccountId } from 'web/services/user-session-management';
import {
  deactivateProvider as deactivateStripeIntegration,
  disconnect as disconnectStripeAccount,
  saveSettings as saveStripeSettings,
} from '@sb-billing/business-logic/payment-provider/services/client-api';
import { getTrustAccounts, getOperatingAccount, isTrustAccountClosed } from '@sb-billing/redux/bank-account';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { update } from '@intercom/messenger-js-sdk';
import { StripeSettingsForm } from './StripeSettingsForm';

const log = getLogger('StripeSettingsFormContainer');

const mapStateToProps = (state, { t }) => {
  const stripeSettings = getProviderSettings(providers.STRIPE) || {};
  const accountId = getAccountId();

  const trustAccounts = getTrustAccounts().map((ta) => {
    const trustAccountId = ta.id.toLowerCase();

    return {
      bankAccountId: trustAccountId,
      bankAccountType: 'Trust',
      bankAccountName: getBankAccountName(ta, t),
      linkedTrustAccount: (stripeSettings.linkedAccounts || {})[trustAccountId],
      isClosed: isTrustAccountClosed(ta),
    };
  });

  const operatingAccountId = (getOperatingAccount()?.id || `${accountId}/operating`).toLowerCase();
  const operatingAccount = {
    bankAccountId: operatingAccountId,
    bankAccountType: 'Operating',
    bankAccountName: 'Operating Bank Account',
    linkedOperatingAccount: (stripeSettings.linkedAccounts || {})[operatingAccountId],
  };

  return {
    trustAccounts,
    operatingAccount,
    invoiceSettings: {
      showInvoiceLink: stripeSettings.showInvoiceLink || false,
      showScanToPay: stripeSettings.showScanToPay || false,
      clientCoversFeeOnPayments: stripeSettings.clientCoversFeeOnPayments || false,
    },
  };
};

const mapDispatchToProps = () => ({
  onConnectAccount: async ({ bankAccountId, bankAccountType }) => {
    try {
      const requestInfo = {
        connectionStep: 'STRIPE_CREATE_ACCOUNT',
        bankAccountId,
        bankAccountType,
        returnUrl: getStripeOnboardingReturnUrl(),
        refreshUrl: window.location.origin,
      };
      const path = `/billing/payment-provider/connect/stripe/:accountId/`;
      const fetchOptions = { body: JSON.stringify(requestInfo) };
      const { body } = await fetchPostP({ path, fetchOptions });
      window.open(body.onBoardingUrl);
    } catch (err) {
      log.error('Failed to create Stripe account', err);
      displayErrorToUser('Failed to create Stripe account');
    }
  },
  onContinueOnboarding: async ({ stripeAccountId }) => {
    try {
      const requestInfo = {
        connectionStep: 'CONTINUE_ACCOUNT_ONBOARDING',
        stripeAccountId,
        returnUrl: getStripeOnboardingReturnUrl(),
      };
      const path = `/billing/payment-provider/connect/stripe/:accountId/`;
      const fetchOptions = { body: JSON.stringify(requestInfo) };
      const { body } = await fetchPostP({ path, fetchOptions });
      window.open(body.onBoardingUrl);
    } catch (err) {
      log.error('Failed to continue onboarding Stripe account', err);
      displayErrorToUser('Failed to continue onboarding for Stripe account');
    }
  },

  onSave: async (updatedProviderSettings) => {
    const providerSettings = {
      ...(getProviderSettings(providers.STRIPE) || {}),
      ...updatedProviderSettings,
    };

    try {
      await saveStripeSettings({ fetchPostP, providerType: providers.STRIPE, providerSettings });
      displaySuccessToUser('Stripe integration settings saved successfully');
    } catch (err) {
      log.error('Failed to save Stripe integration settings', err);
      displayErrorToUser('Failed to save Stripe integration settings');
    }
  },

  onDisconnectStripeAccount: async ({ bankAccountId, stripeAccountId }) => {
    try {
      const providerSpecificPayload = {
        bankAccountId,
        stripeAccountId,
      };

      await disconnectStripeAccount({ fetchPostP, providerType: providers.STRIPE, providerSpecificPayload });
      displaySuccessToUser('Stripe account disconnected successfully');
    } catch (err) {
      log.error('Failed to disconnect Stripe account', err);
      displayErrorToUser('Failed to disconnect Stripe account');
    }
  },

  onDeactivate: async () => {
    try {
      await deactivateStripeIntegration({ fetchPostP, providerType: providers.STRIPE });
      displaySuccessToUser('Stripe integration disconnected successfully');

      if (featureActive('BB-13244')) {
        window?.ChurnZero?.push(['setAttribute', 'account', 'Payment Processor', 'NONE']);
      }
      if (featureActive('NUCWEB-914') && window?.intercomSettings) {
        window.intercomSettings['Payment Provider'] = 'NONE';
        update(window.intercomSettings);
      }
    } catch (err) {
      log.error('Failed to deactivate Stripe integration', err);
      displayErrorToUser('Failed to deactivate Stripe integration');
    }
  },
});

export const StripeSettingsFormContainer = withReduxStore(
  withTranslation()(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(({ invoiceSettings, onSave, onDeactivate, trustAccounts, ...props }) => {
      const [showInvoiceLink, onShowInvoiceLinkChange] = useState(invoiceSettings.showInvoiceLink);
      const [showScanToPay, onShowScanToPayChange] = useState(invoiceSettings.showScanToPay);
      const [clientCoversFeeOnPayments, onClientCoversFeeOnPaymentsChange] = useState(
        invoiceSettings.clientCoversFeeOnPayments,
      );
      const [showClosedTrustAccounts, onShowClosedTrustAccountsChange] = useState(false);
      const [isSaving, setIsSaving] = useState(false);
      const [isDeactivating, setIsDeactivating] = useState(false);
      const [accountConnecting, setAccountConnecting] = useState('');
      const [accountDisconnecting, setAccountDisconnecting] = useState('');

      const trustAccountsProps = {
        showClosedTrustAccounts,
        onShowClosedTrustAccountsChange,
        trustAccounts: sortByProperty(
          trustAccounts.filter((ta) => showClosedTrustAccounts || !ta.isClosed),
          'bankAccountName',
          'asc',
          false,
        ),
      };

      const invoiceSettingsProps = {
        showInvoiceLink,
        onShowInvoiceLinkChange,
        showScanToPay,
        onShowScanToPayChange,
        clientCoversFeeOnPayments,
        onClientCoversFeeOnPaymentsChange,
      };

      const connectionManagementProps = {
        accountConnecting,
        onConnectAccount: async ({ bankAccountId, bankAccountType }) => {
          setAccountConnecting(bankAccountId); // Action in progress.
          await props.onConnectAccount({ bankAccountId, bankAccountType }); // Handlers must not throw.
          setAccountConnecting(''); // Action complete.
        },
        accountDisconnecting,
        onDisconnectStripeAccount: async ({ bankAccountId, stripeAccountId }) => {
          setAccountDisconnecting(bankAccountId); // Action in progress.
          await props.onDisconnectStripeAccount({ bankAccountId, stripeAccountId }); // Handlers must not throw.
          setAccountDisconnecting(''); // Action complete.
        },
      };

      const createIntegrationActionHandler =
        (handlerFn, stateUpdateFn) =>
        async (...args) => {
          stateUpdateFn(true); // Action in progress.
          await handlerFn(...args); // Handlers must not throw.
          stateUpdateFn(false); // Action complete.
        };

      const saveManagementProps = {
        isSaving,
        onSave: createIntegrationActionHandler(onSave, setIsSaving),
        isDeactivating,
        onDeactivate: createIntegrationActionHandler(onDeactivate, setIsDeactivating),
      };

      return (
        <StripeSettingsForm
          {...props}
          {...invoiceSettingsProps}
          {...connectionManagementProps}
          {...saveManagementProps}
          {...trustAccountsProps}
        />
      );
    }),
  ),
);

StripeSettingsFormContainer.displayName = 'StripeSettingsFormContainer';

StripeSettingsFormContainer.propTypes = {};

StripeSettingsFormContainer.defaultProps = {};
