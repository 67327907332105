import * as React from 'react';
import { fetchGetP, fetchPostP } from '@sb-itops/redux/fetch';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { responseTypes } from '@sb-itops/fetch-wrapper/src/constants';
import { getUserId } from 'web/services/user-session-management';
import { sendMetric } from 'web/services/metrics';
import { InitStaffSettings } from 'web/graphql/queries';
import { useCacheQuery } from './use-cache-query';

const log = getLogger('useCreateCorrespondenceDocument');

export function useCreateCorrespondenceDocument() {
  const [authorizationLoading, setAuthorizationLoading] = React.useState(false);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [isCreatingCorrespondenceDocument, setIsCreatingCorrespondenceDocument] = React.useState(false);
  const { data: staffData } = useCacheQuery(InitStaffSettings.query);

  React.useEffect(() => {
    setAuthorizationLoading(true);

    fetchGetP({
      path: `/integration/endpoint-locator/letter/:accountId/get-user-has-granted-access`,
      responseType: responseTypes.text,
    })
      .then((authorized) => {
        setIsUnauthorized(authorized.body === 'false');
        setAuthorizationLoading(false);
        return undefined;
      })
      .catch((reason) => {
        setIsUnauthorized(true);
        setAuthorizationLoading(false);
        log.error(`Failed to check if user has granted access: `, reason);
        displayErrorToUser(`Failed to check if user has granted access. Please try again later.`);
      });
  }, []);

  async function createCorrespondenceDocumentInWeb(matterId, body, bodyContentType, documentName, interactive) {
    if (interactive === true) {
      sendMetric('Active_Web_DocumentsTab_CreateNewDocument_Clicked', {
        userId: getUserId(),
        email: staffData?.loggedInStaff?.email ?? '',
        platform: 'Web',
      });
    }
    try {
      let requestBodyContentType = `text/plain`;
      if (bodyContentType?.length && bodyContentType.match(/htm/i)) {
        requestBodyContentType = 'text/html';
      }
      setIsCreatingCorrespondenceDocument(true);
      const documentUrlResponse = await fetchPostP({
        path: `/integration/endpoint-locator/correspondence/:accountId/get-correspondence-document-url`,
        fetchOptions: {
          body: JSON.stringify({
            matterId,
            body,
            bodyType: requestBodyContentType,
            documentName,
          }),
        },
        responseType: responseTypes.text,
        skipCamelCase: true,
      });

      return documentUrlResponse.body;
    } catch (error) {
      log.error(`Failed to create correspondence document: `, error);
      displayErrorToUser(`Failed to create document ${documentName}. Please try again later.`);
    } finally {
      setIsCreatingCorrespondenceDocument(false);
    }

    return undefined;
  }

  async function createCorrespondenceDocumentInDesktop(matterId, documentName, interactive) {
    if (interactive === true) {
      sendMetric('Active_Web_DocumentsTab_CreateNewDocument_Clicked', {
        userId: getUserId(),
        email: staffData?.loggedInStaff?.email ?? '',
        platform: 'Desktop',
      });
    }

    try {
      setIsCreatingCorrespondenceDocument(true);

      const addDocumentToMatterResponse = await fetchPostP({
        path: `/integration/endpoint-locator/correspondence/:accountId/add-correspondence-document-to-matter`,
        fetchOptions: {
          body: JSON.stringify({
            matterId,
            documentName,
          }),
        },
        responseType: responseTypes.json,
      });

      const fileId = addDocumentToMatterResponse.body.matterFileApiResponse.fileId;
      const fileName = addDocumentToMatterResponse.body.fileName;

      document.location = `smokeballfilemanager://DownloadFile?matterId=${encodeURIComponent(
        matterId,
      )}&fileId=${encodeURIComponent(fileId)}&fileName=${encodeURIComponent(fileName ?? '')}`;
    } catch (error) {
      log.error(`Failed to create letter: `, error);
      displayErrorToUser(`Failed to create document ${documentName}. Please try again later.`);
    } finally {
      setIsCreatingCorrespondenceDocument(false);
    }

    return undefined;
  }

  return {
    authorizationLoading,
    isUnauthorized,
    createCorrespondenceDocumentInWeb,
    createCorrespondenceDocumentInDesktop,
    isCreatingCorrespondenceDocument,
  };
}
