import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import PropTypes from 'prop-types';
import { billingEventType } from '@sb-billing/business-logic/shared/entities';
import { getEarliestByRelatedIdAndTypeGroupedByDebtor } from '@sb-billing/redux/billing-events';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { InvoiceViewedOnlineDetails } from './InvoiceViewedOnlineDetails';

const mapStateToProps = (state, { invoiceId, debtorId }) => {
  const { INVOICE_VIEWED_ONLINE } = billingEventType;
  const earliestViewedPerContact = getEarliestByRelatedIdAndTypeGroupedByDebtor(invoiceId, INVOICE_VIEWED_ONLINE) || {};
  // When debtorId passed for split billing child invoice, we only show status for that debtor
  const viewedPerContact = Object.values(earliestViewedPerContact)
    .filter((billingEvent) => !debtorId || billingEvent.contactId === debtorId)
    .map((billingEvent) => ({
      ...billingEvent,
      contactDisplayName: getContactDisplay(billingEvent.contactId),
    }));

  return {
    viewedPerContact,
  };
};

export const InvoiceViewedOnlineDetailsContainer = withReduxStore(connect(mapStateToProps)(InvoiceViewedOnlineDetails));

InvoiceViewedOnlineDetailsContainer.displayName = 'InvoiceViewedOnlineDetailsContainer';

InvoiceViewedOnlineDetailsContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  debtorId: PropTypes.string,
};

InvoiceViewedOnlineDetailsContainer.defaultProps = {
  debtorId: undefined,
};
