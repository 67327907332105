import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

function formatPhoneNumber(areaCode, phoneNumber) {
  return `${areaCode ? `(${areaCode}) ` : ''}${phoneNumber || ''}`;
}

export const onCreateEditPersonContact = ({ commandMessage, commandResult }) => {
  const { personFields, addressFields } = commandMessage.contactFields;

  const optimisticAddress = {
    addressLine1: addressFields && addressFields.addressLine1,
    addressLine2: addressFields && addressFields.addressLine2,
    city: addressFields && addressFields.city,
    state: addressFields && addressFields.state,
    zipCode: addressFields && addressFields.zipCode,
    country: addressFields && addressFields.country,
  };

  const optimisticContact = {
    __typename: 'Contact',
    id: commandResult.contact.id,
    displayName: [personFields.lastName, personFields.firstName].filter((name) => name).join(', '),
    removed: false,
    cell: formatPhoneNumber(personFields.cellAreaCode, personFields.cellNumber),
    email: personFields.email,
    birthDate: personFields.birthDate,
    firstName: personFields.firstName,
    lastName: personFields.lastName,
    phone: formatPhoneNumber(personFields.phoneAreaCode, personFields.phoneNumber),
    type: 'person',
    representativeOfs: [],
    representativeOfsDisplayName: '',
    roles: [],
    residentialAddress: optimisticAddress,
    businessAddress: optimisticAddress,
  };

  const apolloClient = getApolloClient();

  if (personFields.linkedCompany) {
    const linkedCompanyId = apolloClient.cache.identify({ __typename: 'Contact', id: personFields.linkedCompany });
    const linkedComanyEntity = apolloClient.readFragment({
      id: linkedCompanyId,
      fragment: gql`
        fragment LinkedCompany on Contact {
          id
          displayName
        }
      `,
    });

    if (linkedComanyEntity) {
      optimisticContact.representativeOfs.push(linkedComanyEntity);
      optimisticContact.representativeOfsDisplayName = linkedComanyEntity.displayName;
    }
  }

  const contactIdentifier = apolloClient.cache.identify(optimisticContact);

  apolloClient.writeFragment({
    id: contactIdentifier,
    fragment: gql`
      fragment OpdateContact on Contact {
        ${Object.keys(optimisticContact).join('\n')}
      }
    `,
    data: {
      ...optimisticContact,
    },
  });

  return {
    eventType: apolloEntityOpdateEventTypes.ENTITY_ADDED,
    typeName: 'Contact',
    optimisticEntity: optimisticContact,
  };
};
