import { getPaymentsByInvoiceId } from '@sb-billing/redux/payments';

angular.module('@sb-billing/services').service('sbInvoicePreviewService', function ($http, sbGenericEndpointService, sbInvoicingService) {
  const that = this;
  //const log = sbLoggerService.getLogger('sbInvoicePreviewService');
  const singleEndpoint = 'billing/invoice-pdf/preview';
  const combinedEndpoint = 'billing/invoice-pdf/preview-combined';
  const consolidatedEndpoint = 'billing/consolidated-billing-pdf/async/';

  that.getSinglePdfPreviewBase64P = getSinglePdfPreviewBase64P;
  that.getSinglePdfPreviewBlobP = getSinglePdfPreviewBlobP;
  that.getCombinedPdfPreviewBase64P = getCombinedPdfPreviewBase64P;

  async function getSinglePdfPreviewBase64P (pdfContext) {
    const extraArgs = { skipCamelCase: true };
    return sbGenericEndpointService.postPayloadP(singleEndpoint, ``, pdfContext, 'POST', extraArgs);
  }

  async function getSinglePdfPreviewBlobP (pdfContext) {
    const extraArgs = { skipCamelCase: true };
    const response = await sbGenericEndpointService.postPayloadP(singleEndpoint, `/presigned-url`, pdfContext, 'POST', extraArgs);
    const request = {
      method: 'GET',
      url: response.preSignedUrl,
      responseType: 'arraybuffer',
    };

    const pdfResponse = await $http(request);
    return new Blob([pdfResponse.data], { type: 'application/pdf' });
  }

  function getCombinedPdfPreviewBase64P (requestId, invoiceIds, consolidated, addresseeId) {
    if (consolidated) {
      return getConsolidatedInvoicesPdfPreview(requestId, invoiceIds, addresseeId);
    }

    return getCombinedPdfPreview(requestId, invoiceIds, addresseeId);
  }

  function getConsolidatedInvoicesPdfPreview (requestId, invoiceIds, addresseeId) {
    const extraArgs = { skipCamelCase: true };
    const body = {
      requestId,
      consolidatedGroups: getConsolidatedGroups(invoiceIds, addresseeId),
      consolidate: true,
      addresseeId,
    };

    return sbGenericEndpointService.postPayloadP(consolidatedEndpoint, ``, body, 'POST', extraArgs);
  }

  // take the invoices and convert it to a
  // {
  //   debtorIdsGroupId: {
  //     matterId,
  //     debtorIds,
  //     invoices: [
  //       {
  //         invoiceId,
  //         paymentIds,
  //         versionId
  //       }
  //     ]
  //   }
  // }
  function getConsolidatedGroups (invoiceIds, addresseeId) {
    return invoiceIds
      .map((invoiceId) => {
        const invoiceVersion = sbInvoicingService.getInvoice(invoiceId);
        const paymentIds = _.map(getPaymentsByInvoiceId(invoiceId), payment => payment.paymentId);
        const debtorIds = invoiceVersion.debtors.map((debtor) => debtor.id);
        const debtorIdsGroupId = debtorIds.sort().join('|');
        return {
          invoiceId,
          debtorIdsGroupId: debtorIdsGroupId,
          debtorIds,
          matterId: invoiceVersion.matterId,
          versionId: invoiceVersion.versionId,
          paymentIds,
        };
      })
      .reduce((all, invoice) => {
        // if an addresseeId has been specified, we are coming from the contact view and should group by that
        // to ensure a single cover letter addressed to them only
        const addresseeKey = addresseeId ? addresseeId : invoice.debtorIdsGroupId;

        if (all[addresseeKey]) {
          all[addresseeKey].invoices.push({
            invoiceId: invoice.invoiceId,
            paymentIds: invoice.paymentIds,
            versionId: invoice.versionId,
          });
        } else {
          all[addresseeKey] = {
            matterId: invoice.matterId,
            debtorIds: invoice.debtorIds,
            invoices: [{
              invoiceId: invoice.invoiceId,
              paymentIds: invoice.paymentIds,
              versionId: invoice.versionId,
            }],
          }
        }

        return all;
      }, {});
  }

  function getCombinedPdfPreview (requestId, invoiceIds, addresseeId) {
    const extraArgs = { skipCamelCase: true };
    const body = {
      requestId,
      invoices: invoiceIds.map((invoiceId) => getCombinedPdfPreviewInvoice(invoiceId, addresseeId)),
    };

    return sbGenericEndpointService.postPayloadP(combinedEndpoint, ``, body, 'POST', extraArgs);
  }

  function getCombinedPdfPreviewInvoice (invoiceId, addresseeId) {
    const payments = getPaymentsByInvoiceId(invoiceId).map((payment) => {
      return {
        paymentId: payment.paymentId,
        reversed: !!payment.reversedAt,
      };
    });
    const invoiceVersion = sbInvoicingService.getInvoice(invoiceId);

    return {
      invoiceId,
      debtorId: addresseeId,
      versionId: invoiceVersion.versionId,
      payments,
    };
  }
});
