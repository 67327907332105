import * as Yup from 'yup';
import { constants as invoiceSettingsConstants, isValidLines } from '@sb-billing/business-logic/invoice-settings';
import { decodeHtml } from '@sb-billing/encode-decode-html-invoice-settings';
import { dateToInteger } from '@sb-itops/date';
import { getTextContent } from '@sb-itops/html';
import { customYupValidators } from '@sb-itops/business-logic/validation/services';
import { PrintManually } from '@sb-billing/business-logic/cheques';
import { maxEntriesPerInvoice } from '@sb-billing/business-logic/invoice/entities';

// Context we need to pass when validating:
// descriptionOnDemandEnabled,
// isProtectedTrustFundsEnabled
// availableTrustFunds
// isShowLessFundsInTrustEnabled
// lessFundsInTrustAmount
// total (totals.total)
// preferredBankAccountTypes
// lastTrustChequeNumber
// nextTrustChequeNumber

function validateFooter(value) {
  const parser = new DOMParser();
  const html = parser.parseFromString(value, 'text/html');
  return isValidLines(html, invoiceSettingsConstants.footerTextMaxLines);
}

export const draftInvoiceSchema = Yup.object().shape({
  issueDate: Yup.date().required(),
  dueDate: Yup.date()
    .required()
    .test('due-date-not-before-issue-date', 'Due Date cannot be before Issue Date', (dueDate, props) => {
      const issueDateInt = dateToInteger(props.parent.issueDate);
      const dueDateInt = dateToInteger(dueDate);
      if (!issueDateInt || dueDateInt >= issueDateInt) {
        return true;
      }
      return false;
    }),
  selectedContacts: Yup.array()
    .of(
      Yup.object({
        id: customYupValidators.uuid().required(),
        displayName: Yup.string().required(),
      }),
    )
    .min(1)
    .required(),
  selectedFeeIds: Yup.array().when('selectedExpenseIds', {
    // Need at least one fee or expense entry selected
    is: (selectedExpenseIds) => !selectedExpenseIds?.length,
    then: (selectedFeeIds) =>
      selectedFeeIds
        .of(customYupValidators.uuid())
        .min(1, 'At least one fee or expense must be selected')
        .required('Must select at least 1 fee')
        .test('entry-limit', 'Maximum number of invoice entries exceeded', (value, { parent }) => {
          // Have to specify the error message for all of the above conditions
          // otherwise Yup will apply the error message from this condition
          const selectedExpenseIdsCount = (parent.selectedExpenseIds || []).length;

          const selectedFeeIdsCount = (value || []).length;

          const isEntryCountBelowMax = selectedFeeIdsCount + selectedExpenseIdsCount <= maxEntriesPerInvoice;
          return isEntryCountBelowMax;
        }),
    otherwise: (selectedFeeIds) =>
      selectedFeeIds
        .optional()
        .test('entry-limit', 'Maximum number of invoice entries exceeded', (value, { parent }) => {
          const selectedExpenseIdsCount = (parent.selectedExpenseIds || []).length;

          const selectedFeeIdsCount = (value || []).length;

          const isEntryCountBelowMax = selectedFeeIdsCount + selectedExpenseIdsCount <= maxEntriesPerInvoice;
          return isEntryCountBelowMax;
        }),
  }),
  selectedExpenseIds: Yup.array().of(customYupValidators.uuid()).optional(),
  configOverrides: Yup.object().shape({
    footer: Yup.string().test('is-valid-footer', 'invalid footer', (value) => {
      const { footer: decodedFooter } = decodeHtml({ footer: value });
      return validateFooter(decodedFooter);
    }),
  }),
  summary: Yup.string().when('$descriptionOnDemandEnabled', {
    is: true,
    then: Yup.string()
      .nullable()
      .test('is-valid-summary', 'Please enter a summary description', (value) => !!getTextContent(value)),
    otherwise: Yup.string().nullable().optional(),
  }),
  feeSummaryLineDescription: Yup.string().when('showFeesEntriesAs', {
    is: 'SUMMARY',
    then: Yup.string().required(),
    otherwise: Yup.string().nullable().optional(),
  }),
  expenseSummaryLineDescription: Yup.string().when('showExpenseEntriesAs', {
    is: 'SUMMARY',
    then: Yup.string().required(),
    otherwise: Yup.string().nullable().optional(),
  }),
  trustChequeReference: Yup.string().when('chequePrintingMethod', {
    is: (value) => value === PrintManually,
    then: (reference) =>
      reference.test('is-valid-reference', '', (trustChequeReference, { createError, options }) => {
        const { lastTrustChequeNumber, nextTrustChequeNumber, t } = options.context || {};

        // Switching returning to an instantiated form will trigger a validation
        // without the context values being provided. A re-render will re-validate
        // in the meantime we can't ascertain whether the form is valid or not
        if (!t) {
          return true;
        }

        if (!trustChequeReference) {
          return createError({
            path: 'trustChequeReference',
            message: `Warning: ${t('capitalize', { val: 'cheque' })} reference is required.`,
          });
        }

        if (!/^[0-9]+$/.test(trustChequeReference)) {
          return createError({
            path: 'trustChequeReference',
            message: `Warning: ${t('capitalize', { val: 'cheque' })} reference must be numeric.`,
          });
        }

        // Strip out leading zeros and compare
        if (nextTrustChequeNumber.replace(/^0+/, '') !== trustChequeReference.replace(/^0+/, '')) {
          return createError({
            path: 'trustChequeReference',
            message: `Warning: ${t('capitalize', { val: 'cheque' })} reference is already in use. Last ${t(
              'cheque',
            ).toLowerCase()} reference printed was ${lastTrustChequeNumber}.`,
          });
        }

        return true;
      }),
    otherwise: (reference) => reference.optional(),
  }),
  quickPayments: Yup.object()
    .shape({
      trust: Yup.object().shape({
        amount: Yup.number().required(),
        matterId: Yup.string().required(),
        source: Yup.string().required(),
        sourceAccountId: Yup.string(), // Not required as default trust account for matter may not exist
        sourceAccountType: Yup.string().required(),
      }),
      operating: Yup.object().shape({
        amount: Yup.number().required(),
        matterId: Yup.string().required(),
        source: Yup.string().required(),
        sourceAccountId: Yup.string().required(),
        sourceAccountType: Yup.string().required(),
      }),
      credit: Yup.object().shape({
        amount: Yup.number().required(),
        matterId: Yup.string().required(),
        source: Yup.string().required(),
        sourceAccountId: Yup.string().required(),
        sourceAccountType: Yup.string().required(),
      }),
    })
    .test('is-under-amount-due', '', (quickPayments, { createError, options, parent }) => {
      const { isShowLessFundsInTrustEnabled, lessFundsInTrustAmount, total, preferredBankAccountTypes } =
        options.context || {};

      const showLessFundsInTrustChecked = parent.showLessFundsInTrust; // fieldValues.showLessFundsInTrust

      if (
        quickPayments.credit.amount +
          quickPayments.trust.amount +
          quickPayments.operating.amount +
          // While we are not taking the lessFundsInTrust amount right now and it is not an actual transaction
          // we still include this psuedo-payment here to ensure the user does not end up with an invoice that appears overpaid.
          (isShowLessFundsInTrustEnabled && showLessFundsInTrustChecked ? lessFundsInTrustAmount : 0) >
        total
      ) {
        // We will display this error against the least preferred bank account with funds allocated to it
        const accountTypeInError = preferredBankAccountTypes?.toReversed().find((accountType) => {
          const accountTypeLowercased = accountType.toLowerCase();
          return !!quickPayments[accountTypeLowercased].amount;
        });

        // We expect to find at least one payment, but just in case we don't
        if (accountTypeInError) {
          return createError({
            path: `quickPayments.${accountTypeInError.toLowerCase()}.amount`,
            message: 'Please enter an amount no greater than the amount due',
          });
        }
      }
      return true;
    })
    .test('is-trust-amount-under-available-balance', '', (quickPayments, { createError, options }) => {
      const { isProtectedTrustFundsEnabled, availableTrustFunds, t } = options.context || {};
      const trustAmount = quickPayments?.trust?.amount;

      if (
        isProtectedTrustFundsEnabled &&
        trustAmount &&
        Number.isFinite(availableTrustFunds) &&
        trustAmount > availableTrustFunds
      ) {
        return createError({
          path: 'quickPayments.trust.amount', // We want to display error specifically for trust
          message: `Payment amount cannot exceed available ${t('trust').toLowerCase()} balance`,
        });
      }
      return true;
    }),
});
