import composeHooks from '@sb-itops/react-hooks-compose';
import { useState } from 'react';
import { SelectMatterTypeModal } from './SelectMatterTypeModal';

export interface ISelectMatterTypeModalContainerProps {
  defaultLocationId?: string;
  onClose: () => void;
  onSubmit: (matterTypeIds: string[]) => void;
}

const hooks = ({ onClose, onSubmit, defaultLocationId }: ISelectMatterTypeModalContainerProps) => ({
  useFormState: () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(defaultLocationId);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
    const [selectedMatterTypeId, setSelectedMatterTypeId] = useState<string>();
    const [selectedOriginalMatterTypeId, setSelectedOriginalMatterTypeId] = useState<string>();

    const onLocationUpdated = (locationId: string) => setSelectedLocationId(locationId);
    const onCategoryUpdated = (categoryId: string) => {
      setSelectedCategoryId(categoryId);
    };
    const onMatterTypeUpdated = (matterType: any) => {
      setSelectedMatterTypeId(matterType?.value);
      setSelectedOriginalMatterTypeId(matterType?.originalMatterTypeId);
    };

    const onSubmitMatterType = () => {
      setIsSubmitting(true);
      onSubmit([selectedOriginalMatterTypeId!]);
      setIsSubmitting(false);
      onClose();
    };

    return {
      onClose,
      isSubmitting,
      onSubmit: onSubmitMatterType,
      selectedLocationId,
      selectedMatterTypeId,
      selectedCategoryId,
      onLocationUpdated,
      onCategoryUpdated,
      onMatterTypeUpdated,
    };
  },
});

export const SelectMatterTypeModalContainer = composeHooks(hooks)(SelectMatterTypeModal);
