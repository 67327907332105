import React from 'react';
import { MatterTypeSelect } from 'web/react-redux/components/matter-type-select';
import { Button, Modal } from '@sb-itops/react';
import Styles from './Modal.module.scss';

export interface ISelectMatterTypeModalProps {
  selectedLocationId?: string;
  selectedMatterTypeId?: string;
  selectedCategoryId?: string;
  isSubmitting: boolean;
  onLocationUpdated: (locationId: string) => void;
  onCategoryUpdated: (categoryId: string) => void;
  onMatterTypeUpdated: (matterTypeId: string) => void;
  onClose: () => void;
  onSubmit: () => void;
}

export const SelectMatterTypeModal = (props: ISelectMatterTypeModalProps) => (
  <Modal
    className={Styles.modal}
    title="Select Matter Type"
    body={
      <MatterTypeSelect
        locationId={props.selectedLocationId}
        matterTypeCategory={props.selectedCategoryId}
        matterTypeId={props.selectedMatterTypeId}
        onLocationUpdated={props.onLocationUpdated}
        onCategoryUpdated={props.onCategoryUpdated}
        onMatterTypeUpdated={props.onMatterTypeUpdated}
        isRequired
        showError={false}
        showOnlyLeads={false}
      />
    }
    footer={
      <>
        <Button
          disabled={
            props.isSubmitting || !props.selectedMatterTypeId || !props.selectedLocationId || !props.selectedCategoryId
          }
          size="full-width"
          onClick={props.onSubmit}
        >
          Select
        </Button>
        <Button disabled={props.isSubmitting} size="full-width" onClick={props.onClose} type="secondary">
          Cancel
        </Button>
      </>
    }
    onModalClose={props.onClose}
  />
);
