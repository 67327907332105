import { gql } from '@apollo/client';

const query = gql`
  query InvoicePreviewData($invoiceId: ID!) {
    invoice(id: $invoiceId) {
      id
      invoicePdfVersionId
      versionId
      invoiceNumber
      isOriginalInvoice
      debtors {
        id
        contact {
          id
          displayNameFull
        }
        invoiceNumberSuffix
      }
      splitBillingSettings {
        isEnabled
      }
    }
  }
`;

const notificationIds = ['InvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoicePreviewData = {
  query,
  notificationIds,
};
