angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('home.billing.split-billing-invoice-proof-of-payment', {
      url: '/invoice-proof-of-payment/:invoiceId/debtor/:debtorId',
      template: `<sb-invoice-proof-of-payment-view style="width:100%"></sb-invoice-proof-of-payment-view>`,
      data: {
        authorized: true,
        navHighlights: ['billing', 'invoice', 'payment', 'receipt'],
        tab: { type: 'invoice-proof-of-payment' }
      }
    });

});
