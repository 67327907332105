angular.module('sb.billing.webapp').controller('ListBillsController', function ( sbSaveInvoiceCommand, sbAsyncOperationsService,
  sbInvoiceSendService, sbLinkService,
) {
  const ctrl = this;

  ctrl.sbAsyncOperationsService = sbAsyncOperationsService;
  ctrl.sbInvoiceSendService = sbInvoiceSendService;
  ctrl.sbSaveInvoiceCommand = sbSaveInvoiceCommand;
  ctrl.onClickLink = sbLinkService.onClickLink;
  ctrl.isCombineInProgress = isCombineInProgress;
  ctrl.isDownloadLedesInProgress = isDownloadLedesInProgress;

  function isCombineInProgress() {
    return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.COMBINE_INVOICES) > 0;
  }

  function isDownloadLedesInProgress() {
    return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.DOWNLOAD_LEDES) > 0;
  }
});
