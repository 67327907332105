import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';

import { MatterUTBMS } from './MatterUTBMS';

const mapStateToProps = (state, { formInitialised, formFields, formDisabled, formSubmitting, submitFailed }) => {
  const {
    isUtbmsEnabled,
    ledesFirmId,
    ledesMatterId,
    ledesClientMatterId,
    ledesTimekeeperClassificationType,
    splitBillingEnabled,
  } = formFields;

  return {
    // form state
    formInitialised,
    formDisabled,
    formSubmitting,
    submitFailed,
    // form fields
    isUtbmsEnabled,
    ledesFirmId,
    ledesMatterId,
    ledesClientMatterId,
    ledesTimekeeperClassificationType,
    splitBillingEnabled,
  };
};

const mapDispatchToProps = (dispatch, { firmDefaultLedesFirmId, formFieldValues, onFieldValuesUpdated }) => ({
  toggleEnableUtbms: (value) => {
    if (value && !formFieldValues.ledesFirmId) {
      onFieldValuesUpdated({
        ledesFirmId: firmDefaultLedesFirmId,
      });
    }
    onFieldValuesUpdated({
      isUtbmsEnabled: value,
    });
  },
});

export const MatterUTBMSContainer = withReduxStore(connect(mapStateToProps, mapDispatchToProps)(MatterUTBMS));

MatterUTBMSContainer.displayName = 'MatterUTBMSContainer';

MatterUTBMSContainer.propTypes = {
  matterId: PropTypes.string,
  firmDefaultLedesFirmId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
};

MatterUTBMSContainer.defaultProps = {
  matterId: undefined,
  firmDefaultLedesFirmId: undefined,
};
