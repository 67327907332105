import React from 'react';
import { EmbeddedIframePosition } from 'web/redux/features/shared/types';
import { ConnectionIcon } from '../icons';

import * as Styles from './Infotrack.module.scss';

export const ErrorMessage = (props: { position: EmbeddedIframePosition; visible: Boolean }) => {
  const position = props.position;
  const style: React.HTMLAttributes<HTMLDivElement>['style'] = position
    ? {
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: `${position.width}px`,
        height: `${position.height}px`,
        visibility: props.visible ? 'visible' : 'hidden',
        zIndex: props.visible ? 1 : -1,
      }
    : undefined;

  return (
    <div className={Styles.container} style={style}>
      <div className={Styles.svg}>
        <ConnectionIcon />
      </div>
      <div className={Styles.errorMessage}>
        InfoTrack service access failed, there was a problem contacting the server.
      </div>
      <div className={Styles.errorDescription}>Please try again later.</div>
    </div>
  );
};
