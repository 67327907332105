import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  useTranslation,
  CheckboxLabelInline,
  ClickableIcon,
  InputWithLoadingState,
  SlidingToggle,
  HtmlWithTooltip,
  Button,
  ContextMenu,
  CurrencyInput2,
  StatelessSelect,
} from '@sb-itops/react';
import {
  notApplicablePrintMethodLocalised,
  PrintLater,
  PrintManually,
  printMethods,
} from '@sb-billing/business-logic/cheques';
import { SendViaCommunicateConfirmModal, InvoiceConfirmFinaliseModal } from 'web/react-redux';
import { featureActive } from '@sb-itops/feature';
import classnames from 'classnames';
import { ProtectedFundsLineItem } from '@sb-billing/react/protected-funds-line-item';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { DRAFT_INVOICE_DELETE_PROCEED_MODAL_ID } from 'web/components';
import { Info } from 'web/components/icons';
import { getOwningCompany } from 'web/services/user-session-management';
import { owningCompanyBrandNames } from '@sb-itops/business-logic/white-label';
import { DiscountOptions } from './discount-options';
import { SurchargeOptions } from './surcharge-options';
import Styles from './FinalisePanel.module.scss';
import StylesRoute from '../BillingDraftInvoiceRoute.module.scss';
import { ContactBalanceAllocationModal } from './contact-balance-allocation-modal';

export const FinalisePanel = ({
  // User billing attributtes messages
  invoiceTutorialLink,
  showInvoiceTutorial,
  onCloseInvoiceTutorialBanner,
  sendViaCommunicateHelpLink,
  doNotShowSendConfirmModalAgain,
  onDoNotShowSendViaCommunicateConfirmModalAgain,
  // Form
  formData,
  formErrors,
  formReady,
  formValid,
  onUpdateField,
  // Discount props
  canApplyDiscount,
  expandedDiscount,
  setExpandedDiscount,
  onConfirmDiscount,
  onDiscardDiscount,
  onApplyDiscountChecked,
  // Surcharge props
  canApplySurcharge,
  expandedSurcharge,
  setExpandedSurcharge,
  onConfirmSurcharge,
  onDiscardSurcharge,
  onApplySurchargeChecked,
  // Allocation
  onAutoAllocate,
  preferredBankAccountTypes,
  onChangeContactBalanceAllocations,
  showAllocationsModal,
  setShowAllocationsModal,
  showPayFromAccordian,
  onSetShowPayFromAccordian,
  // Config
  supportsAutoAllocatePayments,
  supportsOperatingAccount,
  supportsTax,
  showOnlyPrintLaterCheque,
  // Email and communicate
  showInvoiceConfirmFinaliseModal,
  onInvoiceConfirmFinaliseModalConfirm,
  setShowInvoiceConfirmFinaliseModal,
  showSendViaCommunicateConfirmModal,
  setShowSendViaCommunicateConfirmModal,
  onOpenInvoiceCommunicateModal,
  // Save and preview
  saveIsLocked,
  previewIsLocked,
  pdfPreviewIsDisabled,
  handleSaveTypeFinal,
  onSaveAndGenerateLedes,
  handleViewPreview,
  // Rest
  totals,
  balances,
  hasDefaultTrustAccount,
  hasUnpaidAD,
  hasZeroBalance,
  matterId,
  trustAccountsDisabled,
  isBalanceAvailable,
  showRetainer,
  retainerRequestAmount,
  protectedTrustFundsAmount,
  lessFundsInTrustAmount,
  draftInvoice,
  showLedes,
  showTrustCheque,
  trustChequeNumberLoading,
  showLessFundsInTrustSection,
  showClientCoversFeeWarning,
  onUpdateTrustChequeReference,
  onClickLink,
  overrideRedirect,
  closeCurrentTab,
  // Split billing
  isSplitBillingEnabled,
  selectedDebtorId,
  debtorOptions,
  invoiceDebtorTotalsLookup,
  debtorQuickPaymentStatus,
  onUpdateDebtorSpecificQuickPayments,
  onDebtorChange,
  onChangeContactBalanceAllocationsForSplitBilling,
}) => {
  const { t } = useTranslation();

  // Localisation
  const noDefaultTrustAccountTooltip = `To use this feature, please select a default ${t(
    'trustPaymentAccount',
  )} from your Settings page > Accounts page`;
  const notApplicablePrintMethod = notApplicablePrintMethodLocalised(t);
  const chequeLabel = t('capitalize', { val: 'cheque' });
  const brandName = owningCompanyBrandNames[getOwningCompany()] || owningCompanyBrandNames.Smokeball;
  const disableLessFundsInTrustTooltip = isSplitBillingEnabled
    ? 'Less Funds In Trust cannot be added to split billed invoices'
    : noDefaultTrustAccountTooltip;

  // TODO handle loading better
  if (!formReady) {
    <div className={Styles.finalisePanel} />;
  }

  return (
    <div className={Styles.finalisePanel}>
      {showClientCoversFeeWarning && (
        <div className={Styles.retainerFeeWarning}>
          <div className={Styles.warningIcon}>
            <Icon
              type="alert-1"
              tooltip="Credit Card transaction fees will be passed on to the client as this invoice includes a retainer request."
              className={Styles.warningIcon}
            />
          </div>
          <div>
            Credit Card transaction fees will be passed on to the client as this invoice includes a retainer request.
          </div>
        </div>
      )}
      <div className={Styles.totals}>
        <div className={classnames(Styles.draftToggle, 'btn-group')}>
          <div
            className={formData.saveType === 'DRAFT' ? 'btn-primary btn' : 'btn-default btn'}
            onClick={() => onUpdateField('saveType', 'DRAFT')}
          >
            DRAFT
          </div>
          <div
            className={formData.saveType === 'FINAL' ? 'btn-primary btn' : 'btn-default btn'}
            onClick={() => onUpdateField('saveType', 'FINAL')}
          >
            FINAL
          </div>
        </div>

        <div className={Styles.blankSeparator} />
        <div className={Styles.lineItem}>
          <div>Time and Fees</div>
          <div>{t('cents', { val: totals.feeTotal })}</div>
        </div>
        <div className={Styles.lineItem}>
          <div>{t('capitalizeAllWords', { val: 'expenses' })}</div>
          <div>{t('cents', { val: totals.expenseTotal })}</div>
        </div>
        <div className={Styles.lineItem}>
          <div>Subtotal</div>
          <div>{t('cents', { val: totals.billed })}</div>
        </div>

        {!!totals.writtenOff && (
          <div className={Styles.lineItem}>
            <div>Written Off</div>
            <div className={Styles.writtenOff}>{t('cents', { val: -totals.writtenOff })}</div>
          </div>
        )}

        {((showLessFundsInTrustSection && !!totals.total) || canApplyDiscount || canApplySurcharge) && (
          <div className={classnames(Styles.separator, Styles.totalSeparator)} />
        )}
        {canApplyDiscount && (
          <div className={Styles.lineItem}>
            <div className={Styles.checkboxLabel}>
              <CheckboxLabelInline checked={!!formData.discount.enabled} onChange={onApplyDiscountChecked}>
                Apply discount on fees
              </CheckboxLabelInline>
            </div>
            {!!totals.discount && (
              <div className={Styles.editContainer}>
                {!expandedDiscount && (
                  <ClickableIcon
                    className={classnames('fa fa-pencil', Styles.pencilEdit)}
                    onClick={() => setExpandedDiscount(true)}
                  />
                )}
                {t('cents', { val: -totals.discount })}
              </div>
            )}
          </div>
        )}

        {expandedDiscount && (
          <DiscountOptions
            discount={formData?.discount}
            maxValue={totals.feeTotal - totals.writtenOffFeeTotal}
            onConfirmDiscount={onConfirmDiscount}
            onDiscardDiscount={onDiscardDiscount}
          />
        )}
        {canApplySurcharge && (
          <div className={Styles.lineItem}>
            <div className={Styles.checkboxLabel}>
              <CheckboxLabelInline checked={!!formData.surcharge?.enabled} onChange={onApplySurchargeChecked}>
                Apply surcharge
              </CheckboxLabelInline>
            </div>
            {!!totals.surcharge && (
              <div className={Styles.editContainer}>
                {!expandedSurcharge && (
                  <ClickableIcon
                    className={classnames('fa fa-pencil', Styles.pencilEdit)}
                    onClick={() => setExpandedSurcharge(true)}
                  />
                )}
                {t('cents', { val: totals.surcharge })}
              </div>
            )}
          </div>
        )}
        {featureActive('BB-7270') && expandedSurcharge && (
          <SurchargeOptions
            surcharge={formData?.surcharge}
            onConfirmSurcharge={onConfirmSurcharge}
            onDiscardSurcharge={onDiscardSurcharge}
          />
        )}

        {featureActive('BB-6398') && (!hasDefaultTrustAccount || isSplitBillingEnabled) && !!totals.total && (
          <div title={disableLessFundsInTrustTooltip}>
            <div className={Styles.lineItem}>
              <div className={classnames(Styles.checkboxLabel, Styles.disabled)}>
                <CheckboxLabelInline checked={false} disabled onChange={() => {}}>
                  Show less funds held in {t('trust').toLowerCase()}?
                </CheckboxLabelInline>
              </div>
              <div className={Styles.labelDisabled}>n/a</div>
            </div>
            {featureActive('BB-8671') && (
              <div className={Styles.labelDisabled}>
                <ProtectedFundsLineItem indentLeft disabled tooltip={disableLessFundsInTrustTooltip} />
              </div>
            )}
          </div>
        )}
        {featureActive('BB-6398') &&
          hasDefaultTrustAccount &&
          !isSplitBillingEnabled &&
          lessFundsInTrustAmount > 0 &&
          !!totals.total && (
            <>
              <div
                className={Styles.lineItem}
                title={`${t('capitalize', { val: 'trustAccount' })}: ${balances.TRUST.name}`}
              >
                <div className={Styles.checkboxLabel}>
                  <CheckboxLabelInline
                    checked={!!formData.showLessFundsInTrust}
                    onChange={(checked) => {
                      onUpdateField('showLessFundsInTrust', checked);
                    }}
                    disabled={!!formData.quickPayments?.trust?.amount}
                  >
                    Show less funds held in {t('trust').toLowerCase()}?
                  </CheckboxLabelInline>
                </div>
                <div className={formData.showLessFundsInTrust ? Styles.labelFontWeight : Styles.labelDisabled}>
                  {t('cents', { val: -lessFundsInTrustAmount })}
                </div>
              </div>
              {featureActive('BB-8671') && !!protectedTrustFundsAmount && (
                <ProtectedFundsLineItem
                  tooltip={`${t('capitalize', { val: 'trustAccount' })}: ${balances.TRUST.name}`}
                  amount={protectedTrustFundsAmount}
                  onClickLink={onClickLink}
                  indentLeft
                  matterId={matterId}
                  bankAccountId={balances?.TRUST?.bankAccountId}
                  overrideRedirect={overrideRedirect}
                />
              )}
            </>
          )}
        <div className={classnames(Styles.separator, Styles.totalSeparator)} />
        {!!totals.tax && supportsTax && (
          <div className={Styles.lineItem}>
            <label>{t('tax')}</label>
            <div>{t('cents', { val: totals.tax })}</div>
          </div>
        )}

        {showRetainer ? (
          <>
            <div className={Styles.lineItem}>
              <label>{featureActive('BB-6398') ? 'Invoice Balance Due' : 'Invoice Total'}</label>
              <div>{t('cents', { val: totals.total })}</div>
            </div>
            <div className={Styles.lineItem}>
              <label>Retainer Request</label>
              <div>{t('cents', { val: retainerRequestAmount })}</div>
            </div>
            <div className={Styles.lineItem}>
              <label>
                <strong>{featureActive('BB-6398') ? 'Total Payment Due' : 'Total'}</strong>
              </label>
              <div>
                <strong>{t('cents', { val: totals.total + retainerRequestAmount })}</strong>
              </div>
            </div>
          </>
        ) : (
          <div className={Styles.lineItem}>
            <label>
              <strong>{featureActive('BB-6398') ? 'Balance Due' : 'Total'}</strong>
            </label>
            <div>
              <strong>{t('cents', { val: totals.total })}</strong>
            </div>
          </div>
        )}
        {isSplitBillingEnabled && formData.saveType === 'FINAL' && (
          <>
            <div className={classnames(Styles.separator, Styles.totalSeparator)} />
            <StatelessSelect
              options={debtorOptions}
              selectedOption={debtorOptions.find((debtorOption) => debtorOption.value === selectedDebtorId)}
              onChange={(newDebtor) => onDebtorChange(newDebtor.value)}
              className={Styles.debtorSelect}
            />
          </>
        )}
        {supportsAutoAllocatePayments && formData.saveType === 'FINAL' && (
          <div className={Styles.autoAllocate}>
            <SlidingToggle
              disabled={!isBalanceAvailable || isSplitBillingEnabled}
              id="autoAllocateSlider"
              onChange={onAutoAllocate}
              scope="create-invoice-email"
              selected={formData.autoAllocate}
            />
            <span
              className={classnames(
                Styles.paddingLeft,
                isBalanceAvailable && !isSplitBillingEnabled ? Styles.cursorPointer : Styles.disableText,
              )}
              onClick={isBalanceAvailable && !isSplitBillingEnabled ? onAutoAllocate : () => {}}
              title={
                isSplitBillingEnabled ? 'Auto-allocate can not be used for matters with split billing enabled' : ''
              }
            >
              Auto-allocate payments
            </span>
            {!formData.isMatterBalanceType && (
              <span
                // Any changes in the review modal will not be able to be applied if auto allocate is turned on
                // If balance is not available there is nothing to do in this modal
                // in both the above cases, we disable the button
                className={classnames(
                  'link-button',
                  Styles.linkButton,
                  (!isBalanceAvailable || formData.autoAllocate) && Styles.disableText,
                )}
                onClick={() => isBalanceAvailable && !formData.autoAllocate && setShowAllocationsModal(true)}
              >
                <Icon className={Styles.icon} type="circle-person" /> Review
              </span>
            )}
          </div>
        )}
      </div>

      {formData.saveType === 'FINAL' && (
        <>
          {/* Trust Account */}
          {!trustAccountsDisabled && (
            <>
              <div className={Styles.separator} />
              {!hasDefaultTrustAccount ? (
                // do not have default trust account
                <>
                  <div
                    onClick={() =>
                      onSetShowPayFromAccordian((currValue) => ({ ...currValue, trust: !currValue.trust }))
                    }
                    className={Styles.payFrom}
                  >
                    <Icon className={Styles.currencyIcon} type="currency-round" />
                    <span className={classnames(Styles.paddingLeft, Styles.balanceText)}>
                      Pay From {t('trust')} (n/a)
                    </span>
                    <Icon
                      className={Styles.accordianArrowIcon}
                      type={showPayFromAccordian.trust ? 'arrow-37' : 'arrow-40'}
                    />
                  </div>
                  {showPayFromAccordian.trust && (
                    <>
                      <div className={Styles.payFromAccordian}>
                        <div>
                          <strong>No default {t('trust').toLowerCase()} payment account selected</strong>

                          <CurrencyInput2 disabled name="pay-from-trust" value={0} />
                        </div>
                        <div className={Styles.errorMessage} />
                        {showTrustCheque && (
                          <div>
                            {`${chequeLabel} Printing Method:`}
                            <select className="form-control" disabled value={formData.chequePrintingMethod}>
                              <option value={notApplicablePrintMethod.value}>{notApplicablePrintMethod.display}</option>
                            </select>
                          </div>
                        )}
                      </div>
                      {featureActive('BB-8671') && (
                        <div className={Styles.protectedFundsContainer} title={noDefaultTrustAccountTooltip}>
                          <div className={Styles.labelDisabled}>
                            <ProtectedFundsLineItem disabled tooltip={noDefaultTrustAccountTooltip} />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                // has default trust account
                // can be default for multitrust or legacy when multitrust is off
                // if multitrust off and no active trust accounts, don't show this section
                <>
                  <div
                    onClick={() =>
                      onSetShowPayFromAccordian((currValue) => ({ ...currValue, trust: !currValue.trust }))
                    }
                    className={Styles.payFrom}
                  >
                    <Icon className={Styles.currencyIcon} type="currency-round" />
                    <span className={classnames(Styles.paddingLeft, Styles.balanceText)}>
                      {`Pay From ${t('trust')} (${t('cents', { val: balances.TRUST.availableBalanceAsOfDate })} available${
                        isSplitBillingEnabled
                          ? `, ${t('cents', { val: formData.quickPayments.trust.amount })} allocated)`
                          : ')'
                      }`}
                    </span>
                    <Icon
                      className={Styles.accordianArrowIcon}
                      type={showPayFromAccordian.trust ? 'arrow-37' : 'arrow-40'}
                    />
                  </div>
                  {(showPayFromAccordian.trust || formErrors.quickPayments?.trust?.amount?.isInvalid) && (
                    <>
                      <div className={Styles.payFromAccordian}>
                        <div>
                          <strong>{balances.TRUST.name}</strong>
                          <CurrencyInput2
                            disabled={!formData.isMatterBalanceType || balances.TRUST.availableBalanceAsOfDate <= 0}
                            name="pay-from-trust"
                            value={
                              isSplitBillingEnabled
                                ? formData.quickPayments.trust.debtorAllocations[selectedDebtorId]
                                : formData.quickPayments.trust.amount
                            }
                            onChange={(e) => {
                              if (isSplitBillingEnabled) {
                                onUpdateDebtorSpecificQuickPayments({
                                  type: 'trust',
                                  debtorId: selectedDebtorId,
                                  value: e.target.value,
                                });
                              } else {
                                onUpdateField('quickPayments.trust.amount', e.target.value || 0);
                                if (e.target.value) {
                                  onUpdateField('showLessFundsInTrust', false);
                                }
                              }
                            }}
                            min={0}
                            max={Math.max(
                              Math.min(
                                balances.TRUST.availableBalanceAsOfDate,
                                isSplitBillingEnabled
                                  ? invoiceDebtorTotalsLookup[selectedDebtorId]?.totalExcInterest // cannot pay more than the debtor owes
                                  : totals.total,
                              ),
                              0,
                            )}
                          />
                        </div>
                        <div className={Styles.errorMessage}>
                          {formErrors.quickPayments?.trust?.amount?.invalidReason}
                        </div>
                        {showTrustCheque && (
                          <div>
                            {`${chequeLabel} Printing Method:`}
                            <select
                              className="form-control"
                              disabled={!formData.quickPayments.trust.amount}
                              value={formData.chequePrintingMethod}
                              onChange={(e) => onUpdateField('chequePrintingMethod', e.target.value)}
                            >
                              {[
                                notApplicablePrintMethod,
                                // TODO: Confirm this is needed. Print now and print manual is hidden for bulk bill in US but maybe should be hidden here?
                                // In AU we show all print methods when a non-zero amount is being paid from trust
                                ...printMethods.filter(
                                  (option) =>
                                    (!showOnlyPrintLaterCheque && formData.quickPayments.trust.amount) ||
                                    option.value === PrintLater,
                                ),
                              ].map((method) => (
                                <option key={method.code} value={method.value}>
                                  {method.display}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        {formData.chequePrintingMethod === PrintManually && !!formData.quickPayments.trust.amount && (
                          <div>
                            <div>Reference</div>
                            <InputWithLoadingState
                              hasError={formErrors.trustChequeReference.isInvalid}
                              value={formData.trustChequeReference}
                              isLoading={trustChequeNumberLoading}
                              onChange={(e) => {
                                onUpdateTrustChequeReference(e.target.value);
                              }}
                            />
                            <div className={Styles.errorMessage}>{formErrors.trustChequeReference.invalidReason}</div>
                          </div>
                        )}
                      </div>
                      {featureActive('BB-8671') && !!protectedTrustFundsAmount && (
                        <div className={Styles.protectedFundsContainer}>
                          <ProtectedFundsLineItem
                            tooltip={`${t('capitalize', { val: 'trustAccount' })}: ${balances.TRUST.name}`}
                            amount={protectedTrustFundsAmount}
                            onClickLink={onClickLink}
                            matterId={matterId}
                            bankAccountId={balances?.TRUST?.bankAccountId}
                            overrideRedirect={overrideRedirect}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}

          {/* Operating Account */}
          {supportsOperatingAccount && (
            <>
              <div className={Styles.separator} />
              <div
                onClick={() =>
                  onSetShowPayFromAccordian((currValue) => ({ ...currValue, operating: !currValue.operating }))
                }
                className={Styles.payFrom}
              >
                <Icon className={Styles.currencyIcon} type="currency-round" />
                <span className={classnames(Styles.paddingLeft, Styles.balanceText)}>
                  {`Pay From ${t('operatingRetainer')} (${t('cents', { val: balances.OPERATING.balance })} available${
                    isSplitBillingEnabled
                      ? `, ${t('cents', { val: formData.quickPayments.operating.amount })} allocated)`
                      : ')'
                  }`}
                </span>
                {balances.OPERATING.balance > 0 && (
                  <Icon
                    className={Styles.accordianArrowIcon}
                    type={showPayFromAccordian.operating ? 'arrow-37' : 'arrow-40'}
                  />
                )}
              </div>
              {(showPayFromAccordian.operating || formErrors.quickPayments?.operating?.amount?.isInvalid) &&
                balances.OPERATING.balance > 0 && (
                  <div className={Styles.payFromAccordian}>
                    <CurrencyInput2
                      disabled={!formData.isMatterBalanceType}
                      name="pay-from-operating"
                      value={
                        isSplitBillingEnabled
                          ? formData.quickPayments.operating.debtorAllocations[selectedDebtorId]
                          : formData.quickPayments.operating.amount
                      }
                      onChange={(e) => {
                        if (isSplitBillingEnabled) {
                          onUpdateDebtorSpecificQuickPayments({
                            type: 'operating',
                            debtorId: selectedDebtorId,
                            value: e.target.value,
                          });
                        } else {
                          onUpdateField('quickPayments.operating.amount', e.target.value || 0);
                        }
                      }}
                      min={0}
                      max={Math.max(
                        Math.min(
                          balances.OPERATING.balance,
                          isSplitBillingEnabled
                            ? invoiceDebtorTotalsLookup[selectedDebtorId]?.totalExcInterest // cannot pay more than the debtor owes
                            : totals.total,
                        ),
                        0,
                      )}
                    />
                    <div className={Styles.errorMessage}>
                      {formErrors.quickPayments?.operating?.amount?.invalidReason}
                    </div>
                  </div>
                )}
            </>
          )}

          {/* Credit Account */}
          <>
            <div className={Styles.separator} />
            <div
              onClick={() => onSetShowPayFromAccordian((currValue) => ({ ...currValue, credit: !currValue.credit }))}
              className={Styles.payFrom}
            >
              <Icon className={Styles.currencyIcon} type="currency-round" />
              <span className={classnames(Styles.paddingLeft, Styles.balanceText)}>
                {`Pay From Credit (${t('cents', { val: balances.CREDIT.balance })} available${
                  isSplitBillingEnabled
                    ? `, ${t('cents', { val: formData.quickPayments.credit.amount })} allocated)`
                    : ')'
                }`}
              </span>
              {balances.CREDIT.balance > 0 && (
                <Icon
                  className={Styles.accordianArrowIcon}
                  type={showPayFromAccordian.credit ? 'arrow-37' : 'arrow-40'}
                />
              )}
            </div>
            {(showPayFromAccordian.credit || formErrors.quickPayments?.credit?.amount?.isInvalid) &&
              balances.CREDIT.balance > 0 && (
                <div className={Styles.payFromAccordian}>
                  <CurrencyInput2
                    disabled={!formData.isMatterBalanceType}
                    name="pay-from-credit"
                    value={
                      isSplitBillingEnabled
                        ? formData.quickPayments.credit.debtorAllocations[selectedDebtorId]
                        : formData.quickPayments.credit.amount
                    }
                    onChange={(e) => {
                      if (isSplitBillingEnabled) {
                        onUpdateDebtorSpecificQuickPayments({
                          type: 'credit',
                          debtorId: selectedDebtorId,
                          value: e.target.value,
                        });
                      } else {
                        onUpdateField('quickPayments.credit.amount', e.target.value || 0);
                      }
                    }}
                    min={0}
                    max={Math.max(
                      Math.min(
                        balances.CREDIT.balance,
                        isSplitBillingEnabled
                          ? invoiceDebtorTotalsLookup[selectedDebtorId]?.totalExcInterest // cannot pay more than the debtor owes
                          : totals.total,
                      ),
                      0,
                    )}
                  />
                  <div className={Styles.errorMessage}>{formErrors.quickPayments?.credit?.amount?.invalidReason}</div>
                </div>
              )}
            <div className={Styles.separator} />
          </>
        </>
      )}

      {showInvoiceTutorial ? (
        <>
          <div className={Styles.saveSeparator} />
          <div className={Styles.invoiceTutorialLink}>
            <Info className={Styles.infoIcon} />
            <p>
              Did you know you can {t('customise')} your invoice templates?{' '}
              <a href={invoiceTutorialLink} rel="noopener noreferrer" target="_blank">
                Find out more{' '}
              </a>
              or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => onClickLink({ type: 'invoiceSettings' })}>click here to start</a>!
            </p>
            <span className={Styles.closeButton} onClick={() => onCloseInvoiceTutorialBanner()}>
              &#10006;
            </span>
          </div>
          <div className={Styles.separator} />
        </>
      ) : (
        <div className={Styles.saveSeparator} />
      )}

      <div className={Styles.preview}>
        <Button
          type="tertiary"
          title="See a preview of the Invoice"
          locked={previewIsLocked}
          disabled={saveIsLocked || pdfPreviewIsDisabled || previewIsLocked}
          onClick={() => {
            if (pdfPreviewIsDisabled) {
              return;
            }
            handleViewPreview();
          }}
          size="full-width"
        >
          View Preview
        </Button>
      </div>
      <div className={Styles.separator} />

      {formData.saveType === 'FINAL' && (
        <>
          <div className={Styles.toggleRow}>
            <label className={Styles.sendBill} htmlFor="sendNowSlider">
              <SlidingToggle
                id="sendNowSlider"
                onChange={(name, val) => {
                  onUpdateField('sendNowViaEmail', val);
                  // Only one of Email and Communicate can be switched on - not both
                  onUpdateField('sendNowViaCommunicate', false);
                }}
                scope="create-invoice-email"
                selected={formData.sendNowViaEmail}
              />
              <span className={Styles.paddingLeft}>
                {featureActive('BB-9097') ? 'Send invoice via Email' : 'Email this invoice now'}
              </span>
            </label>
          </div>

          {featureActive('BB-9097') && (
            <>
              <div className={Styles.separator} />
              <div className={Styles.toggleRow}>
                <label className={Styles.sendBill}>
                  <SlidingToggle
                    id="sendViaCommunicateSlider"
                    onChange={(name, val) => {
                      onUpdateField('sendNowViaCommunicate', val);
                      // Only one of Email and Communicate can be switched on - not both
                      onUpdateField('sendNowViaEmail', false);
                    }}
                    scope="create-invoice-email"
                    selected={formData.sendNowViaCommunicate}
                  />
                  <span className={Styles.paddingLeft}>Send invoice via Client Portal&nbsp;</span>
                </label>
                <HtmlWithTooltip
                  tooltip={
                    <div>
                      {`Select this option to send your invoice via Client Portal, ${brandName}’s secure messaging system.`}{' '}
                      <a href={sendViaCommunicateHelpLink} className="link-button" target="_blank" rel="noreferrer">
                        More info
                      </a>
                    </div>
                  }
                >
                  <Icon className={Styles.icon} type="question-mark" />
                </HtmlWithTooltip>
              </div>
            </>
          )}
          <div className={Styles.separator} />
        </>
      )}
      <div className={Styles.save}>
        {formData.originalInvoice?.status && formData.originalInvoice.status !== 'DRAFT' ? (
          <div>
            {`Invoice has already been ${
              formData.originalInvoice.status === 'DELETED' ? 'deleted' : `${t('finalised')}`
            }`}
          </div>
        ) : (
          <>
            <Button
              locked={saveIsLocked}
              title={
                formValid
                  ? ''
                  : `Please ensure there are no errors and at least one fee or ${t(
                      'expense',
                    )} is selected before saving`
              }
              disabled={saveIsLocked}
              onClick={async () => {
                if (
                  hasUnpaidAD ||
                  hasZeroBalance ||
                  // finalising split billing invoice with quick payment allocated to some but not all debtors
                  (formData.saveType === 'FINAL' &&
                    isSplitBillingEnabled &&
                    debtorQuickPaymentStatus.debtorsWithQuickPayment.count > 0 &&
                    debtorQuickPaymentStatus.debtorsWithQuickPayment.count < debtorOptions.length)
                ) {
                  setShowInvoiceConfirmFinaliseModal(true);
                } else {
                  await handleSaveTypeFinal();
                }
              }}
              className={Styles.saveButton}
              size="full-width"
            >
              {(() => {
                if (formData.saveType === 'FINAL') {
                  if (formData.sendNowViaEmail || formData.sendNowViaCommunicate) {
                    return `${t('finalise')} & Send`;
                  }
                  return `${t('finalise')} Draft`;
                }
                return 'Save Draft';
              })()}
            </Button>
            <ContextMenu
              position="top-right"
              arrow={false}
              distance={5}
              // eslint-disable-next-line react/no-unstable-nested-components
              body={({ close }) => (
                <div className={classnames('list-group', StylesRoute.contextMenuBody)}>
                  {!draftInvoice.isNewInvoice && (
                    <div
                      type="button"
                      className={classnames('list-group-item', StylesRoute.listGroupItem)}
                      onClick={() => {
                        close();
                        setModalDialogVisible({
                          modalId: DRAFT_INVOICE_DELETE_PROCEED_MODAL_ID,
                          props: {
                            invoiceIds: [formData.invoiceId],
                            onProceedClick: () => {
                              closeCurrentTab();
                            },
                          },
                        });
                      }}
                    >
                      Delete Invoice
                    </div>
                  )}
                  <div
                    type="button"
                    className={classnames('list-group-item', StylesRoute.listGroupItem)}
                    onClick={() => {
                      closeCurrentTab();
                    }}
                  >
                    Close Invoice
                  </div>
                  {showLedes && formData.saveType === 'DRAFT' && (
                    <div
                      type="button"
                      className={classnames('list-group-item', StylesRoute.listGroupItem)}
                      onClick={onSaveAndGenerateLedes}
                    >
                      Save Draft and Generate LEDES1998B
                    </div>
                  )}
                </div>
              )}
            >
              <Icon className={classnames(Styles.iconKebabFill)} type="kebab-fill" />
            </ContextMenu>
          </>
        )}
      </div>
      {/* Modals */}
      {showAllocationsModal && (
        <ContactBalanceAllocationModal
          preferredBankAccountTypes={preferredBankAccountTypes}
          onModalClose={() => {
            setShowAllocationsModal(false);
            onSetShowPayFromAccordian({ trust: true, operating: true, credit: true });
          }}
          onContactLinkClick={onClickLink}
          invoiceTotals={totals}
          onChangeAllocations={onChangeContactBalanceAllocations}
          onChangeSplitBillingAllocations={onChangeContactBalanceAllocationsForSplitBilling}
          balances={balances}
          multiPayments={formData.multiPayments}
          isSplitBillingEnabled={isSplitBillingEnabled}
          selectedDebtorId={selectedDebtorId}
          debtorOptions={debtorOptions}
          invoiceDebtorTotalsLookup={invoiceDebtorTotalsLookup}
          onDebtorChange={onDebtorChange}
        />
      )}

      {featureActive('BB-9097') && (
        <SendViaCommunicateConfirmModal
          doNotShowSendConfirmModalAgain={doNotShowSendConfirmModalAgain}
          showSendViaCommunicateConfirmModal={showSendViaCommunicateConfirmModal}
          setShowSendViaCommunicateConfirmModal={setShowSendViaCommunicateConfirmModal}
          onDoNotShowSendViaCommunicateConfirmModalAgain={onDoNotShowSendViaCommunicateConfirmModalAgain}
          setShowCommunicateModal={onOpenInvoiceCommunicateModal}
        />
      )}

      <InvoiceConfirmFinaliseModal
        isVisible={showInvoiceConfirmFinaliseModal}
        hasZeroBalance={hasZeroBalance}
        hasUnpaidAD={hasUnpaidAD}
        invoiceCount={1}
        onConfirm={onInvoiceConfirmFinaliseModalConfirm}
        onCancel={() => setShowInvoiceConfirmFinaliseModal(false)}
        // split billing
        debtorQuickPaymentStatus={debtorQuickPaymentStatus}
      />
    </div>
  );
};

FinalisePanel.displayName = 'FinalisePanel';

FinalisePanel.propTypes = {
  // User billing attributtes messages
  invoiceTutorialLink: PropTypes.string,
  showInvoiceTutorial: PropTypes.bool,
  onCloseInvoiceTutorialBanner: PropTypes.func.isRequired,
  sendViaCommunicateHelpLink: PropTypes.string,
  doNotShowSendConfirmModalAgain: PropTypes.bool,
  onDoNotShowSendViaCommunicateConfirmModalAgain: PropTypes.func.isRequired,
  // Form
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  formReady: PropTypes.bool.isRequired,
  formValid: PropTypes.bool.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  // Discount props
  canApplyDiscount: PropTypes.bool.isRequired,
  expandedDiscount: PropTypes.bool.isRequired,
  setExpandedDiscount: PropTypes.func.isRequired,
  onConfirmDiscount: PropTypes.func.isRequired,
  onDiscardDiscount: PropTypes.func.isRequired,
  onApplyDiscountChecked: PropTypes.func.isRequired,
  // Surcharge props
  canApplySurcharge: PropTypes.bool.isRequired,
  expandedSurcharge: PropTypes.bool.isRequired,
  setExpandedSurcharge: PropTypes.func.isRequired,
  onConfirmSurcharge: PropTypes.func.isRequired,
  onDiscardSurcharge: PropTypes.func.isRequired,
  onApplySurchargeChecked: PropTypes.func.isRequired,
  // Allocation
  onAutoAllocate: PropTypes.func.isRequired,
  preferredBankAccountTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeContactBalanceAllocations: PropTypes.func.isRequired,
  showAllocationsModal: PropTypes.bool.isRequired,
  setShowAllocationsModal: PropTypes.func.isRequired,
  showPayFromAccordian: PropTypes.shape({
    trust: PropTypes.bool.isRequired,
    operating: PropTypes.bool.isRequired,
    credit: PropTypes.bool.isRequired,
  }).isRequired,
  onSetShowPayFromAccordian: PropTypes.func.isRequired,
  // Config
  supportsAutoAllocatePayments: PropTypes.bool.isRequired,
  supportsOperatingAccount: PropTypes.bool.isRequired,
  supportsTax: PropTypes.bool.isRequired,
  showOnlyPrintLaterCheque: PropTypes.bool.isRequired,
  // Email and communicate
  showInvoiceConfirmFinaliseModal: PropTypes.bool.isRequired,
  onInvoiceConfirmFinaliseModalConfirm: PropTypes.func.isRequired,
  setShowInvoiceConfirmFinaliseModal: PropTypes.func.isRequired,
  showSendViaCommunicateConfirmModal: PropTypes.bool.isRequired,
  setShowSendViaCommunicateConfirmModal: PropTypes.func.isRequired,
  onOpenInvoiceCommunicateModal: PropTypes.func.isRequired,
  // Save and preview
  saveIsLocked: PropTypes.bool.isRequired,
  previewIsLocked: PropTypes.bool.isRequired,
  pdfPreviewIsDisabled: PropTypes.bool.isRequired,
  handleSaveTypeFinal: PropTypes.func.isRequired,
  onSaveAndGenerateLedes: PropTypes.func.isRequired,
  handleViewPreview: PropTypes.func.isRequired,
  // Rest
  totals: PropTypes.object.isRequired,
  balances: PropTypes.object,
  hasDefaultTrustAccount: PropTypes.bool.isRequired,
  hasUnpaidAD: PropTypes.bool.isRequired,
  hasZeroBalance: PropTypes.bool.isRequired,
  matterId: PropTypes.string.isRequired,
  trustAccountsDisabled: PropTypes.bool.isRequired,
  isBalanceAvailable: PropTypes.bool.isRequired,
  showRetainer: PropTypes.bool.isRequired,
  retainerRequestAmount: PropTypes.number.isRequired,
  protectedTrustFundsAmount: PropTypes.number,
  lessFundsInTrustAmount: PropTypes.number.isRequired,
  draftInvoice: PropTypes.object.isRequired,
  showLedes: PropTypes.bool.isRequired,
  showTrustCheque: PropTypes.bool.isRequired,
  trustChequeNumberLoading: PropTypes.bool.isRequired,
  showLessFundsInTrustSection: PropTypes.bool.isRequired,
  showClientCoversFeeWarning: PropTypes.bool.isRequired,
  onUpdateTrustChequeReference: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  overrideRedirect: PropTypes.func.isRequired,
  closeCurrentTab: PropTypes.func.isRequired,
  // Split billing
  isSplitBillingEnabled: PropTypes.bool,
  selectedDebtorId: PropTypes.string,
  debtorOptions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  invoiceDebtorTotalsLookup: PropTypes.object,
  debtorQuickPaymentStatus: PropTypes.shape({
    debtorsWithQuickPayment: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      count: PropTypes.number,
    }),
    debtorWithoutQuickPayment: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      count: PropTypes.number,
    }),
  }),
  onUpdateDebtorSpecificQuickPayments: PropTypes.func,
  onDebtorChange: PropTypes.func,
  onChangeContactBalanceAllocationsForSplitBilling: PropTypes.func.isRequired,
};

FinalisePanel.defaultProps = {
  invoiceTutorialLink: undefined,
  showInvoiceTutorial: false,
  sendViaCommunicateHelpLink: undefined,
  doNotShowSendConfirmModalAgain: false,
  balances: {},
  protectedTrustFundsAmount: 0,
  // Split billing
  isSplitBillingEnabled: false,
  selectedDebtorId: undefined,
  debtorOptions: [],
  invoiceDebtorTotalsLookup: {},
  debtorQuickPaymentStatus: {
    debtorsWithQuickPayment: { count: 0 },
    debtorWithoutQuickPayment: { count: 0 },
  },
  onUpdateDebtorSpecificQuickPayments: () => {},
  onDebtorChange: () => {},
};
