import React from 'react';
import PropTypes from 'prop-types';
import { ActivitiesTab } from 'web/components';
import Styles from './ProductivityActivitiesRoute.module.scss';

export const ProductivityActivitiesRoute = ({ onClickLink }) => (
  <div className={Styles.productivityActivitiesRoute}>
    <div className="page-top-bar">
      <div className="page-name">
        <h1 className="page-name-heading">Activities</h1>
      </div>
      <div className="sb-tab-header">
        <ul className="nav nav-pills">
          <li className="active">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>Activities</a>
          </li>
        </ul>
      </div>
    </div>
    <ActivitiesTab onClickLink={onClickLink} />
  </div>
);

ProductivityActivitiesRoute.displayName = 'ProductivityActivitiesRoute';

ProductivityActivitiesRoute.propTypes = {
  onClickLink: PropTypes.func.isRequired,
};

ProductivityActivitiesRoute.defaultProps = {};
