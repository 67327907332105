import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { INVOICE_EMAIL_MODAL_ID } from 'web/components';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useCacheQuery, useSubscribedQuery } from 'web/hooks';
import {
  DraftInvoiceEmailModalData,
  InitFirmInvoiceEmailSettings,
  InitOperatingBankAccount,
  InitPaymentProviderSettings,
  InitStaffSettings,
  InvoiceEmailModalData,
} from 'web/graphql/queries';
import { accumulateInvoiceEntriesTotalDuration } from '@sb-billing/business-logic/invoice/services';
import { isPayButtonEnabledForMatter } from '@sb-billing/business-logic/matters/invoice-settings';
import { invoiceEmailTemplateDefaults } from '@sb-billing/business-logic/invoice-emailing/entities';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import {
  convertSettingsFromGQL,
  getFirmPaymentProviderPaymentSettings,
} from '@sb-billing/business-logic/payment-provider/services';

import { getRegion } from '@sb-itops/region';

import { MultiTabInvoiceEmailModalFormsContainer } from './MultiTabInvoiceEmailModal.forms.container';
import { InvoiceEmailModalFormsContainer } from './InvoiceEmailModal.forms.container';

const region = getRegion();

/**
 * Builds an object that the invoice email modal forms container can use
 *
 * This is needed for the Draft Invoices Page because the user can create and dynamically update the invoice email data
 *
 * --- Additional info ---
 * Creating and sending an invoice from the draft invoice page will:
 *  1. Be a single invoice
 *  2. Have either a single or multiple debtors
 *  3. Be linked to a single matter
 *
 * @param {object} params
 * @param {object[]} params.contacts
 * @param {object[]} params.fees
 * @param {string} params.invoiceId
 * @param {object} params.matter
 * @returns {object} Matches the data structure returned by the InvoiceEmailModalData query, which is expected by the forms container
 */
function buildInvoiceEmailModalData({ contacts, fees, invoiceId, matter, draftInvoice }) {
  // Only fee entries are relevant for the [HOURS] value displayed in invoice emails
  // Map fees as invoice entries
  const entries = fees.map((fee) => ({
    id: fee.id,
    feeEntity: fee,
  }));

  // Map contacts as invoice debtors
  const debtors = contacts.map((contact) => ({
    id: contact.id,
    contact,
  }));

  return {
    invoices: [
      {
        id: invoiceId,
        debtors,
        entries,
        matter,
        splitBillingSettings: draftInvoice?.splitBillingSettings,
      },
    ],
  };
}

/**
 * This function will extract and process relevant data from a list of invoices in preparation for the forms container and invoice email requests
 *
 * @param {object} params
 * @param {boolean} params.firmCanIncludePaymentLinks
 * @param {object[]} params.invoices
 * @returns {{
 *  debtorIds: string[],
 *  matterIds: string[],
 *  ccAddresses: string[],
 *  bccAddresses: string[],
 * invoiceTotalDurationsByIdMap: object,
 * }}
 */
function extractAndProcessDataFromInvoices({ firmCanIncludePaymentLinks, invoices = [] }) {
  const {
    anyMatterHasPayButtonDisabled,
    anyMatterHasPayButtonEnabled,
    uniqueProperties,
    invoiceTotalDurationsByIdMap,
  } = invoices.reduce(
    (acc, invoice) => {
      const { debtors, matter } = invoice;

      // Debtor ids
      debtors.forEach((debtor) => acc.uniqueProperties.debtorIds.add(debtor.id));

      if (matter) {
        // Matter ids
        acc.uniqueProperties.matterIds.add(matter.id);
        // ccAddresses
        matter.matterEmailSettings?.ccAddresses.forEach((ccAddress) => acc.uniqueProperties.ccAddresses.add(ccAddress));
        // bccAddress
        matter.matterEmailSettings?.bccAddresses.forEach((bccAddress) =>
          acc.uniqueProperties.bccAddresses.add(bccAddress),
        );

        // Can skip if any matters have been found
        if (!acc.anyMatterHasPayButtonEnabled || !acc.anyMatterHasPayButtonDisabled) {
          // If matterInvoiceSettings is null, default to firm settings
          const isPayButtonEnabled = matter.matterInvoiceSettings
            ? isPayButtonEnabledForMatter({ matterInvoiceSettings: matter.matterInvoiceSettings })
            : firmCanIncludePaymentLinks;

          if (isPayButtonEnabled) {
            acc.anyMatterHasPayButtonEnabled = true;
          } else {
            acc.anyMatterHasPayButtonDisabled = true;
          }
        }
      }

      acc.invoiceTotalDurationsByIdMap[invoice.id] = accumulateInvoiceEntriesTotalDuration({
        entries: invoice.entries,
      });

      return acc;
    },
    {
      uniqueProperties: {
        debtorIds: new Set(),
        matterIds: new Set(),
        ccAddresses: new Set(),
        bccAddresses: new Set(),
      },
      invoiceTotalDurationsByIdMap: {},
      anyMatterHasPayButtonEnabled: false,
      anyMatterHasPayButtonDisabled: false,
    },
  );

  return {
    anyMatterHasPayButtonDisabled,
    anyMatterHasPayButtonEnabled,
    debtorIds: Array.from(uniqueProperties.debtorIds),
    matterIds: Array.from(uniqueProperties.matterIds),
    ccAddresses: Array.from(uniqueProperties.ccAddresses),
    bccAddresses: Array.from(uniqueProperties.bccAddresses),
    invoiceTotalDurationsByIdMap,
  };
}

const hooks = () => ({
  useInvoiceEmailModalProps: () => {
    const onModalClose = () => setModalDialogHidden({ modalId: INVOICE_EMAIL_MODAL_ID });

    return {
      onModalClose,
    };
  },
  usePaymentProviderSettingsQuery: () => {
    const { data: operatingBankAccountData } = useCacheQuery(InitOperatingBankAccount.query);
    const { data: paymentProviderSettingsData } = useCacheQuery(InitPaymentProviderSettings.query);

    const activeProviderType = paymentProviderSettingsData.paymentProviderSettings.activeProvider;

    const { showInvoiceLink: firmCanIncludePaymentLinks } = getFirmPaymentProviderPaymentSettings({
      activeProviderFormattedSettings:
        activeProviderType &&
        convertSettingsFromGQL({
          providerType: activeProviderType,
          formattedSettingsFromGQL: paymentProviderSettingsData.paymentProviderSettings.providers[activeProviderType],
        }),
      activeProviderType,
      operatingAccountId: operatingBankAccountData?.bankAccounts[0]?.id,
    });

    return {
      firmCanIncludePaymentLinks,
    };
  },
  useCacheQueryData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const { data: firmInvoiceEmailSettingsData } = useCacheQuery(InitFirmInvoiceEmailSettings.query);

    const getDefaultFirmInvoiceEmailSettings = () => {
      const dodFeatureEnabled = featureActive('BB-5725') && featureActive('BB-6865');
      const defaultFirmInvoiceEmailSettings = invoiceEmailTemplateDefaults({
        showSummaryBox: dodFeatureEnabled,
        allowEmailAttachment: hasFacet(facets.allowEmailAttachment),
      });

      return {
        emailBody: defaultFirmInvoiceEmailSettings.emailBody,
        emailSubject: defaultFirmInvoiceEmailSettings.emailSubject,
        sendCopyToUser: defaultFirmInvoiceEmailSettings.sendCopyToUser,
      };
    };

    return {
      loggedInStaffMember: staffData?.loggedInStaff,
      firmInvoiceEmailSettings:
        firmInvoiceEmailSettingsData.firmInvoiceEmailSettings || getDefaultFirmInvoiceEmailSettings(),
    };
  },
});

const dependentHooks = () => ({
  // This hook is specifically for the draft invoice page
  useDraftInvoiceEmailModalDataQuery: ({ invoiceIds, draftInvoice, firmCanIncludePaymentLinks }) => {
    const { data, loading, error } = useSubscribedQuery(DraftInvoiceEmailModalData, {
      variables: {
        debtorIds: draftInvoice?.debtorIds,
        matterId: draftInvoice?.matterId,
        feeIds: draftInvoice?.feeIds,
      },
      skip: !draftInvoice,
    });

    // Exit early if not draft invoice (useInvoiceEmailModalDataQuery hook runs instead)
    if (!draftInvoice) {
      return {};
    }

    if (error) {
      throw new Error(error);
    }

    const matter = data?.matter ?? {};

    const invoiceEmailModalData = buildInvoiceEmailModalData({
      contacts: data?.contacts ?? [],
      fees: data?.fees ?? [],
      invoiceId: invoiceIds[0],
      matter,
      draftInvoice,
    });
    const invoiceTotalDurationsByIdMap = {
      [invoiceIds[0]]: accumulateInvoiceEntriesTotalDuration({
        entries: invoiceEmailModalData.invoices[0]?.entries,
      }),
    };

    const isConsolidatedMode = false;
    const isMultiInvoice = false;
    const isMultiDebtor = draftInvoice.debtorIds.length > 1;
    const isMultiTabForm = isMultiDebtor && !isMultiInvoice;

    const showPreviewButton = isMultiTabForm ? true : !isMultiInvoice || isConsolidatedMode;

    const matterHasPayButtonEnabled = matter.matterInvoiceSettings
      ? isPayButtonEnabledForMatter({ matterInvoiceSettings: matter.matterInvoiceSettings })
      : firmCanIncludePaymentLinks;

    return {
      anyMatterHasPayButtonEnabled: matterHasPayButtonEnabled, // Only anyMatterHasPayButtonEnabled is required to be passed down (anyMatterHasPayButtonDisabled is not) because to we always deal with a single matter for draft invoices (both are required when multiple matters are involved)
      bccAddresses: matter?.matterEmailSettings?.bccAddresses ?? [],
      ccAddresses: matter?.matterEmailSettings?.ccAddresses ?? [],
      debtorIds: draftInvoice.debtorIds,
      invoiceEmailModalData,
      invoiceIds,
      invoiceTotalDurationsByIdMap,
      isConsolidatedMode,
      isLoading: loading,
      isMultiDebtor,
      isMultiInvoice,
      isReadOnlyToAddress: false,
      matterIds: [draftInvoice.matterId],
      region,
      showIncludeDebtor: isMultiTabForm,
      showPreviewButton,
      isMultiTabForm,
    };
  },
  useInvoiceEmailModalDataQuery: ({
    consolidateEmails,
    debtorId,
    draftInvoice,
    firmCanIncludePaymentLinks,
    invoiceIds,
  }) => {
    const { data, loading, error } = useSubscribedQuery(InvoiceEmailModalData, {
      variables: {
        ids: invoiceIds,
      },
      skip: draftInvoice,
    });

    // Exit early if draft invoice (useDraftInvoiceDataQuery hook runs instead)
    if (draftInvoice) {
      return {};
    }

    // Exit early if invoice data is empty or null, this can happen when a draft
    // invoice is finalised, and before it's written to the DB, user tries to
    // open the email modal from the invoice view.
    const nonNullInvoices = (data?.invoices || []).filter((invoice) => invoice);
    if (!loading && nonNullInvoices.length === 0) {
      return {};
    }

    if (error) {
      throw new Error(error);
    }

    const {
      anyMatterHasPayButtonDisabled,
      anyMatterHasPayButtonEnabled,
      debtorIds,
      matterIds,
      ccAddresses,
      bccAddresses,
      invoiceTotalDurationsByIdMap,
    } = extractAndProcessDataFromInvoices({
      invoices: nonNullInvoices,
      firmCanIncludePaymentLinks,
    });

    // In certain cases, a single debtor id can be passed to be explicitly used
    // This will be used over the debtor ids retrieved from extractAndProcessDataFromInvoices()
    // Such behaviour is required in cases such as consolidated emails (see below for more on this)
    const relevantDebtorIds = debtorId ? [debtorId] : debtorIds;

    const isMultiDebtor = relevantDebtorIds.length > 1;
    const isMultiInvoice = invoiceIds.length > 1;
    const isMultiTabForm = isMultiDebtor && !isMultiInvoice;

    // Consolidated mode: where multiple invoice emails are combined and sent as a single email
    //
    // Conditions:
    //  1. Multiple invoices
    //  2. Single debtor
    //    - This debtor's id must be explicitly passed as a prop
    //    - This ensures only the specified debtor (e.g. belonging to the contact page) will be emailed as there can be cases where one of the selected invoices may contain multiple debtors
    //  3. Consolidated mode must be explicitly requested
    //    - This is because there can be cases where a single debtor and multiple invoices also occur (e.g. Matter page > Invoices tab), but we do not want the consolidated mode
    const isConsolidatedMode = consolidateEmails
      ? consolidateEmails && relevantDebtorIds.length === 1 && isMultiInvoice
      : false;

    const showPreviewButton = isMultiTabForm ? true : !isMultiInvoice || isConsolidatedMode;

    return {
      anyMatterHasPayButtonDisabled,
      anyMatterHasPayButtonEnabled,
      bccAddresses,
      ccAddresses,
      debtorIds: relevantDebtorIds,
      invoiceEmailModalData: data,
      invoiceIds,
      invoiceTotalDurationsByIdMap,
      isConsolidatedMode,
      isLoading: loading,
      isMultiDebtor,
      isMultiInvoice,
      isReadOnlyToAddress: isMultiDebtor && isMultiInvoice,
      matterIds,
      region,
      showIncludeDebtor: isMultiTabForm,
      showPreviewButton,
      isMultiTabForm,
    };
  },
});

export const InvoiceEmailModalContainer = withApolloClient(
  withReduxProvider(
    composeHooks(hooks)(
      composeHooks(dependentHooks)((props) =>
        props.isMultiTabForm ? (
          // Form for a single invoice with multiple debtors
          <MultiTabInvoiceEmailModalFormsContainer {...props} />
        ) : (
          // Standard form
          <InvoiceEmailModalFormsContainer {...props} />
        ),
      ),
    ),
  ),
);

InvoiceEmailModalContainer.displayName = 'InvoiceEmailModalContainer';

InvoiceEmailModalContainer.propTypes = {
  consolidateEmails: PropTypes.bool,
  // debtorId prop
  //  * Normally, the debtorIds will be retrieved from the relevant invoices fetched
  //  * However, there are certain scenarios where we want to override and specify a debtorId instead:
  //    1. Consolidated invoice emails
  //      * This ensures that if one of the selected invoices have multiple debtors, we only send it to the specified debtor
  //    2. Contact Invoices page
  //      * When an invoice has multiple debtors, but we are on a specific contact's invoice page, we send it to the contact whose page we are on
  debtorId: PropTypes.string,
  invoiceIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  scope: PropTypes.string.isRequired,
  // split billing finalised invoice page specific props
  // when click email button, we open modal to debtor tab to match finalized child invoice being viewed
  preselectedSplitBillingDebtorId: PropTypes.string,
  // Callbacks
  onPreview: PropTypes.func.isRequired,
  onSendEmails: PropTypes.func.isRequired,
  // Draft invoice specific props
  //  * The data on the draft invoices page is dynamic as it can be changed by user input (e.g. adding/removing fees or debtors)
  //  *  Therefore, we pass the relevant entity ids, which is sourced from redux. This will ensure we query for the most up to date data
  draftInvoice: PropTypes.shape({
    debtorIds: PropTypes.arrayOf(PropTypes.string),
    feeIds: PropTypes.arrayOf(PropTypes.string),
    matterId: PropTypes.string,
  }),
};

InvoiceEmailModalContainer.defaultProps = {
  consolidateEmails: undefined,
  debtorId: undefined,
  draftInvoice: undefined,
  preselectedSplitBillingDebtorId: undefined,
};
