import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@sb-itops/react';

import { SplitBillingInvoicePreview } from './SplitBillingInvoicePreview';
import Styles from './InvoicePreviewModal.module.scss';

export const INVOICE_PREVIEW_MODAL_ID = 'invoice-preview-modal';

export const InvoicePreviewModal = ({
  isVisible,
  isLoading,
  invoiceId,
  invoiceNumber,
  onClose,
  pdfBlob,
  debtorOptions,
  selectedDebtorOption,
  isDownloadingAll,
  setSelectedDebtorOption,
  onDownloadAll,
}) => {
  if (!isVisible || !invoiceId) {
    return null;
  }

  return (
    <Modal
      isVisible={isVisible}
      isLoading={isLoading}
      title={invoiceNumber ? `Invoice #${invoiceNumber}` : 'New Invoice'}
      body={
        <div className={Styles.invoicePreviewModalBody}>
          <SplitBillingInvoicePreview
            usePdfBlob
            pdfBlob={pdfBlob}
            debtorOptions={debtorOptions}
            selectedDebtorOption={selectedDebtorOption}
            isDownloadingAll={isDownloadingAll}
            setSelectedDebtorOption={setSelectedDebtorOption}
            onDownloadAll={onDownloadAll}
          />
        </div>
      }
      onModalClose={onClose}
      showFooter={false}
      showModalBodyPadding={false}
      isModalBodyScrollable={false}
    />
  );
};

InvoicePreviewModal.displayName = 'InvoicePreviewModal';

InvoicePreviewModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  invoiceId: PropTypes.string,
  invoiceNumber: PropTypes.number,
  onClose: PropTypes.func,
  pdfBlob: PropTypes.object,
  debtorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selectedDebtorOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  isDownloadingAll: PropTypes.bool,
  setSelectedDebtorOption: PropTypes.func,
  onDownloadAll: PropTypes.func,
};

InvoicePreviewModal.defaultProps = {
  isLoading: false,
  invoiceId: undefined,
  invoiceNumber: undefined,
  onClose: () => {},
  pdfBlob: undefined,
  debtorOptions: [],
  selectedDebtorOption: undefined,
  isDownloadingAll: false,
  setSelectedDebtorOption: () => {},
  onDownloadAll: () => {},
};
