import { MatterTypesById } from 'web/graphql/queries';
import { getApolloClient } from 'web/services/apollo';
import { getLogger } from '@sb-itops/fe-logger';

const LOG = getLogger('SbClientSdk');

export const clientSdkGetMatterTypes = async (
  payload: { ids?: string[]; locationIds?: string[]; categories?: string[]; type?: string },
  needsReload = false,
) => {
  const apolloClient = getApolloClient();
  const matterTypeData = await apolloClient.query({
    query: MatterTypesById.query,
    variables: payload.ids ? { ids: payload.ids } : undefined,
    fetchPolicy: needsReload ? 'network-only' : 'cache-first',
  });
  const matterTypeList = (matterTypeData?.data?.matterTypesById || [])
    .filter((matterType) => {
      if (payload.ids) {
        return payload.ids.includes(matterType.matterTypeId);
      }
      if (payload.categories) {
        return payload.categories.some((category) => matterType.categories.includes(category));
      }
      // Unimplemented
      if (payload.locationIds) {
        LOG.error('unimplemented sdk call: clientSdkGetMatterTypes, cannot sort by locationIds', payload);
      }
      if (payload.type) {
        LOG.error('unimplemented sdk call: clientSdkGetMatterTypes, cannot sort by type', payload);
      }
      return true;
    })
    .map((matterType) => ({
      id: matterType.matterTypeId,
      name: matterType.name,
      categories: matterType.categories,
      description: 'Not implemented',
      tags: ['Not implemented'],
      type: 'Not implemented',
      locationId: 'Not implemented',
      // representativeOptions: 'Not implemented',
      // items: 'Not implemented',
    }));
  return { matterTypes: matterTypeList };
};
