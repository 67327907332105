import { actionGuards } from './actions';
import { autoSelectorFactory } from '../shared/redux-helpers';
import { EmbeddedIframePosition } from '../shared/types';

type TState = {
  initialised: boolean; // true if iFrame is loaded
  visible: boolean; // true if iframe should be visible at this moment
  position: null | EmbeddedIframePosition; // absolute iframe position in pixels
  matterId: string | null; // if we are in matter mode this should be set to the matter uuid
  isError: boolean; // we use this to toggle the error page in the embedded component
};

const INITIAL_STATE: TState = {
  initialised: false,
  visible: false,
  position: null,
  matterId: null,
  isError: false,
};

export const reducer = (state = INITIAL_STATE, action?: unknown): TState => {
  if (actionGuards.setInitialised(action)) {
    return {
      ...state,
      initialised: action.payload.value,
    };
  }
  if (actionGuards.hideIframe(action)) {
    if (state.matterId === action.payload.matterId) {
      // because of race conditions when switching from tabs we only want to close if it hasn't switched
      return {
        ...state,
        visible: false,
      };
    }
  }
  if (actionGuards.showIframe(action)) {
    return {
      ...state,
      position: action.payload.position || null,
      matterId: action.payload.matterId || null,
      visible: true,
    };
  }
  if (actionGuards.setError(action)) {
    return {
      ...state,
      isError: action.payload.value,
    };
  }
  return state;
};

const autoSelectors = autoSelectorFactory<TState>()(['matterId', 'position', 'visible', 'initialised', 'isError']);

export const selectors = { ...autoSelectors };
