import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { facets, hasFacet } from '@sb-itops/region-facets';
import { withApolloClient, withReduxProvider } from 'web/react-redux/hocs';
import { MARK_AS_SENT_MODAL_ID } from 'web/components';
import { useSubscribedQuery } from 'web/hooks';
import { MarkAsSentModalData, MarkAsSentModalAdditionalInvoiceData } from 'web/graphql/queries';
import { operationTypes, getExternalSentViaOptions } from '@sb-billing/business-logic/correspondence-history';
import { todayAsInteger } from '@sb-itops/date';

import { MarkAsSentModalFormsContainer } from './MarkAsSentModal.forms.container';

function createInvoiceIdToMatterIdMap({ invoices }) {
  const mappedResult = invoices.reduce((acc, invoice) => {
    const invoiceId = invoice.id;
    const matterId = invoice.matter.id;

    acc[invoiceId] = matterId;
    return acc;
  }, {});

  return mappedResult;
}

function getUniqueContactIdsFromInvoices({ invoices }) {
  const uniqueContactIds = new Set();

  invoices.forEach(({ debtors }) => {
    debtors.forEach(({ id }) => uniqueContactIds.add(id));
  });

  return Array.from(uniqueContactIds);
}

const hooks = () => ({
  useMarkAsSentModalProps: () => {
    const onModalClose = () => setModalDialogHidden({ modalId: MARK_AS_SENT_MODAL_ID });

    return {
      onModalClose,
    };
  },
  // Query that fetches data required by both:
  //  1. Invoice reminders
  //  2. Invoices
  useMarkAsSentModalQueryData: ({ operationType, selectedItems, scope }) => {
    const isInvoiceOperationType = operationType === operationTypes.INVOICE;
    const isInvoiceReminderOperationType = operationType === operationTypes.INVOICE_REMINDER;

    // When marking invoices/invoice-reminders as sent, we require a matter's correspondence preferences to prefill the "sent via" field's value (e.g. Mail)
    //
    // When there are multiple invoices/invoice-reminders across multiple matters (e.g. Invoice list > Bulk actions > Mark as Sent):
    //  * We get the first selected invoice's matter's correspondence preference and use it for all
    //  * AC 6b: https://smokeball.atlassian.net/browse/BB-11455
    let firstInvoiceId;
    if (isInvoiceOperationType) {
      firstInvoiceId = selectedItems[0];
    } else if (isInvoiceReminderOperationType) {
      const firstInvoice = selectedItems[0].invoices?.[0];
      firstInvoiceId = firstInvoice?.invoiceId;
    }

    const { data, loading: isModalDataLoading } = useSubscribedQuery(MarkAsSentModalData, {
      variables: {
        firstInvoiceId,
      },
    });

    const { matter } = data?.invoice || {};
    const matterCommunicationSettings = matter?.matterCommunicationSettings || {};
    const matterCorrespondencePreferences = matterCommunicationSettings.correspondencePreferences || [];

    return {
      isInvoiceOperationType,
      isModalDataLoading,
      matterCorrespondencePreferences,
      operationType,
      selectedItems,
      scope,
    };
  },
  useSentViaOptions: () => {
    const externalSentViaOptions = getExternalSentViaOptions({
      isEBillingAvailable: hasFacet(facets.eBillingSendingPreference),
    });
    return {
      externalSentViaOptions,
    };
  },
});

const dependentHooks = () => ({
  // Query that fetches data required only by:
  //  1. Invoices
  useMarkAsSentModalAdditionalInvoiceDataQuery: ({
    isInvoiceOperationType,
    selectedItems,
    isModalDataLoading: isMatterCommunicationsSettingsQueryLoading,
  }) => {
    const { data, loading: isInvoiceRelatedDataQueryLoading } = useSubscribedQuery(
      MarkAsSentModalAdditionalInvoiceData,
      {
        variables: {
          invoiceIds: isInvoiceOperationType ? selectedItems : [],
          invoiceListFilter: isInvoiceOperationType
            ? {
                invoiceStatuses: ['FINAL', 'OVERDUE', 'PAID'],
                invoiceStatusOverduePivotDate: todayAsInteger(),
              }
            : {},
        },
        skip: !isInvoiceOperationType,
      },
    );

    const invoices = isInvoiceOperationType ? data?.invoiceList?.results || [] : [];
    const invoiceIdToMatterIdMap = isInvoiceOperationType ? createInvoiceIdToMatterIdMap({ invoices }) : {};
    const invoiceContactIds = isInvoiceOperationType ? getUniqueContactIdsFromInvoices({ invoices }) : [];

    return {
      isModalDataLoading: isMatterCommunicationsSettingsQueryLoading || isInvoiceRelatedDataQueryLoading,
      invoiceIdToMatterIdMap,
      invoiceContactIds,
    };
  },
});

export const MarkAsSentModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(MarkAsSentModalFormsContainer))),
);

MarkAsSentModalContainer.propTypes = {
  scope: PropTypes.string,
  operationType: PropTypes.oneOf(Object.values(operationTypes)),
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        matterId: PropTypes.string,
        debtorId: PropTypes.string,
        invoices: PropTypes.arrayOf(
          PropTypes.shape({
            invoiceId: PropTypes.string,
          }),
        ),
      }),
    ]),
  ).isRequired,
};

MarkAsSentModalContainer.defaultProps = {
  scope: 'mark-as-sent-modal',
  operationType: operationTypes.INVOICE, // Default to invoice type
};

MarkAsSentModalContainer.displayName = 'MarkAsSentModalContainer';
