import PropTypes from 'prop-types';
import { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getSbSiteParams } from '@sb-itops/environment-config';
import { getLogger } from '@sb-itops/fe-logger';
import { setDefaultPaymentMethod } from 'web/services/subscription';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { useTranslation } from '@sb-itops/react';
import { sendMetric } from 'web/services/metrics';
import {
  getById as getSubscriptionByAccountId,
  opdateCache as opdateSubscription,
} from '@sb-finance/redux/subscription';
import { subscriptionStatusByType } from '@sb-finance/business-logic/subscription/entities/constants';
import { featureActive, featureData } from '@sb-itops/feature';
import { getAccountId, getIsMissingProductClaim } from 'web/services/user-session-management';
import { infra } from '@sb-itops/business-logic';
import { getRegion } from '@sb-itops/region';
import { SubscriptionPaymentForm } from './SubscriptionPaymentForm';

const { sbSite } = getSbSiteParams();
const { getLocalModeQueryString } = infra.services;
const REGION = getRegion();

const log = getLogger('SubscriptionPaymentForm.container');

const getStripeConfirmSetupReturnUrl = () => {
  const hashBangRoute = `#subscription-complete`;
  if (window.location.origin === 'http://localhost:9001') {
    return `${window.location.origin}/?${getLocalModeQueryString(REGION)}${hashBangRoute}`;
  }
  return `${window.location.origin}/?${hashBangRoute}`;
};

const hooks = () => ({
  useLegalTermsUrl: () => ({
    legalTermsUrl: `${sbSite}/terms`,
  }),
  useDynamicUrls: () => {
    const dynamicUrls = featureActive('BB-12106') ? featureData('BB-12106') : {};

    return {
      contactUsUrl: dynamicUrls.contactUsUrl,
      featureListUrl: dynamicUrls.featureListUrl,
      reachOutUrl: dynamicUrls.reachOutUrl,
    };
  },
  useSubscriptionContext: ({
    isBarBillFreeBoostTrial,
    isUpgradeFromFreeTier,
    subscriptionRecord,
    priceInformation,
    stripeProductId,
  }) => {
    // Determine the price which will apply to this subscription.
    const combinedPriceInfo = Object.values(priceInformation).reduce((acc, tierPriceInfo) => {
      (tierPriceInfo?.prices || []).forEach((productPriceInfo) => {
        acc[productPriceInfo.stripeProductId] = productPriceInfo;
      });
      return acc;
    }, {});

    const priceInfo = combinedPriceInfo[stripeProductId];

    const { t } = useTranslation();
    const formattedAmount = t('cents', { val: priceInfo?.price || 0 });
    const supportsTax = hasFacet(facets.tax);
    const gstText = supportsTax ? ` (${t('excludingTax')})` : '';
    const paymentRate = priceInfo?.interval === 'year' || priceInfo?.interval === 'yearly' ? 'annually' : 'per month';

    const { TRIALING, PAST_DUE } = subscriptionStatusByType;

    const resolvePageContent = () => {
      if (isBarBillFreeBoostTrial && subscriptionRecord.externalPaymentMethodIds.length === 0) {
        return {
          submitLabel: 'Confirm Subscription',
          title: `Loving Smokeball? Confirm your Boost Subscription Today!`,
          subTitle1: `We won't charge you until your trial completes. By entering your details now, you'll be upgraded to a full Smokeball subscription and ensure continuity.`,
          subTitle2: `On completion of your trial, you'll pay just ${formattedAmount}${gstText} per user ${paymentRate}.`,
        };
      }

      if (subscriptionRecord.status === TRIALING && subscriptionRecord.externalPaymentMethodIds.length === 0) {
        return {
          submitLabel: 'Confirm Subscription',
          title: `Loving Smokeball? Subscribe Today!`,
          subTitle1: `We won't charge you until your trial completes. By entering your details now, you'll be upgraded to a full Smokeball subscription and ensure continuity.`,
          subTitle2: `On completion of your trial, you'll pay just ${formattedAmount}${gstText} per user ${paymentRate}.`,
        };
      }

      if (subscriptionRecord.status === PAST_DUE && subscriptionRecord.externalPaymentMethodIds.length === 0) {
        return {
          submitLabel: `Continue`,
          title: `Add your Payment Details`,
          subTitle1: `Your free trial has now completed. To continue using Smokeball, please add your payment information.`,
          subTitle2: `You'll pay just ${formattedAmount}${gstText} per user ${paymentRate}.`,
        };
      }

      if (isUpgradeFromFreeTier) {
        return {
          submitLabel: `Upgrade`,
          title: `Upgrade to Boost Today!`,
          subTitle1: `Upgrade to Boost and get access to your firm productivity tools in addition to your existing billing functionality.`,
          subTitle2: `You'll pay just ${formattedAmount}${gstText} per user ${paymentRate}.`,
        };
      }

      return {
        submitLabel: `Continue`,
        title: `Update your Payment Details`,
        subTitle1: `This will become your new default payment method for future Smokeball invoices`,
      };
    };

    return {
      ...resolvePageContent(),
    };
  },
  useSubmitHandler: (props) => {
    const { onPaymentMethodUpdated, setupIntent, onPaymentMethodUpdateAbort } = props;

    const stripe = useStripe();
    const elements = useElements();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const onSubmit = async (event, tierId, stripeProductId) => {
      setIsSubmitting(true);
      event.preventDefault();
      if (errorMessage) {
        setErrorMessage(undefined);
      }

      if (!stripe || !elements) {
        // This shouldn't happen as the submit button in the form should be disabled whenever stripe or elements not available yet.
        return;
      }

      const stripeSetupReturnUrl = getStripeConfirmSetupReturnUrl();

      const confirmSetupResult = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: stripeSetupReturnUrl,
        },
        redirect: 'if_required',
      });

      if (confirmSetupResult.error) {
        // eslint-disable-next-line no-console
        console.log('Failed to confirm create setup intent', confirmSetupResult.error);
        setIsSubmitting(false);

        if (confirmSetupResult.error.message) {
          setErrorMessage(confirmSetupResult.error.message);
        }

        return;
      }

      // set default payment method
      try {
        const response = await setDefaultPaymentMethod({
          setupIntentId: setupIntent.id,
          stripeProductId,
        });

        const { paymentMethodId } = response.body || {};
        if (!getIsMissingProductClaim()) {
          // We can send the metric only if we have a product id, otherwise this causes logout
          sendMetric('barBillBoostTrialConfirmed', { stripeProductId, paymentMethodId });
        }

        // opdate subscription
        const accountId = getAccountId();
        const subscription = getSubscriptionByAccountId(accountId);
        const subscriptionForOpdate = { ...subscription };

        subscriptionForOpdate.externalPaymentMethodIds = [paymentMethodId];
        opdateSubscription({ optimisticEntities: [subscriptionForOpdate] });

        onPaymentMethodUpdated({ paymentMethodId });
      } catch (err) {
        log.error('Failed to set default payment method', err);
        setErrorMessage('Failed to complete subscription. Please try again or contact Smokeball for assistance.');
        setIsSubmitting(false);
      }
    };

    return {
      isSubmitting,
      errorMessage,
      onSubmit,
      onPaymentMethodUpdateAbort,
    };
  },
});

export const SubscriptionPaymentFormContainer = composeHooks(hooks)(SubscriptionPaymentForm);

SubscriptionPaymentFormContainer.displayName = 'SubscriptionPaymentFormContainer';

SubscriptionPaymentFormContainer.propTypes = {
  setupIntent: PropTypes.object,
  subscriptionRecord: PropTypes.object,
  isUpgradeFromFreeTier: PropTypes.bool.isRequired,
  isBarBillFreeBoostTrial: PropTypes.bool,
  onPaymentMethodUpdated: PropTypes.func.isRequired,
  onPaymentMethodUpdateAbort: PropTypes.func.isRequired,
};

SubscriptionPaymentFormContainer.defaultProps = {
  setupIntent: undefined,
  subscriptionRecord: undefined,
  isBarBillFreeBoostTrial: false,
};
