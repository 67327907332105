angular
  .module('sb.billing.webapp')
  .factory(
    'combineInvoicesOp',
    (
      $http,
      sbInvoicePreviewService,
      sbInvoicingService,
      sbNotifiedOperationP,
      sbLoggerService,
      sbUuidService,
      sbDispatcherService
    ) => {
      return ({ invoiceIds, consolidated, addresseeId }) => {
        const log = sbLoggerService.getLogger('combineInvoicesOp');
        const requestId = sbUuidService.get();
        const minimumTimeout = 120000; // 2 minutes in milliseconds
        const timeoutMs = Math.max(minimumTimeout, invoiceIds.length * 30000);
        let invoicesWithProblems = [];
        const pdfCombinedErrorNotification = 'PdfCombinedErrorNotification';

        // Object representing the current state of this operation.
        const operation = {
          label: `Combining ${invoiceIds.length} Invoices`,
          isComplete: false,
          error: null,
          fileData: null,
          nbInvoices: invoiceIds.length,
          progress: {
            nbSteps: 0,
            maxSteps: 1,
            percentage: null
          }
        };

        // Kick off the operattion
        sbNotifiedOperationP(startPdfCombineP, {
          requestId,
          completionNotification: 'PreviewReadyNotification',
          progressNotification: 'PreviewProgressNotification',
          timeoutMs
        })
          .then(onPdfCombineComplete, undefined, onPdfCombineProgress)
          .catch(onError);

        sbDispatcherService.registerSyncAction(
          pdfCombinedErrorNotification,
          requestId,
          problemFoundNotification,
          message => message.requestId === requestId
        );

        return operation;

        // Returns a promise for combining of invoices into a single PDF. Used by sbNotifiedOperation to begin processing.
        function startPdfCombineP() {
          return sbInvoicePreviewService.getCombinedPdfPreviewBase64P(requestId, invoiceIds, consolidated, addresseeId);
        }

        function problemFoundNotification(msg) {
          log.info('error found in some invoices', msg.payload.invoices);
          invoicesWithProblems = invoicesWithProblems.concat(msg.payload.invoices);
        }

        // Called by sbNotifiedOperation whenever some progress is made during the combine PDF operation.
        function onPdfCombineProgress(msg) {
          const progress = operation.progress;
          ++progress.nbSteps;

          const newMaxProgress = _.get(msg, 'payload.maxProgress') || progress.maxSteps;
          progress.maxSteps = Math.max(newMaxProgress, progress.nbSteps);

          if (progress.maxSteps) {
            progress.percentage = Math.min((progress.nbSteps / progress.maxSteps) * 100, 99.5); // Don't go over 99.5 % for incomplete operation.
          }
        }

        function buildInvoiceDisplayWarningText(invoiceId) {
          const invoice = sbInvoicingService.getInvoice(invoiceId);
          if (invoice) {
            return `#${invoice.invoiceNumber}`;
          }
          return `Unknown Invoice Number`;
        }

        function buildWarnMessage(invoicesWithProblems) {
          return invoicesWithProblems.length > 0
            ? `The following invoice PDFs could not be generated and have been removed from the combined PDF: ${[
                ...new Set(invoicesWithProblems)
              ]
                .map(invoiceId => buildInvoiceDisplayWarningText(invoiceId))
                .join(', ')}`
            : undefined;
        }

        // Called by sbNotifiedOperation when the combine PDF operation completes.
        // Note that we still have a bit of work to do when the PDF combining completes, mainly downloading the document content as base64.
        function onPdfCombineComplete(msg) {
          const payload = msg.payload;
          const request = {
            method: 'GET',
            url: payload.previewUrl,
            responseType: 'arraybuffer'
          };

          log.info('send request', request);
          return $http(request).then(response => {
            sbDispatcherService.unregisterSyncAction(pdfCombinedErrorNotification, requestId);
            const file = new Blob([response.data], { type: 'application/pdf' });
            operation.isComplete = true;
            operation.progress.percentage = 100;
            operation.fileData = file;
            operation.fileWarn = buildWarnMessage(invoicesWithProblems);
          });
        }

        // The hopefully never called error handler.
        function onError(err) {
          sbDispatcherService.unregisterSyncAction(pdfCombinedErrorNotification, requestId);
          log.error(`Failed to generate combined PDF with requestId: ${requestId}`, err);
          operation.error = err;
          operation.isComplete = true;
        }
      };
    }
  );
