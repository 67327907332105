import * as React from 'react';
import PropTypes from 'prop-types';

import { useForm } from '@sb-itops/redux/forms2/use-form';
import composeHooks from '@sb-itops/react-hooks-compose';
import { buildContactToAddress } from '@sb-customer-management/business-logic/contacts/services';
import { regionType } from '@sb-itops/region';
import { capitalize } from '@sb-itops/nodash';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  appendPaymentAndEInvoicePlaceholders,
  getContactFirstAndLastNames,
  getDefaultCommunicateMessage,
} from '@sb-billing/business-logic/invoice-via-communicate';
import { determineEInvoiceEnabled } from '@sb-billing/business-logic/einvoice';
import { featureActive } from '@sb-itops/feature';
import { getUserId } from 'web/services/user-session-management';
import { getLogger } from '@sb-itops/fe-logger';
import {
  isPayButtonEnabledForMatter,
  payButtonEnabledOptionValues,
} from '@sb-billing/business-logic/matters/invoice-settings';
import { InvoiceCommunicateModal } from './InvoiceCommunicateModal';
import { invoiceCommunicateFormSchema } from './InvoiceCommunicateForm.yup';

const log = getLogger('InvoiceCommunicateModal.forms.container');

/**
 * Builds the communicate request required to send an invoice to a debtor
 *
 * @param {object} params
 * @param {string} params.debtorFirstName
 * @param {string[]} params.debtorIds
 * @param {string} params.debtorLastName
 * @param {object} params.formValues
 * @param {string[]} params.invoiceIds
 * @param {object} params.invoiceTotalDurationsByIdMap
 * @returns {object}
 */
function convertToInvoiceCommunicateRequests({
  debtorFirstName,
  debtorIds,
  debtorLastName,
  formValues,
  invoiceIds,
  invoiceTotalDurationsByIdMap,
}) {
  return [
    {
      debtorId: debtorIds.length === 1 ? debtorIds[0] : undefined,
      invoiceIds,
      invoiceTotalDurationsByIdMap,
      template: {
        toAddress: formValues.toAddress,
        message: formValues.message,
        fromUserId: formValues.fromUserId,
        debtorFirstName,
        debtorLastName,
      },
    },
  ];
}

const hooks = () => ({
  useInvoiceCommunicateModalForm: ({
    debtorIds,
    firmCanIncludePaymentLinks,
    firmEInvoiceSettings,
    invoiceCommunicateModalData,
    invoiceIds,
    invoiceTotalDurationsByIdMap,
    isLoadingQuery,
    isMultiDebtorInvoice,
    region,
    scope,
    showPreviewButton,
    // Callbacks
    onModalClose,
    onPreview,
    onSend,
  }) => {
    const invoiceCommunicateForm = useForm({ scope, schema: invoiceCommunicateFormSchema });
    const { formFields, formInitialised, formSubmitting, formValid } = invoiceCommunicateForm;

    const invoice = invoiceCommunicateModalData?.invoices[0];

    // Currently, this container always deals with a single debtor
    const { contactFirstName, contactLastName } = getContactFirstAndLastNames({
      isToAddressReadOnly: invoiceCommunicateForm.formValues.isToAddressReadOnly,
      contact: invoice?.debtors[0]?.contact,
    });

    React.useEffect(
      () => () => invoiceCommunicateForm.onClearForm(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    /**
     * Communicate preview
     */

    const [isLoadingPreview, setIsPreviewLoading] = React.useState(showPreviewButton);
    const [isPreviewMode, setIsPreviewMode] = React.useState(showPreviewButton);
    const [previewMessage, setPreviewMessage] = React.useState('');

    const onPreviewToggled = () => setIsPreviewMode(!isPreviewMode);

    React.useEffect(() => {
      (async () => {
        if (formInitialised && showPreviewButton && isPreviewMode) {
          const invoiceCommunicateRequests = convertToInvoiceCommunicateRequests({
            debtorIds,
            formValues: invoiceCommunicateForm.formValues,
            invoiceIds,
            debtorFirstName: contactFirstName,
            debtorLastName: contactLastName,
            invoiceTotalDurationsByIdMap,
          });

          const previewValues = await onPreview({
            invoiceCommunicateRequest: invoiceCommunicateRequests[0], // Only single invoice communicate requests are supported
          });

          setPreviewMessage(previewValues.message);
          setIsPreviewLoading(false);
        }
      })();
      // invoiceCommunicateModalData is required to re-render the previews from query refetches
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInitialised, isPreviewMode, invoiceCommunicateModalData]);

    /**
     * Form initialisation
     */

    function getDefaulFormValues() {
      const { matter } = invoice;

      const getRelevantContact = () => {
        const invoiceDebtors = invoiceCommunicateModalData.invoices[0].debtors;
        const relevantDebtorId = debtorIds[0];

        // Find the relevant contact (they may not be ordered first in the query)
        const relevantDebtor = invoiceDebtors.find((debtor) => debtor.id === relevantDebtorId);
        return relevantDebtor.contact;
      };

      const contact = getRelevantContact();
      const toAddress = buildContactToAddress({ contact, region });

      // toAddress field is disabled when:
      //  1. Valid toAddress
      //  2. For contacts of type "organisation" or "groupOfPeople" without emails (to prevent the user from sending the communicate request)
      const isToAddressReadOnly =
        !!toAddress || (!toAddress && (contact.type === 'organisation' || contact.type === 'groupOfPeople'));

      const eInvoiceEnabled =
        featureActive('BB-5725') && hasFacet(facets.eInvoiceUserDefinedSwitch)
          ? determineEInvoiceEnabled({
              matterInvoiceSettings: matter?.matterInvoiceSettings,
              eInvoiceSettings: firmEInvoiceSettings,
            })
          : featureActive('BB-5725');

      const payButtonEnabledForMatter = matter?.matterInvoiceSettings
        ? isPayButtonEnabledForMatter({ matterInvoiceSettings: matter.matterInvoiceSettings })
        : firmCanIncludePaymentLinks;

      const isSplitBillingInvoice = invoice.splitBillingSettings?.isEnabled;

      const communicateMessage = appendPaymentAndEInvoicePlaceholders({
        message: getDefaultCommunicateMessage(),
        isMultiDebtorInvoice,
        isSplitBillingInvoice,
        eInvoiceEnabled,
        paymentProviderEnabledForInvoicePayment: firmCanIncludePaymentLinks,
        payButtonEnabledForMatter,
      });

      return {
        debtorDisplayName: contact.displayNameFirstLast,
        // Form component expects it to be capitalised
        //  * E.g. Cache stores the type as: "Person" (form currently follows this)
        //  * E.g. GraphQL returns the type as "person"
        debtorType: capitalize(contact.type),
        fromUserId: getUserId(),
        isToAddressReadOnly,
        message: communicateMessage,
        toAddress,
      };
    }

    const initialiseForm = !isLoadingQuery && !formInitialised;

    if (initialiseForm) {
      const defaultValues = getDefaulFormValues();
      invoiceCommunicateForm.onInitialiseForm(defaultValues);
      invoiceCommunicateForm.onValidateForm();
    }

    /**
     * Form updates
     */

    function onUpdateFormData({ fieldUpdates }) {
      invoiceCommunicateForm.onUpdateFields(fieldUpdates);
      invoiceCommunicateForm.onValidateForm();
    }

    function onFieldValueUpdated(field, newValue) {
      const newFieldValue = {
        [field]: newValue,
      };

      onUpdateFormData({ fieldUpdates: newFieldValue });
    }

    /**
     * Form submission
     */

    async function onFormSubmit() {
      try {
        invoiceCommunicateForm.onValidateForm();

        const isValid = await invoiceCommunicateForm.onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            const invoiceCommunicateRequests = convertToInvoiceCommunicateRequests({
              debtorFirstName: contactFirstName,
              debtorIds,
              debtorLastName: contactLastName,
              formValues: formData,
              invoiceIds,
              invoiceTotalDurationsByIdMap,
            });

            onSend({ invoiceCommunicateRequests });
          },
        });

        if (!isValid) {
          log.warn('Invoice communicate modal form validation failed');
          return;
        }

        onModalClose();
      } catch (error) {
        log.error('Failed to send invoice communicate', error);
      }
    }

    const isLoading = isLoadingQuery || isLoadingPreview;

    return {
      formInitialised,
      isLoading,
      isPreviewMode,
      isSubmitDisabled: isLoading || !formValid || formSubmitting,
      previewMessage,
      ...formFields,
      // Callbacks
      onFieldValueUpdated,
      onPreviewToggled,
      onFormSubmit,
    };
  },
});

const dependentHooks = () => ({});

export const InvoiceCommunicateModalFormsContainer = composeHooks(hooks)(
  composeHooks(dependentHooks)(InvoiceCommunicateModal),
);

InvoiceCommunicateModalFormsContainer.displayName = 'InvoiceCommunicateModalFormsContainer';

const DebtorEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customLetterName: PropTypes.string,
    displayNameFirstLast: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    letterNameFormat: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
});

const MatterEntityType = PropTypes.shape({
  id: PropTypes.string,
  matterInvoiceSettings: PropTypes.shape({
    id: PropTypes.string,
    eInvoiceEnabledOption: PropTypes.number,
    payButtonEnabledOption: PropTypes.oneOf(Object.values(payButtonEnabledOptionValues)),
  }),
});

InvoiceCommunicateModalFormsContainer.propTypes = {
  debtorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  firmCanIncludePaymentLinks: PropTypes.bool.isRequired,
  invoiceIds: PropTypes.arrayOf(PropTypes.string),
  invoiceTotalDurationsByIdMap: PropTypes.object.isRequired,
  isLoadingQuery: PropTypes.bool.isRequired,
  isMultiDebtorInvoice: PropTypes.bool.isRequired,
  region: PropTypes.oneOf(Object.values(regionType)).isRequired,
  scope: PropTypes.string.isRequired,
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  // Callbacks
  onModalClose: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  // Required props but initially undefined
  firmEInvoiceSettings: PropTypes.shape({
    eInvoiceDisabled: PropTypes.bool.isRequired,
  }),
  invoiceCommunicateModalData: PropTypes.shape({
    invoices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        debtors: PropTypes.arrayOf(DebtorEntityType),
        matter: MatterEntityType,
      }),
    ),
  }),
};

InvoiceCommunicateModalFormsContainer.defaultProps = {
  firmEInvoiceSettings: undefined,
  invoiceCommunicateModalData: undefined,
};
