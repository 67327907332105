'use strict';

/**
 * for split billing, the available amount is the total available minus amount allocated on other debtors
 * @param {object} params
 * @param {Object} params.available available account balance
 * @param {Object} params.totalAllocatedAmount total allocated amount on all debtors
 * @param {Object} params.currentDebtorAmount allocated amount on current
 * @returns {Object}
 */
const getAvailableFundsForCurrentDebtor = ({ available, totalAllocatedAmount, currentDebtorAmount }) =>
  // for split billing, the available amount for the current debtor is the total available minus amount allocated on other debtors
  available - (totalAllocatedAmount - currentDebtorAmount);

module.exports = {
  getAvailableFundsForCurrentDebtor,
};
