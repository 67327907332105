/* eslint-disable react/no-unstable-nested-components */
import * as React from 'react';
import {
  Button,
  buttonTypes,
  LoadingBarInfinite,
  useTranslation,
  CollapsibleHeader,
  Checkbox,
  ContextMenu,
  LinkableText,
  Spinner,
  Tooltip,
} from '@sb-itops/react';
import { MultiGrid, AutoSizer } from 'react-virtualized';
import { Activity } from 'types';
import { ArrowDown, ArrowUp, ChevronLeft, ChevronRight, ChevronDown, ChevronUp, Filters } from 'web/components/icons';
import moment from 'moment';
import classNames from 'classnames';
import { useExpandCollapse } from '@sb-itops/redux/expand-collapse/use-expand-collapse';
import { MattersDisplayFromMatters } from '@sb-matter-management/react';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { calculateFeeAmounts } from '@sb-billing/business-logic/fee/services';
import { getLoggedInStaff } from '@sb-firm-management/redux/firm-management';
import { xmlToJson } from '@sb-itops/xml2json';
import { durationType, entryType } from '@sb-billing/business-logic/shared/entities';
import { hasFacet } from '@sb-itops/region-facets';
import facets from '@sb-itops/region-facets/facets';
import { getRegion } from '@sb-itops/region';
import { deriveRate } from '@sb-billing/business-logic/rates';
import { deriveActivityRate } from '@sb-billing/business-logic/activities/services';
import { featureActive } from '@sb-itops/feature';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { ValueOf } from 'type-fest';
import Styles from './ActivitiesTab.module.scss';
import { FEE_MODAL_ID } from '../fee-modal';

interface IActivitiesTabProps {
  loading: boolean;
  activities: Activity[];
  activitiesByDay: { [key: string]: { activityCount: number; durationInMinutes: number } };
  activityListWithHeaders: (Activity | { isRowHeader: boolean; day: string; displayName: string })[];
  lastQueryTime: moment.Moment;
  selectedTime: moment.Moment;
  startTime: moment.Moment;
  endTime: moment.Moment;
  setSelectedTime: (endTime: moment.Moment) => void;
  selectedTimeFrame: moment.unitOfTime.Base;
  setSelectedTimeFrame: (selectedTimeFrame: string) => void;
  firmUsers: any[];
  atLimit: boolean;
  matterCount: number;
  feeDurationTotal: number;
  onClickLink: (data: { type: string; id: string }) => void;
  setSortDirection: (endTime: string) => void;
  sortDirection: string;
  collapsed: { [key: string]: boolean };
  setCollapsed: (collapsed: { [key: string]: boolean }) => void;
  activityCount: number;
  rowCount: number;
  refresh: () => void;
  filters: { [key: string]: { [key: string]: boolean } };
  setFilters: (filters: { [key: string]: { [key: string]: boolean } }) => void;
  resetFilters: () => void;
  userFilter: { [key: string]: boolean };
  setUserFilter: (userFilter: { [key: string]: boolean }) => void;
  taxRateBasisPoints: number;
  loggedInStaff: any;
}

// Activity Types from international/itops/reporting/activity/Entities/ActivityType.cs
export const activityTypeByName = {
  Unknown: 0,
  Matter: 1,
  Event: 2,
  Task: 3,
  Document: 4,
  Email: 5,
  Memo: 6,
  Custom: 7,
  'Matter Admin': 8,
  'Browsed Site': 9,
  Messages: 10,
  Lead: 11,
  Intake: 12,
  Research: 13,
  Archie: 14,
} as const;

const activityNameByType: { [key in ValueOf<typeof activityTypeByName>]: string } = Object.entries(
  activityTypeByName,
).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {} as any);

const activityTypeDefaultDescription = {
  0: '',
  1: 'Matter Drafted',
  2: 'Meeting Attended',
  3: 'Task Completed',
  4: 'Document Edited',
  5: 'Email Sent',
  6: 'Memo Drafted',
  7: '',
  8: 'Matter Administration',
  9: 'Browsed Site',
  10: 'Messages drafted/perused',
  11: 'Lead Created',
  12: 'Intake Form',
  13: 'Research and documentation',
  14: 'Matter related research and documentation',
};

const SubActivityTypes = {
  Created: 'Created',
  Modified: 'Modified',
  Viewed: 'Viewed',
};

const getActivityDescription = (activity, t) => {
  if (activity.activityType === activityTypeByName.Memo) {
    const xmlDOM = new DOMParser().parseFromString(activity.activityData, 'text/xml');
    const activityData = xmlToJson(xmlDOM);
    let subactivities = activityData?.CompoundActivity?.SubActivities?.SubActivity || [];
    if (!Array.isArray(subactivities)) {
      subactivities = [subactivities];
    }
    const created = subactivities.some((a) => a.Type === SubActivityTypes.Created);
    const modified = subactivities.some((a) => a.Type === SubActivityTypes.Modified);
    const reviewed = subactivities.some((a) => a.Type === SubActivityTypes.Viewed);

    let description = 'Memo';

    if (created) {
      description += ` Drafted`;
      if (modified || reviewed) {
        description += ', then';
      }
    }
    if (modified || reviewed) {
      if (modified) {
        description += ` ${t('amended')}`;
      }
      if (reviewed) {
        description += modified ? ' and ' : ' ';
        description += t('perused');
      }
    }

    if (activity.description) {
      description += ` - ${activity.description}`;
    }
    return description;
  }
  if (activity.activityType === activityTypeByName.Intake) {
    const xmlDOM = new DOMParser().parseFromString(activity.activityData, 'text/xml');
    const activityData = xmlToJson(xmlDOM);
    let subactivities = activityData?.CompoundActivity?.SubActivities?.SubActivity || [];
    if (!Array.isArray(subactivities)) {
      subactivities = [subactivities];
    }
    const shared = subactivities.some((a) => a.Type === SubActivityTypes.Created);
    const reviewed = subactivities.some((a) => a.Type === SubActivityTypes.Viewed);
    let description = activity.description || activityTypeDefaultDescription[String(activity.activityType)];
    if (shared) {
      description += ' shared';
      if (reviewed) {
        description += ', then';
      }
    }
    if (reviewed) {
      description += ` ${t('perused')}`;
    }
    return description;
  }
  if (activity.activityType === activityTypeByName.Messages) {
    const xmlDOM = new DOMParser().parseFromString(activity.activityData, 'text/xml');
    const activityData = xmlToJson(xmlDOM);
    const isInternal = activityData?.CompoundActivity?.RelatedEntityId?.string === 'Internal';
    const messageText = isInternal ? 'Internal Message' : 'Message';
    const messageCount = Array.isArray(activityData?.CompoundActivity?.SingleActivityIds?.string)
      ? activityData?.CompoundActivity?.SingleActivityIds?.string?.length
      : 1;
    return messageCount === 1
      ? `${messageCount} ${messageText} drafted/${t('perused')}`
      : `${messageCount} ${messageText}s drafted/${t('perused')}`;
  }
  return activity.description || activityTypeDefaultDescription[String(activity.activityType)];
};

async function saveFee(activity, taxRateBasisPoints, loggedInStaff, t) {
  const xmlDOM = new DOMParser().parseFromString(activity.activityData, 'text/xml');

  const activityData = xmlToJson(xmlDOM);

  const rateInCents =
    loggedInStaff?.id && featureActive('BB-10835')
      ? deriveRate({
          staffId: loggedInStaff.id,
          staffRate: loggedInStaff.rate,
        })
      : deriveActivityRate({
          staffRateConfig: loggedInStaff,
          activity: {} as any,
        });
  const durationInMinutes = activity.durationInMinutes || 0;
  const newestFeeAmounts = calculateFeeAmounts({
    durationInMins: durationInMinutes,
    durationType: durationType.HOURS,
    isBillable: !activityData?.CompoundActivity?.NonBillable,
    isTaxExempt: false,
    isTaxFacetEnabled: hasFacet(facets.tax),
    isTaxInclusive: false,
    rateInCents,
    taxRateBasisPoints,
    region: getRegion(),
  });
  const marshalledData = {
    createdFromActivityId: activityData?.CompoundActivity?.ActivityId,
    amountIncludesTax: false,
    description: getActivityDescription(activity, t),
    duration: durationInMinutes, // Duration Billed
    durationWorked: durationInMinutes, // Duration Worked
    feeDate: moment(activity.dateTime).format('YYYYMMDD'),
    feeEarnerStaffId: getLoggedInStaff().id,
    isInvoicedExternally: false,
    isTaxExempt: false,
    matterId: activity.matter?.id,
    matter: activity.matter,
    notes: '',
    utbmsActivityCode: null,
    utbmsTaskCode: null,
    waived: false,
    feeType: entryType.TIME,
    isBillable: !activityData?.CompoundActivity?.NonBillable,
    rate: rateInCents,
    amount: newestFeeAmounts.taxAmountInCents,
    billableTax: newestFeeAmounts.billableTaxAmountInCents,
    tax: newestFeeAmounts.taxAmountInCents,
  };

  setModalDialogVisible({
    modalId: FEE_MODAL_ID,
    props: {
      onFeeSave: async ({ marshalledData: fee }) => {
        await dispatchCommand({
          type: 'ItOps.Reporting.Activity.Messages.SetActivityBillingFee',
          message: {
            id: activity.id,
            feeId: fee.feeId,
            versionId: fee.feeVersionId,
          },
        });
      },
      fee: marshalledData,
      matterId: activity.matter?.id,
      scope: `activities/fee-modal`,
    },
  });
}

export const ActivitiesTab = ({
  loading,
  activitiesByDay,
  activityListWithHeaders,
  startTime,
  endTime,
  selectedTime,
  setSelectedTime,
  selectedTimeFrame,
  setSelectedTimeFrame,
  firmUsers,
  atLimit,
  matterCount,
  feeDurationTotal,
  onClickLink,
  setSortDirection,
  sortDirection,
  collapsed,
  setCollapsed,
  activityCount,
  rowCount,
  filters,
  setFilters,
  resetFilters,
  userFilter,
  setUserFilter,
  taxRateBasisPoints,
  loggedInStaff,
  refresh,
  lastQueryTime,
}: IActivitiesTabProps) => {
  const { collapsedItemsMap: hiddenFilters, toggleItem, reset } = useExpandCollapse({ scope: 'activitiesPage' });
  const { t } = useTranslation();

  // Force grid to recompute column widths on window resize, otherwise it uses the (stale) cache.
  // This is better than using the window resize event because we need to wait for autosizer to pass the correct size to the multigrid first.
  // If we force a resize immediately, the multigrid will use the stale width
  const multigridRef = React.useRef<any>(null);
  const widthRef = React.useRef<any>(null);
  const heightRef = React.useRef<any>(null);
  const recalcColumns = React.useCallback((width, height) => {
    if (width !== widthRef.current || !heightRef.current !== height) {
      widthRef.current = width;
      heightRef.current = height;
      setTimeout(() => {
        if (multigridRef.current) {
          multigridRef.current.recomputeGridSize();
          multigridRef.current.forceUpdate();
        }
      }, 0);
    }
  }, []);

  const headers = [
    {
      value: (activity: Activity) => activityNameByType[activity.activityType],
      displayName: 'Category',
      width: 120,
    },
    {
      value: (activity: Activity) => moment(activity.dateTime || 0).format('hh:mm a'),
      displayName: (
        <div className={Styles.sortHeader} onClick={() => setSortDirection(sortDirection === 'DESC' ? 'ASC' : 'DESC')}>
          Time {sortDirection === 'DESC' ? <ArrowUp /> : <ArrowDown />}
        </div>
      ),
      width: 100,
    },
    {
      value: (activity: Activity) => {
        if (!activity.matter) {
          return 'Unassigned';
        }
        return activity?.matter?.isLead ? 'Lead' : 'Matter';
      },
      displayName: 'Type',
      width: 100,
    },
    {
      value: (activity: Activity) => <div className={Styles.cellText}>{getActivityDescription(activity, t)}</div>,
      displayName: 'Description',
      width: 80,
      expandableWidth: 100,
    },
    {
      value: (activity: Activity) =>
        activity?.matter && (
          <MattersDisplayFromMatters
            matters={[activity?.matter]}
            asLink
            onClickLink={onClickLink}
            className={Styles.flex}
          />
        ),
      displayName: 'Matter',
      width: 80,
      expandableWidth: 50,
    },
    {
      value: (activity: Activity) => activity?.user?.person?.name || '',
      displayName: 'Staff',
      expandableWidth: 50,
      width: 80,
    },
    {
      value: (activity: Activity) => Math.round(((activity.durationInMinutes || 0) * 100) / 60) / 100,
      displayName: 'Hours',
      width: 80,
    },
    {
      value: (activity: Activity) =>
        activity.billingFeeId ? (
          <LinkableText
            text="View"
            onClickLink={() => {
              setModalDialogVisible({
                modalId: FEE_MODAL_ID,
                props: {
                  feeId: activity.billingFeeId,
                  scope: `activities/fee-modal`,
                },
              });
            }}
            asLink
            inline
          />
        ) : (
          <Tooltip
            position="right"
            title={
              !!activity?.matter?.isLead && !activity?.matter?.matterNumber
                ? `Add a ${t('matterNumber')} to capture time on this lead`
                : ''
            }
            delay={100}
            interactive={false}
          >
            <Button
              disabled={!!activity?.matter?.isLead && !activity?.matter?.matterNumber}
              type={buttonTypes.tertiary}
              className={Styles.addButton}
              onClick={() => saveFee(activity, taxRateBasisPoints, loggedInStaff, t)}
            >
              <span className="small">Add</span>
            </Button>
          </Tooltip>
        ),
      displayName: 'Time Entry',
      width: 100,
    },
  ];
  const fixedWidthTotal = headers.reduce((prev, curr) => prev + curr.width, 0);
  const expandableWidthTotal = headers.reduce((prev, curr) => prev + (curr.expandableWidth || 0), 0);

  const onToggleFilterVisibility = (val) => {
    toggleItem(val.currentTarget.id || val.currentTarget.name);
  };

  // Using this cell renderer with the multigrid to accomodate the fancy new table requirements
  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    // Row 0 is the header
    if (rowIndex === 0) {
      return (
        <div key={key} style={style} className={classNames(Styles.headings, Styles.cell)}>
          {headers[columnIndex].displayName}
        </div>
      );
    }
    const item = activityListWithHeaders[rowIndex - 1];
    if ('isRowHeader' in item) {
      const onClickRowHeader = () => {
        setCollapsed({ ...collapsed, [item.day]: !collapsed[item.day] });
      };
      if (columnIndex === 0) {
        return (
          <div
            onClick={onClickRowHeader}
            key={key}
            style={style}
            className={classNames(Styles.rowHeader, Styles.overlap, Styles.cell)}
          >
            {collapsed[item.day] ? <ChevronUp /> : <ChevronDown />}
            {item.displayName}
          </div>
        );
      }
      if (columnIndex === headers.length - 1) {
        return (
          <div
            onClick={onClickRowHeader}
            key={key}
            style={style}
            className={classNames(Styles.rowHeader, Styles.overlap, Styles.cell, Styles.groupCounts)}
          >
            <div>
              <strong>{activitiesByDay[item.day].activityCount}</strong> Activities
            </div>
            <div>
              <strong>{Math.round((activitiesByDay[item.day].durationInMinutes * 100) / 60) / 100}</strong> Hours
            </div>
          </div>
        );
      }
      return (
        <div onClick={onClickRowHeader} key={key} style={style} className={classNames(Styles.rowHeader, Styles.cell)} />
      );
    }
    return (
      <div key={key} style={style} className={classNames(Styles.cell)}>
        {headers[columnIndex].value(item)}
      </div>
    );
  };

  return (
    <div className={classNames('master-detail-panel', Styles.container)}>
      <LoadingBarInfinite loading={loading} />
      <div className={Styles.topBar}>
        <div className={Styles.calendarNav}>
          <div
            className={Styles.iconButton}
            onClick={() => {
              setSelectedTime(moment(selectedTime).subtract(1, selectedTimeFrame));
            }}
          >
            <ChevronLeft />
          </div>
          <div>
            {selectedTimeFrame === 'week'
              ? `${t('date', {
                  date: startTime.toDate(),
                  format: 'Do MMMM',
                })} - `
              : ''}
            {selectedTimeFrame !== 'month'
              ? `${t('date', {
                  date: selectedTimeFrame === 'week' ? endTime.toDate() : startTime.toDate(),
                  format: 'Do MMMM YYYY',
                })}`
              : ''}
            {selectedTimeFrame === 'month'
              ? t('date', {
                  date: startTime.toDate(),
                  format: 'MMMM YYYY',
                })
              : ''}
          </div>
          <div
            className={Styles.iconButton}
            onClick={() => setSelectedTime(moment(selectedTime).add(1, selectedTimeFrame))}
          >
            <ChevronRight />
          </div>
        </div>
        <div className={Styles.switch}>
          <div
            onClick={() => {
              setSelectedTimeFrame('month');
            }}
            className={classNames(selectedTimeFrame === 'month' && Styles.active, Styles.switchText)}
          >
            Month
          </div>
          <div
            onClick={() => {
              setSelectedTimeFrame('week');
            }}
            className={classNames(selectedTimeFrame === 'week' && Styles.active, Styles.switchText)}
          >
            Week
          </div>
          <div
            onClick={() => {
              setSelectedTimeFrame('day');
            }}
            className={classNames(selectedTimeFrame === 'day' && Styles.active, Styles.switchText)}
          >
            Day
          </div>
        </div>
        <div className={Styles.contextMenu}>
          <ContextMenu
            position="bottom-end"
            arrow={false}
            distance={3}
            render={({ open }) => (
              <div className={classNames(Styles.filtersCustomButton, open && Styles.active)}>
                <Filters />
                Filters
                <div className={Styles.filterHighlight}>
                  {Object.values(filters).reduce(
                    (prev, curr) => prev + Object.values(curr).reduce((p, c) => p + (c ? 1 : 0), 0),
                    0,
                  )}
                </div>
              </div>
            )}
            // eslint-disable-next-line react/no-unstable-nested-components
            body={() => (
              <div className={Styles.filters}>
                <div className={Styles.header}>
                  Filters for Activities
                  <Button
                    type={buttonTypes.link}
                    onClick={() => {
                      reset();
                      resetFilters();
                    }}
                  >
                    Reset Filters
                  </Button>
                </div>
                <div className={Styles.filtersList}>
                  {Object.keys(filters).map((filterTitle) => (
                    <CollapsibleHeader
                      key={filterTitle}
                      onClick={onToggleFilterVisibility}
                      name={filterTitle}
                      text={filterTitle}
                      collapsed={hiddenFilters?.[filterTitle]}
                    >
                      {Object.keys(filters[filterTitle]).map((filterValue) => (
                        <div
                          onClick={() => {
                            setFilters({
                              ...filters,
                              [filterTitle]: {
                                ...filters[filterTitle],
                                [filterValue]: !filters[filterTitle][filterValue],
                              },
                            });
                          }}
                          key={filterValue}
                          className={Styles.filterItem}
                        >
                          <Checkbox
                            onChange={(checked) => {
                              setFilters({
                                ...filters,
                                [filterTitle]: { ...filters[filterTitle], [filterValue]: checked },
                              });
                            }}
                            checked={filters[filterTitle][filterValue]}
                          />
                          {filterValue}
                        </div>
                      ))}
                    </CollapsibleHeader>
                  ))}
                  <CollapsibleHeader
                    onClick={onToggleFilterVisibility}
                    name="Staff"
                    text="Staff"
                    key="Staff"
                    collapsed={hiddenFilters?.['Staff']}
                  >
                    {firmUsers.map((user) => (
                      <div
                        onClick={() => {
                          setUserFilter({
                            ...userFilter,
                            [user.userId]: !userFilter[user.userId],
                          });
                        }}
                        key={user.userId}
                        className={Styles.filterItem}
                      >
                        <Checkbox
                          onChange={(checked) => {
                            setUserFilter({
                              ...userFilter,
                              [user.userId]: checked,
                            });
                          }}
                          checked={userFilter[user.userId]}
                        />
                        {user.name || ''}
                      </div>
                    ))}
                  </CollapsibleHeader>
                </div>
              </div>
            )}
          />
        </div>
      </div>
      {loading ? (
        <div className={Styles.activitiesTable}>
          <div className={Styles.tableReplacement}>
            <Spinner />
          </div>
        </div>
      ) : (
        <div className={Styles.activitiesTable}>
          {activityCount ? (
            <AutoSizer>
              {({ width, height }) => {
                recalcColumns(width, height);
                return (
                  <MultiGrid
                    ref={multigridRef}
                    cellRenderer={cellRenderer}
                    classNameTopLeftGrid="top-left-grid"
                    classNameTopRightGrid="top-right-grid"
                    classNameBottomLeftGrid="bottom-left-grid"
                    classNameBottomRightGrid="bottom-right-grid"
                    fixedColumnCount={2}
                    fixedRowCount={1}
                    height={rowCount * 40 > height ? height : rowCount * 40}
                    width={width}
                    columnCount={headers.length}
                    columnWidth={({ index }) =>
                      headers[index].width +
                      // We want to use any extra space across large fields like description, matter and staff.
                      Math.max(
                        // the 7 here accounts for a vertical scrollbar, otherwise any vertical scrolling will reduce the available space and introduce a horizontal scrollbar as well
                        ((width - fixedWidthTotal - (rowCount * 40 > height ? 7 : 0)) *
                          (headers[index].expandableWidth || 0)) /
                          expandableWidthTotal,
                        0,
                      )
                    }
                    rowCount={rowCount}
                    rowHeight={40}
                  />
                );
              }}
            </AutoSizer>
          ) : (
            <div className={Styles.tableReplacement}>No activities found</div>
          )}
        </div>
      )}
      <div className={Styles.footer}>
        <div>
          <strong>{`${t('date', {
            date: lastQueryTime.toDate(),
            format: 'Do MMMM',
          })}${t('date', {
            date: lastQueryTime.toDate(),
            format: ', YYYY, hh:mm A',
          })}`}</strong>
          {` Last Updated`}
          <Button type={buttonTypes.link} onClick={refresh}>
            Refresh
          </Button>
          {!!atLimit && ' Showing first 500 activities. Please narrow your search to see the remaining activities'}
        </div>
        <div className={Styles.totals}>
          <div>
            <strong>{matterCount}</strong> Matters
          </div>
          <div>
            <strong>{activityCount}</strong> Activities
          </div>
          <div>
            <strong>{Math.round((feeDurationTotal * 100) / 60) / 100}</strong> Hours
          </div>
        </div>
      </div>
    </div>
  );
};

ActivitiesTab.displayName = 'ActivitiesTab';
