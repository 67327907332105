import React from 'react';

const withLink = (type) => (WrappedComponent) => (props) => {
  const onClickLink = (id, params = []) => (props.onClickLink ? props.onClickLink({ type, id, params }) : () => {});
  const handler = {
    [`on${type.charAt(0).toUpperCase()}${type.substring(1)}Link`]: onClickLink,
  };
  return <WrappedComponent {...handler} {...props} />;
};

export default withLink;
