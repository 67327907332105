import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { INVOICE_PREVIEW_MODAL_ID } from 'web/components';

angular
  .module('sb.billing.webapp')
  .controller('SbContactLedgerController', function (
    sbLinkService,
    sbUnsavedChangesService
  ) {
    const that = this;
    const stateKey = `contact-ledger-table-${that.contactId}`;

    that.closeCreditPaymentNModal = closeCreditPaymentNModal;
    that.onRowClick = onRowClick;
    that.onClickLink = sbLinkService.onClickLink;
    that.onSort = onSort;

    that.model = {
      contactLedger: undefined,
    };

    that.tableState = {
      sortDirection: undefined,
      sortBy: undefined,
    };

    that.$onInit = () => {
      that.model.contactLedger = that.contactLedgerList;
      that.tableState = sbUnsavedChangesService.loadMemory(stateKey) || that.tableState;
      that.view = {
        showCreditPaymentModal: false,
        paymentId: '',
      };
    };

    that.$onChanges = (changesObj) => {
      // this is so angular updates the contactLedgerList reference updated by sb-compose. This is until we migrate this to redux.
      if (changesObj && changesObj.contactLedgerList && changesObj.contactLedgerList.currentValue) {
        // get the new value coming from sb-compose
        const updatedContactLedger = changesObj.contactLedgerList.currentValue;
        that.model.contactLedger = updatedContactLedger;
      }
    };

    that.$onDestroy = () => {
      sbUnsavedChangesService.saveMemory(stateKey, { ...that.tableState });
    };

    function onSort({ sortDirection, sortBy }) {
      that.tableState.sortDirection = sortDirection;
      that.tableState.sortBy = sortBy;
    }

    function closeCreditPaymentNModal() {
      that.view.showCreditPaymentModal = false;
    }

    function onRowClick(event, item) {
      event.stopPropagation();
      if (item.source === 'Credit') {
        that.view.showCreditPaymentModal = true;
        that.view.paymentId = item.payment.paymentId;
      } else if (item.payment || item.payments) {
        // Trust to office is a custom item with a payments array
        that.openModal(event, item.payment || item);
      } else {
        if (item.invoice.currentVersion.status === 'DRAFT') {
          that.onClickLink({
            type: 'invoice',
            id: item.invoice.invoiceId,
          });
        } else if (item.invoice.currentVersion.splitBillingSettings?.isEnabled) {
          setModalDialogVisible({
            modalId: INVOICE_PREVIEW_MODAL_ID,
            props: {
              invoiceId: item.invoice.invoiceId,
              invoiceNumber: item.invoice.currentVersion.invoiceNumber,
            },
          });
        } else {
          that.showInvoicePreview(item.invoice);
        }
      }
    }
  });
