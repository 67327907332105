const updateDebtorAllocationsLookup = ({ prev, selectedDebtorId, updatedAllocations }) => {
  const newDebtorAllocationsLookup = {
    ...prev,
    [selectedDebtorId]: updatedAllocations,
  };

  // Re-calculate allocated amount across all debtors for each contact
  const allocatedAmountContactLookup = Object.values(newDebtorAllocationsLookup)
    .flat() // convert debtor allocation values from [[contact1Allocation, contact2Allocation ...], [contact1Allocation, contact2Allocation ...], ...] to a flat array [contact1Allocation, contact2Allocation, ..., contact1Allocation, contact2Allocation, ...]
    .reduce((acc, debtorAllocation) => {
      if (acc[debtorAllocation.contactId]) {
        acc[debtorAllocation.contactId] += debtorAllocation.amount;
      } else {
        acc[debtorAllocation.contactId] = debtorAllocation.amount;
      }
      return acc;
    }, {});

  // Update allocated amount for each debtor allocation
  return Object.entries(newDebtorAllocationsLookup).reduce((acc, [debtorId, debtorAllocations]) => {
    acc[debtorId] = debtorAllocations.map((debtorAllocation) => ({
      ...debtorAllocation,
      allocatedAmount: allocatedAmountContactLookup[debtorAllocation.contactId] || 0,
    }));
    return acc;
  }, {});
};

module.exports = { updateDebtorAllocationsLookup };
