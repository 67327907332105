import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BankAccountDropDown } from 'web/react-redux/components/bank-account-drop-down';
import { CollapsibleHeader, ToggleListFilter, CurrencyInput2, PanelExpander, useTranslation } from '@sb-itops/react';
import { MatterTypeAccordian } from '../../components/matter-type-accordian';
import { StaffMultiSelect } from '../../components/staff-multi-select';
import { DateListFilter } from '../../components/date-list-filter';
import { FinaliseWithPaymentsTable } from '../../components/finalise-with-payments-table';
import Styles from './BillingBillsFinaliseWithPayments.module.scss';

const MATTER_STATUS_FILTER_OPTIONS = [
  { id: 'Open', label: 'Open' },
  { id: 'Pending', label: 'Pending' },
  { id: 'Closed', label: 'Closed' },
];
const BILLING_TYPE_FILTER_OPTIONS = [
  { id: 'time', label: 'Time Based' },
  { id: 'fixed', label: 'Fixed' },
  { id: 'contingency', label: 'Contingency' },
];

const BillingBillsFinaliseWithPayments = React.memo(
  ({
    trustAccounts,
    bankAccountIds,
    invoices,
    invoicesMap,
    matterTypes,
    filters,
    expanded,
    visible,
    selected,
    payments,
    initialised,
    autoAllocations,
    trustChequesEnabled,
    onFilterMatterStatus,
    onFilterBillingType,
    onResetFilters,
    onFilterOperatingBalance,
    onFilterTrustBalance,
    onFilterCreditBalance,
    onFilterMatterType,
    onFilterIssueDate,
    onStaffChange,
    onChangeChequePrintingMethod,
    onVisibilityChange,
    chequePrintingMethod,
    sort,
    sortBy,
    sortDirection,
    onFilterTrustAccount,
    onToggleMatterExpanded,
    onToggleAllMattersExpanded,
    onToggleInvoiceSelected,
    onToggleMatterSelected,
    onToggleSelectAll,
    onToggleAutoAllocate,
    onToggleAutoAllocateAll,
    onClickLink,
    onFinalise,
    onChangePaymentAmount,
    setInitialised,
    selectedInvoiceIds,
    panelExpanded,
    toggleExpanded,
    selectedMatterTypes,
  }) => {
    const { t } = useTranslation();
    return (
      <div className={classnames(Styles.finaliseWithPaymentsPanel, 'master-detail-panel')}>
        <div className="firm-invoices-side-panel">
          <PanelExpander
            className="panel-filter"
            onResetFilters={onResetFilters}
            expanded={panelExpanded}
            toggleExpanded={toggleExpanded}
          >
            <div className={Styles.trustAccountSection}>
              <div className={Styles.trustAccountTitle}>
                <h3>{t('trustAccount')}</h3>
              </div>
              <div className={Styles.trustAccountSelection}>
                <BankAccountDropDown
                  disabled={!trustAccounts.length}
                  placeholder={`No open ${t('trustAccounts').toLowerCase()}`}
                  onValueChange={(bankAccount) => {
                    onFilterTrustAccount(bankAccount);
                  }}
                  options={trustAccounts}
                  selectedOptionValue={filters.trustBankAccountId}
                />
              </div>
            </div>
            <CollapsibleHeader
              text="Issue Date"
              onClick={() => {
                onVisibilityChange('issueDate', !visible.issueDate);
              }}
              name="issueDate"
              collapsed={!visible.issueDate}
            >
              <DateListFilter
                onChange={onFilterIssueDate}
                selectedFilter={filters.issueDate.filterType}
                before={filters.issueDate.before}
                from={filters.issueDate.from}
                to={filters.issueDate.to}
              />
            </CollapsibleHeader>
            <CollapsibleHeader
              text="Billing Type"
              onClick={() => {
                onVisibilityChange('billingTypes', !visible.billingTypes);
              }}
              name="billingType"
              collapsed={!visible.billingTypes}
            >
              <ToggleListFilter
                showAllLabel="All"
                options={BILLING_TYPE_FILTER_OPTIONS}
                selected={filters.billingTypes.selections}
                onSelect={onFilterBillingType}
              />
            </CollapsibleHeader>
            <CollapsibleHeader
              text="Matter Type"
              onClick={() => {
                onVisibilityChange('matterTypes', !visible.matterTypes);
              }}
              name="matterType"
              collapsed={!visible.matterTypes}
            >
              <MatterTypeAccordian
                matterTypes={matterTypes}
                selectedList={selectedMatterTypes}
                onSelect={onFilterMatterType}
                onlyUsed
              />
            </CollapsibleHeader>
            <CollapsibleHeader
              text="Matter Status"
              onClick={() => {
                onVisibilityChange('matterStatuses', !visible.matterStatuses);
              }}
              name="matterStatus"
              collapsed={!visible.matterStatuses}
            >
              <ToggleListFilter
                showAllLabel="All"
                options={MATTER_STATUS_FILTER_OPTIONS}
                selected={filters.matterStatuses}
                onSelect={onFilterMatterStatus}
              />
            </CollapsibleHeader>
            <div className="attorney-responsible-container">
              <CollapsibleHeader
                text="Attorney Responsible"
                onClick={() => {
                  onVisibilityChange('attorneysResponsible', !visible.attorneysResponsible);
                }}
                name="attorneyResponsible"
                collapsed={!visible.attorneysResponsible}
              >
                <StaffMultiSelect
                  className=""
                  selectedStaffIds={filters.attorneysResponsible || []}
                  onStaffChange={onStaffChange}
                  showUsedStaffOnly
                  showNoneOption
                />
              </CollapsibleHeader>
            </div>
            <CollapsibleHeader
              text="Matter Balances"
              onClick={() => {
                onVisibilityChange('matterBalances', !visible.matterBalances);
              }}
              name="matterBalances"
              collapsed={!visible.matterBalances}
            >
              <div className="debtor-balances">
                <div className="balance-type">
                  <span className="balance-type-label">At least {t('currencySymbol')}</span>
                  <CurrencyInput2
                    className={classnames('balance-value', Styles.balanceValue)}
                    value={filters.minimumTrustBalance}
                    onChange={onFilterTrustBalance}
                    hideDollar
                  />
                  <span className={classnames('balance-type-label', Styles.balanceTypeLabelAvoidConflict)}>
                    in {t('trust')}
                  </span>
                </div>
                <div className="balance-type">
                  <span className="balance-type-label">At least {t('currencySymbol')}</span>
                  <CurrencyInput2
                    className={classnames('balance-value', Styles.balanceValue)}
                    value={filters.minimumOperatingBalance}
                    onChange={onFilterOperatingBalance}
                    hideDollar
                  />
                  <span className={classnames('balance-type-label', Styles.balanceTypeLabelAvoidConflict)}>
                    in {t('operating')}
                  </span>
                </div>
                <div className="balance-type">
                  <span className="balance-type-label">At least {t('currencySymbol')}</span>
                  <CurrencyInput2
                    className={classnames('balance-value', Styles.balanceValue)}
                    value={filters.minimumCreditBalance}
                    onChange={onFilterCreditBalance}
                    hideDollar
                  />
                  <span className={classnames('balance-type-label', Styles.balanceTypeLabelAvoidConflict)}>
                    in Credit
                  </span>
                </div>
              </div>
            </CollapsibleHeader>
          </PanelExpander>
        </div>
        <div className="panel-body">
          {/* the ribbon bar should be here - its been moved to the table component for time reasons. See the `FinaliseWithPaymentsTable` component for an explaination */}
          <FinaliseWithPaymentsTable
            onFinalise={onFinalise}
            onChangeChequePrintingMethod={onChangeChequePrintingMethod}
            selectedInvoiceIds={selectedInvoiceIds}
            trustChequesEnabled={trustChequesEnabled}
            chequePrintingMethod={chequePrintingMethod}
            invoices={invoices}
            invoicesMap={invoicesMap}
            expanded={expanded}
            selected={selected}
            payments={payments}
            autoAllocations={autoAllocations}
            bankAccountIds={bankAccountIds}
            initialised={initialised}
            sort={sort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onToggleMatterExpanded={onToggleMatterExpanded}
            onToggleAllMattersExpanded={onToggleAllMattersExpanded}
            onToggleInvoiceSelected={onToggleInvoiceSelected}
            onToggleMatterSelected={onToggleMatterSelected}
            onToggleSelectAll={onToggleSelectAll}
            onClickLink={onClickLink}
            onChangePaymentAmount={onChangePaymentAmount}
            onToggleAutoAllocate={onToggleAutoAllocate}
            onToggleAutoAllocateAll={onToggleAutoAllocateAll}
            setInitialised={setInitialised}
          />
        </div>
      </div>
    );
  },
);

BillingBillsFinaliseWithPayments.displayName = 'BillingBillsFinaliseWithPayments';

BillingBillsFinaliseWithPayments.propTypes = {
  trustAccounts: PropTypes.array.isRequired,
  bankAccountIds: PropTypes.shape({
    TRUST: PropTypes.string,
    OPERATING: PropTypes.string,
    CREDIT: PropTypes.string,
  }).isRequired,
  invoices: PropTypes.any.isRequired,
  invoicesMap: PropTypes.object.isRequired,
  matterTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  expanded: PropTypes.any.isRequired,
  filters: PropTypes.any.isRequired,
  visible: PropTypes.any.isRequired,
  onFilterTrustAccount: PropTypes.func.isRequired,
  onFilterMatterStatus: PropTypes.func.isRequired,
  onFilterBillingType: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onFilterOperatingBalance: PropTypes.func.isRequired,
  onFilterTrustBalance: PropTypes.func.isRequired,
  onFilterCreditBalance: PropTypes.func.isRequired,
  onFilterMatterType: PropTypes.func.isRequired,
  onFilterIssueDate: PropTypes.func.isRequired,
  onStaffChange: PropTypes.func.isRequired,
  onChangeChequePrintingMethod: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  chequePrintingMethod: PropTypes.any,
  sort: PropTypes.func.isRequired,
  onToggleMatterExpanded: PropTypes.func.isRequired,
  onToggleAllMattersExpanded: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onToggleInvoiceSelected: PropTypes.func.isRequired,
  onToggleMatterSelected: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onChangePaymentAmount: PropTypes.func.isRequired,
  selected: PropTypes.any.isRequired,
  payments: PropTypes.any.isRequired,
  initialised: PropTypes.bool.isRequired,
  autoAllocations: PropTypes.any.isRequired,
  trustChequesEnabled: PropTypes.bool.isRequired,
  onToggleSelectAll: PropTypes.func.isRequired,
  onToggleAutoAllocate: PropTypes.func.isRequired,
  onToggleAutoAllocateAll: PropTypes.func.isRequired,
  onFinalise: PropTypes.func.isRequired,
  setInitialised: PropTypes.func.isRequired,
  selectedInvoiceIds: PropTypes.array.isRequired,
  panelExpanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  selectedMatterTypes: PropTypes.array,
};

BillingBillsFinaliseWithPayments.defaultProps = {
  chequePrintingMethod: undefined,
  selectedMatterTypes: [],
};

export default BillingBillsFinaliseWithPayments;
