'use strict';

const { regionType } = require('@sb-itops/region');

// The values **MUST** be in uppercase
const providers = Object.freeze({
  LAWPAY: 'LAWPAY',
  STRIPE: 'STRIPE',
  EZY_COLLECT: 'EZY_COLLECT',
  FEE_WISE: 'FEE_WISE',
});

const providersByRegion = Object.freeze({
  [regionType.US]: {
    [providers.FEE_WISE]: providers.FEE_WISE,
    [providers.LAWPAY]: providers.LAWPAY,
  },
  [regionType.AU]: {
    [providers.FEE_WISE]: providers.FEE_WISE,
    [providers.STRIPE]: providers.STRIPE,
    [providers.EZY_COLLECT]: providers.EZY_COLLECT,
  },
  [regionType.GB]: {
    [providers.FEE_WISE]: providers.FEE_WISE,
    [providers.STRIPE]: providers.STRIPE,
  },
});

// Can be used to determine things like which provider to show first in a provider activation drop down.
const defaultProviderByRegion = Object.freeze({
  [regionType.US]: providers.FEE_WISE,
  [regionType.AU]: providers.FEE_WISE,
  [regionType.GB]: providers.FEE_WISE,
});

const providerNames = Object.freeze({
  LAWPAY: 'LawPay',
  STRIPE: 'Stripe',
  EZY_COLLECT: 'Smokeball payments', // EZY collect is branded as Smokeball payments to our customers.
  FEE_WISE: 'Smokeball payments', // FeeWise is branded as Smokeball payments to our customers.
});

// Currency in which we charge, required by Stripe API
const chargeCurrencyByRegion = Object.freeze({
  [regionType.US]: 'usd',
  [regionType.AU]: 'aud',
  [regionType.GB]: 'gbp',
});

const transactionTypes = {
  BATCH_MATTER_INVOICE_PAYMENT: 'BATCH_MATTER_INVOICE_PAYMENT',
  BATCH_CLIENT_INVOICE_PAYMENT: 'BATCH_CLIENT_INVOICE_PAYMENT',
  INVOICE_PAYMENT: 'INVOICE_PAYMENT',
  DEPOSIT: 'DEPOSIT',
  SAVE_CARD: 'SAVE_CARD',
  INVOICE_STATEMENT_PAYMENT: 'INVOICE_STATEMENT_PAYMENT',
};

// TODO JZ: this is a bit confusing as it doesn't match the types in transactionTypes
// consider moving it closer to where it's used in charge-handler.js and rename it
const transactionTypeLabel = {
  DEPOSIT: 'deposit',
  PAYMENT: 'invoice payment',
  INVOICE_STATEMENT_PAYMENT: 'client statement payment',
};

const chargeStatus = {
  RECEIVED: 'RECEIVED',
  FAILED: 'FAILED',
  SUCCESSFUL: 'SUCCESSFUL',
};

const feeCoverageModes = {
  CLIENT_PAYS: 'CLIENT_PAYS',
  FIRM_PAYS: 'FIRM_PAYS',
};

const smokeballFailureCodes = {
  ZERO_BALANCE_OWING: 'ZERO_BALANCE_OWING',
  INTERNATIONAL_CARDS_NOT_SUPPORTED: 'INTERNATIONAL_CARDS_NOT_SUPPORTED',
  CARD_AND_ADDRESS_COUNTRY_MISMATCH: 'CARD_AND_ADDRESS_COUNTRY_MISMATCH',
  SERVER_ERROR: 'SERVER_ERROR',
};

const smokeballFailureMessages = {
  ZERO_BALANCE_OWING: 'You currently have no outstanding balances for this matter.',
  INTERNATIONAL_CARDS_NOT_SUPPORTED: 'International cards are not supported. Please use a card based in Australia.',
  CARD_AND_ADDRESS_COUNTRY_MISMATCH: `Please ensure the country you've selected matches the country in which your credit card was issued.`,
  SERVER_ERROR: 'Server error.',
};

const paymentFormTypes = {
  CHARGE: 'CHARGE', // form is used for one off chage
  SAVE_DETAILS: 'SAVE_DETAILS', // form is used to capture details for later
};

// These are meant to be region/provider independent abstraction
// For example eCheck is region specific direct debit
const paymentMethodTypes = {
  CREDIT_CARD: 'CREDIT_CARD', // Card
  DIRECT_DEBIT: 'DIRECT_DEBIT', // eCheck in US
};

// NB
// Even though FeeWise technically supports direct debit, the concept works differently than LawPay.
// With FeeWise, we use hosted form (not hosted fields) so we can chose if we wanna show direct debit in the form during initialisation of form.
// With Lawpay, we must separately link SB account to card and eCheck, but with FeeWise, any linked SB account supports both card and direct debit.
// FeeWise direct debit requires user to link/access their actual bank account so we can use it in payment portal only.
//
// paymentMethodTypesByProvider exists so we can decide if we for example want to add eCheck as an option to selectbox (which we want for LawPay only)
const paymentMethodTypesByProvider = Object.freeze({
  [providers.LAWPAY]: [paymentMethodTypes.CREDIT_CARD, paymentMethodTypes.DIRECT_DEBIT],
  [providers.STRIPE]: [paymentMethodTypes.CREDIT_CARD],
  [providers.EZY_COLLECT]: [paymentMethodTypes.CREDIT_CARD],
  [providers.FEE_WISE]: [paymentMethodTypes.CREDIT_CARD],
});

const refundRequestMethod = {
  EMAIL: 'EMAIL',
  API: 'API',
};

const refundStatus = {
  REQUESTED: 'REQUESTED',
  REQUEST_ERROR: 'REQUEST_ERROR', // Error occurred while sending refund request
  // Some potential status to use when have API/webhook integration for refund requests
  // REVIEWING: 'REVIEWING',
  // ACCEPTED: 'ACCEPTED',
  // REFUNDED: 'REFUNDED',
  // DENIED: 'DENIED',
  // ERROR: 'ERROR', // Generic error status e.g. when error occurred handling a webhook event
};

const tempItemTypes = {
  APPLICATION_FORM: 'APPLICATION_FORM',
};

// This could be moved somewhere else as it is not strictly related to payment provider but it is currently used
// by getPaymentFormConfiguration to optionally return different config based on app (such as hosted fields style)
const applicationType = {
  billingWebApp: 'billingWebApp',
  paymentPortal: 'paymentPortal',
};

module.exports = {
  providers,
  providersByRegion,
  defaultProviderByRegion,
  providerNames,
  transactionTypes,
  transactionTypeLabel,
  chargeStatus,
  feeCoverageModes,
  smokeballFailureCodes,
  smokeballFailureMessages,
  chargeCurrencyByRegion,
  paymentFormTypes,
  paymentMethodTypes,
  paymentMethodTypesByProvider,
  refundRequestMethod,
  refundStatus,
  tempItemTypes,
  applicationType,
};
