angular
  .module('sb.billing.webapp')
  .controller('sbViewMatterBillsController', function (
    sbSaveInvoiceCommand,
    sbAsyncOperationsService,
    sbInvoiceSendService,
    sbLinkService,
    $stateParams,
    $state,
  ) {
    const ctrl = this;

    ctrl.matterId = $stateParams.matterId;
    ctrl.sbAsyncOperationsService = sbAsyncOperationsService;
    ctrl.sbSaveInvoiceCommand = sbSaveInvoiceCommand;
    ctrl.sbInvoiceSendService = sbInvoiceSendService;
    ctrl.isDownloadLedesInProgress = isDownloadLedesInProgress;
    ctrl.onNavigateTo = onNavigateTo;
    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.isCombineInProgress = isCombineInProgress;

    function onNavigateTo(route, params) {
      $state.go(route, params);
    }

    function isCombineInProgress() {
      return (
        sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.COMBINE_INVOICES) > 0
      );
    }

    function isDownloadLedesInProgress() {
      return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.DOWNLOAD_LEDES) > 0;
    }
  });
