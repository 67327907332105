import { getPaymentsByInvoiceId } from '@sb-billing/redux/payments';
import { getInvoiceDebtorTotalsByInvoiceId } from '@sb-billing/redux/invoice-debtor-totals';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';

angular.module('sb.billing.webapp').component('sbDataPaymentsByInvoiceAndDebtorId',
{
  'require': {
    composer: '^sbCompose'
  },
  'bindings': {invoiceId: '<', debtorId: '<', entityDataKey: '@'},
  'controller': function ($scope, sbLoggerService, sbContactsMbService, sbPaymentService) {
    'use strict';
    var ctrl = this,
      log = sbLoggerService.getLogger('sbDataPaymentsByInvoiceAndDebtorId'),
      entityDataKey =  ctrl.entityDataKey  || 'splitBillingInvoicePayments';

    ctrl.$onInit = function () {
      log.info('invoiceId', ctrl.invoiceId);
      log.info('debtorId', ctrl.debtorId);
      log.info('entityDataKey', ctrl.entityDataKey);
      updatePayments();
    };

    $scope.$on('smokeball-data-update-sbPaymentService', function () {
      log.info('saw data update');
      updatePayments();
    });
    $scope.$on('smokeball-data-update-sbInvoicingService', function () {
      log.info('saw data update');
      updatePayments();
    });


    function updatePayments() {
      const payments = getPaymentsByInvoiceId(ctrl.invoiceId);
      const filteredPayments = payments.filter(p => p.invoices.some(invoice => invoice.debtorId === ctrl.debtorId) && p.reversedAt === null)
      const sortedPayments = _.sortByOrder(filteredPayments, ['effectiveDate', 'timestamp'], ['asc', 'asc']);
      const invoiceDebtorTotals = getInvoiceDebtorTotalsByInvoiceId(ctrl.invoiceId);
      const debtorTotals = (invoiceDebtorTotals || []).find((t) => t.debtorId === ctrl.debtorId);

      let balance = debtorTotals && debtorTotals.total || 0;
      sortedPayments.forEach(payment => {
        (payment.invoices || []).forEach(invoice => {
          const fullInvoice = getInvoiceById(invoice.invoiceId);
          const currentVersion = fullInvoice && fullInvoice.currentVersion;
          invoice.invoiceNumber = currentVersion && currentVersion.invoiceNumber;
        })
        const debtorInvoice = (payment.invoices || []).find(invoice => invoice.debtorId === ctrl.debtorId);
        payment.amount = debtorInvoice.amount;
        payment.payor = sbContactsMbService.getById(payment.payorId);
        payment.source = sbPaymentService.decodePaymentSource(payment);
        balance = computeRunningBalances(debtorInvoice.amount, balance);
        payment.balance = balance;
      })
      log.info('payments for invoice %s', ctrl.invoiceId,  ctrl.debtorId, sortedPayments);
      ctrl.composer.setComposeData({ dataType: entityDataKey, data: sortedPayments}, entityDataKey);
    }


    function computeRunningBalances(amount, remainingBalance) {
      if (remainingBalance === 0) {
        return 0;
      } else {
        return remainingBalance - amount;
      }
    }

  }
}
);
