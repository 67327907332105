import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';

import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import composeHooks from '@sb-itops/react-hooks-compose';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { useSubscribedQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { InvoicePreviewData } from 'web/graphql/queries';

import { InvoicePreviewModal } from './InvoicePreviewModal';

const log = getLogger('InvoicePreviewModal.container');

// This component should be used for previewing finalised invoices for both split billing and non-split billing, but currently only implemented functions for split billing
// Non-split billing invoice preview is still using legacy angular modal (on ledger list pages, e.g firm-ledger.html)
const hooks = () => ({
  useInvoicePreviewModalDataMap: ({ invoiceId }) => {
    const { data, loading } = useSubscribedQuery(InvoicePreviewData, {
      variables: {
        invoiceId,
      },
    });

    const invoice = useMemo(() => data?.invoice || {}, [data]);

    const [isDownloadingAll, setIsDownloadingAll] = useState(false);
    const [result, setResult] = useState(null);

    const invoiceVersionId = data?.invoice.isOriginalInvoice
      ? data?.invoice.invoicePdfVersionId
      : data?.invoice.versionId;

    // Split Billing
    const invoiceDebtors = useMemo(() => data?.invoice.debtors || [], [data?.invoice]);
    const debtorOptions = useMemo(
      () =>
        invoiceDebtors.map(({ id, contact }) => ({
          label: contact.displayNameFull || 'Not found',
          value: id,
        })) || [],
      [invoiceDebtors],
    );

    const [selectedDebtorOption, setSelectedDebtorOption] = useState(debtorOptions[0] || {});

    useEffect(() => {
      setSelectedDebtorOption(debtorOptions[0] || {});
    }, [debtorOptions]);

    useEffect(() => {
      async function fetchInvoicePdfBlob() {
        if (invoiceVersionId && selectedDebtorOption && selectedDebtorOption.value) {
          const { value: debtorId } = selectedDebtorOption;

          try {
            const response = await fetchGetP({
              path: `/billing/invoice-pdf/:accountId/${invoiceVersionId}/debtor/${debtorId}/presigned-url/`,
            });
            const preSignedUrl = response.body?.preSignedUrl;
            const pdfFileResponse = await fetch(preSignedUrl);
            const fileBlob = await pdfFileResponse.blob();
            const pdfBlob = new Blob([fileBlob], { type: 'application/pdf' }); // without this line the PDF will be downloaded instead of showing a preview
            setResult(pdfBlob);
          } catch (error) {
            log.error(
              `Error occurred while downloading invoice PDF blob for invoice: ${invoice.id}, debtor ${debtorId}`,
              error,
            );
          }
        }
      }
      setResult(null);
      fetchInvoicePdfBlob();
    }, [invoice.id, invoiceVersionId, selectedDebtorOption]);

    const onDownloadAll = async () => {
      try {
        setIsDownloadingAll(true);
        await Promise.all(
          invoiceDebtors.map(async ({ id: debtorId, invoiceNumberSuffix }) => {
            try {
              const response = await fetchGetP({
                path: `/billing/invoice-pdf/:accountId/${invoiceVersionId}/debtor/${debtorId}/presigned-url`,
              });
              const preSignedUrl = response.body?.preSignedUrl;
              if (!preSignedUrl) {
                throw new Error('Pre-signed URL not found');
              }
              const pdfFileResponse = await fetch(preSignedUrl);
              const fileBlob = await pdfFileResponse.blob();
              FileSaver.saveAs(
                fileBlob,
                `invoice-${invoice.invoiceNumber || 'draft'}-${invoiceNumberSuffix || ''}.pdf`,
              );
            } catch (error) {
              log.error(
                `Error occurred while downloading invoice PDF blob for invoice: ${invoice.id}, debtor ${debtorId}`,
                error,
              );
            }
          }),
        );
      } catch (error) {
        displayErrorToUser('Error occurred while generating invoice PDF preview');
      } finally {
        setIsDownloadingAll(false);
      }
    };

    return {
      isLoading: loading,
      pdfBlob: result,
      // isSplitBillingInvoice: invoice.splitBillingSettings?.isEnabled, // TODO: to be utilised when non-split billing invoice preview is implemented
      // Split Billing
      debtorOptions,
      selectedDebtorOption,
      isDownloadingAll,
      setSelectedDebtorOption,
      onDownloadAll,
    };
  },
});

export const InvoicePreviewModalContainer = withApolloClient(composeHooks(hooks)(InvoicePreviewModal));

InvoicePreviewModalContainer.displayName = 'InvoicePreviewModalContainer';

InvoicePreviewModalContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

InvoicePreviewModalContainer.defaultProps = {};
