import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { LinkableText } from '@sb-itops/react/linkable-text';

import {
  operationTypeMap,
  operationTypes,
  sentViaMap,
  sentViaTypes,
} from '@sb-billing/business-logic/correspondence-history';
import { useTranslation } from '@sb-itops/react';

import Styles from './CorrespondenceHistoryTable.module.scss';

const { timestampLocalisedRenderer } = utils;

const CorrespondenceHistoryTable = ({
  correspondenceHistory,
  limitedTo90Days,
  onSort,
  sortBy,
  sortDirection,
  onClickRow,
  onClickLink,
  dataLoading,
}) => {
  const { t } = useTranslation();

  const operationTypeCellRenderer = ({ rowData }) => {
    if (!rowData?.operationType) {
      return undefined;
    }

    if (rowData.operationType === operationTypes.INVOICE_STATEMENT) {
      return t('clientStatement');
    }

    const operationTypeLabel = operationTypeMap[rowData.operationType];
    return operationTypeLabel;
  };

  const sentMethodCellRenderer = ({ rowData }) => {
    if (!Number.isInteger(rowData?.sentVia)) {
      return undefined;
    }
    const sentViaLabel = sentViaMap[rowData?.sentVia || sentViaTypes.EMAIL]; // default to email
    return sentViaLabel;
  };

  const referenceCellRenderer = ({ rowData }) => {
    if (!rowData?.invoiceRelatedItems?.length) {
      return undefined;
    }

    return rowData?.invoiceRelatedItems
      .sort((a, b) => a.invoiceNumber - b.invoiceNumber)
      .map((item, index) => (
        <React.Fragment key={index}>
          <LinkableText
            text={`#${item.invoiceEntity.invoiceNumber}`}
            onClickLink={() => onClickLink({ type: 'invoice', id: item.invoiceEntity.id })}
            asLink
            inline
          />
          {rowData?.invoiceRelatedItems.length > 1 && index < rowData.invoiceRelatedItems.length - 1 ? ', ' : ''}
        </React.Fragment>
      ));
  };

  const debtorCellRenderer = ({ rowData }) => {
    if (!rowData?.contactRelatedItems?.length) {
      return undefined;
    }

    return rowData?.contactRelatedItems.map((item, index) => (
      <React.Fragment key={index}>
        <LinkableText
          text={item?.contactEntity?.displayName} // Check showLastNameFirst?
          onClickLink={() => onClickLink({ type: 'contact', id: item.contactEntity.id })}
          asLink
          inline
        />
        {rowData?.contactRelatedItems.length > 1 && index < rowData.contactRelatedItems.length - 1 ? ', ' : ''}
      </React.Fragment>
    ));
  };

  return (
    <Table
      onRowClick={({ rowData }) => onClickRow({ correspondenceHistoryId: rowData.id })}
      list={correspondenceHistory}
      sort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      dataLoading={dataLoading}
    >
      <Column
        dataKey="sentTimestamp"
        label={`Date${limitedTo90Days ? ' (Last 90 Days)' : ''}`}
        flexGrow={1}
        cellRenderer={timestampLocalisedRenderer}
      />
      <Column dataKey="operationType" label="Type" flexGrow={1.2} cellRenderer={operationTypeCellRenderer} />
      <Column dataKey="invoiceRelatedItems" label="Reference" flexGrow={2} cellRenderer={referenceCellRenderer} />
      <Column dataKey="sentVia" label="Method" flexGrow={1} cellRenderer={sentMethodCellRenderer} />
      <Column dataKey="to" label="To" flexGrow={3} className={Styles.emailDisplay} />
      <Column dataKey="contactRelatedItems" label="Debtor" flexGrow={2} cellRenderer={debtorCellRenderer} />
      <Column dataKey="user" label="Sent By" flexGrow={2} cellDataGetter={userNameGetter} />
    </Table>
  );
};

function userNameGetter({ rowData }) {
  const staffMemberName = rowData.user?.person?.name ?? '';
  return staffMemberName;
}

CorrespondenceHistoryTable.displayName = 'CorrespondenceHistoryTable';

CorrespondenceHistoryTable.propTypes = {
  correspondenceHistory: PropTypes.array,
  limitedTo90Days: PropTypes.bool,
  onClickLink: PropTypes.func.isRequired,
  onClickRow: PropTypes.func,
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  dataLoading: PropTypes.bool,
};

CorrespondenceHistoryTable.defaultProps = {
  correspondenceHistory: [],
  limitedTo90Days: false,
  onClickRow: () => {},
  onSort: () => {},
  sortBy: 'date', // TODO: sortby
  sortDirection: 'desc',
  dataLoading: true,
};

export default CorrespondenceHistoryTable;
