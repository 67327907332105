import { gql } from '@apollo/client';

const query = gql`
  query BulkTrustToOfficeInvoices(
    $invoiceListFilter: InvoiceListFilter
    $paymentPlanStatusAsOfDate: Int
    $offset: Int
    $limit: Int
    $sort: ListSort
  ) {
    invoiceList(
      filter: $invoiceListFilter
      paymentPlanStatusAsOfDate: $paymentPlanStatusAsOfDate
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      results {
        id
        invoiceNumber
        matter {
          id
          matterNumber
          clientDisplay
          otherSideDisplay
          billingConfiguration {
            splitBillingSettings {
              isEnabled
            }
          }
          matterType {
            matterTypeId
            name
          }
        }
        debtors {
          id
          contact {
            displayName
          }
        }
        issuedDate
        dueDate
        splitBillingSettings {
          isEnabled
        }
        validFrom
        totals {
          unpaid
        }
        listItemProperties {
          activePaymentPlanDetails {
            id
            debtorId
            status
            autoCharge
            nextPaymentDate
            installmentFrequency
            installmentAmount
            paymentPlanPosition {
              status
              paymentMethod {
                status
                statusDisplay
              }
            }
          }
          hasUnpaidAnticipatedDisbursements
        }
      }
    }
  }
`;

const notificationIds = [
  'WebQueryDebtorsNotifications.PaymentPlanUpdated',
  'WebQueryAccountsNotifications.AccountBalanceUpdated',
  'WebQueryInvoicingNotifications.InvoiceUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const BulkTrustToOfficeInvoices = {
  query,
  notificationIds,
};
