import { useEffect } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { todayAsInteger } from '@sb-itops/date';
import * as messageDisplay from '@sb-itops/message-display';
import uuid from '@sb-itops/uuid';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import {
  externallySentViaMap,
  operationTypes,
  relatedItemType,
} from '@sb-billing/business-logic/correspondence-history';
import { markAsSentFormSchema } from './MarkAsSentForm.yup';
import { MarkAsSentModal } from './MarkAsSentModal';

const hooks = () => ({
  useForm: ({
    externalSentViaOptions,
    invoiceContactIds,
    invoiceIdToMatterIdMap,
    isModalDataLoading,
    matterCorrespondencePreferences,
    operationType,
    selectedItems,
    scope,
    // callbacks
    onModalClose,
  }) => {
    const markAsSentForm = useForm({
      scope,
      schema: markAsSentFormSchema,
    });
    const {
      formFields,
      formValues,
      formInitialised,
      formValid,
      formSubmitting,
      onInitialiseForm,
      onClearForm,
      onValidateForm,
      onSubmitFormWithValidation,
    } = markAsSentForm;

    const isSubmitDisabled = !formInitialised || formSubmitting || !formValid || isModalDataLoading;

    const { sentVia } = formValues;
    const isSendViaNotMatchMatterPreference =
      matterCorrespondencePreferences.length > 0 && !matterCorrespondencePreferences.includes(sentVia);

    const onFieldValueUpdated = (fieldValues) => {
      markAsSentForm.onUpdateFields(fieldValues);
      onValidateForm();
    };

    const getDefaultFormValues = () => {
      const defaultSentVia =
        matterCorrespondencePreferences.find((preference) => !!externallySentViaMap[preference]) || '';
      const todayAsInt = todayAsInteger();

      return {
        dateSent: todayAsInt,
        sentVia: defaultSentVia,
        internalNote: '',
      };
    };

    const isReadyToInitialiseForm = !formInitialised && !isModalDataLoading;

    if (isReadyToInitialiseForm) {
      const defaultValues = getDefaultFormValues();
      onInitialiseForm(defaultValues);
      onValidateForm();
    }

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
      return () => {
        onClearForm();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onMark = async () => {
      try {
        await onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            // Covert the field selected dateSent to UTC.
            const utcDate = moment(formData.dateSent.toString())
              .set({
                hour: moment().hour(),
                minute: moment().minute(),
                second: moment().second(),
              })
              .utc();
            const sentTimestamp = utcDate.toISOString();

            if (operationType === operationTypes.INVOICE_REMINDER) {
              const promises = selectedItems.map(({ matterId, debtorId, invoices }) => {
                const { relatedIds, relatedItems } = invoices.reduce(
                  (acc, invoice) => {
                    acc.relatedIds.push(invoice.invoiceId);
                    acc.relatedItems.push({
                      id: invoice.invoiceId,
                      type: relatedItemType.INVOICE,
                    });
                    return acc;
                  },
                  { relatedIds: [], relatedItems: [] },
                );

                relatedItems.push(
                  {
                    id: matterId,
                    type: relatedItemType.MATTER,
                  },
                  {
                    id: debtorId,
                    type: relatedItemType.CONTACT,
                  },
                );

                const markAsSentMessage = {
                  sentTimestamp,
                  operationType,
                  correspondenceId: uuid(),
                  sentVia: formData.sentVia,
                  internalNote: formData.internalNote,
                  matterId,
                  contactIds: [debtorId],
                  relatedIds,
                  relatedItems,
                };
                return dispatchCommand({
                  type: 'Billing.Shared.Messages.Commands.NotifyCorrespondenceSucceeded',
                  message: markAsSentMessage,
                });
              });
              await Promise.all(promises);
            } else {
              // currently only operationTypes.INVOICE falls to this block, and selectedItems are invoiceIds when operationType is INVOICE
              const { relatedItems } = selectedItems.reduce(
                (acc, invoiceId) => {
                  // 1. Invoice CHRIs
                  //  * All invoices are submitted as CHRIs
                  acc.relatedItems.push({
                    id: invoiceId,
                    type: relatedItemType.INVOICE,
                  });

                  // 2. Matter CHRIs
                  //  * Unique matters are passed as CHRIs
                  const matterId = invoiceIdToMatterIdMap[invoiceId];
                  if (!acc.relatedItemsMatterIdMap[matterId]) {
                    acc.relatedItems.push({
                      id: matterId,
                      type: relatedItemType.MATTER,
                    });
                    acc.relatedItemsMatterIdMap[matterId] = true;
                  }

                  return acc;
                },
                {
                  relatedItems: [],
                  relatedItemsMatterIdMap: {},
                },
              );

              const markAsSentMessage = {
                sentTimestamp,
                operationType,
                correspondenceId: uuid(),
                sentVia: formData.sentVia,
                internalNote: formData.internalNote,
                contactIds: invoiceContactIds,
                relatedIds: selectedItems, // invoiceIds
                relatedItems, // invoice & matter CHRIs
              };

              await dispatchCommand({
                type: 'Billing.Shared.Messages.Commands.NotifyCorrespondenceSucceeded',
                message: markAsSentMessage,
              });
            }

            onModalClose();
          },
        });
      } catch (err) {
        messageDisplay.error('Failed to mark as sent');
      }
    };

    return {
      // form
      formData: formValues,
      formErrors: formFields, // Contains data related to form errors
      isModalLoading: !formInitialised || isModalDataLoading,
      isSubmitDisabled,
      // other
      externalSentViaOptions,
      isSendViaNotMatchMatterPreference,
      // func & callbacks
      onFieldValueUpdated,
      onMark,
    };
  },
});

export const MarkAsSentModalFormsContainer = composeHooks(hooks)(MarkAsSentModal);

MarkAsSentModalFormsContainer.propTypes = {
  externalSentViaOptions: PropTypes.array.isRequired,
  invoiceContactIds: PropTypes.array,
  invoiceIdToMatterIdMap: PropTypes.object,
  isModalDataLoading: PropTypes.bool.isRequired,
  matterCorrespondencePreferences: PropTypes.array.isRequired,
  operationType: PropTypes.oneOf(Object.values(operationTypes)),
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        matterId: PropTypes.string,
        debtorId: PropTypes.string,
        invoices: PropTypes.arrayOf(
          PropTypes.shape({
            invoiceId: PropTypes.string,
          }),
        ),
      }),
    ]),
  ).isRequired,
  scope: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
};
MarkAsSentModalFormsContainer.defaultProps = {
  invoiceContactIds: [],
};

MarkAsSentModalFormsContainer.displayName = 'MarkAsSentModalFormsContainer';
