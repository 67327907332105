import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { getProductTier, getIsLogoutInProgress, getAccountId } from 'web/services/user-session-management';
import { getSupportHubUrl } from '@sb-itops/environment-config';
import { featureActive, featureData } from '@sb-itops/feature';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import * as yup from 'yup';
import * as messageDisplay from '@sb-itops/message-display';
import { createMatter } from '@sb-matter-management/redux/matters';
import {
  InitStaffSettings,
  InitFirmUtbmsSettings,
  FirmDetails,
  InitFirmFeeConfiguration,
  MatterTypeConfiguration,
} from 'web/graphql/queries';
import { useCacheQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withOnLoad } from '@sb-itops/react';
import { getRegion, getStateIdFromName, states } from '@sb-itops/region';
import { setFeeInterval } from '@sb-billing/redux/fee-configuration/set-fee-interval';
import { sendMetric } from 'web/services/metrics';
import { AddressSchema } from '@sb-customer-management/validation';
import { getLogger } from '@sb-itops/fe-logger';
import { facets, hasFacet } from '@sb-itops/region-facets';
import { saveStaffHourlyRate } from '@sb-billing/redux/staff-hourly-rate/save-staff-hourly-rate';
import { firmManagement } from '@sb-firm-management/redux';
import { firmFeeConfigurationDefaults } from '@sb-billing/business-logic/shared/entities';
import { getAllowedIntervals } from '@sb-billing/redux/fee-configuration';
import { findClientRoleOptions } from '@sb-matter-listing/business-logic/matter-type-configurations/services';
import { getFirmNameAndAddress } from '@sb-firm-management/business-logic/firm-details/services';
import { getFirmDefault, saveTemplate } from '@sb-billing/redux/invoice-settings-template';
import { useQuery } from '@apollo/client';
import { getApolloClient } from 'web/services/apollo';
import { update } from '@intercom/messenger-js-sdk';
import WelcomeModal from './WelcomeModal';

const { editFirm, getFirmDetails } = firmManagement;
const REGION = getRegion();
const WELCOME_MODAL_ID = 'welcomeModal';
const log = getLogger('WelcomeModal');

const OnboardingSchema = yup.object().shape({
  firstName: yup.string().nullable(),
  lastName: yup
    .string()
    .nullable()
    .when('firstName', {
      is: (firstName) => !!firstName,
      then: (lastName) => lastName.required(),
      otherwise: (lastName) => lastName.optional(),
    }),
  email: yup.string().email().nullable(),
  businessAddress: AddressSchema(REGION),
  selectedBillingUnit: yup.string(),
  customUnit: yup
    .number()
    .nullable()
    .when('selectedBillingUnit', {
      is: (selectedBillingUnit) => selectedBillingUnit === 'Custom',
      then: yup.number().integer().min(1).max(60).required(),
      otherwise: yup.number().nullable().notRequired(),
    }),
});

const pluraliseMinutes = (mins) => `minute${mins === 1 ? '' : 's'}`;
const customBillingUnitOption = { value: 'Custom', label: 'Custom' };

const getBillingUnitOptions = (allowedIntervals) => {
  const customBillingUnitsEnabled = featureActive('BB-10195');
  const billingUnitOptions = allowedIntervals.map((mins) => {
    const option = { value: mins, label: `${mins} ${pluraliseMinutes(mins)}` };
    return option;
  });

  // Show Custom as the last in the select box list when feature enabled
  if (customBillingUnitsEnabled) {
    billingUnitOptions.push(customBillingUnitOption);
  }

  return billingUnitOptions;
};

const useHooks = ({ viewedMessages, onClickLink }) => {
  const [isSaving, setIsSaving] = useState(false);

  return {
    useOnboardingModalData: () => {
      const { data: staffData } = useCacheQuery(InitStaffSettings.query);
      const {
        formValues,
        onValidateForm,
        onSubmitFormWithValidation,
        onInitialiseForm,
        onUpdateFields,
        onClearForm,
        formFields,
        formInitialised,
        formValid,
        formSubmitting,
      } = useForm({
        scope: WELCOME_MODAL_ID,
        schema: OnboardingSchema,
      });
      const allowedIntervals = getAllowedIntervals();

      const { data: firmUtbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query);
      const { data: firmFeeConfigData } = useCacheQuery(InitFirmFeeConfiguration.query);
      const {
        data: matterTypeConfig,
        loading,
        error,
      } = useQuery(MatterTypeConfiguration.query, {
        skip: !formFields.originalMatterTypeId?.value,
        variables: {
          matterTypeId: formFields.originalMatterTypeId?.value,
        },
      });

      if (error) {
        throw new Error(error);
      }

      const isUtbmsFacetActive = hasFacet(facets.utbms);

      const isUtbmsEnabledForFirm = isUtbmsFacetActive && firmUtbmsSettingsData?.firmUtbmsSettings?.isUtbmsEnabled;

      useEffect(() => {
        (async () => {
          if (staffData?.loggedInStaff?.id && !formInitialised) {
            const apolloClient = getApolloClient();
            const result = await apolloClient
              .query({
                query: FirmDetails.query,
                fetchPolicy: 'network-only',
              })
              // Ignore any errors and continue
              .catch(() => {});
            const details = result?.data?.firm || {};
            const mergedStreetAddress = details.businessAddress || {};
            const currentBillingUnit =
              firmFeeConfigData?.firmFeeConfiguration?.billableMinutes || firmFeeConfigurationDefaults.BILLABLE_MINUTES;
            const isBillingUnitCustomised = !allowedIntervals.includes(currentBillingUnit);
            onInitialiseForm({
              // Billing units
              selectedBillingUnit: isBillingUnitCustomised ? 'Custom' : currentBillingUnit,
              customUnit: isBillingUnitCustomised ? currentBillingUnit : null,

              // Firm details
              mailingAddress: details.mailingAddress || null,
              businessAddress: {
                buildingLevel: mergedStreetAddress?.buildingLevel || '',
                unitNumber: mergedStreetAddress?.unitNumber || '',
                unitType: mergedStreetAddress?.unitType || '',
                streetNumber: mergedStreetAddress?.streetNumber || '',
                streetName: mergedStreetAddress?.streetName || '',
                streetType: mergedStreetAddress?.streetType || '',
                addressLine1: mergedStreetAddress?.addressLine1 || '',
                addressLine2: mergedStreetAddress?.addressLine2 || '',
                city: mergedStreetAddress?.city || mergedStreetAddress.suburbTown || '',
                state: mergedStreetAddress?.state || states[REGION][0].value,
                zipCode: mergedStreetAddress?.zipCode || mergedStreetAddress.postcode || '',
                county: mergedStreetAddress?.county || '',
                locality: mergedStreetAddress?.locality || '',
                country: mergedStreetAddress?.country || '',
              },
              firmName: details.firmName || '',
              acn: details.acn || '',
              abn: details.abn || '',
              phoneAreaCode: details.phoneAreaCode || '',
              faxAreaCode: details.faxAreaCode || '',
              phoneNumber: details.phoneNumber || '',
              faxNumber: details.faxNumber || '',

              // client details
              firstName: '',
              lastName: '',
              email: '',

              // Matter details
              categoryId: '',
              locationId: getStateIdFromName(mergedStreetAddress?.state, REGION) || mergedStreetAddress?.state || '',
              originalMatterTypeId: '',
              matterTypeId: '',
              clientRole: '',
              hourlyRate: staffData?.loggedInStaff?.rate || 0,
            });
            if (featureActive('NUCWEB-914') && window?.intercomSettings) {
              window.intercomSettings['Onboarding Wizard Status'] = 'Started';
              update(window.intercomSettings);
            }
            onValidateForm();
          }
        })();
        return () => {
          onClearForm();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [staffData?.loggedInStaff?.id]);

      const [activeStep, setActiveStepPre] = useState(1);
      const setActiveStep = (step) => {
        if (step === 4) {
          if (formFields.firstName?.value) {
            sendMetric('Onboarding Modal', { step: '4 - matter' });
          } else {
            sendMetric('Onboarding Modal', { step: '4 - welcome' });
          }
        } else {
          sendMetric('Onboarding Modal', { step });
        }
        setActiveStepPre(step);
      };

      const clientRoleOptions =
        formFields.originalMatterTypeId && !loading
          ? findClientRoleOptions(matterTypeConfig?.matterTypeConfiguration).list
          : [];

      const onSave = async (event, skipCreateMatter) => {
        event.preventDefault();
        onValidateForm();
        try {
          if (formSubmitting) {
            return;
          }
          await onSubmitFormWithValidation({
            submitFnP: async (validFormFields) => {
              sendMetric('Onboarding Modal', { step: 'done' });
              const accountId = getAccountId();

              await editFirm({
                name: validFormFields.firmName,
                acn: validFormFields.acn,
                abn: validFormFields.abn,
                phoneAreaCode: validFormFields.phoneAreaCode,
                faxAreaCode: validFormFields.faxAreaCode,
                phoneNumber: validFormFields.phoneNumber,
                faxNumber: validFormFields.faxNumber,
                businessAddress: validFormFields.businessAddress,
                mailingAddress: validFormFields.mailingAddress || {},
              });

              const template = getFirmDefault();
              if (!template?.settings?.letterhead) {
                const firmDetails = getFirmDetails();
                const letterhead = getFirmNameAndAddress({ firmDetails, divSeparated: true });
                const formattedLetterhead = letterhead.addressLines ? `<p>${letterhead.addressLines}</p>` : '';
                const newTemplate = {
                  id: template.id,
                  name: template && template.name,
                  isDefault: template && template.isDefault,
                  createNew: false,
                  settings: {
                    ...template.settings,
                    letterhead: formattedLetterhead,
                  },
                };

                await saveTemplate(newTemplate);
              }

              await saveStaffHourlyRate({
                staffId: staffData?.loggedInStaff?.id,
                rate: validFormFields.hourlyRate || 0,
              });

              const feeInterval =
                validFormFields.selectedBillingUnit === 'Custom'
                  ? validFormFields.customUnit
                  : validFormFields.selectedBillingUnit;
              await setFeeInterval(feeInterval);

              let contactDispatch;
              if (validFormFields.firstName) {
                contactDispatch = await dispatchCommand({
                  type: 'Integration.CreatePersonContact',
                  message: {
                    isUtbmsEnabled: isUtbmsEnabledForFirm,
                    contactFields: {
                      contactType: 'person',
                      personFields: {
                        firstName: validFormFields.firstName,
                        lastName: validFormFields.lastName,
                        email: validFormFields.email,
                      },
                    },
                  },
                });
              }

              let matterId;
              if (validFormFields.matterTypeId && contactDispatch?.contact?.id && !skipCreateMatter) {
                const clientRole =
                  clientRoleOptions.length === 1 ? clientRoleOptions[0].value : validFormFields.clientRole;
                matterId = await createMatter({
                  accountId,
                  matterTypeId: validFormFields.matterTypeId,
                  clientRole,
                  clientIds: [contactDispatch.contact.id],
                  status: 'Open',
                  description: ' ',
                  attorneyResponsibleId: staffData?.loggedInStaff?.id,
                  matterNumber: `${moment().format('YYYY-MM-DD')}-0001`,
                });
              }

              if (featureActive('NUCWEB-914') && window?.intercomSettings) {
                window.intercomSettings['Onboarding Wizard Status'] = 'Completed';
                update(window.intercomSettings);
              }

              if (!viewedMessages.includes(WELCOME_MODAL_ID)) {
                await dispatchCommand({
                  type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
                  message: { viewedMessage: WELCOME_MODAL_ID },
                });
              }

              if (matterId) {
                onClickLink({ type: 'matter', id: matterId });
              }
            },
          });
          setIsSaving(true);
        } catch (err) {
          messageDisplay.error(
            `Failed to create onboarding information. Please try adding a matter via the 'Create' button`,
          );
          log.error(err);
        }
      };

      return {
        loading,
        billingUnitOptions: getBillingUnitOptions(allowedIntervals),
        formValid,
        formInitialised,
        formSubmitting,
        activeStep,
        setActiveStep,
        formValues,
        formFields,
        clientRoleOptions,
        onSave,
        onFieldValueUpdated: (updatedFieldValues) => {
          onUpdateFields(updatedFieldValues);
          onValidateForm();
        },
      };
    },
    useWelcomeModalData: () => {
      const tier = getProductTier();

      const helpLink = (featureActive('BB-8892') && featureData('BB-8892')[tier || 'SMK001']) || getSupportHubUrl();

      // Determine if the welcome dialog should be shown to this user.
      const openDialog = () => {
        const logoutInProgress = getIsLogoutInProgress();
        // Should we hide it or show it if there is an error? I'm going with show it. If you want to hide on error then place the error object in the props list and add: && !error;
        const welcomeViewed = viewedMessages.includes(WELCOME_MODAL_ID);

        const shouldOpen = !welcomeViewed && !logoutInProgress && !isSaving;
        return shouldOpen;
      };

      // Take action when the user closes the welcome dialog.
      const closeDialog = async () => {
        setIsSaving(true);

        if (featureActive('NUCWEB-914') && window?.intercomSettings) {
          window.intercomSettings['Onboarding Wizard Status'] = 'Completed';
          update(window.intercomSettings);
        }

        dispatchCommand({
          type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
          message: { viewedMessage: WELCOME_MODAL_ID },
        });
        // We don't set the local state back to false after command is successful purposefully because
        // there is a delay between when we dispatch command and when BillingUserAttributtes are saved.
        // For new accounts, what happens is the opdate was overwritten with sync with empty array first
        // and only after then synced with correct value later.
        // This caused the modal was sometimes re-displayed before it hid again.
      };

      return {
        open: openDialog(),
        onClose: closeDialog,
        helpLink,
      };
    },
  };
};

const WelcomeModalContainer = withApolloClient(withReduxProvider(composeHooks(useHooks)(withOnLoad(WelcomeModal))));

WelcomeModalContainer.displayName = 'WelcomeModal';
WelcomeModalContainer.propTypes = {
  viewedMessages: propTypes.array.isRequired,
  onClickLink: propTypes.func.isRequired,
};
WelcomeModalContainer.defaultProps = {};

export default WelcomeModalContainer;
