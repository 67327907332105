import { gql } from '@apollo/client';

const query = gql`
  query ProductivityFirmActivities($filter: ActivityFilter, $matterFilter: MatterFilter, $offset: Int, $limit: Int) {
    activityList(filter: $filter, offset: $offset, limit: $limit) {
      results {
        id
        user {
          id
          person {
            id
            initials
            name
          }
        }
        matter(filter: $matterFilter) {
          id
          matterNumber
          status
          clientDisplayName @client
          clients {
            id
            displayName
          }
          description
          otherSide {
            id
            displayName
          }
          attorneyResponsible {
            id
            initials
          }
          matterType {
            matterTypeId
            name
          }
          isLead
        }
        isRemoved
        activityType
        activityData
        description
        dateTime
        billingFeeId
        durationInMinutes
      }
    }
  }
`;

const notificationIds = ['IGNORED'];
// const notificationIds = ['ItOps.Timeline']; Page now manually refreshed using a button.
// If we hook into a notification then the user will see the page load due to notifyOnNetworkStatusChange: true on the query which is undesirable

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const ProductivityFirmActivities = {
  query,
  notificationIds,
};
