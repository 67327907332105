import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { INVOICE_PREVIEW_MODAL_ID } from 'web/components';

angular.module('sb.billing.webapp').component('sbFirmLedger', {
  bindings: { firmLedgerList: '<' },
  templateUrl: 'ng-components/firm-ledger/firm-ledger.html',
  controller: function (sbLinkService, sbUnsavedChangesService) {
    const ctrl = this;
    const stateKey = 'firm-ledger-table';

    ctrl.showInvoice = showInvoice;
    ctrl.closeCreditPaymentNModal = closeCreditPaymentNModal;
    ctrl.onRowClick = onRowClick;
    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.onSort = onSort;

    ctrl.model = {
      firmLedger: undefined,
    };

    ctrl.tableState = {
      sortDirection: undefined,
      sortBy: undefined,
    };

    ctrl.$onInit = () => {
      ctrl.model.firmLedger = ctrl.firmLedgerList;
      ctrl.tableState = sbUnsavedChangesService.loadMemory(stateKey) || ctrl.tableState;
      ctrl.view = {
        showCreditPaymentModal: false,
        paymentId: '',
      };
    };

    ctrl.$onChanges = (changesObj) => {
      // this is so angular updates the firmLedgerList reference updated by sb-compose. This is until we migrate this to redux.
      if (changesObj && changesObj.firmLedgerList && changesObj.firmLedgerList.currentValue) {
        // get the new value coming from sb-compose
        const updatedFirmLedgerList = changesObj.firmLedgerList.currentValue;
        ctrl.model.firmLedger = updatedFirmLedgerList;
      }
    };

    ctrl.$onDestroy = () => {
      sbUnsavedChangesService.saveMemory(stateKey, { ...ctrl.tableState });
    };

    function onSort({ sortDirection, sortBy }) {
      ctrl.tableState.sortDirection = sortDirection;
      ctrl.tableState.sortBy = sortBy;
    }

    function showInvoice(invoice) {
      if (invoice.currentVersion.status === 'DRAFT') {
        ctrl.onClickLink({
          type: 'invoice',
          id: invoice.invoiceId,
        });
      } else if (invoice.currentVersion.splitBillingSettings?.isEnabled) {
        setModalDialogVisible({
          modalId: INVOICE_PREVIEW_MODAL_ID,
          props: {
            invoiceId: invoice.invoiceId,
            invoiceNumber: invoice.currentVersion.invoiceNumber,
          },
        });
      } else {
        ctrl.showInvoicePreview(invoice);
      }
    }

    function closeCreditPaymentNModal() {
      ctrl.view.showCreditPaymentModal = false;
    }

    function onRowClick($event, item) {
      $event.stopPropagation();

      if (item.source === 'Credit') {
        ctrl.view.showCreditPaymentModal = true;
        ctrl.view.paymentId = item.payment.paymentId;
      } else {
        // Trust to office is a custom item with a payments array
        item.payment || item.payments ? ctrl.openModal($event, item.payment || item) : showInvoice(item.invoice);
      }
    }

  },
});
