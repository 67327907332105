import { gql } from '@apollo/client';
import { expenseEntityFragment } from '../fragments/expenseEntityFragment.graphql';

const query = gql`
  query DraftInvoiceExpenses($matterId: ID!, $includeNonBillableItems: Boolean!, $offset: Int, $limit: Int) {
    unbilledExpenses(
      matterIds: [$matterId]
      filter: { includeNonBillableItems: $includeNonBillableItems }
      offset: $offset
      limit: $limit
    ) {
      ...expenseEntityFragment
    }
  }

  ${expenseEntityFragment}
`;

const notificationIds = [
  'ExpensesNotifications.ExpenseUpdated',
  'ExpensesNotifications.ExpenseDeleted',
  'InvoicingNotifications.InvoiceUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DraftInvoiceExpenses = {
  query,
  notificationIds,
};
