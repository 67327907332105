import React, { useState, useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import {
  Button,
  LoadingBarInfinite,
  InputWithAddon,
  SlidingToggle,
  useIsMounted,
  useTranslation,
} from '@sb-itops/react';
import uuid from '@sb-itops/uuid';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import HtmlToRtfBrowser from '@sb-billing/html-to-rtf-browser';
import { memoizedDebounce, sortByProperty } from '@sb-itops/nodash';
import * as messageDisplay from '@sb-itops/message-display';
import { Memo, Person } from 'types';
import { ViewMemo } from './view-memo';
import { DeleteMemoConfirmationModal } from './DeleteMemoConfirmationModal';
import * as Styles from './MemosTab.module.scss';

const htmlToRtf = new HtmlToRtfBrowser();

interface IMemosTab {
  memos: Memo[];
  loading: boolean;
  matterId: string;
  printTitle: string;
  loggedInStaff: Person;
  selectedMemoId?: string;
}

export const MemosTab = ({ memos, loading, matterId, loggedInStaff, selectedMemoId, printTitle }: IMemosTab) => {
  const [selectedMemoState, setSelectedMemo] = useState(selectedMemoId || '');
  const [searchFilter, setSearchFilter] = useState('');
  const [memosOverride, setMemosOverride] = useState({});
  const [showDeleted, setShowDeleted] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<Memo | false>(false);
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const selectedMemoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedMemoRef.current) {
      selectedMemoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedMemoRef.current]);

  let memoList = memos;

  memoList = sortByProperty(
    memoList
      .filter((memo) => {
        if (memo.isDeleted && !showDeleted) {
          return false;
        }
        if (!searchFilter) {
          return true;
        }
        return memo.title.toLowerCase().includes(searchFilter) || memo.text.toLowerCase().includes(searchFilter);
      })
      .map((memo) => {
        if (memosOverride[memo.id]) {
          return memosOverride[memo.id];
        }
        return memo;
      }),
    'createdDate',
    'desc',
  );

  const addMemo = async () => {
    const marshalledData = {
      id: uuid(),
      matterId,
      title: '',
      createdByStaff: loggedInStaff,
      userStaff: loggedInStaff,
      createdDate: moment().toISOString(),
      timestamp: moment().toISOString(),
      rtf: htmlToRtf.convertHtmlToRtf(''),
      isDeleted: false,
      text: Buffer.from(htmlToRtf.convertHtmlToRtf('')).toString('base64'),
    };

    setMemosOverride((value) => ({
      ...value,
      [marshalledData.id]: {
        ...marshalledData,
        timestamp: moment().toISOString(),
        text: htmlToRtf.convertHtmlToRtf(''),
      },
    }));

    setSelectedMemo(marshalledData.id);

    try {
      await dispatchCommand({ type: 'MatterManagement.Memos.Messages.AddMemo', message: marshalledData });
      messageDisplay.success(`Memo created`);
    } catch (e) {
      messageDisplay.error(`Failed to create memo, please try again later`);
    }
  };

  const debouncedUpdate = useCallback(
    memoizedDebounce(
      async (memo: Memo, staff: Person) => {
        if (memo.id) {
          const marshalledData = {
            id: memo.id,
            matterId,
            title: (memo.title || '').trim(),
            versionId: uuid(),
            rtf: memo.newRTF || '',
            createdByStaff: staff,
            timestamp: memo.timestamp,
            text: Buffer.from(memo.newRTF || '').toString('base64'),
          };

          await dispatchCommand({ type: 'MatterManagement.Memos.Messages.UpdateMemo', message: marshalledData });
        } else {
          const marshalledData = {
            id: uuid(),
            matterId,
            title: (memo.title || '').trim(),
            createdByStaff: staff,
            rtf: memo.newRTF || '',
            timestamp: memo.timestamp,
            text: Buffer.from(memo.newRTF || '').toString('base64'),
            createdDate: moment().toISOString(),
            isDeleted: false,
          };

          await dispatchCommand({ type: 'MatterManagement.Memos.Messages.AddMemo', message: marshalledData });
          if (isMounted?.current) {
            setSelectedMemo(marshalledData.id);
          }
        }
      },
      10000,
      {},
      (memo: Memo) => memo?.id,
    ),
    [],
  );

  const onChange = useCallback((memo, staff) => {
    const newMemo = { ...memo, timestamp: moment().toISOString() };
    setMemosOverride((value) => ({ ...value, [memo.id]: newMemo }));
    debouncedUpdate(newMemo, staff);
  }, []);

  const formatMemoTimeStamp = (ts) => {
    const date = t('date', { ts, format: 'DD MMMM YYYY' });
    const hourAndMinute = t('date', { ts, format: 'hhmm A' });

    const formattedTimeStamp = `${date}, ${hourAndMinute}`;
    return formattedTimeStamp;
  };

  const selectedMemo = selectedMemoState || (memoList.length && memoList[0].id);
  return (
    <div className={classnames('master-detail-panel', Styles.container)}>
      <div className={classnames('panel-filter', Styles.memoPanel)}>
        <div className={Styles.memoList}>
          {memoList.map((memo) => (
            <div
              key={`${memo.id}id`}
              onClick={() => {
                setSelectedMemo(memo.id);
              }}
              className={classnames(
                Styles.item,
                selectedMemo === memo.id && Styles.selected,
                memo.isDeleted && Styles.deletedMemo,
              )}
            >
              <div className={Styles.details}>
                <div className={Styles.title}>{memo.title}</div>
                <div className={Styles.info}>
                  {!!memo.createdByStaff?.colorFill && (
                    <div
                      className={Styles.staffColor}
                      style={{ backgroundColor: `${memo.createdByStaff.colorFill}` }}
                    />
                  )}
                  {memo.createdByStaff?.initials || ''} {formatMemoTimeStamp(memo.timestamp)}
                </div>
              </div>
              <i
                className={classnames(memo.isDeleted ? 'icon icon-loop-1' : 'icon icon-delete-1', Styles.icon)}
                onClick={() => setShowDeleteConfirmation(memo)}
              />
            </div>
          ))}
          <div className={Styles.filler} />
        </div>
        <div className={Styles.showDeleted}>
          <span onClick={() => setShowDeleted(!showDeleted)} className={Styles.label}>
            Show Deleted Memos
          </span>
          <SlidingToggle
            scope="show-deleted-memos"
            onChange={(name, value) => setShowDeleted(value)}
            selected={showDeleted}
          />
        </div>
      </div>
      <div className={classnames('panel-body', Styles.panelBody)}>
        <div className={classnames('ribbon', 'panel', 'panel-primary', Styles.ribbon)}>
          <Button size="full-width" onClick={addMemo}>
            New Memo
          </Button>
          <InputWithAddon
            className={classnames(Styles.search)}
            icon="search-icon"
            placeholder="Search for..."
            value={searchFilter}
            onChange={(event) => setSearchFilter((event.target.value || '').toLowerCase())}
          />
        </div>
        <div className={Styles.memos}>
          <LoadingBarInfinite loading={loading} />
          {memoList.map((memo) => (
            <div
              ref={selectedMemoId === memo.id ? selectedMemoRef : undefined}
              onClick={() => {
                if (memo.id !== selectedMemo) {
                  setSelectedMemo(memo.id);
                }
              }}
              key={`${memo.id}id`}
            >
              <ViewMemo
                selected={memo.id === selectedMemo}
                memo={memo}
                onChange={onChange}
                loggedInStaff={loggedInStaff}
                printTitle={printTitle}
              />
            </div>
          ))}
        </div>
      </div>
      <DeleteMemoConfirmationModal
        isVisible={!!showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        actionType={showDeleteConfirmation && showDeleteConfirmation.isDeleted ? 'Restore' : 'Delete'}
        onConfirm={async () => {
          if (!showDeleteConfirmation) {
            return;
          }
          const marshalledData = {
            id: showDeleteConfirmation.id,
            matterId,
          };
          if (showDeleteConfirmation.isDeleted) {
            try {
              await dispatchCommand({
                type: 'MatterManagement.Memos.Messages.RestoreMemo',
                message: marshalledData,
              });
              messageDisplay.success(`Memo restored`);
            } catch (e) {
              messageDisplay.error(`Failed to restore memo, please try again later`);
            }
            setShowDeleteConfirmation(false);
            return;
          }
          if (selectedMemo === showDeleteConfirmation.id) {
            setSelectedMemo('');
          }

          try {
            await dispatchCommand({
              type: 'MatterManagement.Memos.Messages.DeleteMemo',
              message: marshalledData,
            });
            messageDisplay.success(`Memo deleted`);
          } catch (e) {
            messageDisplay.error(`Failed to delete memo, please try again later`);
          }
          setShowDeleteConfirmation(false);
        }}
      />
    </div>
  );
};
