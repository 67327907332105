import {
  getMap,
  getById as getInvoiceTotalsById,
  getTotalsForInvoiceId,
  updateCache as updateRedux,
  clearCache as clearRedux,
  calculateInvoiceTotals,
} from '@sb-billing/redux/invoice-totals';

import { getInvoiceDebtorTotalsByInvoiceId } from '@sb-billing/redux/invoice-debtor-totals';

import {
  getByDebtorId as getInvoicesByDebtorId
} from '@sb-billing/redux/invoices';

angular.module('@sb-billing/services').service('sbInvoiceTotalsService', function (sbGenericCacheService, sbLoggerService, sbInvoicingCacheManager, sbEndpointType) {
  const that = this, log = sbLoggerService.getLogger('sbInvoiceTotalsService');

  that.getTotalsForInvoiceId = getTotalsForInvoiceId;
  that.getTotalsForDebtorId = getTotalsForDebtorId;
  that.getTotalsChangesetForInvoice = getTotalsChangesetForInvoice;
  that.adjustTotalsForWaive = adjustTotalsForWaive;
  that.adjustTotalsForReversal = adjustTotalsForReversal;
  that.adjustTotalsForPayment = adjustTotalsForPayment;
  that.adjustTotalsForEdit = adjustTotalsForEdit;
  that.getInvoiceTotalsForDebtor = getInvoiceTotalsForDebtor;

  sbGenericCacheService.setupCache({
    name: 'sbInvoiceTotalsService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: 'billing/invoice-totals' },
      poll: 60,
      subscriptions: 'notifier.BillingMattersNotifications.BillingInvoiceTotalsUpdated'
    },
    updateRedux,
    clearRedux,
  });

  function isInvoiceVoided(invoiceId) {
    const invoice = sbInvoicingCacheManager.getById(invoiceId);

    return _.get(invoice, 'currentVersion.status') === 'VOID';
  }

  function getTotalsForDebtorId(debtorId) {
    log.info('fetching debtor totals for debtor ID: %s', debtorId);

    // get all invoices associated with contact, including shared invoices
    const invoices = getInvoicesByDebtorId(debtorId).filter((invoice) => {
      // include only FINAL or PAID invoices, i.e. filter out draft, deleted and voided invoices
      return (invoice.currentVersion.status === 'FINAL' || invoice.currentVersion.status === 'PAID');
    });
    const invoiceOrDebtorTotals = invoices.map((invoice) => {
      if (invoice.currentVersion.splitBillingSettings && invoice.currentVersion.splitBillingSettings.isEnabled) {
        const invoiceDebtorTotals = getInvoiceDebtorTotalsByInvoiceId(invoice.currentVersion.invoiceId);
        const debtorTotals = invoiceDebtorTotals?.find((t) => t.debtorId === debtorId);
        return debtorTotals;
      }
      const invoiceTotals = getInvoiceTotalsById(invoice.invoiceId);
      return invoiceTotals;
    });
    const contactTotals = invoiceOrDebtorTotals
      .reduce((acc, totals) => {
        return {
          unbilled: 0, // all invoices are billed, so this will always be zero
          billed: acc.billed + totals.billed || 0, // invoiceDebtorTotals does not have billed
          paid: acc.paid + totals.paid,
          unpaid: acc.unpaid + totals.unpaid,
          unpaidExcInterest: (acc.unpaidExcInterest || 0) + (_.isNumber(totals.unpaidExcInterest) ? totals.unpaidExcInterest : 0),
          paidByCredit: acc.paidByCredit + (totals.paidByCredit || 0),
        };
      }, {
        unbilled: 0,
        billed: 0,
        paid: 0,
        unpaid: 0,
        unpaidExcInterest: 0,
        paidByCredit: 0,
      });
    return contactTotals;
  }

  function getInvoiceTotalsForDebtor (id) {
    return _.chain(getMap())
      .filter(total => total.debtorId === id)
      .filter(total => !isInvoiceVoided(total.invoiceId))
      .value();
  }

  /*
   For a waive the remaining unpaid is discounted. So paid remains unchanged,
   balance is set to match paid and unpaid is set to ZERO.
   */
  function adjustTotalsForWaive(invoiceTotals) {
    return adjustTotals(invoiceTotals, 0, 0, 0, -invoiceTotals.unpaid, invoiceTotals.unpaid);
  }

  function adjustTotalsForReversal (invoiceTotals) {
    return adjustTotals(invoiceTotals, 0, 0, 0, invoiceTotals.waived, -invoiceTotals.waived);
  }

  function adjustTotalsForPayment(invoiceTotals, amountPaid, amountPaidByCredit) {
    // Amount paid is allowed to be greater than invoice total. Residual lands in operating account (handled elsewhere).
    // In terms of total adjustment, the amount paid becomes the invoice total unpaid amount.
    if (invoiceTotals && amountPaid > invoiceTotals.unpaid) {
      amountPaid = invoiceTotals.unpaid;
    }

    const [billed, paid, paidByCredit, unpaid, waived] = [0, amountPaid, amountPaidByCredit, -amountPaid - amountPaidByCredit, null];
    return adjustTotals(invoiceTotals, billed, paid, paidByCredit, unpaid, waived); // increase paid by amount and decrease unpaid by amount
  }

  /* The business rules for an edit state that an edit can only be performed when no payments have been made.
   * Therefore we only need to remove the 'billed' and 'unpaid' amounts as after the edit it will no longer be considered unpaid.
   */
  function adjustTotalsForEdit(invoiceTotals) {
    invoiceTotals.tax = _.isNumber(invoiceTotals.tax) ? invoiceTotals.tax : 0;
    const [billed, paid, paidByCredit, unpaid, waived, tax] = [-invoiceTotals.billed, 0, 0, -invoiceTotals.unpaid, 0, -invoiceTotals.tax];
    return adjustTotals(invoiceTotals, billed, paid, paidByCredit, unpaid, waived, tax, true);
  }

  /*
   NOTE: this function and its mirror in matter totals service ADD or SUBTRACT
   from the current totals. This is required in order to keep matter and invoice totals in sync.
   For example, we can't simply set unpaid to ZERO for an invoice because we ALWAYS need to add or subtract
   at the matter level. The functions above that call this function must determine how much to add or subtract.
   */
  function adjustTotals(invoiceTotals, billed, paid, paidByCredit, unpaid, waived, tax, bypassValidation) {
    // new fields like paidByCredit newly introduced are not set retrospectively in InvoiceTotals entity
    const invoiceTotalsCopy = { ...invoiceTotals };
    invoiceTotalsCopy.total = invoiceTotalsCopy.total || 0;
    invoiceTotalsCopy.paidByCredit = invoiceTotalsCopy.paidByCredit || 0;

    log.info('adjusting invoice totals by billed:%s, paid:%s, unpaid:%s, waived:%s', billed, paid, unpaid, waived);
    if (!bypassValidation) {
      validateTotals(invoiceTotalsCopy.total + billed, invoiceTotalsCopy.paid + paid, invoiceTotalsCopy.unpaid + unpaid, invoiceTotalsCopy.waived + waived, invoiceTotalsCopy.paidByCredit + paidByCredit);
    }

    const adjustedInvoiceTotals = _.cloneDeep(invoiceTotalsCopy);
    adjustedInvoiceTotals.total += billed;
    adjustedInvoiceTotals.paid += paid;
    adjustedInvoiceTotals.paidByCredit += paidByCredit;
    adjustedInvoiceTotals.unpaid += unpaid;
    adjustedInvoiceTotals.waived += waived || 0;

    if (_.isNumber(tax)) {
      adjustedInvoiceTotals.tax = adjustedInvoiceTotals.tax || 0;
      adjustedInvoiceTotals.tax += tax;
    }

    return adjustedInvoiceTotals;
  }

  function validateTotals(billed, paid, unpaid, waived, paidByCredit) {
    if (!_.isFinite(billed) || billed < 0) {
      throw new Error(`Invalid billed value : ${billed}`);
    }

    if (!_.isFinite(paid) || paid < 0) {
      throw new Error(`Invalid paid value : ${paid}`);
    }
  
    if (!_.isFinite(paidByCredit) || paidByCredit < 0) {
      throw new Error(`Invalid paidByCredit value : ${paidByCredit}`);
    }

    if (!_.isFinite(unpaid) || unpaid < 0) {
      throw new Error(`Invalid unpaid value : ${unpaid}`);
    }

    if (waived) {
      if (paid + unpaid + waived + paidByCredit !== billed) {
        throw new Error(`Totals do not balance : ${paid} + ${unpaid} + ${waived} + ${paidByCredit}  !== ${billed}`);
      }
    } else {
      if (paid + unpaid + paidByCredit !== billed) {
        throw new Error(`Totals do not balance : ${paid} + ${unpaid} + ${paidByCredit} !== ${billed}`);
      }
    }
  }

  function extractInvoiceTotals(invoice) {
    const invoiceTotals = calculateInvoiceTotals(invoice);
    const cacheEntry = _.extend({
      invoiceId: invoice.invoiceId,
      accountId: invoice.accountId,
      debtorId: _.get(invoice, 'currentVersion.debtors[0].id', null),
      matterId: invoice.matterId,
      optimistic: true,
    }, invoiceTotals);

    log.info('adding InvoiceMatterTotals cache entry : ', cacheEntry);
    return cacheEntry;
  }

  function getTotalsChangesetForInvoice(invoice) {
    return [extractInvoiceTotals(invoice)];
  }

});
