import composeHooks from '@sb-itops/react-hooks-compose';
import { featureActive } from '@sb-itops/feature';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { providers } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { paymentMethods } from '@sb-billing/business-logic/payment-provider/services/fee-wise';
import uuid from '@sb-itops/uuid';
import { getLogger } from '@sb-itops/fe-logger';
import { FeeWisePaymentForm } from './FeeWisePaymentForm';

const log = getLogger('FeeWisePaymentFormContainer');
log.setLogLevel('info');

const onSubmitTriggered = async ({ feeWiseApi, onSubmit }) => {
  let paymentMethodDetails;
  let billingDetails;
  let paymentMethod;
  let preChargeErrorDetails;
  try {
    const feeWiseResponse = await feeWiseApi.submit();
    paymentMethodDetails = feeWiseResponse.response.paymentMethodDetails;
    billingDetails = feeWiseResponse.response.billingDetails;
    // paymentMethodDetails contains either debit or card object which determines which one was used
    paymentMethod = Object.prototype.hasOwnProperty.call(paymentMethodDetails, 'debit')
      ? paymentMethods.DirectDebit
      : paymentMethods.Card;
  } catch (err) {
    log.error('Error submitting payment details:', err);
    if (err.error && (err.error.message || err.error.detail)) {
      preChargeErrorDetails = {
        message: err.error.message,
        detail: err.error.detail,
      };
    }
    // We need to provide some payment method so getChargeDescription doesn't throw
    // as we want to throw ourselves during extraction of required charge fields
    paymentMethod = paymentMethods.Card;
    paymentMethodDetails = undefined;
  }

  // What we have available:
  // Card
  //   {
  //     "response": {
  //       "billingDetails": {
  //         "address": "1 Main St , Sydney, VIC, 3580, AU",
  //         "name": "Tim Millard",
  //         "phone": "+61412345678"
  //       },
  //       "paymentMethodDetails": {
  //         "card": {
  //           "cardholderName": "Tim Millard",
  //           "country": "US",
  //           "expirationDate": "2033-03-31T23:59:59.999999999+11:00",
  //           "fundingType": "credit",
  //           "scheme": "visa",
  //           "schemePartialNumber": "4242"
  //         },
  //         "paymentToken": "b00cb080-d268-47f3-b159-80e07c91808c"
  //       }
  //     }
  //   }
  //
  // Direct debit
  // {
  //   "response": {
  //     "billingDetails": {
  //       "email": "tim@rapidpaylegal.com",
  //       "name": "Tim Millard"
  //     },
  //     "paymentMethodDetails": {
  //       "debit": {
  //         "accountPartialNumber": "6789",
  //         "bankName": "STRIPE TEST BANK",
  //         "branchCode": "110000000",
  //         "country": "US"
  //       },
  //       "paymentToken": "0dd4fbe8-80ca-42a4-8544-ae3078f2672c"
  //     }
  //   }
  // }

  onSubmit({
    paymentMethodDetails,
    paymentMethod,
    preChargeErrorDetails,
    // exposing some fields to help make success/failure message more meaningful
    billingName: billingDetails && billingDetails.name,
    cardType: paymentMethodDetails && paymentMethodDetails.card && paymentMethodDetails.card.scheme,
    cardholderName: paymentMethodDetails && paymentMethodDetails.card && paymentMethodDetails.card.cardholderName,
    cardNumberTruncated:
      paymentMethodDetails && paymentMethodDetails.card && paymentMethodDetails.card.schemePartialNumber,
  });
};

const createPaymentToken = async ({ tokenType, payorId, payorDetails, onPreCharge, enabledPaymentMethods }) => {
  log.info('Creating token');
  let captureUri;
  const preChargeRequest = {
    providerType: providers.FEE_WISE,
    providerSpecificFields: {
      tokenType,
      debtorId: payorId,
      debtorDetails: payorDetails,
      enabledPaymentMethods,
    },
  };

  try {
    const response = await onPreCharge(preChargeRequest);
    captureUri = response.captureUri;
  } catch (error) {
    log.error(error);
    captureUri = undefined;
  }

  return captureUri;
};

const initFeeWiseApi = async ({
  tokenType,
  payorId,
  payorDetails,
  hostedFieldsStyle,
  onPreCharge,
  enabledPaymentMethods,
  setupFeewise,
}) => {
  log.info('Init form:', tokenType, payorId);
  // We need to provide debtorId to init form in FeeWise
  // When we save card, we use real debtorId. When we just make a charge, we use random uuid
  const debtorId = payorId || uuid();
  let feeWiseApi;
  try {
    const captureUri = await createPaymentToken({
      tokenType,
      payorId: debtorId,
      payorDetails,
      onPreCharge,
      enabledPaymentMethods,
    });
    if (!captureUri) {
      throw new Error('Cannot get the form URL');
    }
    const unmountOnSuccess = false;
    const collectBillingDetails = true;
    feeWiseApi = await setupFeewise(captureUri, unmountOnSuccess, collectBillingDetails, hostedFieldsStyle);
    await feeWiseApi.mount('#feewise-iframe-wrapper');
  } catch (err) {
    log.error(err);
    feeWiseApi = undefined;
  }
  return feeWiseApi;
};

const hooks = ({
  tokenType,
  payorId,
  payorDetails,
  onReadyForSubmit,
  triggerSubmit,
  onSubmit,
  onPreCharge,
  hostedFieldsStyle,
  enabledPaymentMethods,
}) => ({
  useFeeWiseForm: () => {
    const [feeWiseApi, setFeeWiseApi] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [submissionTriggered, setSubmissionTriggered] = useState(false);
    const [formValid, setFormValid] = useState(false);
    // We don't know when iframe content is fully loaded but loaded form sends formValidChange event.
    // We use first formValidChange event as an indication that FeeWise form is loaded
    const [isFeeWiseFormLoaded, setIsFeeWiseFormLoaded] = useState(false);

    useEffect(() => {
      setIsFeeWiseFormLoaded(false);
      setIsLoading(true);
      setIsError(false);
      const formValidChangeReceived = false;

      async function init() {
        try {
          const { setupFeewise, EVENT_FORM_VALID_CHANGE } = await (featureActive('BB-15924')
            ? import('../assets/feewise-test')
            : import('../assets/feewise'));
          const api = await initFeeWiseApi({
            tokenType,
            payorId,
            payorDetails,
            hostedFieldsStyle,
            onPreCharge,
            enabledPaymentMethods,
            setupFeewise,
          });

          setIsLoading(false);
          setFeeWiseApi(api);

          if (api) {
            api.on(EVENT_FORM_VALID_CHANGE, (event) => {
              setFormValid(event.complete);

              if (!formValidChangeReceived) {
                setIsFeeWiseFormLoaded(true);
              }
            });
          } else {
            setIsError(true);
          }
        } catch (error) {
          log.error(error);
        }
      }

      init();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenType, payorId]);

    onReadyForSubmit(!isLoading && formValid);

    // Trigger the submission.
    if (triggerSubmit && !submissionTriggered) {
      if (!feeWiseApi || !formValid) {
        throw new Error('Credit card form submission should not be triggered when in non-ready state');
      }

      setSubmissionTriggered(true);
      onSubmitTriggered({ feeWiseApi, onSubmit });
    }

    // We are ok to allow submission again.
    if (!triggerSubmit && submissionTriggered) {
      setSubmissionTriggered(false);
    }

    return {
      isLoading,
      isError,
      isFeeWiseFormLoaded,
    };
  },
});

export const FeeWisePaymentFormContainer = composeHooks(hooks)(FeeWisePaymentForm);

FeeWisePaymentFormContainer.displayName = 'FeeWisePaymentFormContainer';

FeeWisePaymentFormContainer.propTypes = {
  // array of paymentMethods keys
  enabledPaymentMethods: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  payorId: PropTypes.string,
  payorDetails: PropTypes.object,
  tokenType: PropTypes.string.isRequired,
  hostedFieldsStyle: PropTypes.object,
  termsText: PropTypes.string,
  triggerSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onReadyForSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPreCharge: PropTypes.func.isRequired,
};

FeeWisePaymentFormContainer.defaultProps = {
  payorId: undefined,
  payorDetails: {},
  termsText: undefined,
};
