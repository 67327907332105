/**
 * This is for Client Portal setting page as it doese not share billingToCommunicateApi with Message.
 * */
import { store } from '@sb-itops/redux';
import { actionCreators } from 'web/redux/features/communicate/actions';

import { getCommunicateHost } from '@sb-itops/communicate-config';
import { getLogger } from '@sb-itops/fe-logger';
import { ICommunicateToBillingApi, IBillingToCommunicateApi } from './api-types';

const log = getLogger('COMMUNICATE_HANDSHAKING');

let iframeApiSetup = false;

const sleep = async (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

/*
  It can take a while for the iframe and contentWindow to become available, we need to wait.
  */
const awaitIframe = async (): Promise<Window> => {
  let comm: HTMLIFrameElement | null = null;

  while (!comm || !comm.contentWindow) {
    comm = document.getElementById('CommIframe') as HTMLIFrameElement | null;
    if (!comm || !comm.contentWindow) {
      /* eslint-disable no-await-in-loop */
      await sleep(1000);
    }
  }

  return comm.contentWindow;
};

/*
  If the API is not set up, we should send a handshake ping to the Iframe's window until it is.
  */
const awaitApiSetup = async () => {
  const contentWindow = await awaitIframe();

  while (!iframeApiSetup) {
    try {
      // this is weird but if this succeeds then we are not cross-origin and should not ping
      contentWindow.location.toString();
    } catch (e) {
      // if this fails we are cross-origin in the iframe and we can send the ping
      contentWindow.postMessage(
        { communicateHandshakePing: { host: `${document.location.protocol}//${document.location.host}` } },
        getCommunicateHost(),
      );
    }

    /* eslint-disable no-await-in-loop */
    await sleep(1000);
  }

  return contentWindow;
};

export const init = (initOptions: Parameters<IBillingToCommunicateApi['init']>) => {
  const billingToCommunicateApi = new Proxy(
    {},
    {
      get(target, prop) {
        return async (...args: any[]) => {
          const contentWindow = await awaitApiSetup();
          log.debug(`billing -> communicate ${prop as any}\n`, args);
          contentWindow.postMessage({ apiCall: { args, func: prop } }, getCommunicateHost());
        };
      },
    },
  ) as IBillingToCommunicateApi;

  billingToCommunicateApi.init(...initOptions);

  const communicateToBillingApi: Partial<ICommunicateToBillingApi> = {
    init: () => {
      store.dispatch(actionCreators.setInitialised({ value: true }));
    },
    reload: () => {
      iframeApiSetup = false;
      store.dispatch(actionCreators.setSpawned({ value: false }));
      // the CommunicateIframe should detect we are unspawned but visible and respawn
    },
  };

  const listener = async (e) => {
    // pong indicates remote window has responded to ping and is ready for setup
    if (e.data && e.data.communicateHandshakePong) {
      // only want to do this once
      if (!iframeApiSetup) {
        iframeApiSetup = true;
        const contentWindow = await awaitIframe();

        contentWindow.postMessage({ setupIframeApi: true }, getCommunicateHost());
        iframeApiSetup = true;
      }
    }

    // indicates call from remote window to us
    if (e.data && e.data.communicateIframeCall) {
      const funcName = e.data.communicateIframeCall.func as keyof ICommunicateToBillingApi;
      const args = e.data.communicateIframeCall.args;

      if (e.origin === getCommunicateHost()) {
        log.debug(`communicate -> billing ${funcName}\n`, args);
        const possibleFunc = (communicateToBillingApi as any)[funcName];
        if (possibleFunc && typeof possibleFunc === 'function') {
          possibleFunc(...args);
        } else {
          const prettyArgs = (args || []).map(JSON.stringify).join(', ');
          log.info(`unimplemented communicateToBillingApi call: ${funcName}(${prettyArgs})`);
        }
      } else {
        log.error(`dropping communicateToBillingApi call as origin is ${e.origin} expected ${getCommunicateHost()}`);
      }
    }
  };

  window.addEventListener('message', listener);

  const cleanUp = () => {
    window.removeEventListener('message', listener);
    iframeApiSetup = false;
  };

  return {
    billingToCommunicateApi,
    cleanUp,
  };
};

export type TContact = {
  id: string;
  firstName: string;
  lastName: string;
  initials: string;
  email: string | null;
  mobilePhone?: string | null | undefined;
};
