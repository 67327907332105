import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, useTranslation } from '@sb-itops/react';
import * as Styles from './InvoiceConfirmFinaliseModal.module.scss';

const InvoiceConfirmFinaliseModal = ({
  isVisible,
  invoiceCount,
  hasSplitBillingWarningMessage,
  splitBillingWarningMessage,
  onConfirm,
  onCancel,
  hasZeroBalance,
  hasUnpaidAD,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title="Please Confirm"
      body={getBody({
        hasZeroBalance,
        hasUnpaidAD,
        invoiceCount,
        hasSplitBillingWarningMessage,
        splitBillingWarningMessage,
        t,
      })}
      footer={
        <div className={Styles.footer}>
          <Button className={Styles.footerBtn} onClick={onConfirm}>
            {t('finalise')}
          </Button>
          <Button className={Styles.footerBtn} type="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      }
      isVisible={isVisible}
      onModalClose={onCancel}
      shouldCloseOnOverlayClick={false}
    />
  );
};

const getBody = ({
  hasZeroBalance,
  hasUnpaidAD,
  invoiceCount,
  hasSplitBillingWarningMessage,
  splitBillingWarningMessage,
  t,
}) => (
  <div className={Styles.invoiceConfirmProceedModal}>
    {hasZeroBalance && !hasUnpaidAD && (
      <>
        <p>
          {`${invoiceCount === 1 ? 'This invoice' : `At least one of the invoices you've selected`} has a ${t(
            'currencySymbol',
          )}0 balance and
        will be marked as paid upon ${t('finalising')}.`}
        </p>
        <p>This status cannot be reversed.</p>
      </>
    )}
    {!hasZeroBalance && hasUnpaidAD && (
      <p>
        {`${invoiceCount === 1 ? 'This invoice' : `One or more invoices`}  contains an anticipated ${t(
          'expense',
        )} that has not yet been paid to the supplier.`}
      </p>
    )}
    {hasZeroBalance && hasUnpaidAD && (
      <ul>
        <li>
          {`${invoiceCount === 1 ? 'This invoice' : `At least one of the invoices you've selected`} has a ${t(
            'currencySymbol',
          )}0 balance and
        will be marked as paid upon ${t('finalising')}. This status cannot be reversed.`}
        </li>
        <li>{`${invoiceCount === 1 ? 'This invoice' : `One or more invoices`}  contains an anticipated ${t(
          'expense',
        )} that has not yet been paid to the supplier.`}</li>
      </ul>
    )}
    {hasSplitBillingWarningMessage && <p>{splitBillingWarningMessage}</p>}
    <p>Are you sure you want to proceed?</p>
  </div>
);

InvoiceConfirmFinaliseModal.displayName = 'InvoiceConfirmFinaliseModal';

InvoiceConfirmFinaliseModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hasZeroBalance: PropTypes.bool.isRequired,
  hasUnpaidAD: PropTypes.bool.isRequired,
  hasSplitBillingWarningMessage: PropTypes.bool,
  splitBillingWarningMessage: PropTypes.string,
  invoiceCount: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

InvoiceConfirmFinaliseModal.defaultProps = {
  hasSplitBillingWarningMessage: false,
  splitBillingWarningMessage: '',
};

export default InvoiceConfirmFinaliseModal;
