import { integerToDate } from '@sb-itops/date';
import { getList as getCorrespondenceHistoryList } from '@sb-billing/redux/correspondence-history';
import { getInvoiceDebtorTotalsByInvoiceId } from '@sb-billing/redux/invoice-debtor-totals';
import { getLastCorrespondenceHistoryForReminders, sendStatuses } from '@sb-billing/business-logic/correspondence-history';
import { getMatterDisplayById, getMattersByStatuses } from '@sb-matter-management/redux/matters';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getById as getMatterCommunicationSettingsById } from '@sb-billing/redux/matter-communication-settings';
import {featureActive} from '@sb-itops/feature';

const createInvoiceReminderLoader = services => {
  const loadInvoiceReminders = () => {
    return fetchData(services);
  };

  return loadInvoiceReminders;
};

export { createInvoiceReminderLoader };

const daysDifferenceToday = (date, now) => {
  if (date === undefined) {
    return;
  }

  const currentDay = now || moment(new Date()).startOf('day');
  const end = moment(date).startOf('day');

  return currentDay.diff(end, 'days');
};

const getLastInvoicedInDays = (reminderLastInvoicedInDays, invoiceIssuedDate) => {
  const issuedFromTodayInDays = daysDifferenceToday(invoiceIssuedDate);

  if (!reminderLastInvoicedInDays && reminderLastInvoicedInDays !== 0) {
    return issuedFromTodayInDays;
  }

  if (issuedFromTodayInDays < reminderLastInvoicedInDays) {
    return issuedFromTodayInDays;
  }

  return reminderLastInvoicedInDays;
};

// This function collects invoice reminder related data from relevant services and processes it into an array with the following structure:
//  [
//    {type: 'REMINDER', ...}
//    {type: 'INVOICE', ...}
//    {type: 'INVOICE', ...}
//    {type: 'REMINDER', ...}
//    {type: 'INVOICE', ...}
//    ...
//  ]
// fetchData() returns an array following the rule: "Invoice elements belonging to a reminder will come after the 'owning' reminder element, but prior to the next reminder element".
// The effect is that if you loop over the resulting array, that you can safely assume that once you hit an element with a type "REMINDER", that all invoices for the preceeding reminder have been processed.
// We rely on that rule for sorting and filtering, so don't dick with it unless you understand the implications.
// TODO: There is an opportunity to make this fetching perform better by applying filters at fetch time rather than in a two-step process - but that logic is more complex and may not be necessary.
function fetchData({
  sbInvoicingService,
  sbInvoiceTotalsService,
  sbContactsMbService,
  sbAccountRemindersService,
  sbMatterTotalsService
}) {
  const matters = getMattersByStatuses(['OPEN', 'PENDING', 'CLOSED']);
  const invoices = sbInvoicingService.getInvoiceSummaries({ matterIds: Object.keys(matters), status: 'FINAL' });
  const lastReminders = sbAccountRemindersService.getLastReminders();
  const correspondenceHistoryList = getCorrespondenceHistoryList();
  const lastCorrespondenceMap = getLastCorrespondenceHistoryForReminders({ correspondenceHistoryList });

  const now = moment(new Date()).startOf('day');
  const reminders = {};

  return invoices.reduce((remindersAndInvoices, invoiceSummary) => {
    const invoice = invoiceSummary.currentVersion;
    const isSplitBillingInvoice = invoice.splitBillingSettings && invoice.splitBillingSettings.isEnabled;
    const matter = matters[invoice.matterId];
    if (!matter) {
      return remindersAndInvoices;
    }

    const invoiceTotal = sbInvoiceTotalsService.getTotalsForInvoiceId(invoice.invoiceId) || {};
    const matterTotals = sbMatterTotalsService.getTotalsForMatterId(matter.matterId);
    const matterUnbilled = (matterTotals && matterTotals.unbilled) || 0;
    const matterCommunicationSettings = getMatterCommunicationSettingsById(matter.matterId) || {};
    const matterSendPreferences = matterCommunicationSettings.correspondencePreferences || [];
    const debtorIds = invoice.debtors.map(debtor => debtor.id);

    let invoiceDebtorTotalsLookup = {};
    if (isSplitBillingInvoice) {
      const invoiceDebtorTotals = getInvoiceDebtorTotalsByInvoiceId(invoice.invoiceId);
      invoiceDebtorTotalsLookup = invoiceDebtorTotals.reduce((acc, debtorTotal) => {
        acc[debtorTotal.debtorId] = debtorTotal;
        return acc;
      }, {});
    } 

    // reminders are centred around a debtor contact, if there are multiple debtors for an invoice
    // a separate entry for each debtor will be created below for UI display and interaction
    invoice.debtors.forEach((debtor) => {
      const debtorId = debtor.id;
      const matterDebtorId = `${matter.matterId}-${debtorId}`;
      const debtorInvoiceId = `${debtorId}|${invoice.invoiceId}`;

      // If this is the first invoice found for this debtor/matter pair, we need to create a new reminder entry.
      let reminder = reminders[matterDebtorId];
      if (!reminder) {
        const debtor = sbContactsMbService.getById(debtorId) || {};
        const lastReminder = lastReminders[matterDebtorId] || {};
        const lastCorrespondenceHistory = lastCorrespondenceMap[matterDebtorId] || {};
        const aggregateSentStatus = lastCorrespondenceHistory ? lastCorrespondenceHistory.status : sendStatuses.NOT_SENT
        const lastReminderDate = featureActive('BB-11448')? lastCorrespondenceHistory.sentTimestamp || lastCorrespondenceHistory.lastUpdated : lastReminder.timestampDate;

        reminder = {
          type: 'REMINDER',
          // id: matterDebtorId, // Reminder items are id'd by matter debtor id.
          matterDebtorId,
          debtorId,
          debtorDisplay: getContactDisplay(debtorId, { showLastNameFirst: true }), // used only for sorting
          debtor,
          matterId: matter.matterId,
          matter,
          matterDisplay: getMatterDisplayById(matter.matterId),
          matterAttorneyResponsible: matter.attorneyResponsibleId,
          matterUnbilled,
          matterSendPreferences,
          lastReminderDate,
          lastReminderInDays: daysDifferenceToday(lastReminderDate, now),
          lastReminderId: lastReminder.id,
          lastReminderInvoices: lastReminder.invoiceIds,
          lastInvoicedInDays: undefined,
          lastCorrespondenceHistory,
          matterDebtorContainsNotOverdueInvoices: false,
          aggregateSentStatus,
          totalInterest: 0,
          totalUnpaidExcInterest: 0,
          totalUnpaid: 0,
          invoices: []
        };
  
        reminders[matterDebtorId] = reminder;
        remindersAndInvoices.push(reminder);
      }
  
      // If the invoice is a non overdue invoice, we don't want to add it as an entry in the list.
      // We just need to track some meta data on the reminder to help with filtering.
      if (moment(integerToDate(invoice.dueDate)).startOf('day') >= now) {
        reminder.matterDebtorContainsNotOverdueInvoices = true;
        reminder.lastInvoicedInDays = getLastInvoicedInDays(reminder.lastInvoicedInDays, integerToDate(invoice.issuedDate));
        return remindersAndInvoices;
      }
  
      // Add this invoice's values to the parent reminder's totals.
      let totalUnpaidExcInterest = 0;
      let totalInterest = 0;
      let totalUnpaid = 0;
      if (isSplitBillingInvoice) {
        const debtorTotals = invoiceDebtorTotalsLookup[debtorId];
        totalUnpaidExcInterest = debtorTotals && debtorTotals.unpaidExcInterest || 0;
        totalInterest = debtorTotals && debtorTotals.interest || 0;
        totalUnpaid = debtorTotals && debtorTotals.unpaid || 0;
      } else {
        totalUnpaidExcInterest = invoiceTotal.unpaidExcInterest;
        totalInterest = invoiceTotal.unpaid - invoiceTotal.unpaidExcInterest;
        totalUnpaid = invoiceTotal.unpaid;
      }

      reminder.totalUnpaidExcInterest += totalUnpaidExcInterest;
      reminder.totalInterest += totalInterest;
      reminder.totalUnpaid += totalUnpaid;

      const invoiceEntry = {
        type: 'INVOICE',
        // id: invoice.invoiceId, // Invoice items are id'd by invoiceId
        debtorInvoiceId,
        debtorId,
        invoiceId: invoice.invoiceId,
        reminder,
        matterDebtorId,
        debtorIds,
        invoiceNumber: invoice.invoiceNumber,
        isSplitBillingInvoice,
        invoiceNumberSuffix: isSplitBillingInvoice ? debtor.invoiceNumberSuffix : undefined,
        daysOverdue: moment().diff(moment(invoice.dueDate, 'YYYYMMDD'), 'days'),
        totalUnpaid,
        totalInterest,
        totalUnpaidExcInterest,
      };
  
      // Add the invoice entry to the reduced remindersAndInvoices array.
      reminder.lastInvoicedInDays = getLastInvoicedInDays(reminder.lastInvoicedInDays, integerToDate(invoice.issuedDate));
      reminder.invoices.push(invoiceEntry);
      remindersAndInvoices.push(invoiceEntry);
    });

    return remindersAndInvoices;
  }, []);
}
