import { useState, useMemo } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { ContactBalanceAllocationModal } from './ContactBalanceAllocationModal';
import { initAllocations, initDebtorAllocationsLookup } from './init-allocations';

const hooks = () => ({
  useAllocations: ({
    balances,
    multiPayments,
    invoiceTotals,
    debtorOptions,
    isSplitBillingEnabled,
    selectedDebtorId,
    invoiceDebtorTotalsLookup,
  }) => {
    const debtorIds = useMemo(() => debtorOptions.map((debtor) => debtor.value), [debtorOptions]);

    const {
      trustAllocations: initialTrustAllocations,
      operatingAllocations: initialOperatingAllocations,
      creditAllocations: initialCreditAllocations,
      trustDebtorAllocationsLookup: initialTrustDebtorAllocationsLookup,
      operatingDebtorAllocationsLookup: initialOperatingDebtorAllocationsLookup,
      creditDebtorAllocationsLookup: initialCreditDebtorAllocationsLookup,
    } = useMemo(() => {
      if (isSplitBillingEnabled) {
        return {
          trustDebtorAllocationsLookup: initDebtorAllocationsLookup({
            balances,
            multiPayments,
            accountType: bankAccountTypeEnum.TRUST,
            debtorIds,
          }),
          operatingDebtorAllocationsLookup: initDebtorAllocationsLookup({
            balances,
            multiPayments,
            accountType: bankAccountTypeEnum.OPERATING,
            debtorIds,
          }),
          creditDebtorAllocationsLookup: initDebtorAllocationsLookup({
            balances,
            multiPayments,
            accountType: bankAccountTypeEnum.CREDIT,
            debtorIds,
          }),
          trustAllocations: [],
          operatingAllocations: [],
          creditAllocations: [],
        };
      }
      return {
        trustAllocations: initAllocations({
          balances,
          multiPayments,
          accountType: bankAccountTypeEnum.TRUST,
        }),
        operatingAllocations: initAllocations({
          balances,
          multiPayments,
          accountType: bankAccountTypeEnum.OPERATING,
        }),
        creditAllocations: initAllocations({
          balances,
          multiPayments,
          accountType: bankAccountTypeEnum.CREDIT,
        }),
        trustDebtorAllocationsLookup: {},
        operatingDebtorAllocationsLookup: {},
        creditDebtorAllocationsLookup: {},
      };
    }, [balances, multiPayments, debtorIds, isSplitBillingEnabled]);

    const [trustAllocations, setTrustAllocations] = useState(initialTrustAllocations);
    const [operatingAllocations, setOperatingAllocations] = useState(initialOperatingAllocations);
    const [creditAllocations, setCreditAllocations] = useState(initialCreditAllocations);

    const [trustDebtorAllocationsLookup, setTrustDebtorAllocationsLookup] = useState(
      initialTrustDebtorAllocationsLookup,
    );
    const [operatingDebtorAllocationsLookup, setOperatingDebtorAllocationsLookup] = useState(
      initialOperatingDebtorAllocationsLookup,
    );
    const [creditDebtorAllocationsLookup, setCreditDebtorAllocationsLookup] = useState(
      initialCreditDebtorAllocationsLookup,
    );

    const totalAmount = trustAllocations
      .concat(operatingAllocations)
      .concat(creditAllocations)
      .reduce((acc, allocation) => acc + allocation.amount, 0);

    const debtorTotalAmountLookup = useMemo(() => {
      const debtorTotalAmounts = {};
      debtorIds.forEach((debtorId) => {
        debtorTotalAmounts[debtorId] = trustDebtorAllocationsLookup[debtorId]
          .concat(operatingDebtorAllocationsLookup[debtorId])
          .concat(creditDebtorAllocationsLookup[debtorId])
          .reduce((acc, allocation) => acc + allocation.amount, 0);
      });
      return debtorTotalAmounts;
    }, [trustDebtorAllocationsLookup, operatingDebtorAllocationsLookup, creditDebtorAllocationsLookup, debtorIds]);

    const errorDebtorLookup = Object.entries(invoiceDebtorTotalsLookup).reduce((acc, [debtorId, debtorTotals]) => {
      acc[debtorId] = debtorTotals.totalExcInterest < debtorTotalAmountLookup[debtorId]; // any debtor has more allocated than total, the debtor is in error
      return acc;
    }, {});

    return {
      trustAllocations,
      onChangeTrustAllocations: setTrustAllocations,
      operatingAllocations,
      onChangeOperatingAllocations: setOperatingAllocations,
      creditAllocations,
      onChangeCreditAllocations: setCreditAllocations,
      totalAmount: isSplitBillingEnabled ? debtorTotalAmountLookup[selectedDebtorId] : totalAmount,
      errorDebtorLookup,
      hasError: isSplitBillingEnabled
        ? Object.values(errorDebtorLookup).some((hasError) => hasError) // any debtor has error, disable 'Apply Allocation' button
        : invoiceTotals.total < totalAmount,
      trustDebtorAllocationsLookup,
      onChangeTrustDebtorAllocationsLookup: setTrustDebtorAllocationsLookup,
      operatingDebtorAllocationsLookup,
      onChangeOperatingDebtorAllocationsLookup: setOperatingDebtorAllocationsLookup,
      creditDebtorAllocationsLookup,
      onChangeCreditDebtorAllocationsLookup: setCreditDebtorAllocationsLookup,
    };
  },
});

export const ContactBalanceAllocationModalContainer = composeHooks(hooks)(ContactBalanceAllocationModal);

ContactBalanceAllocationModalContainer.displayName = 'ContactBalanceAllocationModalContainer';

ContactBalanceAllocationModalContainer.propTypes = {
  preferredBankAccountTypes: PropTypes.array.isRequired,
  onChangeAllocations: PropTypes.func.isRequired,
  onContactLinkClick: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  invoiceTotals: PropTypes.object,

  balances: PropTypes.shape({
    TRUST: PropTypes.shape({
      contactBalances: PropTypes.array.isRequired,
    }),
    OPERATING: PropTypes.shape({
      contactBalances: PropTypes.array.isRequired,
    }),
    CREDIT: PropTypes.shape({
      contactBalances: PropTypes.array.isRequired,
    }),
  }).isRequired,
  multiPayments: PropTypes.array,
  isSplitBillingEnabled: PropTypes.bool,
  selectedDebtorId: PropTypes.string,
  debtorOptions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  invoiceDebtorTotalsLookup: PropTypes.object,
};

ContactBalanceAllocationModalContainer.defaultProps = {
  invoiceTotals: undefined,
  multiPayments: [],
  isSplitBillingEnabled: false,
  selectedDebtorId: undefined,
  debtorOptions: [],
  invoiceDebtorTotalsLookup: {},
};
