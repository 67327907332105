import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

function formatPhoneNumber(areaCode, phoneNumber) {
  return `${areaCode ? `(${areaCode}) ` : ''}${phoneNumber || ''}`;
}

export const onCreateEditCompanyContact = ({ commandMessage, commandResult }) => {
  const { companyFields, addressFields } = commandMessage.contactFields;

  const optimisticAddress = {
    addressLine1: addressFields && addressFields.addressLine1,
    addressLine2: addressFields && addressFields.addressLine2,
    city: addressFields && addressFields.city,
    state: addressFields && addressFields.state,
    zipCode: addressFields && addressFields.zipCode,
    country: addressFields && addressFields.country,
  };

  const optimisticContact = {
    __typename: 'Contact',
    id: commandResult.contact.id,
    removed: false,
    type: 'organisation',
    displayName: companyFields.companyName,
    email: companyFields.companyEmail,
    cell: '',
    phone: formatPhoneNumber(companyFields.companyPhoneAreaCode, companyFields.companyPhoneNumber),
    phone2: formatPhoneNumber(companyFields.companyFaxAreaCode, companyFields.companyFax),
    representativeOfs: [],
    representativeOfsDisplayName: '',
    businessAddress: optimisticAddress,
    residentialAddress: optimisticAddress,
    roles: [],
    people: [],
  };

  const apolloClient = getApolloClient();
  const contactIdentifier = apolloClient.cache.identify(optimisticContact);

  apolloClient.writeFragment({
    id: contactIdentifier,
    fragment: gql`
      fragment OpdateContact on Contact {
        ${Object.keys(optimisticContact).join('\n')}
      }
    `,
    data: {
      ...optimisticContact,
    },
  });

  return {
    eventType: apolloEntityOpdateEventTypes.ENTITY_ADDED,
    typeName: 'Contact',
    optimisticEntity: optimisticContact,
  };
};
