import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import InvoiceConfirmFinaliseModal from './InvoiceConfirmFinaliseModal';

const hooks = () => ({
  useDebtorNames: ({ debtorQuickPaymentStatus }) => {
    const { debtorsWithQuickPayment, debtorWithoutQuickPayment } = debtorQuickPaymentStatus;

    // Format the list of debtors for the message like <debtor-name>, <debtor-name> and <debtor-name>
    const formatDebtorNames = (debtors) => {
      const names = Object.values(debtors).filter((name) => typeof name === 'string'); // Exclude 'count'
      if (names.length === 0) return '';
      if (names.length === 1) return names[0];
      return `${names.slice(0, -1).join(', ')} and ${names[names.length - 1]}`;
    };

    const debtorWithPayments = formatDebtorNames(debtorsWithQuickPayment);
    const debtorWithoutPayments = formatDebtorNames(debtorWithoutQuickPayment);

    let hasSplitBillingWarningMessage;
    let splitBillingWarningMessage;

    if (!debtorWithPayments || !debtorWithoutPayments) {
      hasSplitBillingWarningMessage = false;
      splitBillingWarningMessage = '';
    } else {
      hasSplitBillingWarningMessage = true;
      splitBillingWarningMessage = `You have applied payments against ${debtorWithPayments}, but not ${debtorWithoutPayments}. Are you sure you wish to continue?`;
    }

    return { hasSplitBillingWarningMessage, splitBillingWarningMessage };
  },
});

export const InvoiceConfirmFinaliseModalContainer = composeHooks(hooks)(InvoiceConfirmFinaliseModal);

InvoiceConfirmFinaliseModalContainer.displayName = 'InvoiceConfirmFinaliseModalContainer';

InvoiceConfirmFinaliseModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hasZeroBalance: PropTypes.bool.isRequired,
  hasUnpaidAD: PropTypes.bool.isRequired,
  invoiceCount: PropTypes.number.isRequired,
  debtorQuickPaymentStatus: PropTypes.shape({
    debtorsWithQuickPayment: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      count: PropTypes.number,
    }),
    debtorWithoutQuickPayment: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      count: PropTypes.number,
    }),
  }),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

InvoiceConfirmFinaliseModalContainer.defaultProps = {
  debtorNames: {},
  debtorQuickPaymentStatus: {
    debtorsWithQuickPayment: { count: 0 },
    debtorWithoutQuickPayment: { count: 0 },
  },
};
