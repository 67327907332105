import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withOnLoad } from '@sb-itops/react';
import moment from 'moment';
import { getLogger } from '@sb-itops/fe-logger';
import { getDevExtremeResourceIdFromUserIds } from 'web/business-logic/calendar';
import { Appointment, MatterSummary, Person } from 'types';
import { checkMatterPermissionsAndDisplayErrorPopupP } from 'web/services/matter-permissions';
import { rrulestr } from 'rrule';
import { useMatterParties } from 'web/hooks/use-matter-parties';
import { AddEventModal, DevExtremeInitAppointment, AskEditAppointmentModalMode } from './AddEventModal';
import { AppointmentFields, getContactIdFromBrokenParties } from './AddEvent';
import { AppointmentSchema } from './AppointmentSchema';

const log = getLogger('AddEventModalContainer');

export interface IAddEventModalContainerProps {
  onDeleteAppointment(options: { appointmentData: any }): Promise<void>;
  onSaveAppointment(options: { appointmentData: any }): Promise<void>;
  appointmentData: Appointment | DevExtremeInitAppointment | {};
  matterSummaries: MatterSummary[];
  matterId?: string;
  loggedInStaff: Person;
  attendees: { id: string; name: string }[];
  onClose: () => void;
}

const DELETED_RECURRING_EVENT_INSTANCE = 4;
const MODIFIED_RECURRING_EVENT_INSTANCE = 3;

const scope = 'AddEvent';

const hooks = ({
  onDeleteAppointment,
  onSaveAppointment,
  appointmentData,
  loggedInStaff,
  onClose,
  matterId,
}: IAddEventModalContainerProps) => ({
  useModalData: () => {
    const {
      formFields,
      formSubmitting,
      formInitialised,
      formValidation,
      onInitialiseForm,
      onClearForm,
      onResetForm,
      onUpdateFieldValues,
      onFieldValueSet,
      onSubmitFormWithValidation,
      onValidateForm,
      submitFailed,
      formValues,
    } = useForm<AppointmentFields>({ scope, schema: AppointmentSchema });
    const { selectableContacts, loading } = useMatterParties(formValues.matterId);
    // Instances of recurring appointments do not have their own id, but they have a reference to their parent which is originalId
    const isNewModifiedAppointment =
      !!(appointmentData as Appointment).originalId && !(appointmentData as Appointment).id;

    const isModifiedRecurringAppointment =
      !!(appointmentData as Appointment).patternAppointmentId || isNewModifiedAppointment;

    return {
      parties: selectableContacts.filter((contact) => contact.type === 'person'),
      partiesLoading: loading,
      formFields,
      formSubmitting,
      formInitialised,
      onInitialiseForm,
      formValidation,
      onClearForm,
      onUpdateFieldValues,
      onFieldValueSet,
      submitFailed,
      mode:
        (appointmentData as Appointment).originalId || (appointmentData as Appointment).id
          ? AskEditAppointmentModalMode.edit
          : AskEditAppointmentModalMode.create,
      onLoad: () => {
        // appointmentData contains startTime and startDate.
        // startTime is the start of the first appointment in a recurring series and startDate is the start of the selected appointment
        const initialStartTime =
          (appointmentData as DevExtremeInitAppointment).startDate || (appointmentData as Appointment).startTime;
        // If there is no initial start time, the appointment should start on the nearest 30 minute interval
        const remainder = 30 - (moment().minute() % 30);
        const startTime = initialStartTime ? moment(initialStartTime) : moment().add(remainder, 'minutes');
        const initialEndTime =
          (appointmentData as DevExtremeInitAppointment).endDate || (appointmentData as Appointment).endTime;
        const endTime = initialEndTime ? moment(initialEndTime) : moment(startTime).add(30, 'minutes');

        onInitialiseForm({
          location: '',
          subject: '',
          description: '',
          rRULE: '',
          allDay: false,
          resourceIds: [loggedInStaff?.id],
          resourceId: getDevExtremeResourceIdFromUserIds(
            (appointmentData as Appointment)?.resourceIds || [loggedInStaff?.id],
          ),
          ...appointmentData,
          patternAppointmentId:
            (appointmentData as Appointment).patternAppointmentId || isModifiedRecurringAppointment
              ? (appointmentData as Appointment).patternAppointmentId || (appointmentData as Appointment).originalId
              : '',
          matterId: (appointmentData as Appointment).matterId || matterId || '',
          startTime: startTime.toDate(),
          endTime: endTime.toDate(),
          attendeeEntityIds: (appointmentData as Appointment).attendeeEntityIds || [],
        });

        return onClearForm;
      },
      onDelete: async () => {
        try {
          if (isModifiedRecurringAppointment) {
            const originalStartTime =
              ((appointmentData as Appointment).patternAppointmentId &&
                (appointmentData as Appointment).originalStartTime) ||
              (appointmentData as DevExtremeInitAppointment).startDate ||
              '';
            const originalEndTime =
              ((appointmentData as Appointment).patternAppointmentId &&
                (appointmentData as Appointment).originalEndTime) ||
              (appointmentData as DevExtremeInitAppointment).endDate ||
              '';
            const recurrenceIndex = rrulestr((appointmentData as Appointment).originalRRULE || '', {
              dtstart: moment((appointmentData as Appointment).startTime).toDate(),
            }).between(
              moment((appointmentData as Appointment).startTime).toDate(),
              moment(originalEndTime).toDate(),
            ).length;
            await onSaveAppointment({
              appointmentData: {
                ...appointmentData,
                originalStartTime,
                originalEndTime,
                startTime: (appointmentData as DevExtremeInitAppointment).startDate,
                endTime: (appointmentData as DevExtremeInitAppointment).endDate,
                eventType: DELETED_RECURRING_EVENT_INSTANCE,
                recurrenceInfo: `<RecurrenceInfo Id="${
                  (appointmentData as Appointment).recurrenceInfoId
                }" Index="${recurrenceIndex}" />`,
                patternAppointmentId:
                  (appointmentData as Appointment).patternAppointmentId || isModifiedRecurringAppointment
                    ? (appointmentData as Appointment).patternAppointmentId ||
                      (appointmentData as Appointment).originalId
                    : '',
              },
            });
          } else {
            await onDeleteAppointment({ appointmentData });
          }
          displaySuccessToUser('Event deleted');
          onClose();
        } catch (e: any) {
          displayErrorToUser('Failed to delete event');
        }
      },
      onSubmit: async () => {
        onValidateForm();

        await onSubmitFormWithValidation({
          submitFnP: async (finalFormValues) => {
            if (
              !(await checkMatterPermissionsAndDisplayErrorPopupP(finalFormValues.matterId, {
                group: 'matter-permissions-events',
              }))
            ) {
              return;
            }

            try {
              const finalValues = finalFormValues;
              finalValues.subject = (finalValues.subject || '').trim();
              if (isModifiedRecurringAppointment) {
                finalValues.originalStartTime =
                  ((appointmentData as Appointment).patternAppointmentId &&
                    (appointmentData as Appointment).originalStartTime) ||
                  (appointmentData as DevExtremeInitAppointment).startDate ||
                  '';
                finalValues.originalEndTime =
                  ((appointmentData as Appointment).patternAppointmentId &&
                    (appointmentData as Appointment).originalEndTime) ||
                  (appointmentData as DevExtremeInitAppointment).endDate ||
                  '';
                finalValues.eventType = MODIFIED_RECURRING_EVENT_INSTANCE;
                if (isNewModifiedAppointment) {
                  const recurrenceIndex = rrulestr(finalValues.originalRRULE || finalValues.rRULE, {
                    dtstart: moment((appointmentData as Appointment).startTime).toDate(),
                  }).between(
                    moment((appointmentData as Appointment).startTime).toDate(),
                    moment(finalValues.originalEndTime).toDate(),
                  ).length;
                  finalValues.recurrenceInfo = `<RecurrenceInfo Id="${finalValues.recurrenceInfoId}" Index="${recurrenceIndex}" />`;
                }
              }
              delete finalValues.originalAppointment;
              // Fix any broken parties
              finalValues.attendeeEntityIds = finalValues.attendeeEntityIds
                .map((entityId) => getContactIdFromBrokenParties(entityId, selectableContacts))
                .filter((entityId) => !!entityId);
              await onSaveAppointment({ appointmentData: finalValues });
              displaySuccessToUser('Successfully saved event');
              onClose();
            } catch (err: any) {
              displayErrorToUser('Failed to save event');
              log.error(err);
            }
          },
        });
      },
      onSubmitSaveAndNew: async () => {
        onValidateForm();

        await onSubmitFormWithValidation({
          submitFnP: async (finalFormValues) => {
            if (
              !(await checkMatterPermissionsAndDisplayErrorPopupP(finalFormValues.matterId, {
                group: 'matter-permissions-events',
              }))
            ) {
              return;
            }

            try {
              await onSaveAppointment({ appointmentData: finalFormValues });
              displaySuccessToUser('Successfully saved appointment');
              onResetForm();
            } catch (err: any) {
              displayErrorToUser('Failed to save appointment');
              log.error(err);
            }
          },
        });
      },
    };
  },
});

export const AddEventModalContainer = composeHooks(hooks)(withOnLoad(AddEventModal));
