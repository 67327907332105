angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('settings.client-portal', {
      template: '<sbb-communicate-iframe mode="\'SETTINGS\'" style="flex: 1;"></sbb-communicate-iframe>',
      url: '/communicate',
      data: {
        navHighlights: ['client-portal']
      }
    });
});
