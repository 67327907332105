import { getInvoiceDebtorTotalsByInvoiceId } from '@sb-billing/redux/invoice-debtor-totals';
import { getCurrentConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';
import * as messageDisplay from '@sb-itops/message-display';

angular.module('sb.billing.webapp').controller('SbViewSplitBillingController', function ($scope, $state, $stateParams, $controller, sbInvoicingService, sbLoggerService, sbMessageDisplayService, $http, sbGenericEndpointService, FileSaver, Blob) {
  'use strict';

  let that = this;
  angular.extend(this, $controller('SbPartialStateController', { $scope: $scope }));

  const log = sbLoggerService.getLogger('SbViewSplitBillingController');
  const invoiceId = $stateParams.invoiceId;
  const debtorId = $stateParams.debtorId;

  that.isDownloading= false;
  that.sbParams.sbSubState = getSubSState($state.current.name);
  that.isDebtorInvoicePaid = getIsDebtorInvoicePaid();
  that.matterId = sbInvoicingService.getInvoice(invoiceId).matterId;
  that.isSplitBillingEnabledOnMatter = getCurrentConfigurationByMatterId(that.matterId)?.splitBillingSettings?.isEnabled;

  $scope.$on('smokeball-data-update-sbInvoiceTotalsService', function () {
    that.isDebtorInvoicePaid = getIsDebtorInvoicePaid();
  });

  $scope.$on('smokeball-data-update-sbBillingConfigurationService', function () {
    that.isSplitBillingEnabledOnMatter = that.sbData.matterBillingConfig?.splitBillingSettings?.isEnabled;
  });

  $scope.$on('$stateChangeSuccess', (event, state) => {
    that.sbParams.sbSubState = getSubSState(state.name);
  });

  function getSubSState(name) {
    var subState = name.match(/^.*\.([^.]*)$/);
    return subState ? subState[1] : name;
  }

  function getIsDebtorInvoicePaid() {
    const invoiceDebtorTotals = getInvoiceDebtorTotalsByInvoiceId(invoiceId);
    const debtorTotals = (invoiceDebtorTotals || []).find((t) => t.debtorId === debtorId);
    return debtorTotals && debtorTotals.unpaid === 0;
  }

  that.generatePaymentDetails = function () {
    if (!invoiceId) {
      messageDisplay.warn('Failed to generate payment detail due to missing invoice ID.');
      throw new Error('Could not find invoice ID for generating payment details');
    }
    if (!debtorId) {
      messageDisplay.warn('Failed to generate payment detail due to missing debtor ID.');
      throw new Error('Could not find debtor ID for generating payment details');
    }

    $state.go('home.billing.split-billing-invoice-proof-of-payment', { invoiceId, debtorId })
  };

  that.downloadSingleDebtorInvoicePdf = function ({ debtorId }) {
    const invoice = sbInvoicingService.getInvoice(invoiceId);

    if (invoice) {
      let invoiceVersionId = invoice.versionId;
      if (invoice.isOriginalInvoice) {
        invoiceVersionId = sbInvoicingService.getOriginalInvoiceVersionId(invoice.invoiceId);
      }
      if (sbInvoicingService.isInvoiceVoided(invoice.invoiceId)) {
        invoiceVersionId = sbInvoicingService.getClassicInvoiceVersionId(invoice.invoiceId);
      }

      const splitBillingDebtor = invoice.debtors && invoice.debtors.find((d) => d.id === debtorId);
      const invoiceNumberSuffix = splitBillingDebtor && splitBillingDebtor.invoiceNumberSuffix;

      that.isDownloading = true;
      return downloadAndBlobifyChildInvoicePdfP({ invoiceVersionId, debtorId }).then(function (blob) {
        FileSaver.saveAs(blob, `invoice-${invoice.invoiceNumber}-${invoiceNumberSuffix || ''}.pdf`);
      }).catch(function (err) {
        sbMessageDisplayService.error('Failed to download invoice. Please check your connection and try again later');
        log.error('Failed to download invoice', err);
      })
      .finally(function () {
        that.isDownloading = false;
      });
    } else {
      throw new Error('no invoice found to download');
    }
  }

  that.downloadAllDebtorInvoicePdf = function () {
    const invoice = sbInvoicingService.getInvoice(invoiceId);
    if (invoice && invoice.debtors) {
      invoice.debtors.forEach((d) => {
        that.downloadSingleDebtorInvoicePdf({ debtorId: d.id });
      })
    } else {
      throw new Error('no invoice found to download');
    }
  }

  function downloadAndBlobifyChildInvoicePdfP({ invoiceVersionId, debtorId }) {
    const payloadConfig = {
      skipCamelCase: true,
    };
    const namespace = `billing/invoice-pdf`;
    const additional = `${invoiceVersionId}/debtor/${debtorId}/presigned-url` 
    log.info(`Attempting to get presigned url invoiceId: '${that.invoiceId}', versionId '${invoiceVersionId}', debtorId: '${debtorId}'`);

    return sbGenericEndpointService.getPayloadP(namespace, additional, payloadConfig).then(async (payload) => {
      const request = {
        method: 'GET',
        url: payload.preSignedUrl,
        responseType: 'arraybuffer',
      };
      const pdfResponse = await $http(request);
      const pdfBlob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      return pdfBlob;
    });
  }
});