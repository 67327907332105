import { featureActive } from '@sb-itops/feature';
import { shutdown, Intercom, update } from "@intercom/messenger-js-sdk";
import { getFirmName, getLoggedInUserId } from '@sb-firm-management/redux/firm-management';
import { getAccountId, getProductTier, getUserId, getUsername } from 'web/services/user-session-management';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { getRegion } from '@sb-itops/region';
import { fetchSmokeballSubscriptionRecord } from '../subscription';
import { store } from '@sb-itops/redux';
import { InitStaffSettings, IntercomUserSetup, MicrosoftIdentity, UserTwoFactorStatus } from 'web/graphql/queries';
import { getApolloClient } from '../apollo';
import { getTrustAccounts } from '@sb-billing/redux/bank-account';
import { productTierLabelByTierId } from '@sb-finance/business-logic/subscription/entities/constants';
import { isSubscriptionFree } from '@sb-finance/redux/subscription';

const APP_ID = "c1sbgle1";

const getActivePaymentProviderType = async ({ log }) => {
  try {
    const response = await fetchGetP({ path: '/billing/payment-provider/settings/sync/:accountId/all' });

    if (response.status === 200 && response.body) {
      return response.body.activeProvider || 'NONE';
    }
  } catch (err) {
    log.warn('Failed to get active provider during Intercom initialisation', err);
  }
};

const CALENDAR_SCOPE = 'calendar';

const initialiseIntercom = async ({ defaultTeardown, log }) => {
  if (!featureActive('NUCWEB-914')) {
    return defaultTeardown;
  }

  const ENDPOINT = `/itops/authorisation/intercom/:accountId/`;
  let reduxSubscriptionTeardown = null;

  // Deliberately not awaited right now so it doesnt block loading, not sure if this will be necessary in future if we use this for onboarding to prevent it flashing in
  fetchGetP({
    path: ENDPOINT,
  })
    .then(async (response) => {
      const apolloClient = getApolloClient();
      const { data: staffData } = await apolloClient.query({query: InitStaffSettings.query, fetchPolicy: 'cache-only',});
      const loggedInStaffMember = (staffData && staffData.loggedInStaff) || {};
      Intercom({
        // Intercom values
        app_id: APP_ID,
        user_id: getLoggedInUserId(),
        user_hash: response.body.token,
        email: getUsername(),
        // "Last seen": moment().unix(), // TODO I think we get this by default, check for us
        created_at: 'UNAVAILABLE',
        name: loggedInStaffMember.name,
        page_title: 'dashboard', // hook for page_title is in link-service
        // Custom fields
        "Account Id": getAccountId(),
        "Firm Name": getFirmName(),
        "Smokeball Region": getRegion(),
      });

      // Fetch init data for intercom so the correct tours are shown
      let intercomInitCalls = [];
      // intercomSetupResults
      intercomInitCalls.push(apolloClient.query({
        query: IntercomUserSetup,
        variables: {
          staffId: loggedInStaffMember.id,
        }
      }));
      //subscriptionRecord
      intercomInitCalls.push(fetchSmokeballSubscriptionRecord({ accountId: getAccountId() }).catch(() => log.info('Failed to fetch subscription for intercom setup')));
      //activeProviderType
      intercomInitCalls.push(getActivePaymentProviderType({ log }).catch(() => log.info('Failed to fetch active provider type for intercom setup')));

      let userTwoFactorStatusQuery = Promise.resolve();
      if (featureActive('BB-13237')) {
        userTwoFactorStatusQuery = apolloClient.query({
          query: UserTwoFactorStatus.query,
          variables: {
            id: getUserId(),
          },
        });
      }
      intercomInitCalls.push(userTwoFactorStatusQuery);
      
      let microsoftIdentityResultsQuery = Promise.resolve();
      if (featureActive('NUCWEB-609')) {
        microsoftIdentityResultsQuery = apolloClient.query({query: MicrosoftIdentity.query});
      }
      intercomInitCalls.push(microsoftIdentityResultsQuery);
      
    
      const [intercomSetupResults, subscriptionRecord, activeProviderType, userTwoFactorStatusResults, microsoftIdentityResults  ] = await Promise.all(intercomInitCalls);
      
      const intercomUserSetup = intercomSetupResults?.data?.intercomUserSetup || {};
      const twoFactorEnabled = userTwoFactorStatusResults?.data?.userTwoFactorStatus?.twoFactorEnabled;
      const outlookSynced = microsoftIdentityResults?.data?.microsoftIdentity?.microsoftId &&
       (microsoftIdentityResults?.data?.microsoftIdentity?.authorizedScopes || []).includes(CALENDAR_SCOPE);

      const tier = getProductTier();

      let billingStatusText = 'None';
      if (subscriptionRecord?.status === 0) {
        billingStatusText = 'Trial';
      }
      if (subscriptionRecord?.status === 1) {
        billingStatusText = 'Paid';
      }
      if (isSubscriptionFree(subscriptionRecord, tier)) {
        billingStatusText = 'Free';
      }

      if (window?.intercomSettings) {
        window.intercomSettings = {
          ...window.intercomSettings,
          // Custom fields
          "Billing Tier": productTierLabelByTierId[tier],
          "Billing Status": billingStatusText,
          "TwoFA Enabled": twoFactorEnabled,
          "Outlook Synced": outlookSynced,
          "Payment Provider": activeProviderType,
          "Matter Created": intercomUserSetup.hasCreatedMatter,
          "Invoice Created": intercomUserSetup.hasCreatedInvoice,
          "Fee Created": intercomUserSetup.hasCreatedFee,
          "Memo Created": intercomUserSetup.hasCreatedMemo,
          "Event Created": intercomUserSetup.hasCreatedEvent,
          "Trust Account Created": !!(getTrustAccounts() || []).length > 1,
          // "Add Ons Enabled": 'Add Ons Enabled',
        };
        update(window.intercomSettings);
      }

      reduxSubscriptionTeardown = store.subscribe(() => {
        // Shuts down intercom if the feature is turned off. Does not enable the feature after it is turned on again, user would need to refresh.
        if (!featureActive('NUCWEB-914')) {
          shutdown();
          reduxSubscriptionTeardown && reduxSubscriptionTeardown();
          reduxSubscriptionTeardown = null;
          return;
        }
        const data = window.intercomSettings;
        const tierText = productTierLabelByTierId[getProductTier()];
        if (data && data["Billing Tier"] !== tierText) {
          data["Billing Tier"] = tierText;
          update(data);
        }
        if (data && data["Firm Name"] !== getFirmName()) {
          data["Firm Name"] = getFirmName();
          update(data);
        }
      });
    })
    .catch((err) => {
      log.error('Error initialising intercom', err)
    });

  return () => {
    shutdown();
    reduxSubscriptionTeardown && reduxSubscriptionTeardown();
    reduxSubscriptionTeardown = null;
  };
};

export { initialiseIntercom };
