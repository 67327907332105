import { make } from '../shared/redux-helpers';
import { defaultPath } from './default-path';
import { EmbeddedIframePosition } from '../shared/types';

const SHOW = `${defaultPath}/SHOW`;
const HIDE = `${defaultPath}/HIDE`;
const ERROR = `${defaultPath}/ERROR`;
const INITIALISED = `${defaultPath}/INITIALISED`;

type ActionPayloads = {
  showIframe: {
    position: EmbeddedIframePosition;
    matterId: string | null;
  };
  hideIframe: {
    matterId: string | null;
  };
  setError: {
    value: boolean;
  };
  setInitialised: {
    value: boolean;
  };
};

const actionTypeMapping: { [k in keyof ActionPayloads]: string } = {
  showIframe: SHOW,
  hideIframe: HIDE,
  setError: ERROR,
  setInitialised: INITIALISED,
};

export const { actionCreators, actionGuards } = make<ActionPayloads>(actionTypeMapping);
