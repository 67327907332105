/* eslint-disable func-names */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getAvailableFundsForCurrentDebtor } from '@sb-billing/business-logic/split-billing';
import { Table, Column, utils } from '@sb-itops/react/table';
import { Checkbox } from '@sb-itops/react/checkbox';
import { Cent } from '@sb-itops/money';
import { CurrencyInput2 } from '@sb-itops/react';
import { ContactDisplay } from '@sb-customer-management/react';
import classnames from 'classnames';

import Styles from './InvoicePaymentAllocationTable.module.scss';

const { balanceCellLocalisedRenderer } = utils;

const checkboxHeaderCellRenderer =
  ({ allocations, onAllocateAll, isSplitBillingEnabled }) =>
  () => {
    const selected =
      allocations.length > 0 &&
      allocations.every((allocation) => {
        const available = isSplitBillingEnabled
          ? getAvailableFundsForCurrentDebtor({
              available: allocation.available,
              totalAllocatedAmount: allocation.allocatedAmount,
              currentDebtorAmount: allocation.amount,
            })
          : allocation.available;
        return allocation.amount === available;
      });

    return (
      <div className={Styles.allocateCheckbox}>
        <Checkbox checked={selected} onChange={() => onAllocateAll()} />
      </div>
    );
  };

const checkboxCellRenderer =
  ({ onChange, isSplitBillingEnabled }) =>
  ({ rowData }) => {
    const available = isSplitBillingEnabled
      ? getAvailableFundsForCurrentDebtor({
          available: rowData.available,
          totalAllocatedAmount: rowData.allocatedAmount,
          currentDebtorAmount: rowData.amount,
        })
      : rowData.available;
    return (
      <div className={Styles.inclusionCheckbox}>
        <Checkbox
          checked={rowData.amount === available}
          onChange={() => {
            onChange({ ...rowData, amount: rowData.amount === available ? 0 : available });
          }}
        />
      </div>
    );
  };

const footer = ({ cellData }) => (
  <div className={classnames(Styles.rightAlign, Styles.footer)}>{`$${new Cent(cellData.total)}`}</div>
);

const amountCellRenderer =
  ({ onChange, isSplitBillingEnabled }) =>
  ({ rowData, cellData }) => {
    const available = isSplitBillingEnabled
      ? getAvailableFundsForCurrentDebtor({
          available: rowData.available,
          totalAllocatedAmount: rowData.allocatedAmount,
          currentDebtorAmount: rowData.amount,
        })
      : rowData.available;

    return (
      <div className={Styles.rightAlign}>
        <CurrencyInput2
          min={0}
          value={cellData}
          onChange={(e) => {
            const amount = Math.min(e.target.value || 0, available);
            onChange({ ...rowData, amount });
          }}
          max={available}
        />
      </div>
    );
  };

const amountHeaderRenderer = () => (
  <div className={Styles.rightAlign}>
    <span className="header-label">Amount</span>
  </div>
);

const balanceHeaderRenderer = () => (
  <div className={Styles.availableBalanceColumn}>
    <span className="header-label">Available</span>
  </div>
);

const allocatedHeaderRenderer = () => (
  <div className={Styles.allocatedAmountColumn}>
    <span className="header-label">Allocated</span>
  </div>
);

const contactCellRenderer =
  ({ onContactLinkClick }) =>
  ({ cellData }) => <ContactDisplay contactId={cellData} onClickLink={onContactLinkClick} asLink showLastNameFirst />;

const InvoicePaymentAllocationTable = memo(
  ({ allocations, onChange, onContactLinkClick, onAllocateAll, isSplitBillingEnabled }) => {
    // build the summary based on the matters
    const summary = allocations.reduce(
      (acc, allocation) => {
        acc.amount.total += Number.isFinite(allocation.amount) ? allocation.amount : 0;
        return acc;
      },
      {
        amount: {
          total: 0,
        },
      },
    );

    return (
      <Table className={Styles.allocationsTableContainer} list={allocations} summary={summary} showFooter>
        <Column
          dataKey="contactId"
          label="Contact"
          cellRenderer={contactCellRenderer({ onContactLinkClick })}
          flexGrow={14}
          disableSort
        />
        <Column
          dataKey="available"
          label="Available"
          flexGrow={2}
          width={150}
          headerRenderer={balanceHeaderRenderer}
          cellRenderer={({ cellData }) =>
            balanceCellLocalisedRenderer({ cellData, classNames: Styles.availableBalanceColumn })
          }
          disableSort
        />
        {isSplitBillingEnabled && (
          <Column
            dataKey="allocatedAmount"
            label="Allocated"
            flexGrow={2}
            width={150}
            headerRenderer={allocatedHeaderRenderer}
            cellRenderer={({ cellData }) =>
              balanceCellLocalisedRenderer({ cellData, classNames: Styles.availableBalanceColumn })
            }
            disableSort
          />
        )}
        <Column
          dataKey="amount"
          label="Amount"
          flexGrow={2}
          width={150}
          cellRenderer={amountCellRenderer({ onChange, isSplitBillingEnabled })}
          disableSort
          headerRenderer={amountHeaderRenderer}
          footerRenderer={footer}
        />
        <Column
          dataKey="UNNEEDED"
          label=""
          width={34}
          cellRenderer={checkboxCellRenderer({ onChange, isSplitBillingEnabled })}
          headerRenderer={checkboxHeaderCellRenderer({ allocations, onAllocateAll, isSplitBillingEnabled })}
          disableSort
        />
      </Table>
    );
  },
);

InvoicePaymentAllocationTable.displayName = 'InvoicePaymentAllocationTable';

InvoicePaymentAllocationTable.propTypes = {
  allocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  isSplitBillingEnabled: PropTypes.bool,
  onAllocateAll: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onContactLinkClick: PropTypes.func,
};

InvoicePaymentAllocationTable.defaultProps = {
  allocations: [],
  isSplitBillingEnabled: false,
  onContactLinkClick: undefined,
};

export default InvoicePaymentAllocationTable;
