import { useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getRegion } from '@sb-itops/region';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { featureActive } from '@sb-itops/feature';
import { getLogger } from '@sb-itops/fe-logger';
import {
  providersByRegion,
  defaultProviderByRegion,
  providerNames,
  providers,
} from '@sb-billing/business-logic/payment-provider/entities/constants';
import {
  activateProvider,
  deactivateProvider,
  saveSettings,
} from '@sb-billing/business-logic/payment-provider/services/client-api';
import { getAllSettings, getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';
import { sendMetric } from 'web/services/metrics';
import { update } from '@intercom/messenger-js-sdk';
import { PaymentProviderSettings } from './PaymentProviderSettings';

const logger = getLogger('payment-provider-activation-form-container');
const providersForRegion = providersByRegion[getRegion()] || {};
const defaultProviderType = defaultProviderByRegion[getRegion()];

// Providers may have some settings saved, but they are not actually configured properly.
// For this reason, we define what "configured provider" means
const providerConfiguredFn = {
  [providers.LAWPAY]: (settings) => !!settings.publicKey,
  [providers.STRIPE]: (settings) => !!settings.publicKey,
  [providers.EZY_COLLECT]: (settings) => !!settings.publicKey,
  [providers.FEE_WISE]: (settings) => !!settings.firmId,
};

/**
 * Logic for which provider tab should be selected by default https://smokeball.atlassian.net/browse/BB-12212
 * 1. Active payment provider
 * 2. else configured payment provider
 *   2.1 If two are configured and none active, default to Smokeball
 * 3. else application started payment provider
 * 4. else Smokeball
 */
const determineDefaultProviderSelected = (providerOptions) => {
  const availableProviders = providerOptions.map((option) => option.value);
  const allSettings = getAllSettings();
  const settingsProviderTypes = Object.keys(allSettings.providers);

  // 1. Active payment provider
  if (allSettings.activeProvider) {
    return allSettings.activeProvider;
  }

  // 2. else configured payment provider
  if (settingsProviderTypes.length === 1 && availableProviders.includes(settingsProviderTypes[0])) {
    const providerType = settingsProviderTypes[0];
    const providerSettings = allSettings.providers[providerType];
    const isProviderConfiguredFn = providerConfiguredFn[providerType];

    // We default to this provider only if we consider it configured. This is to cover case when Lawpay in old UI
    // was set to active with default settings before even finishing connection. Therefore firms may have default
    // settings for Lawpay even if it is not connected and we don't want the new UI to default to Lawpay tab.
    if (isProviderConfiguredFn(providerSettings)) {
      return settingsProviderTypes[0];
    }
  }

  // 2.1 If two are configured and none active, default to Smokeball
  if (settingsProviderTypes.length > 1 && availableProviders.includes(providers.FEE_WISE)) {
    return providers.FEE_WISE;
  }

  // 3. else application started payment provider
  // TODO - do we need this? This is specific to FeeWise only and FeeWise is default in step 4 anyway
  // For this it would be easier to have this info in cache, otherwise I would have to query DynamoDB and make this async

  // 4. Default payment provider for region
  if (defaultProviderType && availableProviders.includes(defaultProviderType)) {
    return defaultProviderType;
  }

  return (availableProviders.length && availableProviders[0]) || '';
};

const hooks = () => ({
  useAvailableProviders: () => {
    const providerOptions = Object.keys(providersForRegion).reduce((acc, providerType) => {
      if (
        (providerType === providers.EZY_COLLECT && !featureActive('BB-10108')) ||
        (providerType === providers.FEE_WISE && !featureActive('BB-12038'))
      ) {
        return acc;
      }

      // In regular context FeeWise is displayed as "Smokeball payments" but for settings menu we display it as
      // "Smokeball" only. I didn't want to introduce it as another constant in BL unless we have multiple uses
      const providerOption = {
        label: providerType === providers.FEE_WISE ? 'Smokeball' : providerNames[providerType],
        value: providerType,
      };
      acc.push(providerOption);

      return acc;
    }, []);

    const [providerTypeSelected, setProviderTypeSelected] = useState(() =>
      determineDefaultProviderSelected(providerOptions),
    );

    return {
      providerOptions,
      providerTypeSelected,
      onProviderTypeSelected: (providerType) => setProviderTypeSelected(providerType),
    };
  },
  useActivation: () => {
    const [savingProviderType, setSavingProviderType] = useState('');

    return {
      savingProviderType,
      onSaveSettings: async ({ providerType, providerActivateToggled, providerSpecificSettings }) => {
        try {
          setSavingProviderType(providerType);

          sendMetric('SavePaymentProvider');

          await saveSettings({
            fetchPostP,
            providerType,
            providerSettings: providerSpecificSettings,
          });
          displaySuccessToUser('Successfully saved settings');

          const activeProvider = getActiveProvider();
          const { activateType, deactivateType } = getActivateDeactivateProviderTypes({
            activeProvider,
            targetProvider: providerType,
            providerActivateToggled,
          });

          try {
            if (deactivateType) {
              await deactivateProvider({ fetchPostP, providerType: deactivateType });

              if (featureActive('BB-13244')) {
                window?.ChurnZero?.push(['setAttribute', 'account', 'Payment Processor', 'NONE']);
              }
              if (featureActive('NUCWEB-914') && window?.intercomSettings) {
                window.intercomSettings['Payment Provider'] = 'NONE';
                update(window.intercomSettings);
              }
            }
            if (activateType) {
              await activateProvider({ fetchPostP, providerType: activateType });

              if (featureActive('BB-13244')) {
                window?.ChurnZero?.push(['setAttribute', 'account', 'Payment Processor', activateType]);
              }
              if (featureActive('NUCWEB-914') && window?.intercomSettings) {
                window.intercomSettings['Payment Provider'] = activateType;
                update(window.intercomSettings);
              }
            }
            showSuccess({ activateType, deactivateType });
          } catch (err) {
            logger.error(`Failed to activate(${activateType})/deactivate(${deactivateType}) payment provider`, err);
            displayErrorToUser(`Failed to activate/deactivate payment provider, please try again later.`);
          }
        } catch (err) {
          logger.error('Failed to save settings', err);
          displayErrorToUser('Failed to save settings');
          throw err;
        } finally {
          setSavingProviderType('');
        }
      },
    };
  },
});

const showSuccess = ({ activateType, deactivateType }) => {
  if (activateType && deactivateType) {
    displaySuccessToUser(
      `${providerNames[deactivateType] || 'payment provider'} deactivated, ${
        providerNames[activateType] || 'payment provider'
      } activated.`,
    );
  } else if (deactivateType && !activateType) {
    displaySuccessToUser(`${providerNames[deactivateType] || 'payment provider'} deactivated`);
  } else if (activateType && !deactivateType) {
    displaySuccessToUser(`${providerNames[activateType] || 'payment provider'} activated`);
  }
};

// We need to cover 3 situations:
// 1. Target provider is active and we wan't it activate, or is not active and we want it not active
//    - Don't do anything
// 2. Target provider is active but we don't want it active
//    - Deactive target provider
// 3. Target provider is not active, but we want it active
//    - Deactivate currently activated provider (if any) and active target provider

const getActivateDeactivateProviderTypes = ({ activeProvider, targetProvider, providerActivateToggled }) => {
  const isTargetProviderActive = activeProvider === targetProvider;

  const providerTypes = {
    deactivateType: undefined,
    activateType: undefined,
  };

  if (isTargetProviderActive === providerActivateToggled) {
    return providerTypes;
  }

  if (isTargetProviderActive && !providerActivateToggled) {
    providerTypes.deactivateType = targetProvider;
  } else if (!isTargetProviderActive && providerActivateToggled) {
    providerTypes.deactivateType = activeProvider;
    providerTypes.activateType = targetProvider;
  }

  return providerTypes;
};

export const PaymentProviderSettingsContainer = withReduxProvider(composeHooks(hooks)(PaymentProviderSettings));

PaymentProviderSettingsContainer.displayName = 'PaymentProviderSettingsContainer';

PaymentProviderSettingsContainer.propTypes = {};

PaymentProviderSettingsContainer.defaultProps = {};
