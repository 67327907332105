import {
  bankAccountTypeDisplayByValue,
  bankAccountEntityTypeEnum,
  bankAccountTypeEnum,
} from '@sb-billing/business-logic/bank-account/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';

export const constructBalances = ({
  bankAccountsWithBalances,
  defaultTrustAccountId,
  isMatterContactBalanceType,
  t,
  matterBalancesAsOfDateMap,
  matterContactBalancesAsOfDateMap,
}) => {
  let matterTrustBalance = 0;
  let trustAccountsDisabled = true;
  const balances = {
    [bankAccountTypeEnum.TRUST]: {
      type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.TRUST],
      balance: 0, // Used for cases such as lessFundsInTrust
      availableBalanceAsOfDate: 0, // Used for payments (invoices should only be paid by what funds are available for use in the trust account)
      contactBalances: [],
    },
    [bankAccountTypeEnum.OPERATING]: {
      type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.OPERATING],
      balance: 0,
      contactBalances: [],
    },
    [bankAccountTypeEnum.CREDIT]: {
      type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.CREDIT],
      balance: 0,
      contactBalances: [],
    },
  };

  bankAccountsWithBalances.forEach((bankAccount) => {
    switch (bankAccount.accountType) {
      case bankAccountTypeEnum.TRUST:
        trustAccountsDisabled = false; // we have at least 1 active trust account
        // When calculating the "matterTrustBalance", we use balance (as opposed to the available balance as of date)
        //  * This is used for calculating the retainer request amount
        //  * As such, we should account for all funds paid by the firm's client, including funds yet to be deposited into trust accounts (i.e. future dated transactions)
        //    * Otherwise, every invoice until the effective date of whatever payment meets the minimum threshold will request for extra funds
        matterTrustBalance += bankAccount.bankAccountBalances?.matterBalances?.[0]?.balance || 0;

        if (defaultTrustAccountId && bankAccount.id === defaultTrustAccountId) {
          const trustAccountContactBalances = isMatterContactBalanceType
            ? Object.values(matterContactBalancesAsOfDateMap?.[defaultTrustAccountId] || {})
            : [];

          balances[bankAccountTypeEnum.TRUST] = {
            type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.TRUST],
            name: getBankAccountName(bankAccount, t),
            bankAccountId: bankAccount?.id,
            // for trust, we use available balance, which is balance minus protected funds
            balance: bankAccount?.bankAccountBalances?.matterBalances?.[0]?.availableBalance || 0,
            availableBalanceAsOfDate: deriveAvailableBalanceAsOfDateForTrustAccount({
              matterBalancesAsOfDateMap,
              trustAccountContactBalances,
              trustAccountId: defaultTrustAccountId,
              isMatterContactBalanceType,
            }),
            contactBalances: isMatterContactBalanceType
              ? trustAccountContactBalances.map((contactBalance) => ({
                  ...contactBalance,
                  // add the (account) type to the balance, as this makes it easier for consumers of this data to interact with the balance entities
                  type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.TRUST],
                }))
              : [],
          };
        }
        break;
      case bankAccountTypeEnum.OPERATING:
        balances[bankAccountTypeEnum.OPERATING] = {
          type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.OPERATING],
          bankAccountId: bankAccount?.id,
          balance: bankAccount?.bankAccountBalances?.matterBalances?.[0]?.balance || 0,
          contactBalances: isMatterContactBalanceType
            ? (bankAccount?.bankAccountBalances?.contactBalances || []).map((contactBalance) => ({
                ...contactBalance,
                // add the (account) type to the balance, as this makes it easier for consumers of this data to interact with the balance entities
                type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.OPERATING],
                bankAccountId: bankAccount?.id,
              }))
            : [],
        };
        break;
      case bankAccountTypeEnum.CREDIT:
        balances[bankAccountTypeEnum.CREDIT] = {
          type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.CREDIT],
          bankAccountId: bankAccount?.id,
          balance: bankAccount?.bankAccountBalances?.matterBalances?.[0]?.balance || 0,
          contactBalances: isMatterContactBalanceType
            ? (bankAccount?.bankAccountBalances?.contactBalances || []).map((contactBalance) => ({
                ...contactBalance,
                // add the (account) type to the balance, as this makes it easier for consumers of this data to interact with the balance entities
                type: bankAccountTypeDisplayByValue[bankAccountEntityTypeEnum.CREDIT],
                bankAccountId: bankAccount?.id,
              }))
            : [],
        };
        break;

      default:
        break;
    }
  });

  return {
    balances,
    trustAccountsDisabled,
    matterTrustBalance, // matter balance of all active trust accounts
    isBalanceAvailable: Object.values(balances).some((balance) => balance.balance > 0),
  };
};

function deriveAvailableBalanceAsOfDateForTrustAccount({
  isMatterContactBalanceType,
  trustAccountId,
  matterBalancesAsOfDateMap,
  trustAccountContactBalances,
}) {
  if (isMatterContactBalanceType) {
    const { availableBalanceAsOfDate } = trustAccountContactBalances.reduce(
      (acc, matterContactBalance) => {
        acc.availableBalanceAsOfDate += matterContactBalance.availableBalance;
        return acc;
      },
      {
        availableBalanceAsOfDate: 0,
      },
    );

    return availableBalanceAsOfDate;
  }

  return matterBalancesAsOfDateMap?.[trustAccountId]?.availableBalance ?? 0;
}
