import PropTypes from 'prop-types';
import { useState } from 'react';

import { useInvoiceZeroBalance } from '@sb-itops/react';
import { sort } from '@sb-itops/sort';
import composeHooks from '@sb-itops/react-hooks-compose';

import { getInvoiceDebtorIds } from '@sb-billing/redux/invoices';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getMap as getBankAccountMap } from '@sb-billing/redux/bank-account-balances';
import * as bankAccountBalances from '@sb-billing/redux/bank-account-balances.2';
import { getCurrentConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import FinaliseWithPaymentsTable from './FinaliseWithPaymentsTable';

const { getMatterContactBalances, getAllMatterBalances } = bankAccountBalances.selectors;

const hooks = () => ({
  useInvoiceZeroBalance,
  useMattersWithPayments: () => {
    const [mattersWithPayments, setMattersWithPayments] = useState(undefined);

    return {
      mattersWithPayments,
      setMattersWithPayments,
    };
  },
  useFinaliseWithPaymentsTableData: ({
    invoices,
    invoicesMap,
    sortBy,
    sortDirection,
    expanded,
    selected,
    selectedInvoiceIds,
    payments,
    autoAllocations,
    bankAccountIds,
    onToggleSelectAll,
  }) => {
    const matterBalancesMap = getAllMatterBalances(getBankAccountMap());
    const matterInvoicesMap = invoices.reduce((acc, invoice) => {
      if (acc[invoice.matterId]) {
        acc[invoice.matterId].push(invoice);
      } else {
        // eslint-disable-next-line no-param-reassign
        acc[invoice.matterId] = [invoice];
      }
      return acc;
    }, {});

    const matterInvoiceRowsMap = Object.entries(matterInvoicesMap).reduce((acc, [matterId, matterInvoices]) => {
      // we always generate the invoice rows as a selected, hidden invoice row
      // with an error should block finalisation
      const matterWithInvoiceRows = getInvoiceRows(
        sort(matterInvoices, ['issuedDate', 'validFrom'], ['asc', 'asc']),
        selected,
        bankAccountIds,
        payments,
        // a matter wont have a balance if a transaction hasnt been made for it
        matterBalancesMap[matterId] || {},
        // if a matter has no autoAllocation value it is a new matter, which we default to being auto allocated.
        // otherwise, take the user specified autoAllocation value
        autoAllocations[matterId] === undefined || autoAllocations[matterId],
        getCurrentConfigurationByMatterId(matterId),
      );

      acc[matterId] = matterWithInvoiceRows;
      return acc;
    }, {});

    const { matterRows, matterRowsMap } = getMatterRows(
      matterInvoiceRowsMap,
      expanded,
      matterBalancesMap,
      bankAccountIds,
      autoAllocations,
    );
    const sortedMatterRows = sort(matterRows, [sortBy], [sortDirection]);
    const rows = sortedMatterRows.reduce((acc, matterRow) => {
      acc.push(matterRow);
      if (matterRow.isExpanded) {
        acc.push(...matterInvoiceRowsMap[matterRow.id].invoiceRows);
      }
      return acc;
    }, []);

    const selectedInvoiceIdsToFinalise = selectedInvoiceIds.reduce((acc, invoiceId) => {
      const invoice = invoicesMap[invoiceId];
      const matterRow = matterRowsMap[invoice.matterId];

      // don't include split billing related invoices, e.g.
      // 1. split billing invoice
      // 2. matter has split billing enabled
      // 3. matter has split billing invoice already (e.g. if split billing was turned on then turned off)
      if (
        invoice.splitBillingSettings?.isEnabled ||
        matterRow?.matterHasSplitBillingInvoice ||
        matterRow?.billingConfiguration?.splitBillingSettings?.isEnabled
      ) {
        return acc;
      }
      acc.push(invoiceId);
      return acc;
    }, []);

    return {
      rows,
      bankAccountIds,
      // TODO this is only here as i have this container doing data collection, when it shouldnt be
      matterRows,
      matterIds: Object.keys(matterInvoicesMap),
      invoiceIds: Object.values(matterInvoicesMap)
        .flat()
        .map((invoice) => invoice.invoiceId),
      allExpanded: matterRows.length > 0 && matterRows.every((matterRow) => matterRow.isExpanded),
      allSelected: matterRows.length > 0 && matterRows.every((matterRow) => matterRow.isSelected),
      isError: matterRows.some((matterRow) => matterRow.trustIsError || matterRow.operatingIsError),
      selectedInvoiceIdsToFinalise,
      onToggleSelectAll,
    };
  },
});

function getMatterRows(matterInvoiceRowsMap, expanded, matterBalancesMap, bankAccountIds, autoAllocations) {
  const matterRowsMap = {};
  const matterRows = Object.entries(matterInvoiceRowsMap).map(([matterId, matterWithInvoiceRows]) => {
    const { matterHasSplitBillingInvoice, invoiceRows } = matterWithInvoiceRows;
    const matterBalance = matterBalancesMap[matterId] || {};
    const invoiceIds = invoiceRows.map((invoiceRow) => invoiceRow.id);
    const isExpanded = expanded[matterId] || false;
    const matterRow = {
      type: 'MATTER',
      id: matterId,
      isExpanded,
      matterDisplay: getMatterDisplayById(matterId),
      isSelected: invoiceRows.every((invoiceRow) => invoiceRow.isSelected),
      isMultipleContactBalances:
        getMatterContactBalances(getBankAccountMap(), { matterId, bankAccountId: bankAccountIds.CREDIT }).length > 1 ||
        getMatterContactBalances(getBankAccountMap(), { matterId, bankAccountId: bankAccountIds.TRUST }).length > 1 ||
        getMatterContactBalances(getBankAccountMap(), { matterId, bankAccountId: bankAccountIds.OPERATING }).length > 1,
      trustIsError: invoiceRows.some((invoiceRow) => invoiceRow.trustError),
      operatingIsError: invoiceRows.some((invoiceRow) => invoiceRow.operatingError),
      // business rule: Matters added to the list after its already loaded should come in with the auto allocate toggle on
      isAutoAllocated: autoAllocations[matterId] === undefined || autoAllocations[matterId],
      invoiceIds, // these are in order of oldest to newest
      matterBalance,
      // these balance fields are for the view only - they are there only due to lack of time
      creditBalance: matterBalance[bankAccountIds.CREDIT] || 0,
      trustBalance: matterBalance[bankAccountIds.TRUST] || 0,
      operatingBalance: matterBalance[bankAccountIds.OPERATING] || 0,
      totalDue: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.totalDue, 0),
      trustAmount: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.trustAmount, 0),
      operatingAmount: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.operatingAmount, 0, 0),
      creditAmount: invoiceRows.reduce((total, invoiceRow) => total + invoiceRow.creditAmount, 0),
      billingConfiguration: getCurrentConfigurationByMatterId(matterId),
      matterHasSplitBillingInvoice,
    };

    matterRowsMap[matterId] = matterRow;
    return matterRow;
  });

  return {
    matterRows,
    matterRowsMap,
  };
}

function getInvoiceRows(
  invoices,
  selected,
  bankAccountIds,
  payments,
  matterBalance,
  isAutoAllocated,
  matterBillingConfiguration,
) {
  let runningMatterCreditBalance = matterBalance[bankAccountIds.CREDIT] || 0;
  let runningMatterTrustBalance = matterBalance[bankAccountIds.TRUST] || 0;
  let runningMatterOperatingBalance = matterBalance[bankAccountIds.OPERATING] || 0;

  let matterHasSplitBillingInvoice = false;
  const invoiceRows = invoices.map((invoice) => {
    const isSelected = selected[invoice.invoiceId];
    const totalDue = getInvoiceDue(invoice.invoiceId);
    const creditAmount = isSelected ? getPayment(payments, bankAccountIds.CREDIT, invoice.invoiceId) : 0;
    const trustAmount = isSelected ? getPayment(payments, bankAccountIds.TRUST, invoice.invoiceId) : 0;
    const operatingAmount = isSelected ? getPayment(payments, bankAccountIds.OPERATING, invoice.invoiceId) : 0;

    const creditError =
      isSelected &&
      (getIsPaymentError(creditAmount, runningMatterCreditBalance, totalDue) ||
        creditAmount + trustAmount + operatingAmount > totalDue);
    const trustError =
      isSelected &&
      (getIsPaymentError(trustAmount, runningMatterTrustBalance, totalDue) ||
        creditAmount + trustAmount + operatingAmount > totalDue);
    const operatingError =
      isSelected &&
      (getIsPaymentError(operatingAmount, runningMatterOperatingBalance, totalDue) ||
        creditAmount + trustAmount + operatingAmount > totalDue);

    const creditBalance = runningMatterCreditBalance;
    const trustBalance = runningMatterTrustBalance;
    const operatingBalance = runningMatterOperatingBalance;
    runningMatterCreditBalance -= isSelected ? creditAmount : 0;
    runningMatterTrustBalance -= isSelected ? trustAmount : 0;
    runningMatterOperatingBalance -= isSelected ? operatingAmount : 0;
    const debtorIds = getInvoiceDebtorIds(invoice);

    const isSplitBillingInvoice = invoice.splitBillingSettings?.isEnabled;
    matterHasSplitBillingInvoice = matterHasSplitBillingInvoice || isSplitBillingInvoice;

    return {
      type: 'INVOICE',
      id: invoice.invoiceId,
      debtorIds,
      totalDue,
      issueDate: invoice.issueDate,
      isSelected,
      matterId: invoice.matterId,
      creditAmount,
      trustAmount,
      operatingAmount,
      creditBalance,
      trustBalance,
      operatingBalance,
      creditError,
      trustError,
      operatingError,
      isAutoAllocated,
      debtorDisplay: debtorIds.map((debtorId) => getContactDisplay(debtorId, { showLastNameFirst: true })).join(' | '),
      isSplitBillingInvoice,
      matterBillingConfiguration,
    };
  });

  // must be assigned after going through all invoices for the matter
  invoiceRows.forEach((invoiceRow) => {
    // eslint-disable-next-line no-param-reassign
    invoiceRow.matterHasSplitBillingInvoice = matterHasSplitBillingInvoice;
  });

  const matterWithInvoiceRows = { matterHasSplitBillingInvoice, invoiceRows };
  return matterWithInvoiceRows;
}

function getIsPaymentError(paymentAmount, matterBalance, invoiceDue) {
  return !!paymentAmount && (paymentAmount < 0 || paymentAmount > matterBalance || paymentAmount > invoiceDue);
}

function getPayment(payments, bankAccountId, invoiceId) {
  const paymentsByInvoiceId = payments[invoiceId];

  if (!paymentsByInvoiceId) {
    return 0;
  }

  return paymentsByInvoiceId[bankAccountId] || 0;
}

// while there is a business rule that every invoice has an invoiceTotals entity,
// in practise this is not the case due to race conditions
function getInvoiceDue(invoiceId) {
  try {
    return getTotalsForInvoiceId(invoiceId).unpaidExcInterest;
  } catch (err) {
    // log the error.message as a normal log as its normal flow that getting the totals will fail with an
    // error thrown. Not using the log module as we dont want to swamp the server with this kind of error
    // messaging
    // eslint-disable-next-line no-console
    console.log('finalise with payments table: getting invoice due', err.message);
    return 0;
  }
}

const FinaliseWithPaymentsTableContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(FinaliseWithPaymentsTable)),
);

FinaliseWithPaymentsTableContainer.displayName = 'FinaliseWithPaymentsTableContainer';

FinaliseWithPaymentsTableContainer.propTypes = {
  invoices: PropTypes.any.isRequired,
  invoicesMap: PropTypes.object.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  expanded: PropTypes.any.isRequired,
  selected: PropTypes.any.isRequired,
  selectedInvoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  payments: PropTypes.any.isRequired,
  autoAllocations: PropTypes.any.isRequired,
  bankAccountIds: PropTypes.shape({
    TRUST: PropTypes.string,
    OPERATING: PropTypes.string,
    CREDIT: PropTypes.string,
  }).isRequired,
};
FinaliseWithPaymentsTableContainer.defaultProps = {};

export default FinaliseWithPaymentsTableContainer;
