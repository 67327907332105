import { make } from '../shared/redux-helpers';
import { defaultPath } from './default-path';
import { EmbeddedIframePosition } from '../shared/types';

const SHOW = `${defaultPath}/SHOW`;
const HIDE = `${defaultPath}/HIDE`;
const INITIALISED = `${defaultPath}/INITIALISED`;
const SPAWNED = `${defaultPath}/SPAWNED`;
const SET_UNREAD_CONV_POLLING = `${defaultPath}/SET_UNREAD_CONV_POLLING`;
const SET_UNREAD_CONV_NOTIFY = `${defaultPath}/SET_UNREAD_CONV_NOTIFY`;
const CLOSE = `${defaultPath}/CLOSE`;

type ActionPayloads = {
  showIframe: {
    position: EmbeddedIframePosition;
    matterId: string | null;
  };
  hideIframe: {
    matterId: string | null;
  };
  setInitialised: {
    value: boolean;
  };
  setSpawned: {
    value: boolean;
  };
  setUnreadConvPolling: {
    value: { matterId: string; conversationId: string }[];
  };
  setUnreadConvNotify: {
    value: { matterId: string; conversationId: string }[];
  };
  closeIframe: {
    value: boolean;
  };
};

const actionTypeMapping: { [k in keyof ActionPayloads]: string } = {
  showIframe: SHOW,
  hideIframe: HIDE,
  setInitialised: INITIALISED,
  setSpawned: SPAWNED,
  setUnreadConvPolling: SET_UNREAD_CONV_POLLING,
  setUnreadConvNotify: SET_UNREAD_CONV_NOTIFY,
  closeIframe: CLOSE,
};

export const { actionCreators, actionGuards } = make<ActionPayloads>(actionTypeMapping);
