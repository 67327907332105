'use strict';

const { splitBillingMethods } = require('./split-billing-method');

function calculatePart(ratio, amount, roundDown = false) {
  let totalPortion = amount * ratio;
  // First round to 10 decimals to avoid issues with numbers ending with ...9999999
  totalPortion = parseFloat(totalPortion.toFixed(10));
  // Now round to whole cents
  totalPortion = roundDown ? Math.floor(totalPortion) : Math.round(totalPortion);
  return totalPortion;
}

/**
 * Get the invoice debtor totals lookup based on the provided split billing settings
 * @param {object} params
 * @param {Object} params.invoiceTotal
 * @param {Object} params.splitBillingSettings
 * @returns {Object}
 */
function getInvoiceDebtorTotalsLookup({ invoiceTotals, splitBillingSettings }) {
  if (!splitBillingSettings?.isEnabled) {
    return {};
  }
  const { debtors, splitMethod, remainderDebtorId } = splitBillingSettings;
  const invoiceDebtorTotalsLookup = {};

  const billed = invoiceTotals.feeTotal + invoiceTotals.expenseTotal;
  const writtenOff = invoiceTotals.writtenOffFeeTotal + invoiceTotals.writtenOffExpenseTotal;
  const totalExcInterest = billed - writtenOff + invoiceTotals.surcharge + invoiceTotals.tax - invoiceTotals.discount;
  const totalTax = invoiceTotals.tax;

  let totalSum = 0;
  let taxSum = 0;

  debtors.forEach((debtor) => {
    const { debtorId, debtorRatio } = debtor;
    const effectiveDebtorRatio =
      splitMethod === splitBillingMethods.USE_RATIO ? debtorRatio / 10000 : 1 / debtors.length;

    const debtorTotals = {
      totalExcInterest: calculatePart(effectiveDebtorRatio, totalExcInterest),
      tax: calculatePart(effectiveDebtorRatio, totalTax),
    };
    invoiceDebtorTotalsLookup[debtorId] = debtorTotals;

    totalSum += debtorTotals.totalExcInterest;
    taxSum += debtorTotals.tax;
  });

  // Adjust the totals to match the total
  if (totalSum !== totalExcInterest) {
    let newTotalSum = 0; // New total sum after we've adjusted the totals

    if (totalSum > totalExcInterest) {
      // Sum of total is above what we expected - recalculate the totals, but round down
      debtors.forEach((debtor) => {
        const { debtorId, debtorRatio } = debtor;
        const debtorTotals = invoiceDebtorTotalsLookup[debtorId];

        const effectiveDebtorRatio =
          splitMethod === splitBillingMethods.USE_RATIO ? debtorRatio / 10000 : 1 / debtors.length;
        debtorTotals.totalExcInterest = calculatePart(effectiveDebtorRatio, totalExcInterest, true);
        // then recalculate the sum (which should now be equal to or lower than the actual total)
        // so we'll be able to add to the debtor that's meant to receive the remainder
        newTotalSum += debtorTotals.totalExcInterest;
      });
    } else if (totalSum < totalExcInterest) {
      // Sum of total is below what we expected - no need recalculate the totals
      newTotalSum = totalSum;
    }
    // sum is lower than actual total (or we have lowered it because it was higher) - we just add the remainder to the designated debtor
    const totalExcInterestRemainder = totalExcInterest - newTotalSum;
    invoiceDebtorTotalsLookup[remainderDebtorId].totalExcInterest += totalExcInterestRemainder;
  }

  // Same for tax
  if (taxSum !== totalTax) {
    let newTaxSum = 0; // New total sum after we've adjusted the totals

    if (taxSum > totalTax) {
      debtors.forEach((debtor) => {
        const { debtorId, debtorRatio } = debtor;
        const debtorTotals = invoiceDebtorTotalsLookup[debtorId];

        const effectiveDebtorRatio =
          splitMethod === splitBillingMethods.USE_RATIO ? debtorRatio / 10000 : 1 / debtors.length;
        debtorTotals.tax = calculatePart(effectiveDebtorRatio, totalTax, true);
        newTaxSum += debtorTotals.tax;
      });
    } else if (taxSum < totalTax) {
      newTaxSum = taxSum;
    }
    const taxRemainder = totalTax - newTaxSum;
    invoiceDebtorTotalsLookup[remainderDebtorId].tax += taxRemainder;
  }

  return invoiceDebtorTotalsLookup;
}

module.exports = {
  getInvoiceDebtorTotalsLookup,
};
