import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getAvailableFundsForCurrentDebtor } from '@sb-billing/business-logic/split-billing';
import { CurrencyDisplay, StatelessSelect } from '@sb-itops/react';
import { InvoicePaymentAllocationTable } from '../invoice-payment-allocation-table';
import { updateDebtorAllocationsLookup } from './update-debtor-allocations-lookup';
import Styles from './ApplyPaymentsForm.module.scss';

/**
 * @typedef {object} Allocation
 * @property {matterId} contactId
 * @property {number} available available balance
 * @property {number} amount amount to pay
 */

function updateAllocations(arr, item) {
  const allocs = arr || [];
  const index = allocs.findIndex((a) => a.contactId === item.contactId);

  return [...allocs.slice(0, index), item, ...allocs.slice(index + 1)];
}

function allocateAll(allocations, isSplitBillingEnabled = false) {
  const isAllAllocated = allocations.every((allocation) => {
    const available = isSplitBillingEnabled
      ? getAvailableFundsForCurrentDebtor({
          available: allocation.available,
          totalAllocatedAmount: allocation.allocatedAmount,
          currentDebtorAmount: allocation.amount,
        })
      : allocation.available;
    return allocation.amount === available;
  });

  return allocations.map((allocation) => {
    const available = isSplitBillingEnabled
      ? getAvailableFundsForCurrentDebtor({
          available: allocation.available,
          totalAllocatedAmount: allocation.allocatedAmount,
          currentDebtorAmount: allocation.amount,
        })
      : allocation.available;
    return {
      ...allocation,
      amount: isAllAllocated ? 0 : available,
    };
  });
}

const ApplyPaymentsForm = memo(
  ({
    invoiceTotals,
    totalAmount,
    trustAllocations,
    operatingAllocations,
    onChangeTrustAllocations,
    onChangeOperatingAllocations,
    onChangeCreditAllocations,
    creditAllocations,
    onContactLinkClick,
    hasError,
    // Split billing
    isSplitBillingEnabled,
    selectedDebtorId,
    debtorOptions,
    invoiceDebtorTotalsLookup,
    onDebtorChange,
    onChangeTrustDebtorAllocationsLookup,
    onChangeOperatingDebtorAllocationsLookup,
    onChangeCreditDebtorAllocationsLookup,
  }) => {
    const totalDue = isSplitBillingEnabled
      ? invoiceDebtorTotalsLookup[selectedDebtorId]?.totalExcInterest || 0
      : invoiceTotals.total || 0;
    const invoiceBalance = totalAmount > totalDue ? 0 : totalDue - totalAmount;

    return (
      <div className={Styles.applyPaymentsForm}>
        {isSplitBillingEnabled && (
          <div>
            <label className={Styles.uppercaseLabel}>Debtor</label>
            <StatelessSelect
              options={debtorOptions}
              selectedOption={debtorOptions.find((debtorOption) => debtorOption.value === selectedDebtorId)}
              onChange={(newDebtor) => onDebtorChange(newDebtor.value)}
              className={Styles.debtorSelect}
            />
          </div>
        )}
        <div className={Styles.totals}>
          <div className={classnames(Styles.total, 'form-group')}>
            <label className={Styles.uppercaseLabel}>{isSplitBillingEnabled ? 'Debtor Due' : 'Due'}</label>
            <CurrencyDisplay amount={totalDue} />
          </div>
          <div className={classnames(Styles.total, 'form-group')}>
            <label className={Styles.uppercaseLabel}>Amount Allocated</label>
            <CurrencyDisplay amount={totalAmount} hasError={hasError} />
          </div>
          <div className={classnames(Styles.total, 'form-group')}>
            <label className={Styles.uppercaseLabel}>Invoice Balance</label>
            <CurrencyDisplay amount={invoiceBalance} />
          </div>
        </div>
        {!!trustAllocations.length && (
          <div className={classnames(Styles.invoicePaymentAllocationTable, Styles.marginBottom)}>
            <label className={Styles.uppercaseLabel}>
              {/* All trustAllocations are for same trust account so we can use name of the first one;
              autoAllocatePayments only available in US, it's fine to not localise 'Trust Account' here */}
              {`Trust Account: ${trustAllocations[0].bankAccountName}`}
            </label>
            <InvoicePaymentAllocationTable
              allocations={trustAllocations}
              isSplitBillingEnabled={isSplitBillingEnabled}
              onChange={(allocation = {}) =>
                isSplitBillingEnabled
                  ? onChangeTrustDebtorAllocationsLookup((prev) =>
                      updateDebtorAllocationsLookup({
                        prev,
                        selectedDebtorId,
                        updatedAllocations: updateAllocations(trustAllocations, allocation),
                      }),
                    )
                  : onChangeTrustAllocations(updateAllocations(trustAllocations, allocation))
              }
              onAllocateAll={() =>
                isSplitBillingEnabled
                  ? onChangeTrustDebtorAllocationsLookup((prev) =>
                      updateDebtorAllocationsLookup({
                        prev,
                        selectedDebtorId,
                        updatedAllocations: allocateAll(trustAllocations, isSplitBillingEnabled),
                      }),
                    )
                  : onChangeTrustAllocations(allocateAll(trustAllocations, isSplitBillingEnabled))
              }
              onContactLinkClick={onContactLinkClick}
            />
          </div>
        )}
        {!!operatingAllocations.length && (
          <div className={classnames(Styles.invoicePaymentAllocationTable, Styles.marginBottom)}>
            <label className={Styles.uppercaseLabel}>Operating Retainer</label>
            <InvoicePaymentAllocationTable
              allocations={operatingAllocations}
              isSplitBillingEnabled={isSplitBillingEnabled}
              onChange={(allocation) =>
                isSplitBillingEnabled
                  ? onChangeOperatingDebtorAllocationsLookup((prev) =>
                      updateDebtorAllocationsLookup({
                        prev,
                        selectedDebtorId,
                        updatedAllocations: updateAllocations(operatingAllocations, allocation),
                      }),
                    )
                  : onChangeOperatingAllocations(updateAllocations(operatingAllocations, allocation))
              }
              onAllocateAll={() =>
                isSplitBillingEnabled
                  ? onChangeOperatingDebtorAllocationsLookup((prev) =>
                      updateDebtorAllocationsLookup({
                        prev,
                        selectedDebtorId,
                        updatedAllocations: allocateAll(operatingAllocations, isSplitBillingEnabled),
                      }),
                    )
                  : onChangeOperatingAllocations(allocateAll(operatingAllocations, isSplitBillingEnabled))
              }
              onContactLinkClick={onContactLinkClick}
            />
          </div>
        )}
        {!!creditAllocations.length && (
          <div className={Styles.invoicePaymentAllocationTable}>
            <label className={Styles.uppercaseLabel}>Credits</label>
            <InvoicePaymentAllocationTable
              allocations={creditAllocations}
              isSplitBillingEnabled={isSplitBillingEnabled}
              onChange={(allocation) =>
                isSplitBillingEnabled
                  ? onChangeCreditDebtorAllocationsLookup((prev) =>
                      updateDebtorAllocationsLookup({
                        prev,
                        selectedDebtorId,
                        updatedAllocations: updateAllocations(creditAllocations, allocation),
                      }),
                    )
                  : onChangeCreditAllocations(updateAllocations(creditAllocations, allocation))
              }
              onAllocateAll={() =>
                isSplitBillingEnabled
                  ? onChangeCreditDebtorAllocationsLookup((prev) =>
                      updateDebtorAllocationsLookup({
                        prev,
                        selectedDebtorId,
                        updatedAllocations: allocateAll(creditAllocations, isSplitBillingEnabled),
                      }),
                    )
                  : onChangeCreditAllocations(allocateAll(creditAllocations, isSplitBillingEnabled))
              }
              onContactLinkClick={onContactLinkClick}
            />
          </div>
        )}
      </div>
    );
  },
);

ApplyPaymentsForm.displayName = 'ApplyPaymentsForm';

ApplyPaymentsForm.propTypes = {
  trustAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      bankAccountId: PropTypes.string.isRequired,
      bankAccountName: PropTypes.string.isRequired,
    }),
  ),
  operatingAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      bankAccountId: PropTypes.string.isRequired,
    }),
  ),
  creditAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      bankAccountId: PropTypes.string.isRequired,
    }),
  ),
  invoiceTotals: PropTypes.object,
  onContactLinkClick: PropTypes.func.isRequired,
  onChangeTrustAllocations: PropTypes.func.isRequired,
  onChangeOperatingAllocations: PropTypes.func.isRequired,
  onChangeCreditAllocations: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  totalAmount: PropTypes.number,
  // Split billing
  isSplitBillingEnabled: PropTypes.bool,
  selectedDebtorId: PropTypes.string,
  debtorOptions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  invoiceDebtorTotalsLookup: PropTypes.object,
  onDebtorChange: PropTypes.func,
  onChangeTrustDebtorAllocationsLookup: PropTypes.func,
  onChangeOperatingDebtorAllocationsLookup: PropTypes.func,
  onChangeCreditDebtorAllocationsLookup: PropTypes.func,
};

ApplyPaymentsForm.defaultProps = {
  trustAllocations: [],
  operatingAllocations: [],
  creditAllocations: [],
  invoiceTotals: {},
  hasError: false,
  totalAmount: 0,
  // Split billing
  isSplitBillingEnabled: false,
  selectedDebtorId: undefined,
  debtorOptions: [],
  invoiceDebtorTotalsLookup: {},
  onDebtorChange: () => {},
  onChangeTrustDebtorAllocationsLookup: () => {},
  onChangeOperatingDebtorAllocationsLookup: () => {},
  onChangeCreditDebtorAllocationsLookup: () => {},
};

export default ApplyPaymentsForm;
