/*
 * Due to problems with the current version of ui-router we are using (0.X), we need to use $location
 * to update the url rather than $state.go.
 *
 * The problem isnt with changing the params themselves, but with how ui-router looks at those changes
 * and reloading EVERYTHING if it sees changes.
 *
 * The ui-router team know this and they have tackled the problem in 1.X.
 *
 * Many pages on it on GH, but https://github.com/angular-ui/ui-router/issues/125 is one that the
 * ui-router team kept linking to.
 *
 * Most of this file is this way because of work arounds due to the old version of ui-router
 *
 * */

import { sendMetric } from 'web/services/metrics';
import { hasBillingAccess } from 'web/services/user-session-management';

angular.module('sb.billing.webapp').controller('SbAdvancedSearchController', function ($scope, $state, sbLoggerService, $location, focusService, sbAsyncOperationsService, sbInvoiceSendService, sbLinkService, sbSaveInvoiceCommand) {
  const ctrl = this;
  const log = sbLoggerService.getLogger('SbAdvancedSearchController');

  ctrl.searchOptions = {
    allMatters: true
  };

  sendMetric('NavAdvancedsearch');

  let initialLoad = true;

  ctrl.sbAsyncOperationsService = sbAsyncOperationsService;
  ctrl.sbInvoiceSendService = sbInvoiceSendService;
  ctrl.sbSaveInvoiceCommand = sbSaveInvoiceCommand;
  ctrl.isDownloadLedesInProgress = isDownloadLedesInProgress;
  ctrl.invoiceCount = undefined;
  ctrl.areInvoicesLoading = true;
  ctrl.getInvoiceCountData = getInvoiceCountData;

  // When the LOD Invoice list is used, we need to reflect:
  //  1. The query results from the LOD implementation (both rows and count)
  //  2. Loading state while fetching data
  // 
  // The above two are handled in Angular, so we need to fetch and set data from React/LOD
  function getInvoiceCountData({ isLoading, invoiceCount }) {
    if (isLoading) {
      ctrl.invoiceCount = undefined; // only the spinner should be displayed upon loading
      ctrl.areInvoicesLoading = true;
      return;
    }

    ctrl.areInvoicesLoading = false;
    ctrl.invoiceCount = invoiceCount;
  }

  cleanSearchTerm();
  ctrl.sbData = { stateName: $state.current.name };

  ctrl.updateUrlParams = updateUrlParams;
  ctrl.dataChangeFunction = dataChangeFunction;
  ctrl.isTabActive = isTabActive;
  ctrl.hasBillingAccess = hasBillingAccess;

  ctrl.isCombineInProgress = isCombineInProgress;
  ctrl.onClickLink = sbLinkService.onClickLink;

  $scope.$on('$stateChangeSuccess', () => {
    focusService.focusOn('advanced-search-input');
    cleanSearchTerm();
  });

  function cleanSearchTerm() {
    const dirty = $location.search().searchTerm;
    dirty && updateUrlParams(dirty.replace(/~2f/gi, '/'));
  }

  function dataChangeFunction(key, doc) {
    log.info('data change', key, doc);

    if (doc && doc.data) {
      ctrl.sbData[key] = doc.data;
    } else {
      ctrl.sbData[key] = doc;
    }
    $scope.$applyAsync();

    if (key === 'searchResults') {
      if (initialLoad) {
        initialLoad = false;
        selectTab(doc);
      }
    }
  }

  //if the person is on a tab that has data, stay at that one
  function selectTab(searchData) {
    if (searchData.matters.length) {
      changeTab('matters');
    } else if (searchData.leads.length) {
      changeTab('leads');
    } else if (searchData.contacts.length) {
      changeTab('contacts');
    } else if (searchData.invoices.length) {
      changeTab('invoices');
    }
  }

  function getSubSState(name) {
    const subState = name.match(/^.*\.([^.]*)$/);
    return subState ? subState[1] : name;
  }

  function changeTab(tab) {
    $state.go(`home.billing.advanced-search.${tab}`, { searchTerm: ctrl.searchTerm });
    sendMetric('NavQuicksearchTab', { tab });
  }

  function updateUrlParams(text) {
    ctrl.searchTerm = text;
    $location.search({ searchTerm: ctrl.searchTerm });
  }

  function isTabActive(tab) {
    return getSubSState($state.current.name) === tab ? 'active' : '';
  }

  function isCombineInProgress() {
    return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.COMBINE_INVOICES) > 0;
  }


  function isDownloadLedesInProgress() {
    return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.DOWNLOAD_LEDES) > 0;
  }
});
