import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query MarkAsSentModalAdditionalInvoiceData($invoiceIds: [ID], $invoiceListFilter: InvoiceListFilter) {
    invoiceList(ids: $invoiceIds, filter: $invoiceListFilter) {
      results {
        id
        matter {
          id
        }
        debtors {
          id
        }
      }
    }
  }
`;

const notificationIds = ['IGNORED'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MarkAsSentModalAdditionalInvoiceData = {
  query,
  notificationIds,
};
