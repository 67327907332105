import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { ContactDisplay2 } from '@sb-customer-management/react/contact-display';
import { Table, Column, utils } from '@sb-itops/react/table';
import { Checkbox } from '@sb-itops/react/checkbox';
import { InvoiceStatusIndicator } from '@sb-billing/react/invoice-status-indicator';
import { sentViaTypes } from '@sb-billing/business-logic/correspondence-history';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { featureActive } from '@sb-itops/feature';
import { status as INVOICE_STATUS } from '@sb-billing/business-logic/invoice/entities/status';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { LinkableText, useTranslation } from '@sb-itops/react';
import { status as invoiceStatusesMap } from '@sb-billing/business-logic/invoice/entities';
import { PaymentPlanIndicator } from 'web/components/payment-plan-indicator';
import { InvoiceCorrespondenceSendStatus } from 'web/components/invoice-correspondence-send-status';
import { InvoiceViewedOnlineStatusIndicator } from 'web/components/invoice-viewed-online-status-indicator';
import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';

import { getLabel, userNameGetter, dataGetter, invoiceTotalsDataGetter } from './helpers';

import Styles from './InvoiceTable.module.scss';

const {
  balanceCellLocalisedRenderer,
  yyyymmddLocalisedRenderer,
  timestampLocalisedRenderer,
  checkboxCellWrapperRenderer,
  headerCellRenderer,
} = utils;

const contextMenuBodyRenderer = ({
  printReceiptText,
  sendOptionsMenu,
  // Callbacks
  onPrintReceipt,
}) => (
  <div className="context-menu-body list-group">
    {sendOptionsMenu}
    <button type="button" className="list-group-item" onClick={onPrintReceipt}>
      {printReceiptText}
    </button>
  </div>
);

// This InvoiceTable is the LOD implementation
//  * Everything from the legacy implementation has been copied over
//  * This component will contain modifications to support LOD
//
// For the legacy implementation, please see:
//  * File: monorepo/apps/smokeball-billing-web/src/react-redux/components/invoice-table
export const InvoiceTable = (props) => {
  const { invoices, isLoading, sort, sortBy, summary } = props;
  const sortDirection = props.sortDirection.toLowerCase(); // Child components expect this format

  const { t } = useTranslation();

  // It's possible that the currently set sortBy column is not present in the columns to be rendered.
  // If that's the case, we will set sortBy to be a column that's always present (invoiceNumber)
  const columns = getColumns(props, t);
  const sortConfig = updateSortIfNecessary({ sort, sortBy, sortDirection, columns });

  return (
    <Table
      className={Styles.invoiceTable}
      dataLoading={isLoading}
      onRowClick={({ rowData }) =>
        props.onInvoiceLink(
          rowData.id,
          rowData?.debtorTotals?.[0]?.debtorId ? [undefined, undefined, rowData.debtorTotals[0].debtorId] : undefined,
        )
      }
      list={invoices}
      sort={sort}
      sortBy={sortConfig.sortBy}
      sortDirection={sortConfig.sortDirection}
      summary={summary}
      showFooter
    >
      {columns}
    </Table>
  );
};

const updateSortIfNecessary = ({ sort, sortBy, sortDirection, columns }) => {
  const currentSortColumnValid = columns.some((column) => column && column.props && column.props.dataKey === sortBy);

  if (columns.length && !currentSortColumnValid) {
    const newSortConfig = { sortBy: 'invoiceNumber', sortDirection: 'desc' };
    sort(newSortConfig);
    return newSortConfig;
  }

  return { sortBy, sortDirection };
};

const Columns = {
  CHECKBOX: ({ props }) => (
    <Column
      key="UNNEEDED"
      dataKey="UNNEEDED"
      label=""
      width={34}
      headerRenderer={checkboxCellWrapperRenderer(checkboxHeaderCellRenderer({ props }))}
      cellRenderer={checkboxCellWrapperRenderer(checkboxCellRenderer({ props }))}
      disableSort
    />
  ),
  PAID_ON: () => (
    <Column
      key="paidDate"
      dataKey="paidDate"
      label="Date Paid On"
      cellDataGetter={dataGetter}
      cellRenderer={yyyymmddLocalisedRenderer}
      width={100}
    />
  ),
  VOIDED_ON: () => (
    <Column
      key="validFrom"
      dataKey="validFrom"
      label="Voided On"
      cellDataGetter={dataGetter}
      cellRenderer={timestampLocalisedRenderer}
      width={100}
    />
  ),
  // dataKey is not used in userNameGetter, but is still required for sorting
  VOIDED_BY: () => (
    <Column key="staffName" dataKey="staffName" label="Voided By" cellDataGetter={userNameGetter} flexGrow={3} />
  ),
  ISSUED: () => (
    <Column
      key="issuedDate"
      dataKey="issuedDate"
      label="Issued"
      cellDataGetter={dataGetter}
      cellRenderer={yyyymmddLocalisedRenderer}
      width={100}
    />
  ),
  DUE: () => (
    <Column
      key="dueDate"
      dataKey="dueDate"
      label="Due"
      cellDataGetter={dataGetter}
      cellRenderer={yyyymmddLocalisedRenderer}
      width={100}
    />
  ),
  INVOICE_NUMBER: ({ t }) => (
    <Column
      key="invoiceNumber"
      dataKey="invoiceNumber"
      label="Invoice #"
      cellDataGetter={dataGetter}
      cellRenderer={invoiceNumberCellRenderer({ t })}
      width={85}
    />
  ),
  DEBTOR: ({ props }) =>
    props.showDebtor && (
      <Column
        key="debtor"
        dataKey="debtor"
        label={featureActive('BB-4416') ? 'Debtor(s)' : 'Debtor'}
        cellRenderer={debtorCellRenderer({ props })}
        flexGrow={15}
      />
    ),
  MATTER: ({ props }) =>
    props.showMatter && (
      <Column key="matter" dataKey="matter" label="Matter" cellRenderer={matterCellRenderer({ props })} flexGrow={15} />
    ),
  AMOUNT: ({ props }) => (
    <Column
      key="totalExcInterest"
      className="right-align"
      dataKey="totalExcInterest"
      label={getLabel({ ...props, label: 'amount' })}
      cellDataGetter={invoiceTotalsDataGetter}
      cellRenderer={balanceCellLocalisedRenderer}
      flexGrow={2}
      width={60}
      footerRenderer={balanceCellLocalisedRenderer}
    />
  ),
  INTEREST: () =>
    hasFacet(facets.interest) ? (
      <Column
        key="interest"
        className={classnames(Styles.interest, 'right-align')}
        dataKey="interest"
        label="Interest"
        cellDataGetter={invoiceTotalsDataGetter}
        cellRenderer={balanceCellLocalisedRenderer}
        flexGrow={2}
        width={70}
        footerRenderer={balanceCellLocalisedRenderer}
      />
    ) : null,
  PAID: () => (
    <Column
      key="paid"
      className="right-align"
      dataKey="paid"
      label={hasFacet(facets.interest) ? 'Paid (Inc Interest)' : 'Paid'}
      cellDataGetter={invoiceTotalsDataGetter}
      cellRenderer={balanceCellLocalisedRenderer}
      flexGrow={2}
      width={70}
      footerRenderer={balanceCellLocalisedRenderer}
    />
  ),
  CREDIT: () => (
    <Column
      key="paidByCredit"
      className="right-align"
      dataKey="paidByCredit"
      label="Credit"
      cellDataGetter={invoiceTotalsDataGetter}
      cellRenderer={balanceCellLocalisedRenderer}
      flexGrow={2}
      width={70}
      footerRenderer={balanceCellLocalisedRenderer}
    />
  ),
  UNPAID: () => (
    <Column
      key="unpaid"
      className="right-align"
      dataKey="unpaid"
      label={hasFacet(facets.interest) ? 'Unpaid (Inc Interest)' : 'Unpaid'}
      cellDataGetter={invoiceTotalsDataGetter}
      cellRenderer={balanceCellLocalisedRenderer}
      flexGrow={2}
      width={70}
      footerRenderer={balanceCellLocalisedRenderer}
    />
  ),
  SEND_STATUS: ({ props }) => (
    <Column
      key="Sent"
      dataKey="sentStatus"
      label="Sent"
      labelTooltip="Send status"
      width={65}
      cellRenderer={sentStatusCellRenderer({ props })}
      headerRenderer={(headerRendererProps) =>
        headerCellRenderer({
          ...headerRendererProps,
          className: Styles.sentColumnHeaderContainer,
        })
      }
    />
  ),
  VIEWED_ONLINE: ({ props }) =>
    featureActive('BB-5725') && (
      <Column
        key="Viewed"
        dataKey="viewedOnline"
        label="Viewed"
        labelTooltip="Viewed Online"
        width={65}
        cellRenderer={viewedOnlineCellRenderer({ props })}
      />
    ),
  STATUS: () => <Column key="status" dataKey="status" label="Status" cellRenderer={statusCellRenderer} width={80} />,
  STATEMENT: ({ props }) =>
    props.showStatementColumn && (
      <Column
        key="statement"
        dataKey="invoiceStatement"
        label="Statement"
        cellRenderer={invoiceStatementRenderer({ props })}
        width={80}
      />
    ),
  CONTEXT_MENU: ({ props, t }) => (
    <Column
      key="contextMenu"
      dataKey="contextMenu"
      label=""
      width={40}
      cellRenderer={contextMenuCellRenderer({ props, t })}
      disableSort
    />
  ),
};

function checkboxCellRenderer({ props }) {
  return ({ rowData }) => {
    const { onToggleInvoices, selectedInvoices } = props;
    const isChecked = selectedInvoices[rowData.id];

    return (
      <Checkbox
        checked={isChecked}
        disabled={rowData.pseudoStatus === 'VOID'}
        onChange={() =>
          onToggleInvoices({
            invoices: [
              {
                id: rowData.id,
                pseudoStatus: rowData.pseudoStatus,
                isUtbmsEnabledForInvoiceMatter: !!rowData.matter.billingConfiguration?.isUtbmsEnabled,
                debtorCount: rowData.debtors?.length,
                isSplitBillingEnabled: rowData.splitBillingSettings?.isEnabled,
                invoiceStatementId: rowData.invoiceStatement?.id,
                showRetainer: rowData.showRetainer,
              },
            ],
          })
        }
      />
    );
  };
}

const invoiceNumberCellRenderer =
  ({ t }) =>
  ({ rowData }) => {
    // Contact invoices list graphql will only return debtors, splitBillingSettings.debtors and debtorTotals related to the contact
    const invoiceNumberDisplay =
      rowData.splitBillingSettings?.isEnabled && rowData?.debtors?.length === 1
        ? `${rowData.invoiceNumber}-${rowData?.debtors[0]?.invoiceNumberSuffix}`
        : rowData.invoiceNumber;

    return (
      <div className={Styles.invoiceNumberContainer}>
        <div className={Styles.invoiceNumber} title={invoiceNumberDisplay}>
          {invoiceNumberDisplay}
        </div>
        {featureActive('BB-9573') && rowData.listItemProperties.hasUnpaidAnticipatedDisbursements && (
          <>
            {rowData.pseudoStatus === INVOICE_STATUS.DRAFT && (
              <div className={classnames(Styles.warningIconContainer, Styles.orange)}>
                <i
                  title={`This invoice contains an anticipated ${t(
                    'expense',
                  )} that has not yet been paid to the supplier.`}
                  className="icon-alert-1"
                />
              </div>
            )}
            {(rowData.pseudoStatus === INVOICE_STATUS.FINAL || rowData.pseudoStatus === 'OVERDUE') && (
              <div className={Styles.warningIconContainer}>
                <i
                  title={`This invoice contains an anticipated ${t(
                    'expense',
                  )} that has not yet been paid to the supplier.`}
                  className="icon-alert-1"
                />
              </div>
            )}
            {rowData.pseudoStatus === INVOICE_STATUS.PAID && (
              <div className={Styles.warningIconContainer}>
                <i
                  title={`This invoice contains an anticipated ${t(
                    'expense',
                  )} that has not yet been paid to the supplier. This invoice has been paid by the client.`}
                  className="icon-icon-fat-exclamation-circle-filled"
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

function checkboxHeaderCellRenderer({ props }) {
  return () => {
    const { selectedInvoices, invoices, onToggleInvoices } = props;

    // Ensure voided invoices aren't selected
    const invoicesWithoutVoided = invoices.filter((invoice) => invoice.pseudoStatus !== invoiceStatusesMap.VOID);
    const allInvoicesOnCurrentPageAreSelected =
      !!invoices.length &&
      !!Object.keys(selectedInvoices).length &&
      invoicesWithoutVoided.every((invoice) => selectedInvoices[invoice.id]);

    const getInvoicesToToggle = () => {
      // Deselecting all invoices
      //  * We only deselect all invoices if all invoices on the current page are selected
      //  * Otherwise we default to "select all"
      if (allInvoicesOnCurrentPageAreSelected) {
        return invoicesWithoutVoided.map((invoice) => ({
          id: invoice.id,
          pseudoStatus: invoice.pseudoStatus,
          isUtbmsEnabledForInvoiceMatter: !!invoice.matter.billingConfiguration?.isUtbmsEnabled,
          isSplitBillingEnabled: invoice.splitBillingSettings?.isEnabled,
          debtorCount: invoice.debtors?.length,
          invoiceStatementId: invoice.invoiceStatement?.id,
          showRetainer: invoice.showRetainer,
        }));
      }

      // Selecting all invoices
      return invoicesWithoutVoided.reduce((acc, invoice) => {
        const isAlreadySelected = selectedInvoices[invoice.id];

        // In some cases, some invoices may be selected already. If so:
        //  * Skip over it so we keep it selected (and don't unselect it)
        if (isAlreadySelected) {
          return acc;
        }

        acc.push({
          id: invoice.id,
          pseudoStatus: invoice.pseudoStatus,
          isUtbmsEnabledForInvoiceMatter: !!invoice.matter.billingConfiguration?.isUtbmsEnabled,
          isSplitBillingEnabled: invoice.splitBillingSettings?.isEnabled,
          debtorCount: invoice.debtors?.length,
          invoiceStatementId: invoice.invoiceStatement?.id,
          showRetainer: invoice.showRetainer,
        });
        return acc;
      }, []);
    };

    const onToggleAllInvoicesOnCurrentPage = () =>
      onToggleInvoices({
        invoices: getInvoicesToToggle(),
      });

    return <Checkbox checked={allInvoicesOnCurrentPageAreSelected} onChange={onToggleAllInvoicesOnCurrentPage} />;
  };
}

function matterCellRenderer({ props }) {
  return ({ cellData: matter }) => {
    const matterDisplay = getMatterDisplay(matter, matter?.matterType?.name);

    return (
      <LinkableText
        text={matterDisplay}
        onClickLink={() => props.onClickLink({ type: 'matter', id: matter?.id })}
        inline
        asLink
      />
    );
  };
}

function debtorCellRenderer({ props }) {
  return ({ rowData }) => {
    const debtors = rowData.debtors;
    const paymentPlan = rowData.listItemProperties.activePaymentPlanDetails;

    return (
      rowData && (
        <div className={Styles.invoiceDebtors}>
          <span className={Styles.debtorsDisplay}>
            {debtors
              ?.map(({ contact }) => (
                <ContactDisplay2
                  key={contact.id}
                  asLink
                  contact={contact}
                  inline
                  onClickLink={props.onClickLink}
                  showLastNameFirst
                  tooltip={contact.displayName}
                />
              ))
              .reduce((acc, elem) => {
                if (acc === null) {
                  return [elem];
                }
                return [...acc, ' | ', elem];
              }, null)}
          </span>
          {debtors?.length > 1 && (
            <span className={Styles.multiDebtorsIcon}>
              <i
                title={`${rowData.splitBillingSettings?.isEnabled ? 'Split billing invoice - ' : ''}${debtors.map(({ contact }) => contact.displayName).join(' | ')}`}
                className={classnames(
                  'icon',
                  rowData.splitBillingSettings?.isEnabled ? 'fa-files-o' : 'icon-contacts-1',
                )}
              />
            </span>
          )}
          {paymentPlan?.status === paymentPlanStatuses.ACTIVE && (
            <PaymentPlanIndicator paymentPlan={paymentPlan} onClickLink={props.onClickLink} />
          )}
        </div>
      )
    );
  };
}

function statusCellRenderer({ rowData }) {
  return <InvoiceStatusIndicator status={rowData.pseudoStatus} />;
}

function invoiceStatementRenderer({ props }) {
  return ({ cellData }) =>
    cellData && (
      <LinkableText
        key={cellData.id}
        text={cellData.number}
        asLink
        inline
        onClickLink={() => props.onClickLink({ type: 'invoiceStatementView', id: cellData.id })}
        tooltip={cellData.number}
      />
    );
}

function sentStatusCellRenderer({ props }) {
  return ({ rowData }) => {
    const onResendClicked = ({ invoiceId, contactId, sentVia }) => {
      const sendViaCommunicateEnabled = featureActive('BB-9097');
      // If the feature is not enabled, show send email modal when clicking resend even it sent via communicate previously
      if (sentVia === sentViaTypes.COMMUNICATE && sendViaCommunicateEnabled) {
        props.onSendInvoiceCommunicate({ invoiceId, contactId });
      } else {
        props.onSendInvoiceEmail({ invoiceId, contactId });
      }
    };

    return (
      <InvoiceCorrespondenceSendStatus
        invoiceId={rowData.id}
        sendStatus={rowData.listItemProperties?.sentStatus}
        allowResend={!['DRAFT', 'VOID', 'DELETED'].includes(rowData.pseudoStatus)}
        onResendClicked={onResendClicked}
        latestCorrespondenceLoading={props.latestCorrespondenceLoading}
        latestCorrespondencePerContact={props.latestCorrespondencePerContact}
        onFetchLatestCorrespondenceDetailsForInvoice={props.onFetchLatestCorrespondenceDetailsForInvoice}
      />
    );
  };
}

function viewedOnlineCellRenderer() {
  return ({ rowData }) => (
    <InvoiceViewedOnlineStatusIndicator
      invoiceId={rowData.id}
      hasBeenViewedOnline={rowData.listItemProperties.hasBeenViewedOnline}
    />
  );
}

function getColumns(props, t) {
  const columnsToDisplay = getColumnsToDisplay(props);

  // Add invoice status column when:
  //  1. Invoices with different statuses are being displayed
  //  2. Not Adv. search page (it adds this column by default)
  if (props.invoiceStatuses.length > 1 && props.displayMode !== 'SEARCH') {
    columnsToDisplay.splice(1, 0, Columns.STATUS);
  }

  return columnsToDisplay.map((columnFunc) => columnFunc({ props, t }));
}

function getColumnsToDisplay({ displayMode, invoiceStatuses }) {
  if (displayMode === 'SEARCH') {
    return [
      Columns.CHECKBOX,
      Columns.STATUS,
      Columns.ISSUED,
      Columns.DUE,
      Columns.INVOICE_NUMBER,
      Columns.DEBTOR,
      Columns.MATTER,
      Columns.AMOUNT,
      Columns.INTEREST,
      Columns.CREDIT,
      Columns.PAID,
      Columns.UNPAID,
      Columns.SEND_STATUS,
      Columns.VIEWED_ONLINE,
      Columns.STATEMENT,
      Columns.CONTEXT_MENU,
    ];
  }

  const columnsToShowPerStatus = invoiceStatuses.map((invoiceStatus) => TableColumns[invoiceStatus]);
  return _.intersection(...columnsToShowPerStatus);
}

function contextMenuCellRenderer({ props, t }) {
  return ({ rowData }) => {
    const invoiceId = rowData.id;
    const { pseudoStatus } = rowData;
    const { onInvoiceProofOfPaymentLink } = props;
    const printReceiptText = t('printDetail');

    const onSendViaMenuClicked = ({ sendVia }) => {
      if (sendVia === sentViaTypes.EMAIL) {
        props.onSendInvoiceEmail({ invoiceId });
      } else if (sendVia === sentViaTypes.COMMUNICATE) {
        props.onSendInvoiceCommunicate({ invoiceId });
      } else {
        props.onMarkInvoiceAsSent({ invoiceId });
      }
    };

    const sendOptionsMenu = (
      <>
        <button
          type="button"
          className="list-group-item"
          onClick={() => {
            onSendViaMenuClicked({ sendVia: sentViaTypes.EMAIL });
          }}
        >
          Send via Email
        </button>
        {featureActive('BB-9097') && (
          <button
            type="button"
            className="list-group-item"
            onClick={() => {
              onSendViaMenuClicked({ sendVia: sentViaTypes.COMMUNICATE });
            }}
          >
            Send via Client Portal
          </button>
        )}
        <button type="button" className="list-group-item" onClick={onSendViaMenuClicked}>
          Mark as Sent
        </button>
      </>
    );

    return (
      (pseudoStatus === invoiceStatusesMap.FINAL ||
        pseudoStatus === invoiceStatusesMap.PAID ||
        pseudoStatus === 'OVERDUE') && (
        <ContextMenu
          body={() =>
            contextMenuBodyRenderer({
              printReceiptText,
              sendOptionsMenu,
              onPrintReceipt: () => onInvoiceProofOfPaymentLink(invoiceId),
            })
          }
        >
          <div className="context-menu-cell">...</div>
        </ContextMenu>
      )
    );
  };
}

const TableColumns = {
  DRAFT: [
    Columns.CHECKBOX,
    Columns.ISSUED,
    Columns.DUE,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.SEND_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.STATEMENT,
    Columns.CONTEXT_MENU,
  ],
  FINAL: [
    Columns.CHECKBOX,
    Columns.ISSUED,
    Columns.DUE,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.INTEREST,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.SEND_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.STATEMENT,
    Columns.CONTEXT_MENU,
  ],
  OVERDUE: [
    Columns.CHECKBOX,
    Columns.ISSUED,
    Columns.DUE,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.INTEREST,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.SEND_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.STATEMENT,
    Columns.CONTEXT_MENU,
  ],
  PAID: [
    Columns.CHECKBOX,
    Columns.PAID_ON,
    Columns.ISSUED,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.SEND_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.STATEMENT,
    Columns.CONTEXT_MENU,
  ],
  VOID: [
    Columns.CHECKBOX,
    Columns.VOIDED_ON,
    Columns.VOIDED_BY,
    Columns.ISSUED,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.SEND_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.STATEMENT,
    Columns.CONTEXT_MENU,
  ],
  SEARCH: [
    Columns.CHECKBOX,
    Columns.ISSUED,
    Columns.DUE,
    Columns.INVOICE_NUMBER,
    Columns.DEBTOR,
    Columns.MATTER,
    Columns.AMOUNT,
    Columns.INTEREST,
    Columns.CREDIT,
    Columns.PAID,
    Columns.UNPAID,
    Columns.STATUS,
    Columns.SEND_STATUS,
    Columns.VIEWED_ONLINE,
    Columns.STATEMENT,
    Columns.CONTEXT_MENU,
  ],
};

InvoiceTable.displayName = 'InvoiceTable';

// NOTE you will need to have either a value for displayMode or a value from invoiceMode
// this was done like this to match the pre-react implementation of the table.
// we will be improving this components interface in the future - TH
InvoiceTable.propTypes = {
  displayMode: PropTypes.oneOf(['SEARCH']),
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  invoiceStatuses: PropTypes.arrayOf(
    PropTypes.oneOf([
      invoiceStatusesMap.DRAFT,
      invoiceStatusesMap.FINAL,
      invoiceStatusesMap.PAID,
      invoiceStatusesMap.VOID,
      'OVERDUE',
    ]),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  latestCorrespondenceLoading: PropTypes.bool,
  latestCorrespondencePerContact: PropTypes.arrayOf(PropTypes.object), // CorrespondenceHistory entity,
  selectedInvoices: PropTypes.object.isRequired,
  showDebtor: PropTypes.bool,
  showMatter: PropTypes.bool,
  showStatementColumn: PropTypes.bool,
  sort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  summary: PropTypes.object.isRequired,
  // Callbacks
  onFetchLatestCorrespondenceDetailsForInvoice: PropTypes.func.isRequired,
  onInvoiceLink: PropTypes.func.isRequired,
  onInvoiceProofOfPaymentLink: PropTypes.func,
  onMarkInvoiceAsSent: PropTypes.func.isRequired,
  onSendInvoiceCommunicate: PropTypes.func.isRequired,
  onSendInvoiceEmail: PropTypes.func.isRequired,
  onToggleInvoices: PropTypes.func.isRequired,
};

InvoiceTable.defaultProps = {
  displayMode: undefined,
  latestCorrespondenceLoading: undefined,
  latestCorrespondencePerContact: undefined,
  showDebtor: undefined,
  showMatter: undefined,
  showStatementColumn: undefined,
  onInvoiceProofOfPaymentLink: undefined,
};
