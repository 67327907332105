import * as React from 'react';
import { InvoiceCorrespondenceHistory } from 'web/graphql/queries';
import { useSubscribedLazyQuery } from './use-subscribed-lazy-query';

/**
 * Provides the data for the invoice correspondence history details
 */
export function useInvoiceCorrespondenceDetailsData({ skipQuery, contactId } = {}) {
  const [getInvoiceCorrespondenceDetails, queryResult] = useSubscribedLazyQuery(InvoiceCorrespondenceHistory, {
    variables: {},
    skip: skipQuery,
  });

  const { data, loading } = queryResult;

  const latestCorrespondencePerContact = React.useMemo(() => {
    const perContact = data?.correspondenceHistoryInvoiceLatest
      ? data.correspondenceHistoryInvoiceLatest.map((entity) => {
          const relatedContactEntity = entity.relatedItems?.find((related) => related.contactEntity);

          return {
            ...entity,
            contactDisplayName:
              relatedContactEntity?.displayNameFirstLast || entity.contacts?.[0]?.displayNameFirstLast || '',
            userDisplayName: entity.user?.person?.name || '',
          };
        })
      : undefined;

    return perContact;
  }, [data]);

  const onFetchLatestCorrespondenceDetailsForInvoice = ({ invoiceId: requestInvoiceId }) => {
    if (!requestInvoiceId) {
      return;
    }

    getInvoiceCorrespondenceDetails({ variables: { invoiceId: requestInvoiceId, contactId } });
  };

  return {
    latestCorrespondencePerContact,
    latestCorrespondenceLoading: loading,
    onFetchLatestCorrespondenceDetailsForInvoice,
  };
}
