module.exports = {
  split,
  interpolate,
  interpolateValues,
};

// used to split the description into an array of arbitary words and <identifier>:<id>.
// EX: ahahaha account:11111111-1111-1111-1111-111111111111/Operating invoice:11111111-1111-1111-1111-111111111111
//  => ['ahahaha ', 'accountId:11111111-1111-1111-1111-111111111111/Operating', '#invoiceId:11111111-1111-1111-1111-111111111111']
const DESCRIPTION_REGEX =
  /((?:\S*):(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:\|[a-zA-Z]*)?(?:\|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?\/?(?:Trust|Operating)?)/;

// used to split the aforementioned `<identifier>:<id> strings into [identifier, id]
// EX: 'accountId:11111111-1111-1111-1111-111111111111/Operating' => [accountId, 11111111-1111-1111-1111-111111111111/Operating]
//     'invoiceId:11111111-1111-1111-1111-111111111111|B|22222222-1111-1111-1111-222222222222' => [invoiceId, 11111111-1111-1111-1111-111111111111|B|22222222-1111-1111-1111-222222222222]
const ID_REGEX =
  /(\S*):([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:\|?[a-zA-Z]*\|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?\/?(?:Trust|Operating)?)/;

// available identifiers that can be used in the description
const AVAILABLE_IDENTIFIERS = ['account', 'contact', 'invoice', 'matter'];

function split(description = '', idHandler = () => {}) {
  return description.split(DESCRIPTION_REGEX).map((part) => {
    const trimmedPart = part.trim();
    const match = trimmedPart.match(ID_REGEX);

    if (!match) {
      return { type: 'TEXT', value: trimmedPart };
    }

    // invoiceIds are represented in the descriptions as `#invoiceId` (rather than just `invoiceId`).
    // everything else is represented as <identifier>Id, so we remove the `Id` part
    const type = match[1] === '#invoiceId' ? 'invoice' : match[1].slice(0, -2);

    // if the type is not in the available identifiers, we return the part as text. This is due to some user input could result in an unexpected identifier (BB-14874)
    if (!AVAILABLE_IDENTIFIERS.includes(type)) {
      return { type: 'TEXT', value: trimmedPart };
    }

    const idString = match[2];

    // id can be appended with a suffix followed by the child id (e.g. invoiceId:11111111-1111-1111-1111-111111111111|A|22222222-1111-1111-1111-222222222222)
    const idParts = idString.split('|');
    const id = idParts[0];

    let typeSpecificMetaData;
    if (type === 'invoice' && idParts.length > 1) {
      typeSpecificMetaData = {
        invoiceNumberSuffix: idParts[1],
        debtorId: idParts[2],
      };
    }

    // allow the caller to use the type & id how they wish
    // will tend to be the faciliation of bulk data collection
    idHandler(type, id);

    return { type, value: id, typeSpecificMetaData };
  });
}

// interpolate the description parts and reduce the array into a string
// MOST use-cases will use this rather than `interpolateValues`
function interpolate(descriptionParts = [], dataGetter = () => {}) {
  return descriptionParts
    .map(({ type, value, typeSpecificMetaData }) => {
      if (type === 'TEXT') {
        return value;
      }

      return dataGetter(type, value, typeSpecificMetaData);
    })
    .join(' ');
}

// interpolate the values of the description parts, but do NOT flatten the array into only values.
// this is valuable if you want to keep the data points collected but discretely apart.
// AN example of this is in the frontend where we have descriptions that are a mixture of text + links
function interpolateValues(descriptionParts = [], dataGetter = () => {}) {
  return descriptionParts.map((part) => {
    if (part.type === 'TEXT') {
      return part;
    }

    const value = dataGetter(part.type, part.value);

    if (value === undefined) {
      console.log(`Unable to get data for interpolated value`, part);
    }

    return {
      ...part,
      value: value || '',
    };
  });
}
