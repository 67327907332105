import { getCurrentConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';

angular.module('sb.billing.webapp').controller('SbViewBillController', function ($scope, $state, $stateParams, $controller, sbInvoicingService) {
  'use strict';

  let that = this;
  angular.extend(this, $controller('SbPartialStateController', { $scope: $scope }));

  const invoiceId = $stateParams.invoiceId;

  that.sbParams.sbSubState = getSubState($state.current.name);
  that.matterId = sbInvoicingService.getInvoice(invoiceId).matterId;
  that.isSplitBillingEnabledOnMatter = getCurrentConfigurationByMatterId(that.matterId)?.splitBillingSettings?.isEnabled;

  $scope.$on('smokeball-data-update-sbBillingConfigurationService', function () {
    that.isSplitBillingEnabledOnMatter = that.sbData.matterBillingConfig?.splitBillingSettings?.isEnabled;
  });

  $scope.$on('$stateChangeSuccess', (event, state) => {
    that.sbParams.sbSubState = getSubState(state.name);
  });


  function getSubState(name) {
    var subState = name.match(/^.*\.([^.]*)$/);
    return subState ? subState[1] : name;
  }
});