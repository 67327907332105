import React from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, Modal, Radio, RadioList, Spinner } from '@sb-itops/react';
import { statusByName as invoiceStatusByName } from '@sb-billing/business-logic/invoice/entities';

import { InvoiceConfirmFinaliseModal } from 'web/react-redux/components/invoice-confirm-finalise-modal';

import * as Styles from './BulkCreateInvoicesModal.module.scss';

export const BulkCreateInvoicesModal = ({
  // form state
  formFields,

  // other
  hasUnpaidAD,
  isVisible,
  isConfirmModalVisible,
  isSaving,
  selectedMatterValidCount,
  zeroBalanceInvoiceCount,
  invoiceCount,

  // function/callbacks,
  onCloseModal,
  onUpdateFieldValues,
  onUpdateIssuedDate,
  onSave,
  onCancel,
  onConfirm,
}) => {
  const isFormDisabled = isSaving || selectedMatterValidCount === 0;
  return (
    <Modal
      className={Styles.modal}
      isVisible={isVisible}
      title={`Create ${selectedMatterValidCount} invoice(s)`}
      body={
        <div className={Styles.formWrapper}>
          <InvoiceConfirmFinaliseModal
            isVisible={isConfirmModalVisible}
            hasZeroBalance={zeroBalanceInvoiceCount > 0}
            hasUnpaidAD={hasUnpaidAD}
            invoiceCount={invoiceCount}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
          <fieldset disabled={isFormDisabled}>
            <div className="row">
              <div className="col-xs-6 form-group">
                <label>DATE ISSUED</label>
                <DatePicker
                  disabled={isFormDisabled}
                  hasError={formFields?.issuedDate?.isInvalid}
                  onSelect={onUpdateIssuedDate}
                  value={formFields?.issuedDate?.value}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 form-group">
                <label>DUE DATE</label>
                <DatePicker
                  disabled={isFormDisabled}
                  hasError={formFields?.dueDate?.isInvalid}
                  onSelect={(date) => onUpdateFieldValues('dueDate', date)}
                  value={formFields?.dueDate?.value}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 form-group">
                <label>SAVE INVOICE AS</label>
                <RadioList
                  selected={formFields?.invoiceStatus?.value || invoiceStatusByName.DRAFT}
                  onChange={(event, value) => onUpdateFieldValues('invoiceStatus', value)}
                >
                  <Radio value={invoiceStatusByName.DRAFT}> Draft </Radio>
                  <Radio value={invoiceStatusByName.FINAL}> Final </Radio>
                </RadioList>
              </div>
            </div>
          </fieldset>
        </div>
      }
      footer={
        <div>
          <Button disabled={isFormDisabled} onClick={onSave}>
            Save as {formFields?.invoiceStatus?.value === invoiceStatusByName.FINAL ? 'Final' : 'Draft'}
            {isSaving && <Spinner small />}
          </Button>
        </div>
      }
      onModalClose={onCloseModal}
    />
  );
};

BulkCreateInvoicesModal.displayName = 'BulkCreateInvoicesModal';

BulkCreateInvoicesModal.propTypes = {
  // form state
  formFields: PropTypes.object.isRequired,
  hasUnpaidAD: PropTypes.bool,
  isConfirmModalVisible: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  selectedMatterValidCount: PropTypes.number,
  zeroBalanceInvoiceCount: PropTypes.number,
  invoiceCount: PropTypes.number,

  // function/callbacks
  onCloseModal: PropTypes.func.isRequired,
  onUpdateFieldValues: PropTypes.func.isRequired,
  onUpdateIssuedDate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

BulkCreateInvoicesModal.defaultProps = {
  hasUnpaidAD: false,
  selectedMatterValidCount: 0,
  zeroBalanceInvoiceCount: 0,
  invoiceCount: 0,
};
