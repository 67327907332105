import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@sb-itops/react/drop-down-list';
import {
  forms2PropTypes,
  ClickableIcon,
  FormLabel,
  Typeahead,
  DatePicker,
  useTranslation,
  Spinner,
} from '@sb-itops/react';
import classnames from 'classnames';
import { contactTypes } from '@sb-customer-management/business-logic/contacts/entities';
import { schemeMappings, testingGetAllValuesForKey } from '@sb-itops/region-schemes';
import { phonePlaceholders } from '@sb-customer-management/business-logic/contacts/entities/constantsTypescript';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { ContactPersonForm } from '../contact-person-form';
import Styles from './ContactTrustForm.module.scss';
import { AddressForm } from '../address-form';
import { ContactTypeahead2 } from '../contact-typeahead.2';

const trustStatusOptions = [
  'as Trustee of',
  'as Trustees of',
  'as Co-Trustees of',
  'as an Individual and as Trustee of',
  'as Successive Trustees of',
].map((o) => ({ label: o, value: o }));

const trusteeOptions = ['Company', 'Individual'].map((o) => ({ label: o, value: o }));

const ContactTrustForm = ({
  formInitialised,
  phoneNumberScheme,
  addressScheme,
  trustName,
  trustStatus,
  trustNumber,
  trustDate,
  trustees,
  trustPhoneAreaCode,
  trustPhoneNumber,
  trustFaxAreaCode,
  trustLedesClientId,
  trustFax,
  accountName,
  bankName,
  bankBranchNumber,
  accountNumber,
  showBankDetailsTab,
  onFieldValueUpdated,
  onFieldValueSet,
  deleteField,
  formDisabled,
  addressFields,
  submitFailed,
  additionalContactProps,
  validateForm,
  peopleOptions,
  peopleOptionsDataLoading,
  peopleOptionsHasMore,
  onFetchPeopleOptions,
  onFetchMorePeopleOptions,
  contactId,
  optionalAddress,
  inline,
  isUtbmsEnabled,
  onAddressAutocompleteSelected,
}) => {
  const { t } = useTranslation();

  const [contactIndex, setContactIndex] = useState(0);
  if (!formInitialised) {
    return <Spinner small />;
  }

  const showPhoneAreaCodeField = phoneNumberScheme === schemeMappings.phoneNumberScheme.US;

  const isErrorClassnames = (field, overrideStyle) =>
    field?.isInvalid && (field?.isDirty || submitFailed) ? classnames(Styles.hasError, overrideStyle) : '';

  const onTrustFieldsUpdated = (payload) => onFieldValueUpdated({ trustFields: payload });
  const onAddressFieldsUpdated = (payload) => onFieldValueUpdated({ addressFields: payload });
  const onBankFieldsUpdated = (payload) => onFieldValueUpdated({ bankAccountFields: payload });

  const additionalContacts = Object.keys(additionalContactProps || {}).map((key) => {
    const onPersonFieldsUpdated = (payload) => onTrustFieldsUpdated({ additionalContactProps: { [key]: payload } });
    const contact = additionalContactProps[key];
    return (
      <div key={key} className={classnames(Styles.additionalContact, inline ? Styles.mutedShadow : '')}>
        <h5>
          Contact Details{' '}
          <ClickableIcon
            className={classnames('icon-bin-2', Styles.binIcon)}
            onClick={() => {
              deleteField(`trustFields.additionalContactProps.${key}`);
            }}
          />
        </h5>
        <div className={Styles.addExistingContact}>
          <label>Select an Existing Contact?</label>
          {featureActive('NUCWEB-726') ? (
            <ContactTypeahead2
              contactOptions={
                additionalContactProps
                  ? peopleOptions.filter(
                      (option) =>
                        Object.entries(additionalContactProps).filter(
                          ([k, c]) => c.id?.value === option.data.id && k !== key,
                        ).length === 0,
                    )
                  : peopleOptions
              }
              contactOptionsHasMore={peopleOptionsHasMore}
              defaultContactId={contact.id?.value}
              defaultContactOptions={peopleOptions}
              disabled={formDisabled}
              isClearable
              isLoading={peopleOptionsDataLoading}
              maxMenuHeight={250}
              // Callbacks
              onContactSelected={(option) => {
                onTrustFieldsUpdated({
                  additionalContactProps: {
                    [key]: {
                      contactType: contactTypes.PERSON,
                      id: option?.value || '',
                      existing: !!option?.value,
                    },
                  },
                });
              }}
              onFetchContactOptions={onFetchPeopleOptions}
              onFetchMoreContactOptions={onFetchMorePeopleOptions}
              menuPlacement="top"
              placeholder="Select contact..."
              className={classnames(Styles.formSelect)}
            />
          ) : (
            <Typeahead
              menuPlacement="top"
              selectedOption={contact.id?.value}
              options={
                // Remove already selected options
                additionalContactProps
                  ? peopleOptions.filter(
                      (option) =>
                        Object.entries(additionalContactProps).filter(
                          ([k, c]) => c.id?.value === option.value.entityId && k !== key,
                        ).length === 0,
                    )
                  : peopleOptions
              }
              disabled={formDisabled}
              placeholder="Select contact..."
              className={classnames(Styles.formSelect)}
              onSelect={(option) => {
                onTrustFieldsUpdated({
                  additionalContactProps: {
                    [key]: {
                      contactType: contactTypes.PERSON,
                      title: '',
                      id: option?.value?.entityId || '',
                      firstName: option?.value?.firstName || '',
                      lastName: option?.value?.lastName || '',
                      phoneAreaCode: '',
                      phoneNumber: option?.value?.phone || '',
                      email: option?.value?.email || '',
                      cellAreaCode: '',
                      cellNumber: option?.value?.phone2 || '',
                      existing: !!option?.value?.entityId,
                    },
                  },
                });
              }}
            />
          )}
        </div>
        {!contact.id?.value && (
          <ContactPersonForm
            formInitialised={formInitialised}
            phoneNumberScheme={phoneNumberScheme}
            key={key}
            {...(contact || {})}
            validateForm={validateForm}
            onFieldValueUpdated={onPersonFieldsUpdated}
            submitFailed={submitFailed}
            formDisabled={formDisabled || contact.existing?.value}
            removeRequired={contact.existing?.value}
            isUtbmsEnabled={isUtbmsEnabled}
          />
        )}
      </div>
    );
  });

  return (
    <div>
      <fieldset disabled={formDisabled}>
        <div className="row">
          <div className="col-xs-12">
            <FormLabel label="Name" field={trustName} submitFailed={submitFailed} />
            <input
              name="trustName"
              type="text"
              className={classnames('form-control', isErrorClassnames(trustName, Styles.formControl))}
              value={trustName?.value || ''}
              onBlur={validateForm}
              onChange={(e) => {
                onTrustFieldsUpdated({ trustName: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-7">
            <label>Status</label>
            <DropDownList
              selectedOption={trustStatusOptions.find(
                (option) => option.value === trustStatus?.value || option.label === trustStatus?.value,
              )}
              options={trustStatusOptions}
              disabled={formDisabled}
              onChange={({ value }) => {
                validateForm();
                onTrustFieldsUpdated({ trustStatus: value });
              }}
              className={classnames(isErrorClassnames(trustStatus, Styles.formSelect))}
              stateless
            />
          </div>
          <div className="col-xs-5">
            <label>Executed Date</label>
            <DatePicker
              onSelect={(date) => {
                onTrustFieldsUpdated({ trustDate: date });
              }}
              value={trustDate?.value}
              hasError={trustDate && trustDate?.isInvalid}
              format="DD/MM/YYYY"
              hideDelete
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <FormLabel optional label="Trust Number" field={trustNumber} submitFailed={submitFailed} />
            <input
              name="trustNumber"
              type="text"
              className={classnames('form-control', isErrorClassnames(trustNumber, Styles.formControl))}
              value={trustNumber?.value || ''}
              onBlur={validateForm}
              onChange={(e) => {
                onTrustFieldsUpdated({ trustNumber: e.target.value });
              }}
            />
          </div>
        </div>
        {(trustees || []).map((trustee, i) => (
          <div key={`${i}`} className="row">
            <div className="col-xs-8">
              <FormLabel optional label="Trustee" field={trustee?.name} submitFailed={submitFailed} />
              <input
                name="trusteeName"
                type="text"
                className={classnames('form-control', isErrorClassnames(trustee?.name, Styles.formControl))}
                value={trustee?.name?.value || ''}
                onBlur={validateForm}
                onChange={(e) => {
                  const newTrustees = trustees.map((te) => ({ name: te?.name?.value, type: te?.type?.value }));
                  newTrustees[i].name = e.target.value;
                  onFieldValueSet('trustFields.trustees', newTrustees);
                }}
              />
            </div>
            <div className="col-xs-3">
              <label className={Styles.hiddenLabel}>TRUSTEE TYPE</label>
              <DropDownList
                selectedOption={trusteeOptions.find(
                  (option) => option.value === trustee?.type?.value || option.label === trustee?.type?.value,
                )}
                options={trusteeOptions}
                disabled={formDisabled}
                onChange={({ value }) => {
                  validateForm();
                  const newTrustees = trustees.map((te) => ({ name: te?.name?.value, type: te?.type?.value }));
                  newTrustees[i].type = value;
                  onFieldValueSet('trustFields.trustees', newTrustees);
                }}
                className={classnames(isErrorClassnames(trustee?.type, Styles.formSelect))}
                stateless
              />
            </div>
            <div className={classnames('col-xs-1', Styles.trashColumn)}>
              {i !== 0 && (
                <div
                  className={`fa fa-trash icon ${Styles.trashcan}`}
                  onClick={() => {
                    const newTrustees = trustees.map((te) => ({ name: te?.name?.value, type: te?.type?.value }));
                    newTrustees.splice(i, 1);
                    onFieldValueSet('trustFields.trustees', newTrustees);
                  }}
                />
              )}
            </div>
          </div>
        ))}
        <div
          className={classnames('link-button', Styles.addContact)}
          onClick={() => {
            const newTrustees = trustees.map((te) => ({ name: te?.name?.value, type: te?.type?.value }));
            newTrustees.push({ name: '', type: '' });
            onFieldValueSet('trustFields.trustees', newTrustees);
            setContactIndex(contactIndex + 1);
          }}
        >
          <i className="icon icon-add-3" />
          ADD ANOTHER TRUSTEE
        </div>
        <div className="row">
          <div className="col-xs-6">
            <FormLabel label="Phone" field={trustPhoneNumber} submitFailed={submitFailed} optional />
            <div className="row">
              {showPhoneAreaCodeField && (
                <div className="col-xs-3">
                  <input
                    name="trustPhoneAreaCode"
                    type="text"
                    pattern="[0-9]+"
                    className={classnames('form-control', isErrorClassnames(trustPhoneAreaCode, Styles.formControl))}
                    value={trustPhoneAreaCode?.value || ''}
                    placeholder={phonePlaceholders.phoneAreaCode[phoneNumberScheme]}
                    onBlur={validateForm}
                    onChange={(e) => {
                      onTrustFieldsUpdated({ trustPhoneAreaCode: e.target.value });
                    }}
                  />
                </div>
              )}
              <div className={showPhoneAreaCodeField ? 'col-xs-9' : 'col-xs-12'}>
                <input
                  name="trustPhoneNumber"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(trustPhoneNumber, Styles.formControl))}
                  value={trustPhoneNumber?.value || ''}
                  placeholder={phonePlaceholders.phoneNumber[phoneNumberScheme]}
                  onBlur={validateForm}
                  onChange={(e) => {
                    onTrustFieldsUpdated({ trustPhoneNumber: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-6">
            <label>Fax</label>
            <div className="row">
              {showPhoneAreaCodeField && (
                <div className="col-xs-3">
                  <input
                    name="trustFaxAreaCode"
                    type="text"
                    pattern="[0-9]+"
                    className={classnames('form-control', isErrorClassnames(trustFaxAreaCode, Styles.formControl))}
                    value={trustFaxAreaCode?.value || ''}
                    onBlur={validateForm}
                    onChange={(e) => {
                      onTrustFieldsUpdated({ trustFaxAreaCode: e.target.value });
                    }}
                  />
                </div>
              )}
              <div className={showPhoneAreaCodeField ? 'col-xs-9' : 'col-xs-12'}>
                <input
                  name="trustFax"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(trustFax, Styles.formControl))}
                  value={trustFax?.value || ''}
                  onBlur={validateForm}
                  onChange={(e) => {
                    onTrustFieldsUpdated({ trustFax: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {showBankDetailsTab && inline && hasFacet(facets.contactBankBalance) && (
          <>
            <hr className={Styles.hr} />
            <div>
              <div className={classnames('form-group')}>
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>Account Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={accountName.value}
                      onChange={(e) => onBankFieldsUpdated({ accountName: e.target.value })}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label>Bank</label>
                    <input
                      type="text"
                      className="form-control"
                      value={bankName.value}
                      onChange={(e) => onBankFieldsUpdated({ bankName: e.target.value })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>{t('bankBranchNumber')}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={bankBranchNumber.value}
                      onChange={(e) => {
                        const re = /^(\d{1,3}|\d{3}-?\d{0,3})$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          onBankFieldsUpdated({ bankBranchNumber: e.target.value });
                        }
                      }}
                    />
                  </div>

                  <div>
                    <div className="form-group col-lg-6">
                      <label>Account Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={accountNumber.value}
                        onChange={(e) => {
                          const re = /^[0-9]*$/;
                          if (e.target.value === '' || re.test(e.target.value)) {
                            onBankFieldsUpdated({ accountNumber: e.target.value });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <hr className={Styles.hr} />
        <AddressForm
          formInitialised={formInitialised}
          addressScheme={addressScheme}
          {...addressFields}
          onFieldValueUpdated={onAddressFieldsUpdated}
          validateForm={validateForm}
          submitFailed={submitFailed}
          formDisabled={formDisabled}
          optional={optionalAddress}
          onAddressAutocompleteSelected={onAddressAutocompleteSelected}
          forceShowState
        />
        {!!isUtbmsEnabled && (
          <div className="row">
            <div className="col-xs-12">
              <label>Ledes Client Id</label>
              <input
                name="trustLedesClientId"
                type="text"
                className={classnames('form-control', isErrorClassnames(trustLedesClientId, Styles.formControl))}
                value={trustLedesClientId?.value || ''}
                onBlur={validateForm}
                onChange={(e) => {
                  onTrustFieldsUpdated({ trustLedesClientId: e.target.value });
                }}
                required
              />
            </div>
          </div>
        )}
        {!contactId && (
          <>
            <hr className={classnames(Styles.hr, Styles.hrTop)} />
            <h4>Trust Contacts</h4>
            {additionalContacts}
            <div
              className={classnames('link-button', Styles.addContact)}
              onClick={() => {
                onTrustFieldsUpdated({
                  additionalContactProps: {
                    [`contact${contactIndex}`]: {
                      contactType: contactTypes.PERSON,
                      id: '',
                      title: '',
                      firstName: '',
                      lastName: '',
                      phoneAreaCode: '',
                      phoneNumber: '',
                      email: '',
                      cellAreaCode: '',
                      cellNumber: '',
                      existing: false,
                    },
                  },
                });
                setContactIndex(contactIndex + 1);
              }}
            >
              <i className="icon icon-add-3" />
              ADD CONTACT
            </div>
          </>
        )}
      </fieldset>
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

ContactTrustForm.displayName = 'ContactTrustForm';
ContactTrustForm.propTypes = {
  formInitialised: PropTypes.bool,
  submitFailed: PropTypes.bool,
  addressFields: PropTypes.object,
  additionalContactProps: PropTypes.object,
  phoneNumberScheme: PropTypes.oneOf(testingGetAllValuesForKey('phoneNumberScheme')).isRequired,
  addressScheme: PropTypes.oneOf(testingGetAllValuesForKey('addressScheme')).isRequired,
  trustName: Forms2Field,
  trustStatus: Forms2Field,
  trustNumber: Forms2Field,
  trustDate: Forms2Field,
  trustees: PropTypes.arrayOf(PropTypes.object),
  trustPhoneAreaCode: Forms2Field,
  trustPhoneNumber: Forms2Field,
  trustFaxAreaCode: Forms2Field,
  trustFax: Forms2Field,
  trustLedesClientId: Forms2Field,
  accountName: Forms2Field,
  accountNumber: Forms2Field,
  bankBranchNumber: Forms2Field,
  bankName: Forms2Field,
  showBankDetailsTab: PropTypes.bool,
  onAddressAutocompleteSelected: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  onFieldValueSet: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  formDisabled: PropTypes.bool,
  validateForm: PropTypes.func.isRequired,
  peopleOptions: PropTypes.arrayOf(Forms2Field),
  contactId: PropTypes.string,
  optionalAddress: PropTypes.bool,
  inline: PropTypes.bool,
  isUtbmsEnabled: PropTypes.bool,
  peopleOptionsDataLoading: PropTypes.bool,
  peopleOptionsHasMore: PropTypes.bool,
  onFetchPeopleOptions: PropTypes.func,
  onFetchMorePeopleOptions: PropTypes.func,
};

ContactTrustForm.defaultProps = {
  formInitialised: false,
  submitFailed: false,
  addressFields: undefined,
  showBankDetailsTab: undefined,
  additionalContactProps: undefined,
  accountName: undefined,
  accountNumber: undefined,
  bankBranchNumber: undefined,
  bankName: undefined,
  trustName: undefined,
  trustStatus: undefined,
  trustNumber: undefined,
  trustDate: undefined,
  trustees: undefined,
  trustPhoneAreaCode: undefined,
  trustPhoneNumber: undefined,
  trustFaxAreaCode: undefined,
  trustLedesClientId: undefined,
  trustFax: undefined,
  formDisabled: false,
  peopleOptions: [],
  contactId: undefined,
  optionalAddress: false,
  inline: false,
  isUtbmsEnabled: undefined,
  peopleOptionsDataLoading: undefined,
  peopleOptionsHasMore: undefined,
  onFetchPeopleOptions: undefined,
  onFetchMorePeopleOptions: undefined,
};

export default ContactTrustForm;
