import PropTypes from 'prop-types';

import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';

import { useCacheQuery, useSubscribedQuery } from 'web/hooks';
import {
  CreditCardPaymentModalData,
  InitFirmDetails,
  InitOperatingBankAccount,
  InitPaymentProviderSettings,
  InitStaffSettings,
} from 'web/graphql/queries';

import { convertSettingsFromGQL } from '@sb-billing/business-logic/payment-provider/services';
import { CreditCardPaymentModalFormsContainer } from './CreditCardPaymentModal.forms.container';

export const CREDIT_CARD_PAYMENT_MODAL_ID = 'CREDIT_CARD_PAYMENT_MODAL_ID';

const onCreditCardPaymentModalClose = () => setModalDialogHidden({ modalId: CREDIT_CARD_PAYMENT_MODAL_ID });

const hooks = () => ({
  useCreditCardPaymentModal: (props) => ({
    showCreditCardPaymentModal: props.showModal,
    onCreditCardPaymentModalClose,
  }),
  useQueryData: ({ invoiceId, debtorId }) => {
    const { data, loading, error } = useSubscribedQuery(CreditCardPaymentModalData, {
      variables: {
        id: invoiceId,
      },
    });

    if (error) {
      throw new Error(error);
    }

    const invoice = data?.invoice;
    const isSplitBillingInvoice = !!(invoice?.splitBillingSettings?.isEnabled && invoice?.debtors?.length > 1);

    let payor;
    let invoiceNumberSuffix;
    if (isSplitBillingInvoice && debtorId) {
      payor = (invoice?.debtors || []).find((d) => d.id === debtorId)?.contact;
      invoiceNumberSuffix = (invoice?.debtors || []).find((d) => d.id === debtorId)?.invoiceNumberSuffix;
    } else {
      payor = invoice?.debtors?.[0]?.contact;
    }

    let unpaidAmount;
    if (isSplitBillingInvoice && debtorId) {
      const invoiceDebtorTotals = invoice?.debtorTotals;
      const debtorTotals = (invoiceDebtorTotals || []).find((t) => t.debtorId === debtorId);
      unpaidAmount = debtorTotals?.unpaid;
    } else {
      unpaidAmount = invoice?.totals?.unpaid;
    }

    return { debtorId, invoice, invoiceNumberSuffix, isSplitBillingInvoice, payor, unpaidAmount, isLoading: loading };
  },
  useOperatingBankAccountData: () => {
    const { data } = useCacheQuery(InitOperatingBankAccount.query);
    const operatingBankAccountId = data.bankAccounts[0]?.id;

    return {
      operatingBankAccountId,
    };
  },
  usePaymentProviderData: () => {
    const { data } = useCacheQuery(InitPaymentProviderSettings.query);
    const paymentProviderSettings = data.paymentProviderSettings;
    const activeProviderType = paymentProviderSettings.activeProvider;

    const formattedActiveProviderType = convertSettingsFromGQL({
      providerType: activeProviderType,
      formattedSettingsFromGQL: paymentProviderSettings.providers[activeProviderType],
    });

    return {
      formattedProviderSpecificSettings: formattedActiveProviderType,
      providerType: activeProviderType,
    };
  },
  useStaffData: () => {
    const { data } = useCacheQuery(InitStaffSettings.query);
    const loggedInStaffMember = data.loggedInStaff;

    return {
      loggedInStaffMember,
    };
  },
  useFirmData: () => {
    const { data } = useCacheQuery(InitFirmDetails.query);
    const firmName = data.firm.firmName;

    return {
      firmName,
    };
  },
});

const dependentHooks = () => ({});

export const CreditCardPaymentModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(CreditCardPaymentModalFormsContainer))),
);

CreditCardPaymentModalContainer.displayName = 'CreditCardPaymentModalContainer';

CreditCardPaymentModalContainer.propTypes = {
  debtorId: PropTypes.string,
  invoiceId: PropTypes.string.isRequired,
  sbNotifiedOperationP: PropTypes.func.isRequired, // sbNotifiedOperationP is required so that a callback can be executed once the "PaymentProviderChargeCompleted" notification (related to the credit card payment) is received
  scope: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
};

CreditCardPaymentModalContainer.defaultProps = {
  debtorId: undefined,
};
