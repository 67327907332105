import React, { useEffect, useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { MatterTypeDetails } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { SbClientSdk } from 'web/react-redux/components/sb-client-sdk';
import { withApolloClient } from 'web/react-redux/hocs';
import { selectors } from 'web/redux/features/infotrack';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getInfoTrackUrl } from 'web/services/infotrack-urls';
import { sendMetric } from 'web/services/metrics';
import { useSelector } from 'react-redux';
import { EmbeddedIframePosition } from 'web/redux/features/shared/types';
import { ErrorMessage } from './ErrorMessage';

export const INFOTRACK_MODAL_ID = 'INFOTRACK_MODAL_ID';

const hooks = ({ onClickLink }) => ({
  useMatterTypeDetails: () => {
    const matterId: string | undefined = useSelector(selectors.getMatterId);
    const position: EmbeddedIframePosition = useSelector(selectors.getPosition);
    const visible: boolean = useSelector(selectors.getVisible);
    const matterTypeQueryResult = useSubscribedQuery(MatterTypeDetails, {
      skip: !matterId,
      variables: {
        matterId,
        matterFilter: {
          includeNonBillableLeadMatters: true,
          includeDeleted: true,
        },
      },
    });

    if (matterTypeQueryResult.error) {
      throw matterTypeQueryResult.error;
    }

    const { data } = matterTypeQueryResult;
    const matterTypeId = data?.matter.matterType.matterTypeId;

    return { matterId, onClickLink, matterTypeId, position, visible };
  },
});

const Container = (props: {
  matterId: string | undefined;
  onClickLink: any;
  matterTypeId: string | undefined;
  position: EmbeddedIframePosition;
  visible: boolean;
  documentId?: string | undefined;
}) => {
  const [url, setUrl] = useState<string>();
  const [isError, setIsError] = useState(false);
  const { onClickLink, matterId, matterTypeId, documentId, position, visible } = props;

  const style: React.HTMLAttributes<HTMLIFrameElement>['style'] = position
    ? {
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: `${position.width}px`,
        height: `${position.height}px`,
        position: 'absolute',
        visibility: visible ? 'visible' : 'hidden',
        zIndex: visible ? 1 : -1,
      }
    : undefined;

  useEffect(() => {
    sendMetric('OpenInfoTrack');
    setUrl('');
    setIsError(false);
    if (matterId && matterTypeId) {
      getInfoTrackUrl(matterId, matterTypeId, documentId).then(
        (v) => {
          setUrl(v);
        },
        () => {
          setIsError(true);
        },
      );
    }
  }, [matterTypeId]);

  useEffect(() => {
    if (matterId && matterTypeId && isError && visible) {
      getInfoTrackUrl(matterId, matterTypeId, documentId).then((v) => {
        setUrl(v);
        setIsError(false);
      });
    }
  }, [visible]);

  if (isError) {
    return <ErrorMessage position={position} visible={visible} />;
  }

  return url ? <SbClientSdk sdkAppUrl={url} onClickLink={onClickLink} matterId={matterId} style={style} /> : null;
};

export const InfoTrackIframeContainer = withReduxProvider(withApolloClient(composeHooks(hooks)(Container)));
