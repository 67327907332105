// DK 29/5/24 - Adding entryTypes to UnbilledMatterListFilter caused issues with the amount fields (unbilledAmountExcTax etc.).
//
// By default, we normalize entities by id field. Threfore, UnbilledMatter was normalized in the Apollo cache as 'UnbilledMatter:<id>'.
// This was an issue, as changing entry types which we want to include may change the amount fields, but since the UnbilledMatter id stays the same,
// same normalized UnbilledMatter in cache was used for different entry types combination. This caused the amounts to be wrong sometimes.
// After discussion with Igor, we decided to alias id to matterId which effectively disables normalization of UnbilledMatter for this query.
// Alternative would be disabling normalisation for UnbilledMatter type globally.

import { gql } from '@apollo/client';

const query = gql`
  query UnbilledMatterTableData(
    $unbilledMatterListFilter: UnbilledMatterListFilter
    $offset: Int
    $limit: Int
    $sort: ListSort
  ) {
    unbilledMatterList(filter: $unbilledMatterListFilter, offset: $offset, limit: $limit, sort: $sort) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      results {
        matterId: id # read explanation above, this purposely renames id field to avoid normalization of UnbilledMatter
        billingConfiguration {
          splitBillingSettings {
            isEnabled
          }
        }
        clientDisplay
        clients {
          id
          displayName
        }
        description
        hasUnpaidAnticipatedDisbursements
        lastInvoicedDate
        matterNumber
        status
        matterType {
          matterTypeId
          name
        }
        otherSideDisplay
        unbilledAmountExcTax
        writtenOffAmountExcTax
        nonBillableAmountExcTax
      }
    }
  }
`;

const notificationIds = [
  // the below notifications should be sufficient to cover the scenarios we care about, these
  // are mostly thing that affect important values like unbilled amount, trust balance shown in
  // the table, as well as anything that affects filtering and thus the list of matters returned, e.g.
  // 1) matter totals should change when fee/expense is added
  // 2) billing configurations that affect filtering should be notified
  // 3) any trust/operating balance change should also be notified as it affects filtering
  // 4) when fee/expenses are updated with different date, this is important when date filter is used
  'WebQueryBillingMattersNotifications.BillingMatterTotalsUpdated',
  'WebQueryBillingMattersNotifications.BillingConfigurationUpdated',
  'WebQueryAccountsNotifications.AccountBalanceUpdated',
  'WebQueryBillingSharedNotifications.BillingBulkActionFinished',
  'WebQueryFeesNotifications.AccountFeesUpdated',
  'WebQueryExpensesNotifications.ExpenseUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const UnbilledMatterTableData = {
  query,
  notificationIds,
};
