'use strict';

const { wait } = require('./wait');
const { pollUntil } = require('./poll-until');

// originally thought of putting this into the '@sb-itops/timer' module,
// the issue is that the timer module uses perf_hook which is a node thing

module.exports = {
  wait,
  pollUntil,
};
