const { wait } = require('./wait');

/**
 * Poll until the given condition is met or max retries is reached
 * @param {object} params
 * @param {function} params.pollfn polling function to execute
 * @param {function} params.untilConditionFn function with until condition. This accepts the result of the polling function as the first parameter.
 * @param {number} [params.ms] amount of time in milli-seconds between each poll
 * @param {number} [params.maxRetries]  maximum retries before existing polling
 * @returns 
 */
async function pollUntil({ pollFn, untilConditionFn, ms = 3000, maxRetries = 20 }) {
  let result = await pollFn();
  let attempt = 1;
  while (!untilConditionFn(result) && attempt < maxRetries) {
    await wait(ms);
    result = await pollFn();
    attempt += 1;
  }
  return result;
}

module.exports = {
  pollUntil,
};
