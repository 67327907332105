import { gql } from '@apollo/client';
import { calculateFeeBillableDuration } from '@sb-billing/business-logic/fee/services';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';
import { sendMetric } from 'web/services/metrics';
import { featureActive } from '@sb-itops/feature';
import { update } from '@intercom/messenger-js-sdk';

export const onSaveFee = ({ commandResult }) => {
  const { version } = commandResult;

  const billableDuration = calculateFeeBillableDuration({ fee: version });

  const optimisticFee = {
    __typename: 'Fee',
    id: commandResult.feeId,
    activityCode: version.feeActivityId || version.utbmsActivityCode,
    amountExcTax: version.rate * version.duration,
    amountIncludesTax: version.amountIncludesTax,
    billableAmountExcTax: version.rate * billableDuration,
    billableTax: version.billableTax || 0,
    billableDuration,
    createdByAutoTime: false,
    createdByUserId: version.createdByUserId,
    description: version.description,
    duration: version.duration,
    durationWorked: version.durationWorked,
    feeActivityId: version.feeActivityId, // Actually a custom activity code, e.g. CT3
    feeDate: version.feeDate,
    feeEarnerStaff: {
      id: version.feeEarnerStaffId,
    },
    feeType: version.feeType,
    feeVersionId: version.feeVersionId,
    invoice: !version.invoiceId
      ? null
      : {
          id: version.invoiceId,
        },
    isBillable: version.isBillable,
    isDeleted: version.isDeleted,
    isInvoicedExternally: null,
    isTaxExempt: version.isTaxExempt,
    matter: {
      id: version.matterId,
    },
    notes: version.notes || null,
    rate: version.rate,
    sourceItems: version.sourceItems || [],
    tax: version.tax || 0,
    utbmsActivityCode: version.utbmsActivityCode || null,
    utbmsTaskCode: version.utbmsTaskCode || null,
    validFrom: null,
    validTo: null,
    waived: version.waived,
  };

  const apolloClient = getApolloClient();

  const feeIdentifier = apolloClient.cache.identify(optimisticFee);

  apolloClient.writeFragment({
    id: feeIdentifier,
    fragment: gql`
      fragment OpdateFee on Fee {
        ${Object.keys(optimisticFee).join('\n')}
      }
    `,
    data: {
      ...optimisticFee,
    },
  });

  sendMetric('FeesAmountIncludesTax', {amountIncludesTax: version.amountIncludesTax});

  if (featureActive('NUCWEB-914') && window && window.intercomSettings && !window.intercomSettings['Fee Created']) {
    window.intercomSettings['Fee Created'] = true;
    update(window.intercomSettings);
  }

  return {
    eventType: commandResult.isUpdate
      ? apolloEntityOpdateEventTypes.ENTITY_UPDATED
      : apolloEntityOpdateEventTypes.ENTITY_ADDED,
    typeName: 'Fee',
    optimisticEntity: optimisticFee,
  };
};
