import { gql } from '@apollo/client';

const query = gql`
  query MarkAsSentModalData($firstInvoiceId: ID!) {
    invoice(id: $firstInvoiceId) {
      id
      matter {
        id
        matterCommunicationSettings {
          id
          correspondencePreferences
        }
      }
    }
  }
`;

const notificationIds = ['BillingMattersNotifications.MatterCommunicationSettingsUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MarkAsSentModalData = {
  query,
  notificationIds,
};
