angular
  .module('sb.billing.webapp')
  .controller('ViewContactBillsController', function (
    sbSaveInvoiceCommand,
    sbAsyncOperationsService,
    sbInvoiceSendService,
    sbLinkService,
    $stateParams,
  ) {
    const ctrl = this;
    const contactId = $stateParams.contactId;

    ctrl.contactId = contactId;
    ctrl.sbAsyncOperationsService = sbAsyncOperationsService;
    ctrl.sbSaveInvoiceCommand = sbSaveInvoiceCommand;
    ctrl.sbInvoiceSendService = sbInvoiceSendService;
    ctrl.isDownloadLedesInProgress = isDownloadLedesInProgress;
    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.isCombineInProgress = isCombineInProgress;

    function isCombineInProgress() {
      return (
        sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.COMBINE_INVOICES) > 0
      );
    }

    function isDownloadLedesInProgress() {
      return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.DOWNLOAD_LEDES) > 0;
    }
  });
