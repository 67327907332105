import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, utils } from '@sb-itops/react/table';
import { Checkbox } from '@sb-itops/react/checkbox';
import { t } from '@sb-itops/localisation-web';
import { ExpandCollapseToggler } from '@sb-itops/react/expand-collapse-toggler';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { featureActive } from '@sb-itops/feature';

const { yyyymmddLocalisedRenderer, balanceCellLocalisedRenderer, checkboxCellWrapperRenderer } = utils;

class CreateInvoicesTable extends React.Component {
  static amountRenderer({ rowData, cellData }) {
    if (rowData.clientId) {
      return null;
    }

    return balanceCellLocalisedRenderer({ cellData });
  }

  constructor(props) {
    super(props);

    this.checkboxMatterCellRenderer = this.checkboxMatterCellRenderer.bind(this);
    this.checkboxContactCellRenderer = this.checkboxContactCellRenderer.bind(this);
    this.checkboxHeaderCellRenderer = this.checkboxHeaderCellRenderer.bind(this);
    this.expanderCellRenderer = this.expanderCellRenderer.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
    this.matterOrContactRenderer = this.matterOrContactRenderer.bind(this);

    this.renderDefaultMode = this.renderDefaultMode.bind(this);
    this.renderGroupByClientMode = this.renderGroupByClientMode.bind(this);
  }

  rowStyle({ rowData }) {
    const displayMode = this.props.displayMode === 'default' ? 'group-by-matter' : 'group-by-client';

    if (rowData && rowData.matterId && !rowData.hasDebtor) {
      return rowData.matterId === this.props.selectedMatter
        ? `${displayMode} matter-row no-debtor selected`
        : `${displayMode} matter-row no-debtor`;
    }

    if (rowData && rowData.clientId) {
      return `${displayMode} debtor-row`;
    }

    if (rowData && (rowData.clientId || rowData.matterId !== this.props.selectedMatter)) {
      return `${displayMode} matter-row`;
    }

    return `${displayMode} matter-row selected`;
  }

  checkboxContactCellRenderer({ rowData }) {
    if (!rowData.clientId) {
      return null;
    }

    const { onToggleSelectMatters } = this.props;
    const { selectedMatters } = this.props;
    const clientMatters = Object.values(rowData.matters);
    // selected if all the matter included under this contact are selected
    const selected = clientMatters.every((matter) => selectedMatters[matter.matterId] || false);

    const onToggleSelectClientMatters = () => {
      const mattersToToggle = selected
        ? clientMatters // if all selected, toggle all matters
        : clientMatters.filter((matter) => !selectedMatters[matter.matterId]); // if not all selected, toggle only unselected matters

      onToggleSelectMatters(mattersToToggle);
    };

    return (
      <Checkbox
        disabled={rowData.clientId === 'noDebtor'}
        checked={selected && rowData.clientId !== 'noDebtor'}
        onChange={onToggleSelectClientMatters}
      />
    );
  }

  checkboxMatterCellRenderer({ rowData }) {
    if (rowData.clientId) {
      return null;
    }

    const { onToggleSelectMatters } = this.props;
    const { selectedMatters } = this.props;
    const selected = selectedMatters[rowData.matterId] || false;
    const isSplitBillingMatter = rowData.billingConfiguration?.splitBillingSettings?.isEnabled;
    const disabled = !rowData.hasDebtor || isSplitBillingMatter;

    return (
      <Checkbox
        disabled={disabled}
        checked={selected && !disabled}
        onChange={() => onToggleSelectMatters([rowData])}
        title={
          // eslint-disable-next-line no-nested-ternary
          !rowData.hasDebtor
            ? 'Debtor missing.  Create invoice manually.'
            : isSplitBillingMatter
              ? 'Split billing matter. Bulk action is disabled. Action from matter.'
              : undefined
        }
      />
    );
  }

  checkboxHeaderCellRenderer() {
    const { selectedMatters, matters, onToggleSelectMatters } = this.props;
    const currentPageSelectedCount = matters.filter((matter) => selectedMatters[matter.matterId]).length;
    const isAllSelected = !!currentPageSelectedCount && currentPageSelectedCount === matters.length;
    const onToggleSelectAllMatters = () => {
      const mattersToToggle = isAllSelected
        ? matters // if all selected, toggle all matters
        : matters.filter((matter) => !selectedMatters[matter.matterId]); // if not all selected, toggle only unselected matters
      onToggleSelectMatters(mattersToToggle);
    };

    return <Checkbox checked={isAllSelected} onChange={onToggleSelectAllMatters} />;
  }

  expanderCellRenderer({ rowData }) {
    if (!rowData.clientId) {
      return null;
    }

    const { expandedContacts, onCollapseToggle } = this.props;
    const contactExpanded = expandedContacts[rowData.clientId];
    return (
      <ExpandCollapseToggler
        isExpanded={contactExpanded}
        onToggled={() => {
          onCollapseToggle(rowData);
        }}
      />
    );
  }

  matterOrContactRenderer({ rowData }) {
    const { displayMode } = this.props;
    const defaultMode = displayMode === 'default';

    if (!rowData.matterId) {
      if (rowData.clientId === 'noDebtor') {
        return <span>{rowData.display || rowData.displayName}</span>;
      }
      return (
        <LinkableText
          className="client"
          text={rowData.displayName}
          onClickLink={() => {
            this.props.onClickLink({ type: 'contact', id: rowData.clientId });
          }}
          asLink
        />
      );
    }

    if (!rowData.hasDebtor) {
      const missingLinkText = defaultMode ? 'debtor missing: create invoice manually' : 'add missing debtor';
      return (
        <div className="matter-with-checkbox-wrapper">
          {!defaultMode && (
            <div className="checkbox-container">
              {checkboxCellWrapperRenderer(this.checkboxMatterCellRenderer)({ rowData })}
            </div>
          )}
          <div className="matter-wrapper">
            <LinkableText
              className="matter"
              text={rowData.matterSummaryLine}
              onClickLink={() => {
                this.props.onClickLink({ type: 'matter', id: rowData.matterId });
              }}
              asLink
            />
            <span
              className="add-debtor-button"
              role="button"
              tabIndex={0}
              onClick={(e) => {
                e.stopPropagation();
                this.props.onMissingLinkClick(rowData.matterId);
              }}
            >
              {missingLinkText}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="matter-with-checkbox-wrapper">
        {!defaultMode && (
          <div className="checkbox-container">
            {checkboxCellWrapperRenderer(this.checkboxMatterCellRenderer)({ rowData })}
          </div>
        )}
        <div className="matter-wrapper">
          <div className="matter-link">
            <LinkableText
              className="matter"
              text={rowData.matterSummaryLine}
              onClickLink={() => {
                this.props.onClickLink({ type: 'matter', id: rowData.matterId });
              }}
              asLink
            />
            {rowData.billingConfiguration?.splitBillingSettings?.isEnabled && (
              <span className="split-billing-icon">
                <i
                  title="This is a split billing invoice. Bulk action is disabled. Action from matter."
                  className="icon fa-files-o"
                />
              </span>
            )}
          </div>
          {featureActive('BB-9573') && rowData && rowData.hasUnpaidAD && (
            <div className="info-icon">
              <i
                title={`This invoice contains an anticipated ${t('capitalizeAllWords', {
                  val: 'expense',
                })} that has not yet been paid to the supplier.`}
                className="icon-alert-1"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderDefaultMode() {
    const { matters, mattersLoading, onClickRow, showUnbillable, sort, sortBy, sortDirection } = this.props;
    return (
      <Table
        onRowClick={onClickRow}
        dataLoading={mattersLoading}
        list={matters}
        rowClassName={this.rowStyle}
        sort={sort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          dataKey="UNNEEDED"
          label=""
          width={34}
          headerRenderer={checkboxCellWrapperRenderer(this.checkboxHeaderCellRenderer)}
          cellRenderer={checkboxCellWrapperRenderer(this.checkboxMatterCellRenderer)}
          disableSort
        />
        <Column
          dataKey="matterSummaryLine"
          label="Client & Matter"
          cellRenderer={this.matterOrContactRenderer}
          flexGrow={16}
        />
        <Column
          className="right-align"
          dataKey="totalAmount"
          label="Unbilled"
          cellRenderer={balanceCellLocalisedRenderer}
          flexGrow={1}
          width={100}
        />
        {showUnbillable && (
          <Column
            className="right-align"
            dataKey="unbillableAmount"
            label="Non-Billable"
            cellRenderer={balanceCellLocalisedRenderer}
            flexGrow={1}
            width={100}
          />
        )}
        <Column
          dataKey="lastInvoicedDate"
          className="right-align"
          label="Last Invoiced"
          cellRenderer={yyyymmddLocalisedRenderer}
          flexGrow={1}
          width={100}
        />
      </Table>
    );
  }

  renderGroupByClientMode() {
    const { mattersLoading, clientMatters, onClickRow, showUnbillable, sort, sortBy, sortDirection } = this.props;
    return (
      <Table
        onRowClick={onClickRow}
        dataLoading={mattersLoading}
        list={clientMatters}
        rowClassName={this.rowStyle}
        sort={sort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          dataKey="rowExpander"
          label=""
          width={25}
          cellRenderer={this.expanderCellRenderer}
          disableSort
          className="row-expander"
        />
        <Column
          dataKey="UNNEEDED"
          label=""
          className="check"
          width={34}
          headerRenderer={checkboxCellWrapperRenderer(this.checkboxHeaderCellRenderer)}
          cellRenderer={checkboxCellWrapperRenderer(this.checkboxContactCellRenderer)}
          disableSort
        />
        <Column
          dataKey="matterSummaryLine"
          label="Client & Matter"
          cellRenderer={this.matterOrContactRenderer}
          flexGrow={16}
          className="client-matter-column"
        />
        <Column
          className="right-align"
          dataKey="totalAmount"
          label="Unbilled"
          cellRenderer={CreateInvoicesTable.amountRenderer}
          flexGrow={1}
          width={100}
        />
        {showUnbillable && (
          <Column
            className="right-align"
            dataKey="unbillableAmount"
            label="Non-Billable"
            cellRenderer={CreateInvoicesTable.amountRenderer}
            flexGrow={1}
            width={100}
          />
        )}
        <Column
          dataKey="lastInvoicedDate"
          className="right-align"
          label="Last Invoiced"
          cellRenderer={yyyymmddLocalisedRenderer}
          flexGrow={1}
          width={100}
        />
      </Table>
    );
  }

  render() {
    const { displayMode } = this.props;
    const defaultMode = displayMode === 'default';

    return (
      <div className="create-invoices-table">
        {defaultMode ? this.renderDefaultMode() : this.renderGroupByClientMode()}
      </div>
    );
  }
}

CreateInvoicesTable.propTypes = {
  /**
   * Data to be rendered in the table for 'groupByClient' mode
   */
  clientMatters: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        clientId: PropTypes.string.isRequired,
        displayName: PropTypes.string,
      }),
      PropTypes.shape({
        matterId: PropTypes.string.isRequired,
        matterSummaryLine: PropTypes.string.isRequired,
        totalAmount: PropTypes.number.isRequired,
        hasDebtor: PropTypes.bool.isRequired,
        hasUnpaidAD: PropTypes.bool.isRequired,
        unbillableAmount: PropTypes.number,
        lastInvoicedDate: PropTypes.number,
      }),
    ]),
  ).isRequired,

  /**
   * Indicate if matters are loading in LOD mode
   */
  mattersLoading: PropTypes.bool,

  /**
   * Data to be rendered in the table for 'default' mode
   */
  matters: PropTypes.arrayOf(
    PropTypes.shape({
      matterId: PropTypes.string.isRequired,
      matterSummaryLine: PropTypes.string.isRequired,
      hasDebtor: PropTypes.bool.isRequired,
      hasUnpaidAD: PropTypes.bool.isRequired,
      totalAmount: PropTypes.number.isRequired,
      unbillableAmount: PropTypes.number,
      lastInvoicedDate: PropTypes.number,
    }),
  ).isRequired,

  /**
   * A list of key - value pairs to provide information of the contacts that are expanded.
   */
  expandedContacts: PropTypes.object.isRequired,

  /**
   * Type of the list view
   */
  displayMode: PropTypes.oneOf(['default', 'groupByClient']),

  /**
   * A list of matters that are selected
   */
  selectedMatters: PropTypes.object,

  /**
   * Current selected matter
   */
  selectedMatter: PropTypes.string,

  /**
   * If true show non billable column
   */
  showUnbillable: PropTypes.bool,

  /**
   * Matter link click handler
   */
  onClickRow: PropTypes.func,

  /**
   * Handler to invoke when user clicks on contact / matter link
   */
  onClickLink: PropTypes.func,

  /**
   * Handler to invoke when user clicks on missing info link
   */
  onMissingLinkClick: PropTypes.func,

  /**
   *  Handler to toggle the expand on a contact
   */
  onCollapseToggle: PropTypes.func.isRequired,

  /**
   * Handler for new toggle selection with LOD
   */
  onToggleSelectMatters: PropTypes.func,

  sort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
};

CreateInvoicesTable.defaultProps = {
  displayMode: 'default',
  mattersLoading: false,
  selectedMatters: {},
  selectedMatter: undefined,
  showUnbillable: true,
  onToggleSelectMatters: () => {},
  onMissingLinkClick: () => {},
  onClickRow: () => {},
  onClickLink: () => {},
  sort: undefined,
  sortBy: undefined,
  sortDirection: undefined,
};

CreateInvoicesTable.displayName = 'CreateInvoicesTable';

export default CreateInvoicesTable;
