/**
 * Wait for specified milliseconds
 * @param {number} ms 
 * @returns
 */
function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

module.exports = {
  wait,
};
