import { gql } from '@apollo/client';
import { feeEntityFragment } from '../fragments/feeEntityFragment.graphql';

const query = gql`
  query DraftInvoiceFees($matterId: ID!, $includeNonBillableItems: Boolean!, $offset: Int, $limit: Int) {
    unbilledFees(
      matterIds: [$matterId]
      filter: { includeNonBillableItems: $includeNonBillableItems }
      offset: $offset
      limit: $limit
    ) {
      ...feeEntityFragment
    }
  }

  ${feeEntityFragment}
`;

const notificationIds = ['FeesNotifications.AccountFeesUpdated', 'InvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DraftInvoiceFees = {
  query,
  notificationIds,
};
