/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forms2PropTypes, FormLabel, StatelessSelect } from '@sb-itops/react';
import { ledesTimekeeperClassificationTypeOptions } from '@sb-billing/business-logic/ledes';
import { featureActive } from '@sb-itops/feature';

import { includeErrorClassnameIfInvalid } from '../include-error-classname-if-invalid';
import styles from './MatterUTBMS.module.scss';

export const MatterUTBMS = ({
  // form state
  formInitialised,
  formDisabled,
  // formSubmitting,
  submitFailed,
  // form fields
  isUtbmsEnabled,
  ledesFirmId,
  ledesMatterId,
  ledesClientMatterId,
  ledesTimekeeperClassificationType,
  splitBillingEnabled,
  // callbacks
  toggleEnableUtbms,
  onFieldValuesUpdated,
}) => {
  if (!formInitialised) {
    return null;
  }

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      <div className="row">
        <div
          className={classnames('col-sm-12', isUtbmsEnabled && isUtbmsEnabled.value && 'form-group')}
          title={
            splitBillingEnabled.value === true
              ? 'UTBMS can not be turned on, when Split Billing is active for matter.'
              : undefined
          }
        >
          <label>
            <input
              type="checkbox"
              disabled={splitBillingEnabled.value === true}
              className={styles.utbmsCheckbox}
              checked={(isUtbmsEnabled && isUtbmsEnabled.value) || false}
              onChange={(e) => toggleEnableUtbms(e.target.checked)}
            />
            <span className={styles.utbmsCheckboxLabel} disabled={splitBillingEnabled.value === true}>
              Enable UTBMS Billing
            </span>
          </label>
        </div>
      </div>
      {isUtbmsEnabled.value && (
        <>
          <div className="row">
            <div className="col-sm-6 form-group">
              <FormLabel
                label="LEDES Firm ID"
                field={ledesFirmId}
                submitFailed={submitFailed}
                optional={!isUtbmsEnabled.value}
              />
              <div>
                <input
                  className={classnames('form-control', includeErrorClassnameIfInvalid(ledesFirmId, submitFailed))}
                  type="text"
                  disabled={formDisabled}
                  value={(ledesFirmId && ledesFirmId.value) || ''}
                  maxLength={256}
                  onChange={(e) => onFieldValuesUpdated({ ledesFirmId: e.target.value })}
                />
              </div>
            </div>
            <div className="col-sm-6 form-group">
              <FormLabel
                label="LEDES Matter ID"
                field={ledesMatterId}
                submitFailed={submitFailed}
                optional={!isUtbmsEnabled.value}
              />
              <div>
                <input
                  className={classnames('form-control', includeErrorClassnameIfInvalid(ledesMatterId, submitFailed))}
                  type="text"
                  disabled={formDisabled}
                  value={(ledesMatterId && ledesMatterId.value) || ''}
                  maxLength={256}
                  onChange={(e) => onFieldValuesUpdated({ ledesMatterId: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <FormLabel
                label="LEDES Client Matter Id"
                field={ledesClientMatterId}
                submitFailed={submitFailed}
                optional
              />
              <div>
                <input
                  className="autosave-input form-control show-on-focus"
                  type="text"
                  disabled={formDisabled}
                  value={(ledesClientMatterId && ledesClientMatterId.value) || ''}
                  maxLength={256}
                  onChange={(e) => onFieldValuesUpdated({ ledesClientMatterId: e.target.value })}
                />
              </div>
            </div>
            {featureActive('BB-9195') && (
              <div className="col-sm-6 form-group">
                <FormLabel
                  label="Timekeeper classifications"
                  field={ledesTimekeeperClassificationType}
                  submitFailed={submitFailed}
                  optional
                />
                <div>
                  <StatelessSelect
                    options={ledesTimekeeperClassificationTypeOptions}
                    selectedOption={ledesTimekeeperClassificationType && ledesTimekeeperClassificationType.value}
                    onChange={(newOption) => {
                      onFieldValuesUpdated({ ledesTimekeeperClassificationType: newOption.value });
                    }}
                    disabled={formDisabled}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </fieldset>
  );
};

const { Forms2Field } = forms2PropTypes;

MatterUTBMS.propTypes = {
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form fields
  isUtbmsEnabled: Forms2Field,
  ledesFirmId: Forms2Field,
  ledesMatterId: Forms2Field,
  ledesClientMatterId: Forms2Field,
  ledesTimekeeperClassificationType: Forms2Field,
  splitBillingEnabled: Forms2Field,
  // callbacks
  toggleEnableUtbms: PropTypes.func.isRequired,
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
};

MatterUTBMS.defaultProps = {
  // form state
  formDisabled: undefined,
  formSubmitting: undefined,
  submitFailed: undefined,
  // form fields
  isUtbmsEnabled: false,
  ledesFirmId: undefined,
  ledesMatterId: undefined,
  ledesClientMatterId: undefined,
  ledesTimekeeperClassificationType: undefined,
  splitBillingEnabled: undefined,
};

MatterUTBMS.displayName = 'MatterUTBMS';
