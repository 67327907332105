/* eslint-disable func-names */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { Table, Column, utils } from '@sb-itops/react/table';
import styles from './ControlledMoneyLedgerTransactionTable.module.scss';
import { InterpolatedDescription } from '../interpolated-description';

const {
  amountCellLocalisedRenderer,
  balanceCellLocalisedRenderer,
  timestampLocalisedRenderer,
  yyyymmddLocalisedRenderer,
} = utils;

const systemDateRenderer = ({ rowData, cellData }) => {
  if (rowData.type.toLowerCase() === 'transfer') {
    return null;
  }

  return timestampLocalisedRenderer({ cellData });
};

const contextMenuCellRenderer =
  ({ onReceiptLink, onVendorProofOfPaymentLink }) =>
  ({ rowData }) => {
    const { id, paymentId, isVendorPayment, hasReceipt } = rowData;

    return (
      (hasReceipt || isVendorPayment) && (
        <ContextMenu
          body={() => (
            <div className="context-menu-body list-group">
              {hasReceipt && (
                <button type="button" className="list-group-item" onClick={() => onReceiptLink({ transactionId: id })}>
                  Print Receipt
                </button>
              )}
              {isVendorPayment && (
                <button
                  type="button"
                  className="list-group-item"
                  onClick={() => onVendorProofOfPaymentLink({ transactionId: id, paymentId })}
                >
                  Print Detail
                </button>
              )}
            </div>
          )}
        >
          <div className="context-menu-cell">...</div>
        </ContextMenu>
      )
    );
  };

const contactCellRenderer =
  ({ onClickLink }) =>
  ({ rowData }) => {
    if (!rowData.contactId && !rowData.contactIds) {
      return <span />;
    }

    const contactIds = rowData.contactIds || [rowData.contactId];
    const tooltip =
      rowData.contactIds && // If multiple contacts process the tooltip, otherwise use default
      contactIds.map((contactId) => getContactDisplay(contactId, { showLastNameFirst: true })).join(' | ');

    return (
      <div className={styles.invoiceDebtors}>
        <span className={styles.debtorsDisplay}>
          {contactIds
            .map((contactId) => (
              <ContactDisplay
                key={contactId}
                contactId={contactId}
                onClickLink={onClickLink}
                asLink
                inline
                showLastNameFirst
                tooltip={tooltip}
              />
            ))
            .reduce((acc, elem) => {
              if (acc === null) {
                return [elem];
              }
              return [...acc, ' | ', elem];
            }, null)}
        </span>
        {contactIds.length > 1 && (
          <span className={styles.multiDebtorsIcon}>
            <i title={tooltip} className={classnames('icon', 'icon-contacts-1')} />
          </span>
        )}
      </div>
    );
  };

const descriptionCellRenderer =
  ({ onClickLink }) =>
  ({ rowData, cellData }) => (
    <InterpolatedDescription description={cellData} deleted={rowData.isHidden} onClickLink={onClickLink} />
  );

const ControlledMoneyLedgerTransactionTable = ({
  modal,
  transactions,
  sortBy,
  sortDirection,
  summary,
  onSort,
  showEnteredDateColumn,
  showHidden,
  onClickLink,
  onReceiptLink, // from withLinkHandler
  onVendorProofOfPaymentLink, // from withLinkHandler
}) => (
  <Table
    onRowClick={({ rowData }) => modal({ rowData, showHidden })}
    list={transactions}
    sort={onSort}
    sortBy={sortBy}
    sortDirection={sortDirection}
    summary={summary}
    showFooter
  >
    <Column
      className="date effective-date"
      dataKey="effectiveDate"
      label="Transaction"
      cellRenderer={yyyymmddLocalisedRenderer}
      flexGrow={2}
    />
    {showEnteredDateColumn && (
      <Column className="date" dataKey="timestamp" label="Entered" cellRenderer={systemDateRenderer} width={100} />
    )}
    <Column
      dataKey="description"
      label="Description"
      cellRenderer={descriptionCellRenderer({ onClickLink })}
      flexGrow={3}
      disableSort
    />
    <Column dataKey="reference" label="Reference" flexGrow={2} />
    <Column
      dataKey="contact"
      label="Contact(s)"
      cellRenderer={contactCellRenderer({ onClickLink })}
      flexGrow={4}
      disableSort
    />
    <Column
      className="right-align debit"
      dataKey="debit"
      label="Debit"
      cellRenderer={amountCellLocalisedRenderer}
      width={150}
      footerRenderer={balanceCellLocalisedRenderer}
      disableSort
    />
    <Column
      className="right-align credit"
      dataKey="credit"
      label="Credit"
      cellRenderer={amountCellLocalisedRenderer}
      width={150}
      footerRenderer={balanceCellLocalisedRenderer}
      disableSort
    />
    <Column
      className="right-align balance"
      dataKey="balance"
      label="Balance"
      cellRenderer={balanceCellLocalisedRenderer}
      width={150}
      footerRenderer={balanceCellLocalisedRenderer}
      disableSort
    />
    <Column
      dataKey=""
      label=""
      cellRenderer={contextMenuCellRenderer({ onReceiptLink, onVendorProofOfPaymentLink })}
      width={40}
      disableSort
    />
  </Table>
);

ControlledMoneyLedgerTransactionTable.propTypes = {
  printCheque: PropTypes.func,
  onReceiptLink: PropTypes.func,
  onVendorProofOfPaymentLink: PropTypes.func,
  onClickLink: PropTypes.func,
  modal: PropTypes.func,
  transactions: PropTypes.array,
  showHidden: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  summary: PropTypes.object,
  showEnteredDateColumn: PropTypes.bool,
};

ControlledMoneyLedgerTransactionTable.defaultProps = {
  printCheque: undefined,
  onReceiptLink: undefined,
  onVendorProofOfPaymentLink: undefined,
  onClickLink: undefined,
  modal: undefined,
  transactions: undefined,
  sortBy: undefined,
  sortDirection: undefined,
  summary: undefined,
  showEnteredDateColumn: false,
  showHidden: false,
};

export default ControlledMoneyLedgerTransactionTable;
