import { gql } from '@apollo/client';

const query = gql`
  query MatterTypesById($ids: [ID]) {
    matterTypesById(ids: $ids) {
      matterTypeId
      matterTypeIdWithoutLocation
      name
      categories
    }
  }
`;

const notificationIds = ['BrowseMattersNotifications.MatterUpdated'];
/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */

export const MatterTypesById = {
  query,
  notificationIds,
};
