import * as React from 'react';
import PropTypes from 'prop-types';
import { MatterDisplay } from '@sb-matter-management/react';
import { StatelessSelect } from '@sb-itops/react';

import Styles from './FinalisedSplitBillingInvoiceHeader.module.scss';

export const FinalisedSplitBillingInvoiceHeader = ({
  debtorId,
  debtorOptions,
  invoiceNumber,
  invoiceNumberSuffix,
  isNewBillingMode,
  matterId,
  onClickLink,
  onChangeDebtor,
}) => (
  <div className={Styles.finalisedSplitBillingInvoiceHeader}>
    {!isNewBillingMode && (
      <span
        className={Styles.invoiceText}
      >{`Invoice #${invoiceNumber}${invoiceNumberSuffix ? `-${invoiceNumberSuffix}` : ''}`}</span>
    )}

    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a className={Styles.matterDisplay} onClick={() => onClickLink({ type: 'matter', id: matterId })}>
      <MatterDisplay matterId={matterId} inline />
    </a>

    <div className={Styles.debtorSelectContainer}>
      <label className={Styles.debtorSelectLabel}>DEBTOR</label>
      <StatelessSelect
        className={Styles.debtorSelect}
        options={debtorOptions}
        selectedOption={debtorId}
        onChange={onChangeDebtor}
      />
    </div>
  </div>
);

FinalisedSplitBillingInvoiceHeader.displayName = 'FinalisedSplitBillingInvoiceHeader';

FinalisedSplitBillingInvoiceHeader.propTypes = {
  debtorId: PropTypes.string.isRequired,
  debtorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  isNewBillingMode: PropTypes.bool,
  invoiceNumber: PropTypes.number.isRequired,
  invoiceNumberSuffix: PropTypes.string.isRequired,
  matterId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onChangeDebtor: PropTypes.func.isRequired,
};

FinalisedSplitBillingInvoiceHeader.defaultProps = {
  isNewBillingMode: undefined,
};
