angular.module('sb.billing.webapp').service('sbAsyncOperationsService', function(
  sbLoggerService, sbUuidService, combineInvoicesOp, combineChequesOp, combineOperatingChequesOp, downloadLedesOp,
  generateReportOp, combineAccountRemindersOp, downloadTransactionDetailsPdfOp,
  exportCorrespondenceHistoryOp, exportExpenseOp, exportFeeOp, exportTrustChequesOp) {
  const svc = this;
  const operations = [];
  const operationsArchive = [];
  const log = sbLoggerService.getLogger('sbAsyncOperationsService');

  const supportedOperations = {
    COMBINE_ACCOUNT_REMINDERS: 'COMBINE_ACCOUNT_REMINDERS',
    COMBINE_INVOICES: 'COMBINE_INVOICES',
    COMBINE_CHEQUES: 'COMBINE_CHEQUES',
    COMBINE_OPERATING_CHEQUES: 'COMBINE_OPERATING_CHEQUES',
    DOWNLOAD_LEDES: 'DOWNLOAD_LEDES',
    GENERATE_REPORT: 'GENERATE_REPORT',
    DOWNLOAD_TRANSACTION_DETAILS_PDF: 'DOWNLOAD_TRANSACTION_DETAILS_PDF',
    EXPORT_CORRESPONDENCE_HISTORY: 'EXPORT_CORRESPONDENCE_HISTORY',
    EXPORT_EXPENSE: 'EXPORT_EXPENSE',
    EXPORT_FEE: 'EXPORT_FEE',
    EXPORT_TRUST_CHEQUES: 'EXPORT_TRUST_CHEQUES',
  };

  const operationFactory = {
    [supportedOperations.COMBINE_ACCOUNT_REMINDERS]: combineAccountRemindersOp,
    [supportedOperations.COMBINE_INVOICES]: combineInvoicesOp,
    [supportedOperations.COMBINE_CHEQUES]: combineChequesOp,
    [supportedOperations.COMBINE_OPERATING_CHEQUES]: combineOperatingChequesOp,
    [supportedOperations.DOWNLOAD_LEDES]: downloadLedesOp,
    [supportedOperations.GENERATE_REPORT]: generateReportOp,
    [supportedOperations.DOWNLOAD_TRANSACTION_DETAILS_PDF]: downloadTransactionDetailsPdfOp,
    [supportedOperations.EXPORT_CORRESPONDENCE_HISTORY]: exportCorrespondenceHistoryOp,
    [supportedOperations.EXPORT_EXPENSE]: exportExpenseOp,
    [supportedOperations.EXPORT_FEE]: exportFeeOp,
    [supportedOperations.EXPORT_TRUST_CHEQUES]: exportTrustChequesOp,
  };

  svc.supportedOperations = Object.freeze(supportedOperations);
  svc.getOperations = getOperations;
  svc.getArchivedOperations = getArchivedOperations;
  svc.getOperationById = getOperationById;
  svc.removeOperation = removeOperation;
  svc.archiveOperation = archiveOperation;
  svc.nbActiveOperations = nbActiveOperations;

  svc.startCombineAccountReminders = (reminders) => startOperation(supportedOperations.COMBINE_ACCOUNT_REMINDERS, reminders); //TODO
  svc.startCombineInvoices = ({ invoiceIds, consolidated, addresseeId }) =>
    startOperation(supportedOperations.COMBINE_INVOICES, { invoiceIds, consolidated, addresseeId });
  svc.startChequeCreation = (chequeIds) => startOperation(supportedOperations.COMBINE_CHEQUES, chequeIds);
  svc.startDownloadLedes = (invoiceIds) => startOperation(supportedOperations.DOWNLOAD_LEDES, invoiceIds);
  svc.startOperatingChequeCreation = (chequeIds) => startOperation(supportedOperations.COMBINE_OPERATING_CHEQUES, chequeIds);
  svc.startGenerateReport = (format, reportData) => startOperation(supportedOperations.GENERATE_REPORT, format, reportData);
  svc.startDownloadTransactionDetailsPdf = (transactionData) => startOperation(supportedOperations.DOWNLOAD_TRANSACTION_DETAILS_PDF, transactionData);
  svc.startExportCorrespondenceHistoryCsv = (correspondenceFilter) => startOperation(supportedOperations.EXPORT_CORRESPONDENCE_HISTORY, correspondenceFilter);
  svc.startExportExpenseCsv = (expenseFilter) => startOperation(supportedOperations.EXPORT_EXPENSE, expenseFilter);
  svc.startExportFeeCsv = (feeFilter) => startOperation(supportedOperations.EXPORT_FEE, feeFilter);
  svc.startExportTrustChequesCsv = (trustChequesFilter) => startOperation(supportedOperations.EXPORT_TRUST_CHEQUES, trustChequesFilter);

  function startOperation (operationType, ...args) {
    const operationStarterFn = _.get(operationFactory, operationType);

    if (!_.isFunction(operationStarterFn)) {
      return log.warn(`Ignoring request to start unregistered operation type: '${operationType}'`);
    }

    const newOperation = {
      operationType,
      id: sbUuidService.get(),
      data: operationStarterFn(...args)
    };

    operations.push(newOperation);
    return newOperation;
  }

  function getOperations() {
    return operations;
  }

  function getArchivedOperations() {
    return operationsArchive;
  }

  function getOperationById(asyncOperationId, searchArchive = true) {
    // First try searching the active operations.
    let operation = _.find(operations, {id: asyncOperationId});

    if (searchArchive && !operation) {
      // Now try the archive.
      operation = _.find(operationsArchive, {id: asyncOperationId});
    }

    return operation;
  }

  function nbActiveOperations(opType) {
    return _.size(_.filter(operations, op => (op.operationType === opType && (op.data && !op.data.isComplete))));
  }

  function archiveOperation(asyncOperationId) {
    const operationToArchive = _.last(_.remove(operations, (operation) => operation.id === asyncOperationId));
    if (operationToArchive) {
      operationToArchive.data.isComplete = true;
      operationsArchive.push(operationToArchive);
    }
  }

  function removeOperation(asyncOperationId) {
    const removedOperation = _.remove(operations, (operation) => operation.id === asyncOperationId);

    if (!removedOperation) {
      return _.remove(operationsArchive, (operation) => operation.id === asyncOperationId);
    }

    return removedOperation;
  }
});
