import React from 'react';
import { getRegion, regionType } from '@sb-itops/region';
import { Pause as LucidePause, Play as LucidePlay } from 'lucide-react';
import * as Styles from './Icons.module.scss';

const region = getRegion();

export const Pause = () => <LucidePause className={Styles.pause} />;
export const Play = () => <LucidePlay className={Styles.play} />;

interface IconType {
  fill?: string;
}

const InvoiceAUUSIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5Z" />
    <path d="M12 5v14M16 7H9.6C9.067 7 8 7.5 8 9.5S9.067 12 9.6 12h4.8c.533 0 1.6.5 1.6 2.5S14.933 17 14.4 17H8" />
  </svg>
);
const InvoiceDottedAUUSIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path strokeDasharray="5 5" d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5Z" />
    <path d="M12 5v14M16 7H9.6C9.067 7 8 7.5 8 9.5S9.067 12 9.6 12h4.8c.533 0 1.6.5 1.6 2.5S14.933 17 14.4 17H8" />
  </svg>
);

const InvoiceDottedUKIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path strokeDasharray="5 5" d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5ZM7 13h6" />
    <path d="M10 18V8.25c0-.862.316-1.689.879-2.298A2.888 2.888 0 0 1 13 5c.796 0 1.559.342 2.121.952.563.61.879 1.436.879 2.298M7 18h9" />
  </svg>
);

const InvoiceUKIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5ZM7 13h6" />
    <path d="M10 18V8.25c0-.862.316-1.689.879-2.298A2.888 2.888 0 0 1 13 5c.796 0 1.559.342 2.121.952.563.61.879 1.436.879 2.298M7 18h9" />
  </svg>
);

export const InvoiceIcon = () => (region === regionType.GB ? <InvoiceUKIcon /> : <InvoiceAUUSIcon />);
export const InvoiceDottedIcon = () => (region === regionType.GB ? <InvoiceDottedUKIcon /> : <InvoiceDottedAUUSIcon />);

export const LeadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 20a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.9a2 2 0 0 1-1.69-.9L9.6 3.9A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h16Z" />
    <path d="M11 8.5V16h4" />
  </svg>
);

// Deprecated
export const MatterIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 20a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.9a2 2 0 0 1-1.69-.9L9.6 3.9A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h16Z" />
    <path d="M8 17v-7h1.23l2.462 6h.616l2.461-6H16v7" />
  </svg>
);

export const MatterIcon2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
    <path d="M448 480L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l128 0c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8l160 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64zM369 241c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 241z" />
  </svg>
);

export const Cross = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#1C2230">
    <path d="M41 39C31.6 29.7 16.4 29.7 7 39S-2.3 63.6 7 73l183 183L7 439c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l183-183L407 473c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-183-183L441 73c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-183 183L41 39z" />
  </svg>
);
export const PlusFF = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="#0265DC" viewBox="0 0 448 512">
    <path d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 160L40 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l160 0 0 160c0 13.3 10.7 24 24 24s24-10.7 24-24l0-160 160 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-160 0 0-160z" />
  </svg>
);
export const Add = ({ onClick }) => (
  <div onClick={onClick} className={Styles.add}>
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="#1C2230" viewBox="0 0 448 512">
      <path d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 160L40 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l160 0 0 160c0 13.3 10.7 24 24 24s24-10.7 24-24l0-160 160 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-160 0 0-160z" />
    </svg>
  </div>
);
export const Remove = ({ onClick }) => (
  <div onClick={onClick} className={Styles.remove}>
    <svg fill="#D31510" width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232l144 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-144 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z" />
    </svg>
  </div>
);
export const FolderIcon = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="#F2DA26" viewBox="0 0 512 512">
    <path d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z" />
  </svg>
);
export const Text = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M48 80l0 48c0 13.3-10.7 24-24 24s-24-10.7-24-24L0 72C0 49.9 17.9 32 40 32l368 0c22.1 0 40 17.9 40 40l0 56c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-48L248 80l0 352 48 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-144 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0 0-352L48 80z" />
  </svg>
);
export const Arrows = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M103 497c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-55-55L424 408c13.3 0 24-10.7 24-24s-10.7-24-24-24L81.9 360l55-55c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 367c-9.4 9.4-9.4 24.6 0 33.9l96 96zM441 145c9.4-9.4 9.4-24.6 0-33.9L345 15c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l55 55L24 104c-13.3 0-24 10.7-24 24s10.7 24 24 24l342.1 0-55 55c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l96-96z" />
  </svg>
);
export const Garbage = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="#D31510" viewBox="0 0 448 512">
    <path d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm63 79c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
  </svg>
);
export const Share = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M288 240l-96 0c-66.2 0-122 44.7-138.8 105.5C49.9 333.1 48 319.3 48 304c0-70.7 57.3-128 128-128l112 0 24 0c13.3 0 24-10.7 24-24l0-24 0-28.1L456.1 208 336 316.1l0-28.1 0-24c0-13.3-10.7-24-24-24l-24 0zm0 48l0 48 0 16c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4s-19 16.6-19 29.2l0 16 0 48-48 0-64 0C78.8 128 0 206.8 0 304c0 78 38.6 126.2 68.7 152.1c4.1 3.5 8.1 6.6 11.7 9.3c3.2 2.4 6.2 4.4 8.9 6.2c4.5 3 8.3 5.1 10.8 6.5c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-6.8-3.6-13.2-8.3-18.1c-.5-.5-.9-.9-1.4-1.4c-2.4-2.3-5.1-5.1-7.7-8.6c-1.7-2.3-3.4-5-5-7.9c-5.3-9.7-9.5-22.9-9.5-40.2c0-53 43-96 96-96l48 0 48 0z" />
  </svg>
);
export const Envelope = ({ fill = '#5E72A1' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
  </svg>
);
export const FileLines = ({ fill = '#5E72A1' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM112 256l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
  </svg>
);
export const FileDoc = ({ fill = '#0265DC' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm16 128l16 0c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96zM456 352l16 0c22.1 0 40 17.9 40 40l0 8c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-8c0-4.4-3.6-8-8-8l-16 0c-4.4 0-8 3.6-8 8l0 80c0 4.4 3.6 8 8 8l16 0c4.4 0 8-3.6 8-8l0-8c0-8.8 7.2-16 16-16s16 7.2 16 16l0 8c0 22.1-17.9 40-40 40l-16 0c-22.1 0-40-17.9-40-40l0-80c0-22.1 17.9-40 40-40zM288 392c0-22.1 17.9-40 40-40l16 0c22.1 0 40 17.9 40 40l0 80c0 22.1-17.9 40-40 40l-16 0c-22.1 0-40-17.9-40-40l0-80zm40-8c-4.4 0-8 3.6-8 8l0 80c0 4.4 3.6 8 8 8l16 0c4.4 0 8-3.6 8-8l0-80c0-4.4-3.6-8-8-8l-16 0z" />
  </svg>
);
export const FileXLS = ({ fill = '#118856' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-176 0c-35.3 0-64 28.7-64 64l0 144-80 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM224 368c0 7.3 2.2 14.4 6.2 20.4l9.8 14.7 9.8-14.7c4-6.1 6.2-13.2 6.2-20.4c0-8.8 7.2-16 16-16s16 7.2 16 16c0 13.6-4 26.9-11.6 38.2L259.2 432l17.2 25.8C284 469.1 288 482.4 288 496c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-7.3-2.2-14.4-6.2-20.4L240 460.8l-9.8 14.7c-4 6.1-6.2 13.2-6.2 20.4c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-13.6 4-26.9 11.6-38.2L220.8 432l-17.2-25.8C196 394.9 192 381.6 192 368c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 128l0-128c0-8.8 7.2-16 16-16s16 7.2 16 16l0 112 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16zm88-98.3c0-25.2 20.4-45.7 45.7-45.7l26.3 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-26.3 0c-7.5 0-13.7 6.1-13.7 13.7c0 5.2 2.9 9.9 7.6 12.2l31.2 15.6c15.5 7.7 25.2 23.5 25.2 40.8c0 25.2-20.4 45.7-45.7 45.7L432 512c-8.8 0-16-7.2-16-16s7.2-16 16-16l26.3 0c7.5 0 13.7-6.1 13.7-13.7c0-5.2-2.9-9.9-7.6-12.2l-31.2-15.6C417.8 430.8 408 415 408 397.7z" />
  </svg>
);
export const FilePPT = ({ fill = '#5E72A1' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM304 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm208-64c0-8.8 7.2-16 16-16l32 0 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-16 0 0 112c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-112-16 0c-8.8 0-16-7.2-16-16z" />
  </svg>
);
export const FileVideo = ({ fill = '#5E72A1' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM64 288c0-17.7 14.3-32 32-32l96 0c17.7 0 32 14.3 32 32l0 96c0 17.7-14.3 32-32 32l-96 0c-17.7 0-32-14.3-32-32l0-96zM300.9 397.9L256 368l0-64 44.9-29.9c2-1.3 4.4-2.1 6.8-2.1c6.8 0 12.3 5.5 12.3 12.3l0 103.4c0 6.8-5.5 12.3-12.3 12.3c-2.4 0-4.8-.7-6.8-2.1z" />
  </svg>
);
export const FileImage = ({ fill = '#5E72A1' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
  </svg>
);
export const EnvelopeRegular = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
  </svg>
);
export const FileLinesRegular = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M64 464c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0z" />
  </svg>
);
export const FileDocRegular = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm16 128l16 0c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96zM456 352l16 0c22.1 0 40 17.9 40 40l0 8c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-8c0-4.4-3.6-8-8-8l-16 0c-4.4 0-8 3.6-8 8l0 80c0 4.4 3.6 8 8 8l16 0c4.4 0 8-3.6 8-8l0-8c0-8.8 7.2-16 16-16s16 7.2 16 16l0 8c0 22.1-17.9 40-40 40l-16 0c-22.1 0-40-17.9-40-40l0-80c0-22.1 17.9-40 40-40zM288 392c0-22.1 17.9-40 40-40l16 0c22.1 0 40 17.9 40 40l0 80c0 22.1-17.9 40-40 40l-16 0c-22.1 0-40-17.9-40-40l0-80zm40-8c-4.4 0-8 3.6-8 8l0 80c0 4.4 3.6 8 8 8l16 0c4.4 0 8-3.6 8-8l0-80c0-4.4-3.6-8-8-8l-16 0z" />
  </svg>
);
export const FileXLSRegular = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M64 464l80 0 0 48-80 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zm160-96c0 7.3 2.2 14.4 6.2 20.4l9.8 14.7 9.8-14.7c4-6.1 6.2-13.2 6.2-20.4c0-8.8 7.2-16 16-16s16 7.2 16 16c0 13.6-4 26.9-11.6 38.2L259.2 432l17.2 25.8C284 469.1 288 482.4 288 496c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-7.3-2.2-14.4-6.2-20.4L240 460.8l-9.8 14.7c-4 6.1-6.2 13.2-6.2 20.4c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-13.6 4-26.9 11.6-38.2L220.8 432l-17.2-25.8C196 394.9 192 381.6 192 368c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 128l0-128c0-8.8 7.2-16 16-16s16 7.2 16 16l0 112 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16zm88-98.3c0-25.2 20.4-45.7 45.7-45.7l26.3 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-26.3 0c-7.5 0-13.7 6.1-13.7 13.7c0 5.2 2.9 9.9 7.6 12.2l31.2 15.6c15.5 7.7 25.2 23.5 25.2 40.8c0 25.2-20.4 45.7-45.7 45.7L432 512c-8.8 0-16-7.2-16-16s7.2-16 16-16l26.3 0c7.5 0 13.7-6.1 13.7-13.7c0-5.2-2.9-9.9-7.6-12.2l-31.2-15.6C417.8 430.8 408 415 408 397.7z" />
  </svg>
);
export const FilePPTRegular = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM304 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm208-64c0-8.8 7.2-16 16-16l32 0 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-16 0 0 112c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-112-16 0c-8.8 0-16-7.2-16-16z" />
  </svg>
);
export const FileVideoRegular = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M320 464c8.8 0 16-7.2 16-16l0-288-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16l256 0zM0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 448c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zM80 288c0-17.7 14.3-32 32-32l96 0c17.7 0 32 14.3 32 32l0 16 44.9-29.9c2-1.3 4.4-2.1 6.8-2.1c6.8 0 12.3 5.5 12.3 12.3l0 103.4c0 6.8-5.5 12.3-12.3 12.3c-2.4 0-4.8-.7-6.8-2.1L240 368l0 16c0 17.7-14.3 32-32 32l-96 0c-17.7 0-32-14.3-32-32l0-96z" />
  </svg>
);
export const FileImageRegular = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M64 464c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm96 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm69.2 46.9c-3-4.3-7.9-6.9-13.2-6.9s-10.2 2.6-13.2 6.9l-41.3 59.7-11.9-19.1c-2.9-4.7-8.1-7.5-13.6-7.5s-10.6 2.8-13.6 7.5l-40 64c-3.1 4.9-3.2 11.1-.4 16.2s8.2 8.2 14 8.2l48 0 32 0 40 0 72 0c6 0 11.4-3.3 14.2-8.6s2.4-11.6-1-16.5l-72-104z" />
  </svg>
);
export const FilePDF = ({ fill = '#D31510' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
  </svg>
);
export const FilePDFRegular = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
  </svg>
);
export const BookmarkFilled = ({ fill }) => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill={fill} viewBox="0 0 384 512">
    <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z" />
  </svg>
);
export const Bookmark = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M0 48C0 21.5 21.5 0 48 0l0 48 0 393.4 130.1-92.9c8.3-6 19.6-6 27.9 0L336 441.4 336 48 48 48 48 0 336 0c26.5 0 48 21.5 48 48l0 440c0 9-5 17.2-13 21.3s-17.6 3.4-24.9-1.8L192 397.5 37.9 507.5c-7.3 5.2-16.9 5.9-24.9 1.8S0 497 0 488L0 48z" />
  </svg>
);
export const Download = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M360 480c13.3 0 24-10.7 24-24s-10.7-24-24-24L24 432c-13.3 0-24 10.7-24 24s10.7 24 24 24l336 0zM174.5 344.4c4.5 4.8 10.9 7.6 17.5 7.6s12.9-2.7 17.5-7.6l128-136c9.1-9.7 8.6-24.8-1-33.9s-24.8-8.6-33.9 1L216 267.5l0-83.5 0-128c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 128 0 83.5L81.5 175.6c-9.1-9.7-24.3-10.1-33.9-1s-10.1 24.3-1 33.9l128 136z" />
  </svg>
);
export const Ellipsis = ({ fill = '#1C2230' }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z" />
  </svg>
);
export const Lock = ({ fill }: IconType) => (
  <svg width="11" height="11" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M144 128l0 64 160 0 0-64c0-44.2-35.8-80-80-80s-80 35.8-80 80zM96 192l0-64C96 57.3 153.3 0 224 0s128 57.3 128 128l0 64 32 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l32 0zM48 256l0 192c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-192c0-8.8-7.2-16-16-16L64 240c-8.8 0-16 7.2-16 16z" />
  </svg>
);
export const ChartMixed = ({ fill }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M500 89c13.8-11 16-31.2 5-45s-31.2-16-45-5L319.4 151.5 211.2 70.4c-11.7-8.8-27.8-8.5-39.2 .6L12 199c-13.8 11-16 31.2-5 45s31.2 16 45 5L192.6 136.5l108.2 81.1c11.7 8.8 27.8 8.5 39.2-.6L500 89zM160 256l0 192c0 17.7 14.3 32 32 32s32-14.3 32-32l0-192c0-17.7-14.3-32-32-32s-32 14.3-32 32zM32 352l0 96c0 17.7 14.3 32 32 32s32-14.3 32-32l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32zm288-64c-17.7 0-32 14.3-32 32l0 128c0 17.7 14.3 32 32 32s32-14.3 32-32l0-128c0-17.7-14.3-32-32-32zm96-32l0 192c0 17.7 14.3 32 32 32s32-14.3 32-32l0-192c0-17.7-14.3-32-32-32s-32 14.3-32 32z" />
  </svg>
);
export const TriangleExclamation = ({ fill }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
  </svg>
);
export const ArrowsSpin = () => (
  <svg width="40" height="40" className={Styles.arrowsSpin} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      className="fa-secondary"
      opacity=".4"
      d="M33.1 255.5c0 11 .8 21.9 2.3 32.5c21.7 0 43.3 0 65 0c-2.2-10.5-3.3-21.3-3.3-32.5c0-39.7 14.5-76 38.4-104l30.5 30.5c10.1 10.1 27.3 2.9 27.3-11.3l0-105.4c0-8.8-7.2-16-16-16L72 49.4c-14.3 0-21.4 17.2-11.3 27.3l29.5 29.5c-35.5 39.6-57 92-57 149.3zm287.8 88l0 105.4c0 8.8 7.2 16 16 16l105.4 0c14.3 0 21.4-17.2 11.3-27.3l-31-31c36.4-39.8 58.6-92.8 58.6-151c0-10.7-.7-21.2-2.2-31.5L414 224c2 10.2 3.1 20.7 3.1 31.5c0 40.5-15.1 77.5-39.9 105.7l-29-29c-10.1-10.1-27.3-2.9-27.3 11.3z"
    />
    <path
      className="fa-primary"
      d="M35.5 288s0 0 0 0l65 0s0 0 0 0l-65 0zM257.1 95.5c-11.4 0-22.4 1.2-33.1 3.4l0-65c10.8-1.6 21.9-2.4 33.1-2.4c58.2 0 111.2 22.2 151 58.6l29.4-29.4c10.1-10.1 27.3-2.9 27.3 11.3l0 105.4c0 8.8-7.2 16-16 16l-105.4 0c-14.3 0-21.4-17.2-11.3-27.3l30.6-30.6c-28.2-24.8-65.2-39.9-105.7-39.9zM182.1 348.2l-28.9 28.9c28 23.9 64.3 38.4 104 38.4c10.6 0 20.9-1 30.9-3l0 64.9c-10.1 1.4-20.4 2.1-30.9 2.1c-57.4 0-109.7-21.6-149.3-57L76.7 453.6c-10.1 10.1-27.3 2.9-27.3-11.3l0-105.4c0-8.8 7.2-16 16-16l105.4 0c14.3 0 21.4 17.2 11.3 27.3z"
    />
  </svg>
);

export const CircleInfo = ({ fill }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
  </svg>
);
export const ListTimeline = ({ fill }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M219.9 112L400 112l0-32L219.9 80l-16 16 16 16zm-72.6-4.7c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4L416 32c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-202.7 0c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3zM64 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm0 160a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM32 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm187.9 16L400 432l0-32-180.1 0-16 16 16 16zm-72.6-4.7c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4L416 352c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-202.7 0c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3zM203.9 256l16 16L464 272l0-32-244.1 0-16 16zm-13.3 54.6l-43.3-43.3c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4L480 192c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-266.7 0c-8.5 0-16.6-3.4-22.6-9.4z" />
  </svg>
);
export const ArrowUp = ({ fill }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M209.4 39.4C204.8 34.7 198.6 32 192 32s-12.8 2.7-17.4 7.4l-168 176c-9.2 9.6-8.8 24.8 .8 33.9s24.8 8.8 33.9-.8L168 115.9 168 456c0 13.3 10.7 24 24 24s24-10.7 24-24l0-340.1L342.6 248.6c9.2 9.6 24.3 9.9 33.9 .8s9.9-24.3 .8-33.9l-168-176z" />
  </svg>
);
export const ArrowDown = ({ fill }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M174.6 472.6c4.5 4.7 10.8 7.4 17.4 7.4s12.8-2.7 17.4-7.4l168-176c9.2-9.6 8.8-24.8-.8-33.9s-24.8-8.8-33.9 .8L216 396.1 216 56c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 340.1L41.4 263.4c-9.2-9.6-24.3-9.9-33.9-.8s-9.9 24.3-.8 33.9l168 176z" />
  </svg>
);
export const Filters = ({ fill }: IconType) => (
  <svg width="16" height="16" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M0 88C0 74.7 10.7 64 24 64l400 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L24 112C10.7 112 0 101.3 0 88zM64 248c0-13.3 10.7-24 24-24l272 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L88 272c-13.3 0-24-10.7-24-24zM288 408c0 13.3-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l80 0c13.3 0 24 10.7 24 24z" />
  </svg>
);
export const ConnectionIcon = () => (
  <svg
    width="108px"
    height="108px"
    viewBox="0 0 108 108"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop" transform="translate(-458.000000, -173.000000)">
        <g id="icon-wifi" transform="translate(458.000000, 173.000000)">
          <circle id="Oval" fill="#4A90E2" cx="54" cy="54" r="54" />
          <path
            d="M40.6602,50.8594 C48.4642,43.0534 61.1242,43.0414 68.9442,50.8594"
            id="Stroke-4849"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M74.5996,43.2032 C63.6716,32.2752 45.9496,32.2552 34.9996,43.2032"
            id="Stroke-4850"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.3144,58.5136 C51.0004,53.8316 58.5964,53.8236 63.2864,58.5136"
            id="Stroke-4851"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M68.9434,50.8594 C61.1394,43.0534 48.4774,43.0414 40.6594,50.8594"
            id="Stroke-4852"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M63.2872,58.5136 C58.6012,53.8316 51.0072,53.8236 46.3152,58.5136"
            id="Stroke-4853"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M58.8008,69 C58.8008,71.208 57.0088,73 54.8008,73 C52.5928,73 50.8008,71.208 50.8008,69 C50.8008,66.792 52.5928,65 54.8008,65 C57.0088,65 58.8008,66.792 58.8008,69 L58.8008,69 Z"
            id="Stroke-4854"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const OutlookLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="86" height="80" viewBox="0 0 86 80" fill="none">
    <g clipPath="url(#clip0_8017_34134)">
      <path
        d="M86.0002 42C86.0049 41.3275 85.6575 40.7017 85.0842 40.35H85.0742L85.0382 40.33L55.2382 22.69C55.1095 22.6031 54.976 22.5236 54.8382 22.452C53.6876 21.8584 52.3208 21.8584 51.1702 22.452C51.0324 22.5237 50.8989 22.6031 50.7702 22.69L20.9703 40.33L20.9343 40.35C20.0235 40.9164 19.7443 42.1139 20.3107 43.0247C20.4776 43.293 20.7074 43.5166 20.9803 43.676L50.7803 61.316C50.9094 61.4022 51.0429 61.4816 51.1803 61.554C52.3309 62.1476 53.6977 62.1476 54.8483 61.554C54.9857 61.4816 55.1192 61.4022 55.2483 61.316L85.0483 43.676C85.6436 43.3289 86.007 42.6891 86.0002 42Z"
        fill="#0A2767"
      />
      <path
        d="M24.444 30.222H44V48.1479H24.444V30.222ZM82 12V3.79998C82.0469 1.74979 80.4241 0.0491272 78.374 0H27.626C25.5758 0.0491272 23.9531 1.74979 24 3.79998V12L54 20L82 12Z"
        fill="#0364B8"
      />
      <path d="M24 12H44V30H24V12Z" fill="#0078D4" />
      <path d="M64 12H44V30L64 48H82V30L64 12Z" fill="#28A8EA" />
      <path d="M44 30H64V48H44V30Z" fill="#0078D4" />
      <path d="M44 48H64V66H44V48Z" fill="#0364B8" />
      <path d="M24.4443 48.1479H44.0003V64.4439H24.4443V48.1479Z" fill="#14447D" />
      <path d="M64 48H82V66H64V48Z" fill="#0078D4" />
      <path
        d="M85.0839 43.566L85.0459 43.586L55.246 60.346C55.116 60.426 54.984 60.502 54.8459 60.57C54.3398 60.811 53.7901 60.9471 53.23 60.97L51.602 60.018C51.4644 59.9489 51.3308 59.8721 51.2019 59.788L21.002 42.552H20.988L20 42V75.928C20.0154 78.1916 21.8624 80.0144 24.126 80H81.9379C81.9719 80 82.0019 79.984 82.0379 79.984C82.516 79.9534 82.9874 79.8551 83.4379 79.692C83.6326 79.6095 83.8205 79.5119 83.9999 79.4C84.1339 79.324 84.3639 79.158 84.3639 79.158C85.3884 78.3999 85.9949 77.2026 85.9999 75.928V42C85.9992 42.6489 85.6492 43.2473 85.0839 43.566Z"
        fill="url(#paint0_linear_8017_34134)"
      />
      <path
        opacity="0.5"
        d="M84.3999 41.866V43.946L53.24 65.4L20.98 42.566C20.98 42.555 20.971 42.546 20.96 42.546L18 40.766V39.266L19.22 39.246L21.8 40.726L21.86 40.746L22.0799 40.886C22.0799 40.886 52.3999 58.186 52.4799 58.226L53.6399 58.9059C53.7399 58.8659 53.8399 58.826 53.9599 58.7859C54.0199 58.7459 84.0599 41.846 84.0599 41.846L84.3999 41.866Z"
        fill="#0A2767"
      />
      <path
        d="M85.0839 43.566L85.0459 43.588L55.2459 60.348C55.1159 60.4279 54.9839 60.5039 54.8459 60.5719C53.6886 61.1373 52.3352 61.1373 51.1779 60.5719C51.0408 60.5041 50.9074 60.4293 50.7779 60.348L20.978 43.588L20.942 43.566C20.3667 43.2541 20.006 42.6544 20 42V75.928C20.0143 78.1912 21.8606 80.0143 24.1238 80C24.1238 80 24.1239 80 24.124 80H81.8759C84.1392 80.0144 85.9855 78.1914 85.9999 75.9282C85.9999 75.9282 85.9999 75.9281 85.9999 75.928V42C85.9992 42.6489 85.6492 43.2473 85.0839 43.566Z"
        fill="#1490DF"
      />
      <path
        opacity="0.1"
        d="M55.6797 60.1L55.2337 60.35C55.1045 60.4323 54.9709 60.5077 54.8337 60.576C54.3423 60.8172 53.808 60.9586 53.2617 60.992L64.5997 74.4L84.3777 79.166C84.9197 78.7566 85.3507 78.2183 85.6317 77.6L55.6797 60.1Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M57.6997 58.964L55.2337 60.35C55.1045 60.4323 54.9709 60.5077 54.8337 60.576C54.3423 60.8172 53.808 60.9586 53.2617 60.992L58.5737 75.638L84.3837 79.1599C85.4005 78.3966 85.9991 77.1994 85.9997 75.9279V75.4899L57.6997 58.964Z"
        fill="black"
      />
      <path
        d="M24.18 79.9999H81.8699C82.7576 80.0045 83.6234 79.7241 84.3399 79.2L51.5999 60.022C51.4624 59.953 51.3288 59.8762 51.1999 59.792L21 42.556H20.986L20 42V75.812C19.9977 78.1227 21.8692 79.9977 24.18 79.9999Z"
        fill="#28A8EA"
      />
      <path
        opacity="0.1"
        d="M48 19.666V62.326C47.9964 63.8217 47.087 65.1661 45.7 65.726C45.2703 65.9106 44.8076 66.0059 44.34 66.006H20V18H24V16H44.34C46.3605 16.0077 47.9956 17.6455 48 19.666Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M46 21.6659V64.3258C46.005 64.809 45.9026 65.2872 45.7 65.7258C45.1446 67.0949 43.8174 67.993 42.34 67.9998H20V17.9999H42.34C42.9203 17.9941 43.4922 18.1389 44 18.4199C45.226 19.0376 45.9995 20.2931 46 21.6659Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M46 21.6659V60.3259C45.9902 62.3455 44.3596 63.9824 42.34 63.9999H20V17.9999H42.34C42.9203 17.9941 43.4922 18.1389 44 18.4199C45.226 19.0376 45.9995 20.2931 46 21.6659Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M44 21.666V60.326C43.9978 62.3487 42.3627 63.9901 40.34 63.9999H20V18H40.34C42.3624 18.0011 44.0011 19.6415 43.9999 21.664C44 21.6647 44 21.6653 44 21.666Z"
        fill="black"
      />
      <path
        d="M3.66598 18H40.334C42.3586 18 43.9999 19.6413 43.9999 21.666V58.334C43.9999 60.3586 42.3586 61.9999 40.334 61.9999H3.66598C1.6413 61.9999 0 60.3586 0 58.334V21.666C0 19.6413 1.64134 18 3.66598 18Z"
        fill="url(#paint1_linear_8017_34134)"
      />
      <path
        d="M11.4576 33.376C12.3611 31.4509 13.8195 29.8399 15.6456 28.75C17.6678 27.5923 19.9703 27.0151 22.2996 27.082C24.4584 27.0352 26.5886 27.5824 28.4576 28.664C30.2148 29.7119 31.6301 31.2473 32.5316 33.084C33.5134 35.1078 34.0027 37.335 33.9596 39.584C34.0072 41.9343 33.5037 44.2632 32.4896 46.384C31.5666 48.2862 30.1064 49.8764 28.2896 50.9579C26.3486 52.0727 24.1393 52.6343 21.9016 52.582C19.6967 52.6352 17.5195 52.0817 15.6076 50.9819C13.8352 49.9327 12.4022 48.3955 11.4796 46.5539C10.492 44.5594 9.99687 42.3572 10.0356 40.1319C9.99447 37.8016 10.4806 35.4921 11.4576 33.376ZM15.9216 44.236C16.4033 45.453 17.2202 46.5085 18.2776 47.28C19.3545 48.0327 20.6441 48.421 21.9575 48.388C23.3564 48.4433 24.7352 48.0417 25.8856 47.244C26.9295 46.4749 27.725 45.4165 28.1735 44.2C28.675 42.8413 28.9225 41.4021 28.9035 39.954C28.919 38.492 28.6864 37.0381 28.2155 35.654C27.7996 34.4043 27.0298 33.3024 25.9995 32.482C24.878 31.6465 23.5029 31.2227 22.1055 31.282C20.7636 31.2473 19.4452 31.6386 18.3396 32.4C17.2644 33.1748 16.4319 34.2396 15.9396 35.47C14.8474 38.2903 14.8417 41.4157 15.9235 44.24L15.9216 44.236Z"
        fill="white"
      />
      <path d="M64 12H82V30H64V12Z" fill="#50D9FF" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_8017_34134" x1="53" y1="42" x2="53" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor="#35B8F1" />
        <stop offset="1" stopColor="#28A8EA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8017_34134"
        x1="7.64368"
        y1="15.1355"
        x2="36.3563"
        y2="64.8645"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1784D9" />
        <stop offset="0.5" stopColor="#107AD5" />
        <stop offset="1" stopColor="#0A63C9" />
      </linearGradient>
      <clipPath id="clip0_8017_34134">
        <rect width="86" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
